import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import type { ReactNode } from "react";
import { useNavigate } from "react-router-dom";

import { AuthenticationContext } from "../../context";

export const AuthenticationProvider = ({
  children,
  auth0Domain,
  auth0ClientId,
  auth0Audience,
}: {
  children?: ReactNode;
  auth0Domain: string;
  auth0ClientId: string;
  auth0Audience: string;
}) => {
  const navigate = useNavigate();

  return (
    <Auth0Provider
      domain={auth0Domain}
      clientId={auth0ClientId}
      redirectUri={window.location.origin}
      audience={auth0Audience}
      onRedirectCallback={(appState) => {
        // if the user is trying to navigate to a specific page,
        // route them to back to it after logging in,
        // otherwise, route them to the root of the app
        if (appState?.returnTo && appState.returnTo !== "/") {
          navigate(appState.returnTo);
        } else {
          navigate("/");
        }
      }}
      useRefreshTokens
    >
      <Auth0AuthenticationProvider>{children}</Auth0AuthenticationProvider>
    </Auth0Provider>
  );
};

// This might look unnecessary, but doing this allows to easily mock the Auth0 client
// in apps that consume this repo without worrying about module mocks, module hoisting issues,
// or the differences between jest, vitest, ava, etc.
function Auth0AuthenticationProvider({ children }: { children: ReactNode }) {
  const auth0Context = useAuth0();

  return (
    <AuthenticationContext.Provider value={auth0Context}>
      {children}
    </AuthenticationContext.Provider>
  );
}
