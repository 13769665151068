import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input as ChakraInput,
  Tooltip,
  VisuallyHidden,
} from "@chakra-ui/react";
import type {
  FormControlProps,
  InputProps as ChakraInputProps,
} from "@chakra-ui/react";
import { useController } from "react-hook-form";
import type {
  FieldPath,
  FieldValues,
  UseControllerProps,
} from "react-hook-form";
import get from "lodash/get.js";
import { faCircleInfo } from "@fortawesome/pro-regular-svg-icons";

import { ConditionalWrapper } from "../ConditionalWrapper";
import { FaIcon } from "../FaIcon";

import { getFieldErrorMessage } from "./utils";

export function RHFInput<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  label,
  hideLabel = false,
  isRequired,
  isDisabled,
  // Controller props
  name,
  rules,
  shouldUnregister,
  defaultValue,
  helperText,
  tooltipText,
  control,
  ...inputProps
}: UseControllerProps<TFieldValues, TName> &
  FormControlProps &
  ChakraInputProps & {
    label: string;
    helperText?: string;
    tooltipText?: string;
    hideLabel?: boolean;
    readOnly?: boolean;
  }) {
  const { field, fieldState, formState } = useController({
    defaultValue: defaultValue ?? ("" as any), // This is to prevent silly jest errors regarding controlled switching to uncontrolled errors
    name,
    rules,
    shouldUnregister,
    control,
  });

  const error = get(formState.errors, field.name.split("."));
  const canShowHelperText = !error && !!helperText;

  const errorMessage = getFieldErrorMessage(formState, field.name);

  // Note: This approach relies on _not_ validating on blur. If you were to desire that to be the behavior,
  // we'd most likely want to check for `touched` on `isInvalid` as well.
  return (
    <FormControl
      isRequired={isRequired}
      isDisabled={isDisabled}
      isInvalid={fieldState.invalid}
      id={field.name}
    >
      <Flex alignItems="center">
        <ConditionalWrapper
          condition={hideLabel}
          wrapper={(children) => <VisuallyHidden>{children}</VisuallyHidden>}
        >
          <FormLabel htmlFor={field.name} marginInlineEnd={2}>
            {label}
          </FormLabel>
        </ConditionalWrapper>

        {tooltipText && (
          <Tooltip label={tooltipText} placement="top" borderRadius="sm">
            <Box>
              <FaIcon
                icon={faCircleInfo}
                pb={1}
                data-testid="tooltip-trigger"
              />
            </Box>
          </Tooltip>
        )}
      </Flex>

      <ChakraInput {...field} {...inputProps} />

      {canShowHelperText && <FormHelperText>{helperText}</FormHelperText>}

      {errorMessage ? (
        <FormErrorMessage>{errorMessage}</FormErrorMessage>
      ) : null}
    </FormControl>
  );
}
