import type { ExceptionTitle } from "../../hooks/queries/useOrderDetails";

export const exceptionLabels: Record<ExceptionTitle, string> = {
  automation_hold: "Automation hold",
  invalid_address: "Invalid address",
  incomplete_address: "Incomplete address",
  invalid_channel: "Invalid channel",
  invalid_facilities: "Invalid facilities",
  invalid_sku: "Invalid SKU",
  invalid_unit: "Invalid unit",
  invalid_tags: "Invalid tags",
  invalid_shipment_type: "Invalid shipment type",
  invalid_shelf_life_requirements: "Shelf life requirements",
  fulfillment_consolidation_hold: "Fulfillment consolidation hold",
  no_supplier: "No supplier",
};
