import {
  Box,
  Flex,
  StatHelpText,
  Text,
  Tooltip,
  Button,
  chakra,
  MenuButton,
  Portal,
  MenuList,
  MenuOptionGroup,
  MenuItemOption,
  Menu,
} from "@chakra-ui/react";
import {
  faAngleDown,
  faCheck,
  faCircleInfo,
} from "@fortawesome/pro-regular-svg-icons";
import { useMemo, useState } from "react";
import { transparentize } from "@chakra-ui/theme-tools";

import {
  Card,
  FaIcon,
  useSearchParams,
  qs,
  useDatadogRUM,
} from "@stordco/fe-components";

import { useNetworkId } from "../../../../components/Network";
import {
  getFacilityDisplayName,
  useTradeConnections,
} from "../../../../hooks/queries/useTradeConnections";
import { formatNumber } from "../../../../lib/numbers";
import { useFormatInPreferredTimezone } from "../../../../hooks";
import { safePercent } from "../../../../utils/safe-percent";

import { OrdersMap } from "./OrdersMap";
import { InternationalOrdersIndicator } from "./InternationalOrdersIndicator";
import { StatButtonLink } from "../../StatButtonLink";
import { useWidgetData } from "./use-widget-data";
import { WidgetErrorFetchingData } from "../../WidgetErrorFetchingData";
import { LoadingOverlay } from "../../LoadingOverlay";

const stateFipsCodesByAbbrev: Record<string, string> = {
  AL: "01",
  NE: "31",
  AK: "02",
  NV: "32",
  AZ: "04",
  NH: "33",
  AR: "05",
  NJ: "34",
  CA: "06",
  NM: "35",
  CO: "08",
  NY: "36",
  CT: "09",
  NC: "37",
  DE: "10",
  ND: "38",
  DC: "11",
  OH: "39",
  FL: "12",
  OK: "40",
  GA: "13",
  OR: "41",
  HI: "15",
  PA: "42",
  ID: "16",
  PR: "72",
  IL: "17",
  RI: "44",
  IN: "18",
  SC: "45",
  IA: "19",
  SD: "46",
  KS: "20",
  TN: "47",
  KY: "21",
  TX: "48",
  LA: "22",
  UT: "49",
  ME: "23",
  VT: "50",
  MD: "24",
  VA: "51",
  MA: "25",
  VI: "78",
  MI: "26",
  WA: "53",
  MN: "27",
  WV: "54",
  MS: "28",
  WI: "55",
  MO: "29",
  WY: "56",
  MT: "30",
};

export function OriginsAndDestinations() {
  const [mapContainerEl, setMapContainerEl] = useState<HTMLDivElement | null>(
    null,
  );

  const networkId = useNetworkId();
  const format = useFormatInPreferredTimezone();

  const [timeframe, setTimeframe] = useState<number | null>(null);
  const [{ facility_id: facilityIdParam, ...restParams }] = useSearchParams();

  const {
    data,
    isPending: isLoadingData,
    isError,
    refetch,
  } = useWidgetData("origins-and-destinations", {
    // Don't include `timeframe` param if it is null
    timeframe: timeframe ?? undefined,
  });

  const { data: tradeConnections, isPending: isLoadingTradeConnections } =
    useTradeConnections();

  const maxCount = data
    ? [
        ...data.destination_counts.map(({ count }) => count),
        data.international_destination_count,
      ].reduce((max, count) => Math.max(max, count), 0)
    : 0;

  const facilityCounts = useMemo(() => {
    if (!data || !tradeConnections) {
      return;
    }

    const tradeConnectionsWithCounts = tradeConnections
      .filter((tradeConnection) => {
        if (!facilityIdParam) {
          return true;
        }

        if (Array.isArray(facilityIdParam)) {
          return facilityIdParam.includes(tradeConnection.facility_id);
        }

        return tradeConnection.facility_id === facilityIdParam;
      })
      .map((tradeConnection) => ({
        tradeConnection,
        count:
          data.facility_counts.find(
            ({ facility_id }) => facility_id === tradeConnection.facility_id,
          )?.count ?? 0,
      }));

    const sortedCounts = tradeConnectionsWithCounts.sort(
      (a, b) => b.count - a.count,
    );

    if (sortedCounts.length > 4) {
      const restCounts = sortedCounts.slice(3);
      const total = restCounts.reduce((total, { count }) => total + count, 0);

      return {
        individualCounts: sortedCounts.slice(0, 3),
        rest: {
          counts: restCounts,
          total,
        },
      };
    }

    return {
      individualCounts: sortedCounts,
    };
  }, [data, facilityIdParam, tradeConnections]);

  const timeframeParams = (() => {
    if (timeframe === null) {
      return {
        status: [
          "accepted",
          "open",
          "partially_shipped",
          "partially_backordered",
        ],
      };
    }

    const date = new Date();

    // Subtract (timeframe - 1) days from now
    date.setDate(date.getDate() - (timeframe - 1));

    // format in network timezone to get the correct date
    return {
      inserted_at_start_date: format(date, {
        formatStr: "yyyy-MM-dd",
      }),
    };
  })();

  const datadogRUM = useDatadogRUM();

  return (
    <Card>
      <Card.PaddedContent display="flex" flexDirection="column" gap={4}>
        <Flex alignItems="center" gap={2}>
          <Card.Title>Origins and destinations</Card.Title>

          <Tooltip
            label="Sales order volume by facility and destination"
            placement="top"
          >
            <FaIcon icon={faCircleInfo} boxSize={3} p={1} m={-1} />
          </Tooltip>

          <Box ml="auto">
            <Menu>
              <MenuButton
                as={Button}
                variant="link"
                rightIcon={<FaIcon icon={faAngleDown} />}
              >
                {timeframe === null ? "Open orders" : `Last ${timeframe} days`}
              </MenuButton>

              <Portal>
                <MenuList>
                  <MenuOptionGroup
                    value={(() => {
                      if (timeframe === null) {
                        return "open_orders";
                      }

                      return `${timeframe}_days`;
                    })()}
                    onChange={(value) => {
                      datadogRUM?.addAction("dashboard:widget_query_changed", {
                        widget: "origins_and_destinations",
                        newValue: value,
                      });

                      setTimeframe(
                        (() => {
                          switch (value as string) {
                            case "7_days":
                              return 7;
                            case "30_days":
                              return 30;
                            case "90_days":
                              return 90;
                            case "open_orders":
                            default:
                              return null;
                          }
                        })(),
                      );
                    }}
                    type="radio"
                  >
                    <MenuItemOption
                      value="open_orders"
                      icon={<FaIcon icon={faCheck} />}
                    >
                      Open orders
                    </MenuItemOption>

                    <MenuItemOption
                      value="7_days"
                      icon={<FaIcon icon={faCheck} />}
                    >
                      Accepted last 7 days
                    </MenuItemOption>

                    <MenuItemOption
                      value="30_days"
                      icon={<FaIcon icon={faCheck} />}
                    >
                      Accepted last 30 days
                    </MenuItemOption>

                    <MenuItemOption
                      value="90_days"
                      icon={<FaIcon icon={faCheck} />}
                    >
                      Accepted last 90 days
                    </MenuItemOption>
                  </MenuOptionGroup>
                </MenuList>
              </Portal>
            </Menu>
          </Box>
        </Flex>

        {isError ? (
          <WidgetErrorFetchingData onRetryClick={refetch} />
        ) : (
          <>
            <Flex wrap="wrap" gap={4}>
              {isLoadingData || isLoadingTradeConnections ? (
                // Render a dummy button while loading to prevent layout shift
                <StatButtonLink
                  number="-"
                  helpText="​"
                  to=""
                  pointerEvents="none"
                />
              ) : null}

              {[
                ...(facilityCounts?.individualCounts.map(
                  ({ tradeConnection, count }) => ({
                    key: tradeConnection.facility_id,
                    count,
                    helpText: getFacilityDisplayName(tradeConnection),
                    facilityParams: {
                      facility_id: tradeConnection.facility_id,
                    },
                  }),
                ) ?? []),
                ...(facilityCounts?.rest
                  ? [
                      {
                        key: "rest",
                        count: facilityCounts.rest.total,
                        helpText: (
                          <Tooltip
                            label={facilityCounts.rest.counts
                              .map(({ tradeConnection }) =>
                                getFacilityDisplayName(tradeConnection),
                              )
                              .join("\n")}
                            whiteSpace="pre"
                          >
                            <StatHelpText
                              textStyle="hoverable"
                              fontWeight="normal"
                              display="inline"
                            >
                              {`${facilityCounts.rest.counts.length} facilities`}
                            </StatHelpText>
                          </Tooltip>
                        ),
                        facilityParams: {
                          facility_id: facilityCounts.rest.counts.map(
                            ({ tradeConnection }) =>
                              tradeConnection.facility_id,
                          ),
                        },
                      },
                    ]
                  : []),
              ].map(({ key, count, helpText, facilityParams }) => (
                <StatButtonLink
                  key={key}
                  number={
                    <>
                      {formatNumber(count)}

                      {facilityCounts!.individualCounts.length > 1 ? (
                        <>
                          {" "}
                          <Text as="span" textStyle="caption">
                            {`(${(
                              safePercent(count, data!.total_count) * 100
                            ).toFixed(1)}%)`}
                          </Text>
                        </>
                      ) : null}
                    </>
                  }
                  helpText={helpText}
                  to={qs.stringifyUrl({
                    url: `/distribution_networks/${networkId}/orders`,
                    query: {
                      type: "sales",
                      ...facilityParams,
                      ...timeframeParams,
                      ...restParams,
                    },
                  })}
                  statProps={{ minWidth: 0 }}
                  // On mobile, set basis to 50% so there are max 2 buttons in a row
                  // >= md, squeeze all into 1 row
                  flex={{ base: "1 1 50%", md: "1" }}
                  onClick={() => {
                    datadogRUM?.addAction("dashboard:view_orders", {
                      type: "origin_facility",
                      count,
                      params: restParams,
                    });
                  }}
                />
              ))}
            </Flex>

            <Box
              ref={setMapContainerEl}
              bg="gray.50"
              rounded="lg"
              px={8}
              py={4}
              position="relative"
              _dark={{
                bg: transparentize("white", 0.08),
              }}
            >
              {mapContainerEl ? (
                <>
                  <OrdersMap
                    style={{ margin: "auto", maxHeight: "400px" }}
                    boundaryElement={mapContainerEl}
                    stateData={data?.destination_counts.reduce(
                      (stateData, { state, count }) => ({
                        ...stateData,
                        [stateFipsCodesByAbbrev[state]]: {
                          count,
                          percentTotal: safePercent(count, data.total_count),
                          percentMax: safePercent(count, maxCount),
                        },
                      }),
                      {} as Record<
                        string,
                        {
                          count: number;
                          percentTotal: number;
                          percentMax: number;
                        }
                      >,
                    )}
                  />

                  <InternationalOrdersIndicator
                    position="absolute"
                    right={4}
                    bottom={4}
                    percentMax={safePercent(
                      data?.international_destination_count ?? 0,
                      maxCount,
                    )}
                    boundary={mapContainerEl}
                  >
                    <Text textStyle="tooltip">Non-USA destinations</Text>

                    <Flex gap={2}>
                      <Text textStyle="caption">Orders</Text>
                      <Text textStyle="tooltip">
                        {formatNumber(
                          data?.international_destination_count ?? 0,
                        )}{" "}
                        <chakra.span fontWeight="normal">{`(${(
                          safePercent(
                            data?.international_destination_count ?? 0,
                            data?.total_count ?? 0,
                          ) * 100
                        ).toFixed(1)}%)`}</chakra.span>
                      </Text>
                    </Flex>
                  </InternationalOrdersIndicator>
                </>
              ) : null}
            </Box>
          </>
        )}

        {isLoadingData ? <LoadingOverlay /> : null}
      </Card.PaddedContent>
    </Card>
  );
}
