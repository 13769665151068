import type { ComponentProps, ElementRef } from "react";
import { forwardRef } from "react";
import { chakra } from "@chakra-ui/react";

export const WidgetTooltip = forwardRef<
  ElementRef<"div">,
  ComponentProps<typeof chakra.div>
>(function WidgetTooltip({ children, ...props }, ref) {
  return (
    <chakra.div
      ref={ref}
      background="white"
      py={2}
      px={3}
      rounded="base"
      shadow="base"
      pointerEvents="none"
      _dark={{
        background: "gray.800",
      }}
      {...props}
    >
      {children}
    </chakra.div>
  );
});
