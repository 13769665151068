export default {
  parts: ["container", "title", "description", "icon", "wrapper"],
  baseStyle: {
    title: {
      color: "heading",
    },
    description: {
      color: "text",
    },
    icon: {
      color: "gray.500",
    },
    wrapper: {
      width: "full",
      height: "full",
    },
    container: {
      textAlign: "center",
      justifyContent: "center",
    },
  },
  sizes: {
    sm: {
      wrapper: {
        minHeight: 32,
      },
      container: {
        minHeight: 32,
        maxWidth: 60,
      },
      description: {
        textStyle: "paragraph",
      },
    },
    md: {
      wrapper: {
        minHeight: 56,
      },
      container: {
        minHeight: 56,
        maxWidth: "md",
      },
      title: {
        textStyle: "label",
      },
      description: {
        textStyle: "caption",
      },
      icon: {
        fontSize: "3xl",
      },
    },
    lg: {
      wrapper: {
        minHeight: 80,
      },
      container: {
        minHeight: 80,
        maxWidth: "md",
      },
      title: {
        textStyle: "subtitle",
      },
      icon: {
        fontSize: "5xl",
      },
    },
  },
  defaultProps: {
    size: "lg",
  },
};
