import type { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import {
  faBuilding,
  faBullseyePointer,
  faArrowsTurnRight,
  faCirclePause,
  faCircleXmark,
  faXmarksLines,
  faHand,
  faHandHoldingBox,
  faHourglassClock,
  faListCheck,
  faMailbox,
  faParachuteBox,
  faSignsPost,
  faSplit,
  faStar,
  faTruckFast,
  faTags,
  faLocationCheck,
  faSackDollar,
  faEnvelopeOpenDollar,
  faCalendarClock,
  faStopwatch,
  faInfinity,
  faForward,
  faNote,
  faMessagePlus,
  faArrowsFromDottedLine,
  faGift,
  faTruckBolt,
  faDolly,
  faCalendarStar,
  faMerge,
  faBoxOpen,
  faCalendarRange,
} from "@fortawesome/pro-light-svg-icons";
import {
  faBuilding as faBuildingRegular,
  faBullseyePointer as faBullseyePointerRegular,
  faArrowsTurnRight as faArrowsTurnRightRegular,
  faCircleXmark as faCircleXmarkRegular,
  faXmarksLines as faXmarksLinesRegular,
  faHand as faHandRegular,
  faListCheck as faListCheckRegular,
  faMailbox as faMailboxRegular,
  faParachuteBox as faParachuteBoxRegular,
  faCalendarStar as faCalendarStarRegular,
  faTruckFast as faTruckFastRegular,
  faTags as faTagsRegular,
  faLocationCheck as faLocationCheckRegular,
  faSackDollar as faSackDollarRegular,
  faEnvelopeOpenDollar as faEnvelopeOpenDollarRegular,
  faCalendarClock as faCalendarClockRegular,
  faStopwatch as faStopwatchRegular,
  faInfinity as faInfinityRegular,
  faForward as faForwardRegular,
  faNote as faNoteRegular,
  faMessagePlus as faMessagePlusRegular,
  faArrowsFromDottedLine as faArrowsFromDottedLineRegular,
  faStar as faStarRegular,
  faGift as faGiftRegular,
  faHandHoldingBox as faHandHoldingBoxRegular,
  faCirclePause as faCirclePauseRegular,
  faSignsPost as faSignsPostRegular,
  faHourglassClock as faHourglassClockRegular,
  faTruckBolt as faTruckBoltRegular,
  faSplit as faSplitRegular,
  faDolly as faDollyRegular,
  faMerge as faMergeRegular,
  faBoxOpen as faBoxOpenRegular,
  faCalendarRange as faCalendarRangeRegular,
} from "@fortawesome/pro-regular-svg-icons";
import type { ComponentType, ReactElement } from "react";

import type { SignatureRequired } from "../../hooks/queries/useOrderDetails";

import { faGiftAddLight, faGiftAddRegular } from "./icons/faGiftAdd";
import {
  faReceiptCircleXmarkLight,
  faReceiptCircleXmarkRegular,
} from "./icons/faReceiptCircleXmark";
import type {
  AutomationGroup,
  AutomationType,
  ActionType,
  BackorderStrategy,
} from "./types";
import type {
  DynamicSelectorProps,
  MultiValueTagLabelProps,
} from "./AutomationForm/AutomationConditions";
import {
  BaseSelector,
  ChannelCategorySelector,
  ChannelSelector,
  MultiChannelSelector,
  CountrySelector,
  DateInput,
  ListingSelector,
  NumberInput,
  PriceInput,
  PrioritySelector,
  SkuSelector,
  StateSelector,
  TextInput,
  TimeInput,
  WeightInput,
  MultiChannelCategorySelector,
  MultiCountrySelector,
  MultiListingSelector,
  MultiPrioritySelector,
  MultiSkuSelector,
  MultiStateSelector,
  ChannelMultiValueTagLabel,
  CountryMultiValueTagLabel,
  ListingMultiValueTagLabel,
  PriorityMultiValueTagLabel,
  SkuMultiValueTagLabel,
  StateMultiValueTagLabel,
  MultiTagSelector,
} from "./AutomationForm/AutomationConditions";
import type { ConditionAttribute, ConditionOperator } from "./types";

export const BACKORDER_STRATEGIES: readonly BackorderStrategy[] = [
  "hold_order",
  "short_ship",
  "fill_and_cancel",
  "fill_or_cancel",
] as const;

type AutomationGroupProps = {
  label: string;
  navLabel?: string;
  description: string;
  lightIcon: IconDefinition;
  regularIcon: IconDefinition;
  automationTypes: AutomationType[];
  typeformPath?: string; // TODO: Only show addon badge if isAddon
  isAddon?: true; // If no access, when not an addon, hide in nav, index view, _and_ sequencing card
};

export const automationGroupProps: Record<
  AutomationGroup,
  AutomationGroupProps
> = {
  fulfillment_consolidation: {
    label: "Fulfillment consolidation",
    navLabel: "Consolidation",
    description:
      "Place orders on a temporary hold to analyze cost-saving opportunities for shipment consolidation.",
    lightIcon: faMerge,
    regularIcon: faMergeRegular,
    automationTypes: ["fulfillment_consolidation"],
    typeformPath: "fulfillment_consolidation",
  },
  order_enrichment: {
    label: "Order enrichment",
    description:
      "Update order attributes such as tags, notes, gift messages, and more.",
    lightIcon: faStar,
    regularIcon: faStarRegular,
    automationTypes: [
      "order_priority",
      "add_note",
      "create_tags",
      "add_gift_message",
      "delivery_specs",
      "shipment_type",
      "shelf_life_compliance",
    ],
  },
  order_modification: {
    label: "Order modification",
    description: "Add SKUs to orders.",
    lightIcon: faGift,
    regularIcon: faGiftRegular,
    automationTypes: ["add_order_lines"],
    isAddon: true,
    typeformPath: "order_modification",
  },
  carrier_selection: {
    label: "Carrier selection",
    description: "Ship orders via the desired service method.",
    lightIcon: faHandHoldingBox,
    regularIcon: faHandHoldingBoxRegular,
    automationTypes: ["carrier_selection"],
  },
  hold_order: {
    label: "Order holds",
    description: "Delay order fulfillment for edits and corrections.",
    lightIcon: faCirclePause,
    regularIcon: faCirclePauseRegular,
    automationTypes: ["hold_order"],
    typeformPath: "order_holds",
    isAddon: true,
  },
  order_routing: {
    label: "Order routing",
    description: "Select the right facility for every sales order.",
    lightIcon: faSignsPost,
    regularIcon: faSignsPostRegular,
    automationTypes: ["force_facility", "order_routing", "split_order"],
    isAddon: true,
    typeformPath: "order_routing",
  },
  backorder_strategy: {
    label: "Backorder behavior",
    description:
      "Process orders when inventory is insufficient to fulfill in full.",
    lightIcon: faHourglassClock,
    regularIcon: faHourglassClockRegular,
    automationTypes: ["backorder_strategy"],
    isAddon: true,
    typeformPath: "backorder_behavior",
  },
};

export function isValidAutomationGroup(
  value: string,
): value is AutomationGroup {
  return value in automationGroupProps;
}

export const automationGroupByType = Object.entries(
  automationGroupProps,
).reduce(
  (automationGroupByType, [automationGroup, { automationTypes }]) => {
    automationTypes.forEach((automationType) => {
      if (automationGroupByType[automationType]) {
        console.warn(
          `AutomationType '${automationType}' defined as belonging to AutomationGroup '${automationGroupByType[automationType]}' and '${automationGroup}'. Please check definition of 'automationGroupProps'`,
        );
      }

      automationGroupByType[automationType] =
        automationGroup as AutomationGroup;
    });

    return automationGroupByType;
  },
  {} as Record<AutomationType, AutomationGroup>,
);

export const NO_OUTBOUND_LIMIT = 9999;

export const SIGNATURE_OPTIONS: readonly SignatureRequired[] = [
  "signature",
  "resident_signature",
  "adult_signature",
  "adult_resident_signature",
] as const;

export const actionTypeProps: Record<
  ActionType,
  {
    lightIcon: IconDefinition;
    regularIcon: IconDefinition;
    text: string;
    subText: string;
  }
> = {
  bdot: {
    lightIcon: faTruckFast,
    regularIcon: faTruckFastRegular,
    text: "Set maximum time in transit",
    subText:
      "Optimize time in transit to meet the delivery promise at the lowest cost.",
  },
  force_facility: {
    lightIcon: faBuilding,
    regularIcon: faBuildingRegular,
    text: "Force order to a facility",
    subText:
      "The closest facility with available inventory fulfills the order.",
  },
  ship_full_closest: {
    lightIcon: faLocationCheck,
    regularIcon: faLocationCheckRegular,
    text: "Ship full from closest facility",
    subText:
      "The closest facility with available inventory fulfills the order.",
  },
  ship_full_cheapest: {
    lightIcon: faSackDollar,
    regularIcon: faSackDollarRegular,
    text: "Ship full at lowest cost",
    subText:
      "Take parcel cost into account when choosing from eligible facilities.",
  },
  service_method: {
    lightIcon: faBullseyePointer,
    regularIcon: faBullseyePointerRegular,
    text: "Assign service method",
    subText:
      "Specify the exact carrier and service method you wish to deliver the order.",
  },
  ship_option: {
    lightIcon: faParachuteBox,
    regularIcon: faParachuteBoxRegular,
    text: "Select service level",
    subText: "Rate shop the order within a specified service level.",
  },
  desired_delivery_date: {
    lightIcon: faCalendarStar,
    regularIcon: faCalendarStarRegular,
    text: "Deliver by desired date",
    subText:
      "Ensure orders are delivered by a specified date at the lowest cost.",
  },
  hold_order: {
    lightIcon: faHand,
    regularIcon: faHandRegular,
    text: "Hold and fulfill in full",
    subText: "Hold the order until it can be fulfilled in full.",
  },
  short_ship: {
    lightIcon: faArrowsTurnRight,
    regularIcon: faArrowsTurnRightRegular,
    text: "Ship available lines",
    subText: "Ship available order lines and hold the rest until available.",
  },
  fill_and_cancel: {
    lightIcon: faXmarksLines,
    regularIcon: faXmarksLinesRegular,
    text: "Cancel unfulfillable lines",
    subText:
      "Ship available order lines and cancel those that cannot be fulfilled.",
  },
  fill_or_cancel: {
    lightIcon: faReceiptCircleXmarkLight,
    regularIcon: faReceiptCircleXmarkRegular,
    text: "Fulfill in full or cancel",
    subText: "If the entire order cannot be fulfilled, cancel the order.",
  },
  order_priority: {
    lightIcon: faArrowsFromDottedLine,
    regularIcon: faArrowsFromDottedLineRegular,
    text: "Set order priority",
    subText: "Update the priority of orders that match certain criteria.",
  },
  create_tags: {
    lightIcon: faTags,
    regularIcon: faTagsRegular,
    text: "Add tag(s)",
    subText:
      "Create rules to automatically apply the tag(s) of your choice to orders.",
  },
  delivery_specs: {
    lightIcon: faMailbox,
    regularIcon: faMailboxRegular,
    text: "Configure delivery specifications",
    subText:
      "Allow Saturday delivery and/or require signature for parcel delivery.",
  },
  no_split: {
    lightIcon: faCircleXmark,
    regularIcon: faCircleXmarkRegular,
    text: "Do not split orders",
    subText:
      "Avoid higher shipping costs by shipping orders in full from one facility.",
  },
  complete_lines_only: {
    lightIcon: faListCheck,
    regularIcon: faListCheckRegular,
    text: "Ship complete order lines only",
    subText:
      "Get product into customer hands faster by splitting orders across your network.",
  },
  third_party_billing: {
    lightIcon: faEnvelopeOpenDollar,
    regularIcon: faEnvelopeOpenDollarRegular,
    text: "Bill to 3rd party account",
    subText:
      "Choose the 3rd party parcel account that will receive the shipping invoice.",
  },
  hold_until_date: {
    lightIcon: faCalendarClock,
    regularIcon: faCalendarClockRegular,
    text: "Hold until specified date",
    subText:
      "Identify a future date when the order should be released for fulfillment.",
  },
  hold_specified_time: {
    lightIcon: faStopwatch,
    regularIcon: faStopwatchRegular,
    text: "Hold for specified time",
    subText:
      "Set up a holding period from the time an order is created before it can be released for fulfillment.",
  },
  hold_indefinitely: {
    lightIcon: faInfinity,
    regularIcon: faInfinityRegular,
    text: "Hold until manual release",
    subText: "Hold the order until it is manually released.",
  },
  do_not_hold: {
    lightIcon: faForward,
    regularIcon: faForwardRegular,
    text: "Do not hold orders",
    subText: "Release orders as soon as they are accepted.",
  },
  add_note: {
    lightIcon: faNote,
    regularIcon: faNoteRegular,
    text: "Add note",
    subText: "Include a note on the order to assist operational workflows.",
  },
  add_gift_message: {
    lightIcon: faMessagePlus,
    regularIcon: faMessagePlusRegular,
    text: "Add gift message",
    subText: "Provide a message for the customer.",
  },
  add_order_lines: {
    lightIcon: faGiftAddLight,
    regularIcon: faGiftAddRegular,
    text: "Add order lines",
    subText:
      "Include one or more additional order lines for qualifying orders.",
  },
  shipment_type: {
    lightIcon: faDolly,
    regularIcon: faDollyRegular,
    text: "Set shipment type",
    subText: "Identify orders as parcel or freight.",
  },
  hold_for_consolidation: {
    lightIcon: faBoxOpen,
    regularIcon: faBoxOpenRegular,
    text: "Hold for consolidation check",
    subText:
      "When multiple sales orders from the same customer are accepted within the hold period, fulfill in a single outbound.",
  },
  do_not_hold_for_consolidation: {
    lightIcon: faForward,
    regularIcon: faForwardRegular,
    text: "Do not hold for consolidation",
    subText: "Skip order hold for fulfillment consolidation.",
  },
  shelf_life_compliance: {
    lightIcon: faCalendarRange,
    regularIcon: faCalendarRangeRegular,
    text: "Shelf life compliance",
    subText:
      "Ensure specific shelf life expectations are met for SKUs on orders.",
  },
};

/**
 * When looking at automations for an automation group, we group automations of the same "type" into tables.
 * These are the attributes needed for those tables.
 * Note that automation types sometimes get their attributes from their group and sometimes from the (single) action type for that automation type.
 */
export const automationTypeProps: Record<
  AutomationType,
  {
    label: string;
    lightIcon: IconDefinition;
    regularIcon: IconDefinition;
    actionTypes: ActionType[];
    exhaustive?: true;
  }
> = {
  order_priority: {
    label: actionTypeProps.order_priority.text,
    lightIcon: actionTypeProps.order_priority.lightIcon,
    regularIcon: actionTypeProps.order_priority.regularIcon,
    actionTypes: ["order_priority"],
  },
  add_note: {
    label: actionTypeProps.add_note.text,
    lightIcon: actionTypeProps.add_note.lightIcon,
    regularIcon: actionTypeProps.add_note.regularIcon,
    actionTypes: ["add_note"],
    exhaustive: true,
  },
  create_tags: {
    label: actionTypeProps.create_tags.text,
    lightIcon: actionTypeProps.create_tags.lightIcon,
    regularIcon: actionTypeProps.create_tags.regularIcon,
    actionTypes: ["create_tags"],
    exhaustive: true,
  },
  add_gift_message: {
    label: actionTypeProps.add_gift_message.text,
    lightIcon: actionTypeProps.add_gift_message.lightIcon,
    regularIcon: actionTypeProps.add_gift_message.regularIcon,
    actionTypes: ["add_gift_message"],
  },
  delivery_specs: {
    label: actionTypeProps.delivery_specs.text,
    lightIcon: actionTypeProps.delivery_specs.lightIcon,
    regularIcon: actionTypeProps.delivery_specs.regularIcon,
    actionTypes: ["delivery_specs"],
  },
  add_order_lines: {
    label: actionTypeProps.add_order_lines.text,
    lightIcon: actionTypeProps.add_order_lines.lightIcon,
    regularIcon: actionTypeProps.add_order_lines.regularIcon,
    actionTypes: ["add_order_lines"],
    exhaustive: true,
  },
  carrier_selection: {
    label: automationGroupProps.carrier_selection.label,
    lightIcon: automationGroupProps.carrier_selection.lightIcon,
    regularIcon: automationGroupProps.carrier_selection.regularIcon,
    actionTypes: [
      "service_method",
      "desired_delivery_date",
      "ship_option",
      "bdot",
      "third_party_billing",
    ],
  },
  hold_order: {
    label: "Order holds",
    lightIcon: faCirclePause,
    regularIcon: faCirclePauseRegular,
    actionTypes: [
      "hold_until_date",
      "hold_specified_time",
      "hold_indefinitely",
      "do_not_hold",
    ],
  },
  order_routing: {
    label: "Ship orders in full",
    lightIcon: faTruckBolt,
    regularIcon: faTruckBoltRegular,
    actionTypes: ["ship_full_cheapest", "ship_full_closest"],
  },
  split_order: {
    label: "Order splits",
    lightIcon: faSplit,
    regularIcon: faSplitRegular,
    actionTypes: ["complete_lines_only", "no_split"],
  },
  force_facility: {
    label: "Force orders",
    lightIcon: faBuilding,
    regularIcon: faBuildingRegular,
    actionTypes: ["force_facility"],
  },
  backorder_strategy: {
    label: "Backorder behavior",
    lightIcon: faHourglassClock,
    regularIcon: faHourglassClockRegular,
    actionTypes: [
      "hold_order",
      "short_ship",
      "fill_and_cancel",
      "fill_or_cancel",
    ],
  },
  shipment_type: {
    label: "Set shipment type",
    lightIcon: faDolly,
    regularIcon: faDollyRegular,
    actionTypes: ["shipment_type"],
  },
  fulfillment_consolidation: {
    label: "Fulfillment consolidation",
    lightIcon: faMerge,
    regularIcon: faMergeRegular,
    actionTypes: ["hold_for_consolidation", "do_not_hold_for_consolidation"],
  },
  shelf_life_compliance: {
    label: "Shelf life compliance",
    lightIcon: faCalendarRange,
    regularIcon: faCalendarRangeRegular,
    actionTypes: ["shelf_life_compliance"],
  },
};

export const attributeConfigs: Record<
  ConditionAttribute,
  {
    component: (props: DynamicSelectorProps) => ReactElement;
    multiComponent?: (props: DynamicSelectorProps) => ReactElement;
    onlyOnce: boolean;
    excludedBy: ConditionAttribute[];
    operators: ConditionOperator[];
    displayName: string;
    type?: "date" | "string" | "number";
    MultiValueTagLabel?: ComponentType<MultiValueTagLabelProps>;
  }
> = {
  brands: {
    component: TextInput,
    displayName: "Brand",
    excludedBy: [],
    onlyOnce: false,
    operators: ["includes"],
  },
  shipping_code: {
    component: TextInput,
    displayName: "Shipping code",
    excludedBy: [],
    onlyOnce: true,
    operators: ["contains", "eq", "not_eq"],
  },
  channel: {
    component: ChannelSelector,
    multiComponent: MultiChannelSelector,
    displayName: "Channel",
    excludedBy: [],
    onlyOnce: true,
    operators: ["eq", "not_eq", "is_any_of", "is_none_of"],
    MultiValueTagLabel: ChannelMultiValueTagLabel,
  },
  channel_category: {
    component: ChannelCategorySelector,
    multiComponent: MultiChannelCategorySelector,
    displayName: "Channel category",
    excludedBy: [],
    onlyOnce: true,
    operators: ["eq", "not_eq", "is_any_of", "is_none_of"],
  },
  destination_country: {
    component: CountrySelector,
    multiComponent: MultiCountrySelector,
    displayName: "Destination country",
    excludedBy: ["destination_state"],
    onlyOnce: true,
    operators: ["eq", "not_eq", "is_any_of", "is_none_of"],
    MultiValueTagLabel: CountryMultiValueTagLabel,
  },
  destination_state: {
    component: StateSelector,
    multiComponent: MultiStateSelector,
    displayName: "Destination state/territory",
    excludedBy: ["destination_country"],
    onlyOnce: true,
    operators: ["eq", "not_eq", "is_any_of", "is_none_of"],
    MultiValueTagLabel: StateMultiValueTagLabel,
  },
  skus: {
    component: SkuSelector,
    multiComponent: MultiSkuSelector,
    displayName: "SKU(s) in order",
    excludedBy: [],
    onlyOnce: false,
    operators: ["includes", "are_any_of", "are_all_of", "are_none_of"],
    MultiValueTagLabel: SkuMultiValueTagLabel,
  },
  listing_ids: {
    component: ListingSelector,
    multiComponent: MultiListingSelector,
    displayName: "Listing(s) in order",
    excludedBy: [],
    onlyOnce: false,
    operators: ["includes", "are_any_of", "are_all_of", "are_none_of"],
    MultiValueTagLabel: ListingMultiValueTagLabel,
  },
  tags: {
    component: TextInput,
    multiComponent: MultiTagSelector,
    displayName: "External tag(s)",
    excludedBy: [],
    onlyOnce: false,
    operators: ["includes", "are_any_of", "are_all_of", "are_none_of"],
  },
  total_weight: {
    component: WeightInput,
    displayName: "Total weight",
    excludedBy: [],
    onlyOnce: false,
    operators: ["lt", "gt_eq"],
  },
  contains_haz_mat: {
    component: BaseSelector,
    displayName: "Hazardous material",
    excludedBy: [],
    onlyOnce: true,
    operators: ["eq", "not_eq"],
  },
  created_at: {
    component: DateInput,
    displayName: "Order creation date",
    excludedBy: [],
    onlyOnce: true,
    operators: ["gt_eq", "lt", "between"],
    type: "date",
  },
  time_in_backorder: {
    component: TimeInput,
    displayName: "Time in backorder",
    excludedBy: [],
    onlyOnce: true,
    operators: ["gt_eq"],
  },
  customer_name: {
    component: TextInput,
    displayName: "Customer name",
    excludedBy: [],
    onlyOnce: false,
    operators: ["contains", "eq", "not_eq"],
  },
  priority: {
    component: PrioritySelector,
    multiComponent: MultiPrioritySelector,
    displayName: "Priority",
    excludedBy: [],
    onlyOnce: false,
    operators: ["eq", "not_eq", "is_any_of", "is_none_of"],
    MultiValueTagLabel: PriorityMultiValueTagLabel,
  },
  custom_reference: {
    component: TextInput,
    displayName: "Custom reference",
    excludedBy: [],
    onlyOnce: false,
    operators: ["contains", "eq", "not_eq"],
  },
  total_sale_price: {
    component: PriceInput,
    displayName: "Total sale price",
    excludedBy: [],
    onlyOnce: true,
    operators: ["lt", "gt_eq"],
  },
  total_units: {
    component: NumberInput,
    displayName: "Total unit quantity",
    excludedBy: [],
    onlyOnce: true,
    operators: ["lt", "gt", "eq"],
  },
  total_order_lines: {
    component: NumberInput,
    displayName: "Total order line quantity",
    excludedBy: [],
    onlyOnce: true,
    operators: ["lt", "gt", "eq"],
  },
  address_line1: {
    component: TextInput,
    displayName: "Address line 1",
    excludedBy: [],
    onlyOnce: true,
    operators: ["contains", "eq", "not_eq"],
  },
  address_line2: {
    component: TextInput,
    displayName: "Address line 2",
    excludedBy: [],
    onlyOnce: true,
    operators: ["contains", "eq", "not_eq"],
  },
  address_locality: {
    component: TextInput,
    displayName: "Address locality",
    excludedBy: [],
    onlyOnce: true,
    operators: ["contains", "eq", "not_eq"],
  },
  address_postal_code: {
    component: TextInput,
    displayName: "Address postal code",
    excludedBy: [],
    onlyOnce: true,
    operators: ["contains", "eq", "not_eq"],
  },
  customer_email: {
    component: TextInput,
    displayName: "Customer email",
    excludedBy: [],
    onlyOnce: true,
    operators: ["contains", "eq", "not_eq"],
  },
  customer_phone: {
    component: TextInput,
    displayName: "Customer phone",
    excludedBy: [],
    onlyOnce: true,
    operators: ["contains", "eq", "not_eq"],
  },
};
