import type React from "react";
import { chakra } from "@chakra-ui/react";

export const HeaderTitle = ({
  children,
  ...props
}: Omit<
  React.ComponentProps<typeof chakra.h2>,
  "color" | "textStyle" | "noOfLines" | "wordBreak" | "lineHeight"
>) => (
  <chakra.h2
    color="heading"
    textStyle="pageTitle"
    noOfLines={1}
    wordBreak="break-all"
    lineHeight={10}
    {...props}
  >
    {children}
  </chakra.h2>
);
