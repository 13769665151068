import { useNetwork } from "../components/Network";
import { useUser } from "../../../../packages/components/src/hooks/useUser";
import type { RealmMinimal } from "./cloudApi/cloudSchemas";

type OMSRole = "admin" | "contributor" | "viewer";

/** A utility hook that returns the user's role in the network. Can return undefined */
export function useNetworkRole() {
  const network = useNetwork();
  const user = useUser();

  return user.roles.find(
    (role) =>
      role.app.alias === "oms" &&
      (role.realm as RealmMinimal).resource_id === network.network_id,
  )?.role as OMSRole | null | undefined;
}
