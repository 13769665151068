/**
 * Generated by @openapi-codegen
 *
 * @version 145.156.0
 */
import * as reactQuery from "@tanstack/react-query";
import { useCxContext, CxContext } from "./cxContext";
import type * as Fetcher from "./cxFetcher";
import { cxFetch } from "./cxFetcher";
import type * as Schemas from "./cxSchemas";

export type PortalAuthenticatePathParams = {
  /**
   * The order ID of the order the claim belongs to.
   *
   * @format uuid
   */
  orderId: string;
};

export type PortalAuthenticateError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: {
        errors: {
          /**
           * @example Invalid Credentials
           */
          message: string;
          /**
           * @example Unauthorized
           */
          title: string;
        }[];
      };
    }
  | {
      status: 422;
      payload: Schemas.JsonErrorResponse;
    }
>;

export type PortalAuthenticateResponse = {
  /**
   * A unix timestamp at a date in the future. By default this will be the current time plus 10 days.
   *
   * @format date-time
   */
  expires_at: number;
  /**
   * @format uuid
   */
  order_id: string;
  status: "ok";
};

export type PortalAuthenticateRequestBody = {
  data: {
    /**
     * @format email
     */
    email: string;
    order_number: string;
  };
};

export type PortalAuthenticateVariables = {
  body: PortalAuthenticateRequestBody;
  pathParams: PortalAuthenticatePathParams;
} & CxContext["fetcherOptions"];

/**
 * Authenticates a user by checking if the email and order number match the order.
 */
export const fetchPortalAuthenticate = (
  variables: PortalAuthenticateVariables,
  signal?: AbortSignal
) =>
  cxFetch<
    PortalAuthenticateResponse,
    PortalAuthenticateError,
    PortalAuthenticateRequestBody,
    {},
    {},
    PortalAuthenticatePathParams
  >({
    url: "/public_portal/v1/consumer_experience/orders/{orderId}/authenticate",
    method: "post",
    ...variables,
    signal,
  });

/**
 * Authenticates a user by checking if the email and order number match the order.
 */
export const usePortalAuthenticate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      PortalAuthenticateResponse,
      PortalAuthenticateError,
      PortalAuthenticateVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCxContext();
  return reactQuery.useMutation<
    PortalAuthenticateResponse,
    PortalAuthenticateError,
    PortalAuthenticateVariables
  >({
    mutationFn: (variables: PortalAuthenticateVariables) =>
      fetchPortalAuthenticate({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type PortalCreateShipmentProtectionClaimPathParams = {
  /**
   * The external reference of the order the claim belongs to.
   */
  orderId: string;
};

export type PortalCreateShipmentProtectionClaimError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: {
        errors: {
          /**
           * @example Invalid Credentials
           */
          message: string;
          /**
           * @example Unauthorized
           */
          title: string;
        }[];
      };
    }
  | {
      status: 422;
      payload: Schemas.JsonErrorResponse;
    }
>;

export type PortalCreateShipmentProtectionClaimResponse = {
  data: {
    data?: Schemas.Claim;
  };
};

export type PortalCreateShipmentProtectionClaimRequestBody = {
  data: Schemas.CreateClaimPortal;
};

export type PortalCreateShipmentProtectionClaimVariables = {
  body: PortalCreateShipmentProtectionClaimRequestBody;
  pathParams: PortalCreateShipmentProtectionClaimPathParams;
} & CxContext["fetcherOptions"];

/**
 * This endpoint will create an internal record of claim for the given provider
 */
export const fetchPortalCreateShipmentProtectionClaim = (
  variables: PortalCreateShipmentProtectionClaimVariables,
  signal?: AbortSignal
) =>
  cxFetch<
    PortalCreateShipmentProtectionClaimResponse,
    PortalCreateShipmentProtectionClaimError,
    PortalCreateShipmentProtectionClaimRequestBody,
    {},
    {},
    PortalCreateShipmentProtectionClaimPathParams
  >({
    url: "/public_portal/v1/consumer_experience/orders/{orderId}/claims",
    method: "post",
    ...variables,
    signal,
  });

/**
 * This endpoint will create an internal record of claim for the given provider
 */
export const usePortalCreateShipmentProtectionClaim = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      PortalCreateShipmentProtectionClaimResponse,
      PortalCreateShipmentProtectionClaimError,
      PortalCreateShipmentProtectionClaimVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCxContext();
  return reactQuery.useMutation<
    PortalCreateShipmentProtectionClaimResponse,
    PortalCreateShipmentProtectionClaimError,
    PortalCreateShipmentProtectionClaimVariables
  >({
    mutationFn: (variables: PortalCreateShipmentProtectionClaimVariables) =>
      fetchPortalCreateShipmentProtectionClaim({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type PortalDocumentUploadPathParams = {
  /**
   * The order ID of the order the document belongs to.
   *
   * @format uuid
   */
  orderId: string;
};

export type PortalDocumentUploadError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: {
        errors: {
          /**
           * @example Invalid Credentials
           */
          message: string;
          /**
           * @example Unauthorized
           */
          title: string;
        }[];
      };
    }
  | {
      status: 422;
      payload: Schemas.JsonErrorResponse;
    }
>;

export type PortalDocumentUploadResponse = {
  data: Schemas.PortalUploadedDocument[];
};

export type PortalDocumentUploadRequestBody = {
  documents: {
    /**
     * @example test.jpg
     */
    file_name: string;
    /**
     * @example image/jpeg
     */
    mime_type: string;
  }[];
};

export type PortalDocumentUploadVariables = {
  body: PortalDocumentUploadRequestBody;
  pathParams: PortalDocumentUploadPathParams;
} & CxContext["fetcherOptions"];

/**
 * Supports document upload for attaching documents to claims
 */
export const fetchPortalDocumentUpload = (
  variables: PortalDocumentUploadVariables,
  signal?: AbortSignal
) =>
  cxFetch<
    PortalDocumentUploadResponse,
    PortalDocumentUploadError,
    PortalDocumentUploadRequestBody,
    {},
    {},
    PortalDocumentUploadPathParams
  >({
    url: "/public_portal/v1/consumer_experience/orders/{orderId}/document_upload",
    method: "post",
    ...variables,
    signal,
  });

/**
 * Supports document upload for attaching documents to claims
 */
export const usePortalDocumentUpload = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      PortalDocumentUploadResponse,
      PortalDocumentUploadError,
      PortalDocumentUploadVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCxContext();
  return reactQuery.useMutation<
    PortalDocumentUploadResponse,
    PortalDocumentUploadError,
    PortalDocumentUploadVariables
  >({
    mutationFn: (variables: PortalDocumentUploadVariables) =>
      fetchPortalDocumentUpload({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type OrdersServiceWebPublicPortalConsumerExperienceV1ShipmentControllerListOrderShipmentsPathParams =
  {
    /**
     * The order ID
     *
     * @format uuid
     */
    orderId: string;
  };

export type OrdersServiceWebPublicPortalConsumerExperienceV1ShipmentControllerListOrderShipmentsError =
  Fetcher.ErrorWrapper<
    | {
        status: 401;
        payload: Schemas.CXPortalUnauthorized;
      }
    | {
        status: 404;
        payload:
          | {
              errors: {
                detail?: string;
              };
            }
          | {
              errors: {
                detail?: string;
              }[];
            };
      }
  >;

export type OrdersServiceWebPublicPortalConsumerExperienceV1ShipmentControllerListOrderShipmentsResponse =
  {
    data: Schemas.CXOrderShipment[];
  };

export type OrdersServiceWebPublicPortalConsumerExperienceV1ShipmentControllerListOrderShipmentsVariables =
  {
    pathParams: OrdersServiceWebPublicPortalConsumerExperienceV1ShipmentControllerListOrderShipmentsPathParams;
  } & CxContext["fetcherOptions"];

/**
 * Returns a single shipment associated with a given order
 */
export const fetchOrdersServiceWebPublicPortalConsumerExperienceV1ShipmentControllerListOrderShipments =
  (
    variables: OrdersServiceWebPublicPortalConsumerExperienceV1ShipmentControllerListOrderShipmentsVariables,
    signal?: AbortSignal
  ) =>
    cxFetch<
      OrdersServiceWebPublicPortalConsumerExperienceV1ShipmentControllerListOrderShipmentsResponse,
      OrdersServiceWebPublicPortalConsumerExperienceV1ShipmentControllerListOrderShipmentsError,
      undefined,
      {},
      {},
      OrdersServiceWebPublicPortalConsumerExperienceV1ShipmentControllerListOrderShipmentsPathParams
    >({
      url: "/public_portal/v1/consumer_experience/orders/{orderId}/shipments",
      method: "get",
      ...variables,
      signal,
    });

/**
 * Returns a single shipment associated with a given order
 */
export const useOrdersServiceWebPublicPortalConsumerExperienceV1ShipmentControllerListOrderShipments =
  <
    TData = OrdersServiceWebPublicPortalConsumerExperienceV1ShipmentControllerListOrderShipmentsResponse
  >(
    variables: OrdersServiceWebPublicPortalConsumerExperienceV1ShipmentControllerListOrderShipmentsVariables,
    options?: Omit<
      reactQuery.UseQueryOptions<
        OrdersServiceWebPublicPortalConsumerExperienceV1ShipmentControllerListOrderShipmentsResponse,
        OrdersServiceWebPublicPortalConsumerExperienceV1ShipmentControllerListOrderShipmentsError,
        TData
      >,
      "queryKey" | "queryFn" | "initialData"
    >
  ) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useCxContext(options);
    return reactQuery.useQuery<
      OrdersServiceWebPublicPortalConsumerExperienceV1ShipmentControllerListOrderShipmentsResponse,
      OrdersServiceWebPublicPortalConsumerExperienceV1ShipmentControllerListOrderShipmentsError,
      TData
    >({
      queryKey: queryKeyFn({
        path: "/public_portal/v1/consumer_experience/orders/{orderId}/shipments",
        operationId:
          "ordersServiceWebPublicPortalConsumerExperienceV1ShipmentControllerListOrderShipments",
        variables,
      }),
      queryFn: ({ signal }) =>
        fetchOrdersServiceWebPublicPortalConsumerExperienceV1ShipmentControllerListOrderShipments(
          { ...fetcherOptions, ...variables },
          signal
        ),
      ...options,
      ...queryOptions,
    });
  };

export type OrdersServiceWebPublicPortalConsumerExperienceV1ShipmentControllerGetOrderShipmentDetailsPathParams =
  {
    /**
     * The order ID
     *
     * @format uuid
     */
    orderId: string;
    /**
     * The shipment ID
     *
     * @format uuid
     */
    shipmentId: string;
  };

export type OrdersServiceWebPublicPortalConsumerExperienceV1ShipmentControllerGetOrderShipmentDetailsError =
  Fetcher.ErrorWrapper<
    | {
        status: 401;
        payload: Schemas.CXPortalUnauthorized;
      }
    | {
        status: 404;
        payload:
          | {
              errors: {
                detail?: string;
              };
            }
          | {
              errors: {
                detail?: string;
              }[];
            };
      }
  >;

export type OrdersServiceWebPublicPortalConsumerExperienceV1ShipmentControllerGetOrderShipmentDetailsResponse =
  {
    data: Schemas.CXOrderShipmentWithoutPII;
  };

export type OrdersServiceWebPublicPortalConsumerExperienceV1ShipmentControllerGetOrderShipmentDetailsVariables =
  {
    pathParams: OrdersServiceWebPublicPortalConsumerExperienceV1ShipmentControllerGetOrderShipmentDetailsPathParams;
  } & CxContext["fetcherOptions"];

/**
 * Returns an order with its related shipments. This does not include PII.
 */
export const fetchOrdersServiceWebPublicPortalConsumerExperienceV1ShipmentControllerGetOrderShipmentDetails =
  (
    variables: OrdersServiceWebPublicPortalConsumerExperienceV1ShipmentControllerGetOrderShipmentDetailsVariables,
    signal?: AbortSignal
  ) =>
    cxFetch<
      OrdersServiceWebPublicPortalConsumerExperienceV1ShipmentControllerGetOrderShipmentDetailsResponse,
      OrdersServiceWebPublicPortalConsumerExperienceV1ShipmentControllerGetOrderShipmentDetailsError,
      undefined,
      {},
      {},
      OrdersServiceWebPublicPortalConsumerExperienceV1ShipmentControllerGetOrderShipmentDetailsPathParams
    >({
      url: "/public_portal/v1/consumer_experience/orders/{orderId}/shipments/{shipmentId}",
      method: "get",
      ...variables,
      signal,
    });

/**
 * Returns an order with its related shipments. This does not include PII.
 */
export const useOrdersServiceWebPublicPortalConsumerExperienceV1ShipmentControllerGetOrderShipmentDetails =
  <
    TData = OrdersServiceWebPublicPortalConsumerExperienceV1ShipmentControllerGetOrderShipmentDetailsResponse
  >(
    variables: OrdersServiceWebPublicPortalConsumerExperienceV1ShipmentControllerGetOrderShipmentDetailsVariables,
    options?: Omit<
      reactQuery.UseQueryOptions<
        OrdersServiceWebPublicPortalConsumerExperienceV1ShipmentControllerGetOrderShipmentDetailsResponse,
        OrdersServiceWebPublicPortalConsumerExperienceV1ShipmentControllerGetOrderShipmentDetailsError,
        TData
      >,
      "queryKey" | "queryFn" | "initialData"
    >
  ) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useCxContext(options);
    return reactQuery.useQuery<
      OrdersServiceWebPublicPortalConsumerExperienceV1ShipmentControllerGetOrderShipmentDetailsResponse,
      OrdersServiceWebPublicPortalConsumerExperienceV1ShipmentControllerGetOrderShipmentDetailsError,
      TData
    >({
      queryKey: queryKeyFn({
        path: "/public_portal/v1/consumer_experience/orders/{orderId}/shipments/{shipmentId}",
        operationId:
          "ordersServiceWebPublicPortalConsumerExperienceV1ShipmentControllerGetOrderShipmentDetails",
        variables,
      }),
      queryFn: ({ signal }) =>
        fetchOrdersServiceWebPublicPortalConsumerExperienceV1ShipmentControllerGetOrderShipmentDetails(
          { ...fetcherOptions, ...variables },
          signal
        ),
      ...options,
      ...queryOptions,
    });
  };

export type GetBrandingsPathParams = {
  /**
   * External reference of the branding.
   *
   * @format uuid
   */
  externalReference: string;
};

export type GetBrandingsQueryParams = {
  /**
   * Number of records to return per page
   *
   * @minimum 1
   */
  limit?: number;
  /**
   * Page cursor to get the previous page
   */
  before?: string;
  /**
   * Page cursor to get the next page
   */
  after?: string;
  /**
   * Sort by a given property. Accepts fields: inserted_at, updated_at. Sorts should be passed in the format of `&sort=property:asc,property2:desc`
   */
  sort?: string;
};

export type GetBrandingsError = Fetcher.ErrorWrapper<
  | {
      status: 403;
      payload: Schemas.JsonErrorResponse;
    }
  | {
      status: 422;
      payload: Schemas.JsonErrorResponse;
    }
>;

export type GetBrandingsResponse = {
  data: Schemas.Branding[];
  metadata: Schemas.PaginationMetadata;
};

export type GetBrandingsVariables = {
  pathParams: GetBrandingsPathParams;
  queryParams?: GetBrandingsQueryParams;
} & CxContext["fetcherOptions"];

/**
 * This endpoint will get brandings.
 */
export const fetchGetBrandings = (
  variables: GetBrandingsVariables,
  signal?: AbortSignal
) =>
  cxFetch<
    GetBrandingsResponse,
    GetBrandingsError,
    undefined,
    {},
    GetBrandingsQueryParams,
    GetBrandingsPathParams
  >({
    url: "/v1/consumer_experience/{externalReference}/brandings",
    method: "get",
    ...variables,
    signal,
  });

/**
 * This endpoint will get brandings.
 */
export const useGetBrandings = <TData = GetBrandingsResponse>(
  variables: GetBrandingsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetBrandingsResponse, GetBrandingsError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCxContext(options);
  return reactQuery.useQuery<GetBrandingsResponse, GetBrandingsError, TData>({
    queryKey: queryKeyFn({
      path: "/v1/consumer_experience/{externalReference}/brandings",
      operationId: "getBrandings",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetBrandings({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type CreateBrandingPathParams = {
  /**
   * External reference of the branding.
   *
   * @format uuid
   */
  externalReference: string;
};

export type CreateBrandingError = Fetcher.ErrorWrapper<
  | {
      status: 403;
      payload: Schemas.JsonErrorResponse;
    }
  | {
      status: 422;
      payload: Schemas.JsonErrorResponse;
    }
>;

export type CreateBrandingResponse = {
  data: {
    data?: Schemas.Branding;
  };
};

export type CreateBrandingRequestBody = {
  data: Schemas.CreateBranding;
};

export type CreateBrandingVariables = {
  body: CreateBrandingRequestBody;
  pathParams: CreateBrandingPathParams;
} & CxContext["fetcherOptions"];

/**
 * This endpoint will create a branding.
 */
export const fetchCreateBranding = (
  variables: CreateBrandingVariables,
  signal?: AbortSignal
) =>
  cxFetch<
    CreateBrandingResponse,
    CreateBrandingError,
    CreateBrandingRequestBody,
    {},
    {},
    CreateBrandingPathParams
  >({
    url: "/v1/consumer_experience/{externalReference}/brandings",
    method: "post",
    ...variables,
    signal,
  });

/**
 * This endpoint will create a branding.
 */
export const useCreateBranding = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      CreateBrandingResponse,
      CreateBrandingError,
      CreateBrandingVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCxContext();
  return reactQuery.useMutation<
    CreateBrandingResponse,
    CreateBrandingError,
    CreateBrandingVariables
  >({
    mutationFn: (variables: CreateBrandingVariables) =>
      fetchCreateBranding({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetAlertTypesPathParams = {
  /**
   * External reference of the alert type.
   *
   * @format uuid
   */
  externalReference: string;
  /**
   * The branding ID of the alert type.
   *
   * @format uuid
   */
  brandingId: string;
};

export type GetAlertTypesQueryParams = {
  /**
   * Number of records to return per page
   *
   * @minimum 1
   */
  limit?: number;
  /**
   * Page cursor to get the previous page
   */
  before?: string;
  /**
   * Page cursor to get the next page
   */
  after?: string;
  /**
   * Sort by a given property. Accepts fields: inserted_at, updated_at. Sorts should be passed in the format of `&sort=property:asc,property2:desc`
   */
  sort?: string;
};

export type GetAlertTypesError = Fetcher.ErrorWrapper<
  | {
      status: 403;
      payload: Schemas.JsonErrorResponse;
    }
  | {
      status: 422;
      payload: Schemas.JsonErrorResponse;
    }
>;

export type GetAlertTypesResponse = {
  data: Schemas.AlertType[];
  metadata: Schemas.PaginationMetadata;
};

export type GetAlertTypesVariables = {
  pathParams: GetAlertTypesPathParams;
  queryParams?: GetAlertTypesQueryParams;
} & CxContext["fetcherOptions"];

/**
 * This endpoint will list alert types.
 */
export const fetchGetAlertTypes = (
  variables: GetAlertTypesVariables,
  signal?: AbortSignal
) =>
  cxFetch<
    GetAlertTypesResponse,
    GetAlertTypesError,
    undefined,
    {},
    GetAlertTypesQueryParams,
    GetAlertTypesPathParams
  >({
    url: "/v1/consumer_experience/{externalReference}/brandings/{brandingId}/alert_types",
    method: "get",
    ...variables,
    signal,
  });

/**
 * This endpoint will list alert types.
 */
export const useGetAlertTypes = <TData = GetAlertTypesResponse>(
  variables: GetAlertTypesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetAlertTypesResponse,
      GetAlertTypesError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCxContext(options);
  return reactQuery.useQuery<GetAlertTypesResponse, GetAlertTypesError, TData>({
    queryKey: queryKeyFn({
      path: "/v1/consumer_experience/{externalReference}/brandings/{brandingId}/alert_types",
      operationId: "getAlertTypes",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetAlertTypes({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type CreateAlertTypePathParams = {
  /**
   * External reference of the alert type.
   *
   * @format uuid
   */
  externalReference: string;
  /**
   * The branding ID of the alert type.
   *
   * @format uuid
   */
  brandingId: string;
};

export type CreateAlertTypeError = Fetcher.ErrorWrapper<
  | {
      status: 403;
      payload: Schemas.JsonErrorResponse;
    }
  | {
      status: 422;
      payload: Schemas.JsonErrorResponse;
    }
>;

export type CreateAlertTypeResponse = {
  data: {
    data?: Schemas.AlertType;
  };
};

export type CreateAlertTypeRequestBody = {
  data: Schemas.CreateAlertType;
};

export type CreateAlertTypeVariables = {
  body: CreateAlertTypeRequestBody;
  pathParams: CreateAlertTypePathParams;
} & CxContext["fetcherOptions"];

/**
 * This endpoint will create an alert type.
 */
export const fetchCreateAlertType = (
  variables: CreateAlertTypeVariables,
  signal?: AbortSignal
) =>
  cxFetch<
    CreateAlertTypeResponse,
    CreateAlertTypeError,
    CreateAlertTypeRequestBody,
    {},
    {},
    CreateAlertTypePathParams
  >({
    url: "/v1/consumer_experience/{externalReference}/brandings/{brandingId}/alert_types",
    method: "post",
    ...variables,
    signal,
  });

/**
 * This endpoint will create an alert type.
 */
export const useCreateAlertType = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      CreateAlertTypeResponse,
      CreateAlertTypeError,
      CreateAlertTypeVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCxContext();
  return reactQuery.useMutation<
    CreateAlertTypeResponse,
    CreateAlertTypeError,
    CreateAlertTypeVariables
  >({
    mutationFn: (variables: CreateAlertTypeVariables) =>
      fetchCreateAlertType({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetAlertChannelsPathParams = {
  /**
   * External reference of the alert channel.
   *
   * @format uuid
   */
  externalReference: string;
  /**
   * The branding ID of the alert channel.
   *
   * @format uuid
   */
  brandingId: string;
  /**
   * The type of the alert
   *
   * @format uuid
   */
  alertTypeId: string;
};

export type GetAlertChannelsQueryParams = {
  /**
   * Number of records to return per page
   *
   * @minimum 1
   */
  limit?: number;
  /**
   * Page cursor to get the previous page
   */
  before?: string;
  /**
   * Page cursor to get the next page
   */
  after?: string;
  /**
   * Sort by a given property. Accepts fields: channel_id. Sorts should be passed in the format of `&sort=property:asc,property2:desc`
   */
  sort?: string;
};

export type GetAlertChannelsError = Fetcher.ErrorWrapper<
  | {
      status: 403;
      payload: Schemas.JsonErrorResponse;
    }
  | {
      status: 422;
      payload: Schemas.JsonErrorResponse;
    }
>;

export type GetAlertChannelsResponse = {
  data: Schemas.AlertChannel[];
  metadata: Schemas.PaginationMetadata;
};

export type GetAlertChannelsVariables = {
  pathParams: GetAlertChannelsPathParams;
  queryParams?: GetAlertChannelsQueryParams;
} & CxContext["fetcherOptions"];

/**
 * This endpoint will list alert channels.
 */
export const fetchGetAlertChannels = (
  variables: GetAlertChannelsVariables,
  signal?: AbortSignal
) =>
  cxFetch<
    GetAlertChannelsResponse,
    GetAlertChannelsError,
    undefined,
    {},
    GetAlertChannelsQueryParams,
    GetAlertChannelsPathParams
  >({
    url: "/v1/consumer_experience/{externalReference}/brandings/{brandingId}/alert_types/{alertTypeId}/alert_channels",
    method: "get",
    ...variables,
    signal,
  });

/**
 * This endpoint will list alert channels.
 */
export const useGetAlertChannels = <TData = GetAlertChannelsResponse>(
  variables: GetAlertChannelsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetAlertChannelsResponse,
      GetAlertChannelsError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCxContext(options);
  return reactQuery.useQuery<
    GetAlertChannelsResponse,
    GetAlertChannelsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/v1/consumer_experience/{externalReference}/brandings/{brandingId}/alert_types/{alertTypeId}/alert_channels",
      operationId: "getAlertChannels",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetAlertChannels({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type CreateAlertChannelPathParams = {
  /**
   * External reference of the alert channel.
   *
   * @format uuid
   */
  externalReference: string;
  /**
   * The branding ID of the alert channel.
   *
   * @format uuid
   */
  brandingId: string;
  /**
   * The type of the alert
   *
   * @format uuid
   */
  alertTypeId: string;
};

export type CreateAlertChannelError = Fetcher.ErrorWrapper<
  | {
      status: 403;
      payload: Schemas.JsonErrorResponse;
    }
  | {
      status: 422;
      payload: Schemas.JsonErrorResponse;
    }
>;

export type CreateAlertChannelResponse = {
  data: {
    data?: Schemas.AlertChannel;
  };
};

export type CreateAlertChannelRequestBody = {
  data: Schemas.CreateAlertChannel;
};

export type CreateAlertChannelVariables = {
  body: CreateAlertChannelRequestBody;
  pathParams: CreateAlertChannelPathParams;
} & CxContext["fetcherOptions"];

/**
 * This endpoint will create an alert channel.
 */
export const fetchCreateAlertChannel = (
  variables: CreateAlertChannelVariables,
  signal?: AbortSignal
) =>
  cxFetch<
    CreateAlertChannelResponse,
    CreateAlertChannelError,
    CreateAlertChannelRequestBody,
    {},
    {},
    CreateAlertChannelPathParams
  >({
    url: "/v1/consumer_experience/{externalReference}/brandings/{brandingId}/alert_types/{alertTypeId}/alert_channels",
    method: "post",
    ...variables,
    signal,
  });

/**
 * This endpoint will create an alert channel.
 */
export const useCreateAlertChannel = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      CreateAlertChannelResponse,
      CreateAlertChannelError,
      CreateAlertChannelVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCxContext();
  return reactQuery.useMutation<
    CreateAlertChannelResponse,
    CreateAlertChannelError,
    CreateAlertChannelVariables
  >({
    mutationFn: (variables: CreateAlertChannelVariables) =>
      fetchCreateAlertChannel({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DeleteAlertChannelPathParams = {
  /**
   * External reference of the alert channel.
   *
   * @format uuid
   */
  externalReference: string;
  /**
   * The branding ID of the alert channel.
   *
   * @format uuid
   */
  brandingId: string;
  /**
   * The type of the alert
   *
   * @format uuid
   */
  alertTypeId: string;
  /**
   * The ID of the alert channel.
   *
   * @format uuid
   */
  id: string;
};

export type DeleteAlertChannelError = Fetcher.ErrorWrapper<
  | {
      status: 403;
      payload: Schemas.JsonErrorResponse;
    }
  | {
      status: 404;
      payload:
        | {
            errors: {
              detail?: string;
            };
          }
        | {
            errors: {
              detail?: string;
            }[];
          };
    }
>;

export type DeleteAlertChannelVariables = {
  pathParams: DeleteAlertChannelPathParams;
} & CxContext["fetcherOptions"];

/**
 * This endpoint will delete an alert channel.
 */
export const fetchDeleteAlertChannel = (
  variables: DeleteAlertChannelVariables,
  signal?: AbortSignal
) =>
  cxFetch<
    undefined,
    DeleteAlertChannelError,
    undefined,
    {},
    {},
    DeleteAlertChannelPathParams
  >({
    url: "/v1/consumer_experience/{externalReference}/brandings/{brandingId}/alert_types/{alertTypeId}/alert_channels/{id}",
    method: "delete",
    ...variables,
    signal,
  });

/**
 * This endpoint will delete an alert channel.
 */
export const useDeleteAlertChannel = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteAlertChannelError,
      DeleteAlertChannelVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCxContext();
  return reactQuery.useMutation<
    undefined,
    DeleteAlertChannelError,
    DeleteAlertChannelVariables
  >({
    mutationFn: (variables: DeleteAlertChannelVariables) =>
      fetchDeleteAlertChannel({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetAlertChannelPathParams = {
  /**
   * External reference of the alert channel.
   *
   * @format uuid
   */
  externalReference: string;
  /**
   * The branding ID of the alert channel.
   *
   * @format uuid
   */
  brandingId: string;
  /**
   * The type of the alert
   *
   * @format uuid
   */
  alertTypeId: string;
  /**
   * The ID of the alert channel.
   *
   * @format uuid
   */
  id: string;
};

export type GetAlertChannelError = Fetcher.ErrorWrapper<
  | {
      status: 403;
      payload: Schemas.JsonErrorResponse;
    }
  | {
      status: 404;
      payload:
        | {
            errors: {
              detail?: string;
            };
          }
        | {
            errors: {
              detail?: string;
            }[];
          };
    }
>;

export type GetAlertChannelResponse = {
  data: Schemas.AlertChannel;
};

export type GetAlertChannelVariables = {
  pathParams: GetAlertChannelPathParams;
} & CxContext["fetcherOptions"];

/**
 * This endpoint will get an alert channel.
 */
export const fetchGetAlertChannel = (
  variables: GetAlertChannelVariables,
  signal?: AbortSignal
) =>
  cxFetch<
    GetAlertChannelResponse,
    GetAlertChannelError,
    undefined,
    {},
    {},
    GetAlertChannelPathParams
  >({
    url: "/v1/consumer_experience/{externalReference}/brandings/{brandingId}/alert_types/{alertTypeId}/alert_channels/{id}",
    method: "get",
    ...variables,
    signal,
  });

/**
 * This endpoint will get an alert channel.
 */
export const useGetAlertChannel = <TData = GetAlertChannelResponse>(
  variables: GetAlertChannelVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetAlertChannelResponse,
      GetAlertChannelError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCxContext(options);
  return reactQuery.useQuery<
    GetAlertChannelResponse,
    GetAlertChannelError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/v1/consumer_experience/{externalReference}/brandings/{brandingId}/alert_types/{alertTypeId}/alert_channels/{id}",
      operationId: "getAlertChannel",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetAlertChannel({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type UpdateAlertChannelPathParams = {
  /**
   * External reference of the alert channel.
   *
   * @format uuid
   */
  externalReference: string;
  /**
   * The branding ID of the alert channel.
   *
   * @format uuid
   */
  brandingId: string;
  /**
   * The type of the alert
   *
   * @format uuid
   */
  alertTypeId: string;
  /**
   * The ID of the alert channel.
   *
   * @format uuid
   */
  id: string;
};

export type UpdateAlertChannelError = Fetcher.ErrorWrapper<
  | {
      status: 403;
      payload: Schemas.JsonErrorResponse;
    }
  | {
      status: 404;
      payload:
        | {
            errors: {
              detail?: string;
            };
          }
        | {
            errors: {
              detail?: string;
            }[];
          };
    }
  | {
      status: 422;
      payload: Schemas.JsonErrorResponse;
    }
>;

export type UpdateAlertChannelResponse = {
  data: {
    data?: Schemas.AlertChannel;
  };
};

export type UpdateAlertChannelRequestBody = {
  data: Schemas.UpdateAlertChannel;
};

export type UpdateAlertChannelVariables = {
  body: UpdateAlertChannelRequestBody;
  pathParams: UpdateAlertChannelPathParams;
} & CxContext["fetcherOptions"];

/**
 * This endpoint will update an alert channel.
 */
export const fetchUpdateAlertChannel = (
  variables: UpdateAlertChannelVariables,
  signal?: AbortSignal
) =>
  cxFetch<
    UpdateAlertChannelResponse,
    UpdateAlertChannelError,
    UpdateAlertChannelRequestBody,
    {},
    {},
    UpdateAlertChannelPathParams
  >({
    url: "/v1/consumer_experience/{externalReference}/brandings/{brandingId}/alert_types/{alertTypeId}/alert_channels/{id}",
    method: "patch",
    ...variables,
    signal,
  });

/**
 * This endpoint will update an alert channel.
 */
export const useUpdateAlertChannel = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      UpdateAlertChannelResponse,
      UpdateAlertChannelError,
      UpdateAlertChannelVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCxContext();
  return reactQuery.useMutation<
    UpdateAlertChannelResponse,
    UpdateAlertChannelError,
    UpdateAlertChannelVariables
  >({
    mutationFn: (variables: UpdateAlertChannelVariables) =>
      fetchUpdateAlertChannel({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type UpdateAlertChannel2PathParams = {
  /**
   * External reference of the alert channel.
   *
   * @format uuid
   */
  externalReference: string;
  /**
   * The branding ID of the alert channel.
   *
   * @format uuid
   */
  brandingId: string;
  /**
   * The type of the alert
   *
   * @format uuid
   */
  alertTypeId: string;
  /**
   * The ID of the alert channel.
   *
   * @format uuid
   */
  id: string;
};

export type UpdateAlertChannel2Error = Fetcher.ErrorWrapper<
  | {
      status: 403;
      payload: Schemas.JsonErrorResponse;
    }
  | {
      status: 404;
      payload:
        | {
            errors: {
              detail?: string;
            };
          }
        | {
            errors: {
              detail?: string;
            }[];
          };
    }
  | {
      status: 422;
      payload: Schemas.JsonErrorResponse;
    }
>;

export type UpdateAlertChannel2Response = {
  data: {
    data?: Schemas.AlertChannel;
  };
};

export type UpdateAlertChannel2RequestBody = {
  data: Schemas.UpdateAlertChannel;
};

export type UpdateAlertChannel2Variables = {
  body: UpdateAlertChannel2RequestBody;
  pathParams: UpdateAlertChannel2PathParams;
} & CxContext["fetcherOptions"];

/**
 * This endpoint will update an alert channel.
 */
export const fetchUpdateAlertChannel2 = (
  variables: UpdateAlertChannel2Variables,
  signal?: AbortSignal
) =>
  cxFetch<
    UpdateAlertChannel2Response,
    UpdateAlertChannel2Error,
    UpdateAlertChannel2RequestBody,
    {},
    {},
    UpdateAlertChannel2PathParams
  >({
    url: "/v1/consumer_experience/{externalReference}/brandings/{brandingId}/alert_types/{alertTypeId}/alert_channels/{id}",
    method: "put",
    ...variables,
    signal,
  });

/**
 * This endpoint will update an alert channel.
 */
export const useUpdateAlertChannel2 = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      UpdateAlertChannel2Response,
      UpdateAlertChannel2Error,
      UpdateAlertChannel2Variables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCxContext();
  return reactQuery.useMutation<
    UpdateAlertChannel2Response,
    UpdateAlertChannel2Error,
    UpdateAlertChannel2Variables
  >({
    mutationFn: (variables: UpdateAlertChannel2Variables) =>
      fetchUpdateAlertChannel2({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetTemplatePathParams = {
  /**
   * External reference of the template.
   *
   * @format uuid
   */
  externalReference: string;
  /**
   * ID of the branding.
   *
   * @format uuid
   */
  brandingId: string;
  /**
   * ID of the alert type.
   *
   * @format uuid
   */
  alertTypeId: string;
  /**
   * ID of the template.
   *
   * @format uuid
   */
  id: string;
};

export type GetTemplateError = Fetcher.ErrorWrapper<
  | {
      status: 403;
      payload: Schemas.JsonErrorResponse;
    }
  | {
      status: 404;
      payload:
        | {
            errors: {
              detail?: string;
            };
          }
        | {
            errors: {
              detail?: string;
            }[];
          };
    }
>;

export type GetTemplateResponse = {
  data: Schemas.Template;
};

export type GetTemplateVariables = {
  pathParams: GetTemplatePathParams;
} & CxContext["fetcherOptions"];

/**
 * This endpoint will return a template.
 */
export const fetchGetTemplate = (
  variables: GetTemplateVariables,
  signal?: AbortSignal
) =>
  cxFetch<
    GetTemplateResponse,
    GetTemplateError,
    undefined,
    {},
    {},
    GetTemplatePathParams
  >({
    url: "/v1/consumer_experience/{externalReference}/brandings/{brandingId}/alert_types/{alertTypeId}/templates/{id}",
    method: "get",
    ...variables,
    signal,
  });

/**
 * This endpoint will return a template.
 */
export const useGetTemplate = <TData = GetTemplateResponse>(
  variables: GetTemplateVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetTemplateResponse, GetTemplateError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCxContext(options);
  return reactQuery.useQuery<GetTemplateResponse, GetTemplateError, TData>({
    queryKey: queryKeyFn({
      path: "/v1/consumer_experience/{externalReference}/brandings/{brandingId}/alert_types/{alertTypeId}/templates/{id}",
      operationId: "getTemplate",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetTemplate({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type UpdateTemplatePathParams = {
  /**
   * External reference of the template.
   *
   * @format uuid
   */
  externalReference: string;
  /**
   * ID of the branding.
   *
   * @format uuid
   */
  brandingId: string;
  /**
   * ID of the alert type.
   *
   * @format uuid
   */
  alertTypeId: string;
  /**
   * ID of the template.
   *
   * @format uuid
   */
  id: string;
};

export type UpdateTemplateError = Fetcher.ErrorWrapper<
  | {
      status: 403;
      payload: Schemas.JsonErrorResponse;
    }
  | {
      status: 404;
      payload:
        | {
            errors: {
              detail?: string;
            };
          }
        | {
            errors: {
              detail?: string;
            }[];
          };
    }
  | {
      status: 422;
      payload: Schemas.JsonErrorResponse;
    }
>;

export type UpdateTemplateResponse = {
  data: Schemas.TemplateRevision;
};

export type UpdateTemplateRequestBody = {
  data: Schemas.UpdateTemplate;
};

export type UpdateTemplateVariables = {
  body: UpdateTemplateRequestBody;
  pathParams: UpdateTemplatePathParams;
} & CxContext["fetcherOptions"];

/**
 * This endpoint will update a template.
 */
export const fetchUpdateTemplate = (
  variables: UpdateTemplateVariables,
  signal?: AbortSignal
) =>
  cxFetch<
    UpdateTemplateResponse,
    UpdateTemplateError,
    UpdateTemplateRequestBody,
    {},
    {},
    UpdateTemplatePathParams
  >({
    url: "/v1/consumer_experience/{externalReference}/brandings/{brandingId}/alert_types/{alertTypeId}/templates/{id}",
    method: "patch",
    ...variables,
    signal,
  });

/**
 * This endpoint will update a template.
 */
export const useUpdateTemplate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      UpdateTemplateResponse,
      UpdateTemplateError,
      UpdateTemplateVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCxContext();
  return reactQuery.useMutation<
    UpdateTemplateResponse,
    UpdateTemplateError,
    UpdateTemplateVariables
  >({
    mutationFn: (variables: UpdateTemplateVariables) =>
      fetchUpdateTemplate({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type UpdateTemplate2PathParams = {
  /**
   * External reference of the template.
   *
   * @format uuid
   */
  externalReference: string;
  /**
   * ID of the branding.
   *
   * @format uuid
   */
  brandingId: string;
  /**
   * ID of the alert type.
   *
   * @format uuid
   */
  alertTypeId: string;
  /**
   * ID of the template.
   *
   * @format uuid
   */
  id: string;
};

export type UpdateTemplate2Error = Fetcher.ErrorWrapper<
  | {
      status: 403;
      payload: Schemas.JsonErrorResponse;
    }
  | {
      status: 404;
      payload:
        | {
            errors: {
              detail?: string;
            };
          }
        | {
            errors: {
              detail?: string;
            }[];
          };
    }
  | {
      status: 422;
      payload: Schemas.JsonErrorResponse;
    }
>;

export type UpdateTemplate2Response = {
  data: Schemas.TemplateRevision;
};

export type UpdateTemplate2RequestBody = {
  data: Schemas.UpdateTemplate;
};

export type UpdateTemplate2Variables = {
  body: UpdateTemplate2RequestBody;
  pathParams: UpdateTemplate2PathParams;
} & CxContext["fetcherOptions"];

/**
 * This endpoint will update a template.
 */
export const fetchUpdateTemplate2 = (
  variables: UpdateTemplate2Variables,
  signal?: AbortSignal
) =>
  cxFetch<
    UpdateTemplate2Response,
    UpdateTemplate2Error,
    UpdateTemplate2RequestBody,
    {},
    {},
    UpdateTemplate2PathParams
  >({
    url: "/v1/consumer_experience/{externalReference}/brandings/{brandingId}/alert_types/{alertTypeId}/templates/{id}",
    method: "put",
    ...variables,
    signal,
  });

/**
 * This endpoint will update a template.
 */
export const useUpdateTemplate2 = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      UpdateTemplate2Response,
      UpdateTemplate2Error,
      UpdateTemplate2Variables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCxContext();
  return reactQuery.useMutation<
    UpdateTemplate2Response,
    UpdateTemplate2Error,
    UpdateTemplate2Variables
  >({
    mutationFn: (variables: UpdateTemplate2Variables) =>
      fetchUpdateTemplate2({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DiscardDraftPathParams = {
  /**
   * External reference of the template.
   *
   * @format uuid
   */
  externalReference: string;
  /**
   * ID of the branding.
   *
   * @format uuid
   */
  brandingId: string;
  /**
   * ID of the alert type.
   *
   * @format uuid
   */
  alertTypeId: string;
  /**
   * ID of the template.
   *
   * @format uuid
   */
  id: string;
};

export type DiscardDraftError = Fetcher.ErrorWrapper<
  | {
      status: 403;
      payload: Schemas.JsonErrorResponse;
    }
  | {
      status: 404;
      payload:
        | {
            errors: {
              detail?: string;
            };
          }
        | {
            errors: {
              detail?: string;
            }[];
          };
    }
>;

export type DiscardDraftVariables = {
  pathParams: DiscardDraftPathParams;
} & CxContext["fetcherOptions"];

/**
 * This endpoint will discard a draft of a template.
 */
export const fetchDiscardDraft = (
  variables: DiscardDraftVariables,
  signal?: AbortSignal
) =>
  cxFetch<
    undefined,
    DiscardDraftError,
    undefined,
    {},
    {},
    DiscardDraftPathParams
  >({
    url: "/v1/consumer_experience/{externalReference}/brandings/{brandingId}/alert_types/{alertTypeId}/templates/{id}/discard_draft",
    method: "put",
    ...variables,
    signal,
  });

/**
 * This endpoint will discard a draft of a template.
 */
export const useDiscardDraft = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DiscardDraftError,
      DiscardDraftVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCxContext();
  return reactQuery.useMutation<
    undefined,
    DiscardDraftError,
    DiscardDraftVariables
  >({
    mutationFn: (variables: DiscardDraftVariables) =>
      fetchDiscardDraft({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetLatestRevisionPathParams = {
  /**
   * External reference of the template.
   *
   * @format uuid
   */
  externalReference: string;
  /**
   * ID of the branding.
   *
   * @format uuid
   */
  brandingId: string;
  /**
   * ID of the alert type.
   *
   * @format uuid
   */
  alertTypeId: string;
  /**
   * ID of the template.
   *
   * @format uuid
   */
  id: string;
};

export type GetLatestRevisionError = Fetcher.ErrorWrapper<
  | {
      status: 403;
      payload: Schemas.JsonErrorResponse;
    }
  | {
      status: 404;
      payload:
        | {
            errors: {
              detail?: string;
            };
          }
        | {
            errors: {
              detail?: string;
            }[];
          };
    }
>;

export type GetLatestRevisionResponse = {
  data: Schemas.TemplateRevision;
};

export type GetLatestRevisionVariables = {
  pathParams: GetLatestRevisionPathParams;
} & CxContext["fetcherOptions"];

/**
 * This endpoint will return the latest revision of a template.
 */
export const fetchGetLatestRevision = (
  variables: GetLatestRevisionVariables,
  signal?: AbortSignal
) =>
  cxFetch<
    GetLatestRevisionResponse,
    GetLatestRevisionError,
    undefined,
    {},
    {},
    GetLatestRevisionPathParams
  >({
    url: "/v1/consumer_experience/{externalReference}/brandings/{brandingId}/alert_types/{alertTypeId}/templates/{id}/latest_revision",
    method: "get",
    ...variables,
    signal,
  });

/**
 * This endpoint will return the latest revision of a template.
 */
export const useGetLatestRevision = <TData = GetLatestRevisionResponse>(
  variables: GetLatestRevisionVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetLatestRevisionResponse,
      GetLatestRevisionError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCxContext(options);
  return reactQuery.useQuery<
    GetLatestRevisionResponse,
    GetLatestRevisionError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/v1/consumer_experience/{externalReference}/brandings/{brandingId}/alert_types/{alertTypeId}/templates/{id}/latest_revision",
      operationId: "getLatestRevision",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetLatestRevision({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type PublishLatestRevisionPathParams = {
  /**
   * External reference of the template.
   *
   * @format uuid
   */
  externalReference: string;
  /**
   * ID of the branding.
   *
   * @format uuid
   */
  brandingId: string;
  /**
   * ID of the alert type.
   *
   * @format uuid
   */
  alertTypeId: string;
  /**
   * ID of the template.
   *
   * @format uuid
   */
  id: string;
};

export type PublishLatestRevisionError = Fetcher.ErrorWrapper<
  | {
      status: 403;
      payload: Schemas.JsonErrorResponse;
    }
  | {
      status: 404;
      payload:
        | {
            errors: {
              detail?: string;
            };
          }
        | {
            errors: {
              detail?: string;
            }[];
          };
    }
>;

export type PublishLatestRevisionResponse = {
  data: Schemas.Template;
};

export type PublishLatestRevisionVariables = {
  pathParams: PublishLatestRevisionPathParams;
} & CxContext["fetcherOptions"];

/**
 * This endpoint will publish the latest revision of a template.
 */
export const fetchPublishLatestRevision = (
  variables: PublishLatestRevisionVariables,
  signal?: AbortSignal
) =>
  cxFetch<
    PublishLatestRevisionResponse,
    PublishLatestRevisionError,
    undefined,
    {},
    {},
    PublishLatestRevisionPathParams
  >({
    url: "/v1/consumer_experience/{externalReference}/brandings/{brandingId}/alert_types/{alertTypeId}/templates/{id}/latest_revision/publish",
    method: "put",
    ...variables,
    signal,
  });

/**
 * This endpoint will publish the latest revision of a template.
 */
export const usePublishLatestRevision = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      PublishLatestRevisionResponse,
      PublishLatestRevisionError,
      PublishLatestRevisionVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCxContext();
  return reactQuery.useMutation<
    PublishLatestRevisionResponse,
    PublishLatestRevisionError,
    PublishLatestRevisionVariables
  >({
    mutationFn: (variables: PublishLatestRevisionVariables) =>
      fetchPublishLatestRevision({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetDefaultTemplatePathParams = {
  /**
   * External reference of the template.
   *
   * @format uuid
   */
  externalReference: string;
  /**
   * ID of the branding.
   *
   * @format uuid
   */
  brandingId: string;
  /**
   * ID of the alert type.
   *
   * @format uuid
   */
  alertTypeId: string;
  /**
   * Type of the template.
   */
  type: "email";
};

export type GetDefaultTemplateError = Fetcher.ErrorWrapper<
  | {
      status: 403;
      payload: Schemas.JsonErrorResponse;
    }
  | {
      status: 404;
      payload:
        | {
            errors: {
              detail?: string;
            };
          }
        | {
            errors: {
              detail?: string;
            }[];
          };
    }
>;

export type GetDefaultTemplateResponse = {
  data: Schemas.DefaultTemplate;
};

export type GetDefaultTemplateVariables = {
  pathParams: GetDefaultTemplatePathParams;
} & CxContext["fetcherOptions"];

/**
 * This endpoint will return a default template.
 */
export const fetchGetDefaultTemplate = (
  variables: GetDefaultTemplateVariables,
  signal?: AbortSignal
) =>
  cxFetch<
    GetDefaultTemplateResponse,
    GetDefaultTemplateError,
    undefined,
    {},
    {},
    GetDefaultTemplatePathParams
  >({
    url: "/v1/consumer_experience/{externalReference}/brandings/{brandingId}/alert_types/{alertTypeId}/templates/{type}/default",
    method: "get",
    ...variables,
    signal,
  });

/**
 * This endpoint will return a default template.
 */
export const useGetDefaultTemplate = <TData = GetDefaultTemplateResponse>(
  variables: GetDefaultTemplateVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetDefaultTemplateResponse,
      GetDefaultTemplateError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCxContext(options);
  return reactQuery.useQuery<
    GetDefaultTemplateResponse,
    GetDefaultTemplateError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/v1/consumer_experience/{externalReference}/brandings/{brandingId}/alert_types/{alertTypeId}/templates/{type}/default",
      operationId: "getDefaultTemplate",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetDefaultTemplate({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type DeleteAlertTypePathParams = {
  /**
   * External reference of the alert type.
   *
   * @format uuid
   */
  externalReference: string;
  /**
   * The branding ID of the alert type.
   *
   * @format uuid
   */
  brandingId: string;
  /**
   * The ID of the alert type.
   *
   * @format uuid
   */
  id: string;
};

export type DeleteAlertTypeError = Fetcher.ErrorWrapper<
  | {
      status: 403;
      payload: Schemas.JsonErrorResponse;
    }
  | {
      status: 404;
      payload:
        | {
            errors: {
              detail?: string;
            };
          }
        | {
            errors: {
              detail?: string;
            }[];
          };
    }
>;

export type DeleteAlertTypeVariables = {
  pathParams: DeleteAlertTypePathParams;
} & CxContext["fetcherOptions"];

/**
 * This endpoint will delete an alert type.
 */
export const fetchDeleteAlertType = (
  variables: DeleteAlertTypeVariables,
  signal?: AbortSignal
) =>
  cxFetch<
    undefined,
    DeleteAlertTypeError,
    undefined,
    {},
    {},
    DeleteAlertTypePathParams
  >({
    url: "/v1/consumer_experience/{externalReference}/brandings/{brandingId}/alert_types/{id}",
    method: "delete",
    ...variables,
    signal,
  });

/**
 * This endpoint will delete an alert type.
 */
export const useDeleteAlertType = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteAlertTypeError,
      DeleteAlertTypeVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCxContext();
  return reactQuery.useMutation<
    undefined,
    DeleteAlertTypeError,
    DeleteAlertTypeVariables
  >({
    mutationFn: (variables: DeleteAlertTypeVariables) =>
      fetchDeleteAlertType({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetAlertTypePathParams = {
  /**
   * External reference of the alert type.
   *
   * @format uuid
   */
  externalReference: string;
  /**
   * The branding ID of the alert type.
   *
   * @format uuid
   */
  brandingId: string;
  /**
   * The ID of the alert type.
   *
   * @format uuid
   */
  id: string;
};

export type GetAlertTypeError = Fetcher.ErrorWrapper<
  | {
      status: 403;
      payload: Schemas.JsonErrorResponse;
    }
  | {
      status: 404;
      payload:
        | {
            errors: {
              detail?: string;
            };
          }
        | {
            errors: {
              detail?: string;
            }[];
          };
    }
>;

export type GetAlertTypeResponse = {
  data: Schemas.AlertType;
};

export type GetAlertTypeVariables = {
  pathParams: GetAlertTypePathParams;
} & CxContext["fetcherOptions"];

/**
 * This endpoint will show an alert type.
 */
export const fetchGetAlertType = (
  variables: GetAlertTypeVariables,
  signal?: AbortSignal
) =>
  cxFetch<
    GetAlertTypeResponse,
    GetAlertTypeError,
    undefined,
    {},
    {},
    GetAlertTypePathParams
  >({
    url: "/v1/consumer_experience/{externalReference}/brandings/{brandingId}/alert_types/{id}",
    method: "get",
    ...variables,
    signal,
  });

/**
 * This endpoint will show an alert type.
 */
export const useGetAlertType = <TData = GetAlertTypeResponse>(
  variables: GetAlertTypeVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetAlertTypeResponse, GetAlertTypeError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCxContext(options);
  return reactQuery.useQuery<GetAlertTypeResponse, GetAlertTypeError, TData>({
    queryKey: queryKeyFn({
      path: "/v1/consumer_experience/{externalReference}/brandings/{brandingId}/alert_types/{id}",
      operationId: "getAlertType",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetAlertType({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type UpdateAlertTypePathParams = {
  /**
   * External reference of the alert type.
   *
   * @format uuid
   */
  externalReference: string;
  /**
   * The branding ID of the alert type.
   *
   * @format uuid
   */
  brandingId: string;
  /**
   * The ID of the alert type.
   *
   * @format uuid
   */
  id: string;
};

export type UpdateAlertTypeError = Fetcher.ErrorWrapper<
  | {
      status: 403;
      payload: Schemas.JsonErrorResponse;
    }
  | {
      status: 404;
      payload:
        | {
            errors: {
              detail?: string;
            };
          }
        | {
            errors: {
              detail?: string;
            }[];
          };
    }
  | {
      status: 422;
      payload: Schemas.JsonErrorResponse;
    }
>;

export type UpdateAlertTypeResponse = {
  data: {
    data?: Schemas.AlertType;
  };
};

export type UpdateAlertTypeRequestBody = {
  data: Schemas.UpdateAlertType;
};

export type UpdateAlertTypeVariables = {
  body: UpdateAlertTypeRequestBody;
  pathParams: UpdateAlertTypePathParams;
} & CxContext["fetcherOptions"];

/**
 * This endpoint will update an alert type.
 */
export const fetchUpdateAlertType = (
  variables: UpdateAlertTypeVariables,
  signal?: AbortSignal
) =>
  cxFetch<
    UpdateAlertTypeResponse,
    UpdateAlertTypeError,
    UpdateAlertTypeRequestBody,
    {},
    {},
    UpdateAlertTypePathParams
  >({
    url: "/v1/consumer_experience/{externalReference}/brandings/{brandingId}/alert_types/{id}",
    method: "patch",
    ...variables,
    signal,
  });

/**
 * This endpoint will update an alert type.
 */
export const useUpdateAlertType = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      UpdateAlertTypeResponse,
      UpdateAlertTypeError,
      UpdateAlertTypeVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCxContext();
  return reactQuery.useMutation<
    UpdateAlertTypeResponse,
    UpdateAlertTypeError,
    UpdateAlertTypeVariables
  >({
    mutationFn: (variables: UpdateAlertTypeVariables) =>
      fetchUpdateAlertType({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type UpdateAlertType2PathParams = {
  /**
   * External reference of the alert type.
   *
   * @format uuid
   */
  externalReference: string;
  /**
   * The branding ID of the alert type.
   *
   * @format uuid
   */
  brandingId: string;
  /**
   * The ID of the alert type.
   *
   * @format uuid
   */
  id: string;
};

export type UpdateAlertType2Error = Fetcher.ErrorWrapper<
  | {
      status: 403;
      payload: Schemas.JsonErrorResponse;
    }
  | {
      status: 404;
      payload:
        | {
            errors: {
              detail?: string;
            };
          }
        | {
            errors: {
              detail?: string;
            }[];
          };
    }
  | {
      status: 422;
      payload: Schemas.JsonErrorResponse;
    }
>;

export type UpdateAlertType2Response = {
  data: {
    data?: Schemas.AlertType;
  };
};

export type UpdateAlertType2RequestBody = {
  data: Schemas.UpdateAlertType;
};

export type UpdateAlertType2Variables = {
  body: UpdateAlertType2RequestBody;
  pathParams: UpdateAlertType2PathParams;
} & CxContext["fetcherOptions"];

/**
 * This endpoint will update an alert type.
 */
export const fetchUpdateAlertType2 = (
  variables: UpdateAlertType2Variables,
  signal?: AbortSignal
) =>
  cxFetch<
    UpdateAlertType2Response,
    UpdateAlertType2Error,
    UpdateAlertType2RequestBody,
    {},
    {},
    UpdateAlertType2PathParams
  >({
    url: "/v1/consumer_experience/{externalReference}/brandings/{brandingId}/alert_types/{id}",
    method: "put",
    ...variables,
    signal,
  });

/**
 * This endpoint will update an alert type.
 */
export const useUpdateAlertType2 = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      UpdateAlertType2Response,
      UpdateAlertType2Error,
      UpdateAlertType2Variables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCxContext();
  return reactQuery.useMutation<
    UpdateAlertType2Response,
    UpdateAlertType2Error,
    UpdateAlertType2Variables
  >({
    mutationFn: (variables: UpdateAlertType2Variables) =>
      fetchUpdateAlertType2({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetTrackingPortalsPathParams = {
  /**
   * External reference of the configuration.
   *
   * @format uuid
   */
  externalReference: string;
  /**
   * Branding ID of the tracking portal.
   *
   * @format uuid
   */
  brandingId: string;
};

export type GetTrackingPortalsQueryParams = {
  /**
   * Number of records to return per page
   *
   * @minimum 1
   */
  limit?: number;
  /**
   * Page cursor to get the previous page
   */
  before?: string;
  /**
   * Page cursor to get the next page
   */
  after?: string;
  /**
   * Sort by a given property. Accepts fields: name. Sorts should be passed in the format of `&sort=property:asc,property2:desc`
   */
  sort?: string;
};

export type GetTrackingPortalsError = Fetcher.ErrorWrapper<
  | {
      status: 403;
      payload: Schemas.JsonErrorResponse;
    }
  | {
      status: 422;
      payload: Schemas.JsonErrorResponse;
    }
>;

export type GetTrackingPortalsResponse = {
  data: Schemas.TrackingPortal[];
  metadata: Schemas.PaginationMetadata;
};

export type GetTrackingPortalsVariables = {
  pathParams: GetTrackingPortalsPathParams;
  queryParams?: GetTrackingPortalsQueryParams;
} & CxContext["fetcherOptions"];

/**
 * This endpoint will list tracking portals.
 */
export const fetchGetTrackingPortals = (
  variables: GetTrackingPortalsVariables,
  signal?: AbortSignal
) =>
  cxFetch<
    GetTrackingPortalsResponse,
    GetTrackingPortalsError,
    undefined,
    {},
    GetTrackingPortalsQueryParams,
    GetTrackingPortalsPathParams
  >({
    url: "/v1/consumer_experience/{externalReference}/brandings/{brandingId}/tracking_portals",
    method: "get",
    ...variables,
    signal,
  });

/**
 * This endpoint will list tracking portals.
 */
export const useGetTrackingPortals = <TData = GetTrackingPortalsResponse>(
  variables: GetTrackingPortalsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetTrackingPortalsResponse,
      GetTrackingPortalsError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCxContext(options);
  return reactQuery.useQuery<
    GetTrackingPortalsResponse,
    GetTrackingPortalsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/v1/consumer_experience/{externalReference}/brandings/{brandingId}/tracking_portals",
      operationId: "getTrackingPortals",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetTrackingPortals({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type CreateTrackingPortalPathParams = {
  /**
   * External reference of the configuration.
   *
   * @format uuid
   */
  externalReference: string;
  /**
   * Branding ID of the tracking portal.
   *
   * @format uuid
   */
  brandingId: string;
};

export type CreateTrackingPortalError = Fetcher.ErrorWrapper<
  | {
      status: 403;
      payload: Schemas.JsonErrorResponse;
    }
  | {
      status: 422;
      payload: Schemas.JsonErrorResponse;
    }
>;

export type CreateTrackingPortalResponse = {
  data: {
    data?: Schemas.TrackingPortal;
  };
};

export type CreateTrackingPortalRequestBody = {
  data: Schemas.CreateTrackingPortal;
};

export type CreateTrackingPortalVariables = {
  body: CreateTrackingPortalRequestBody;
  pathParams: CreateTrackingPortalPathParams;
} & CxContext["fetcherOptions"];

/**
 * This endpoint will create a tracking portal.
 */
export const fetchCreateTrackingPortal = (
  variables: CreateTrackingPortalVariables,
  signal?: AbortSignal
) =>
  cxFetch<
    CreateTrackingPortalResponse,
    CreateTrackingPortalError,
    CreateTrackingPortalRequestBody,
    {},
    {},
    CreateTrackingPortalPathParams
  >({
    url: "/v1/consumer_experience/{externalReference}/brandings/{brandingId}/tracking_portals",
    method: "post",
    ...variables,
    signal,
  });

/**
 * This endpoint will create a tracking portal.
 */
export const useCreateTrackingPortal = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      CreateTrackingPortalResponse,
      CreateTrackingPortalError,
      CreateTrackingPortalVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCxContext();
  return reactQuery.useMutation<
    CreateTrackingPortalResponse,
    CreateTrackingPortalError,
    CreateTrackingPortalVariables
  >({
    mutationFn: (variables: CreateTrackingPortalVariables) =>
      fetchCreateTrackingPortal({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DeleteTrackingPortalPathParams = {
  /**
   * External reference of the configuration.
   *
   * @format uuid
   */
  externalReference: string;
  /**
   * Branding ID of the tracking portal.
   *
   * @format uuid
   */
  brandingId: string;
  /**
   * The tracking portal ID.
   *
   * @format uuid
   */
  id: string;
};

export type DeleteTrackingPortalError = Fetcher.ErrorWrapper<
  | {
      status: 403;
      payload: Schemas.JsonErrorResponse;
    }
  | {
      status: 404;
      payload:
        | {
            errors: {
              detail?: string;
            };
          }
        | {
            errors: {
              detail?: string;
            }[];
          };
    }
>;

export type DeleteTrackingPortalVariables = {
  pathParams: DeleteTrackingPortalPathParams;
} & CxContext["fetcherOptions"];

/**
 * This endpoint will delete a tracking portal.
 */
export const fetchDeleteTrackingPortal = (
  variables: DeleteTrackingPortalVariables,
  signal?: AbortSignal
) =>
  cxFetch<
    undefined,
    DeleteTrackingPortalError,
    undefined,
    {},
    {},
    DeleteTrackingPortalPathParams
  >({
    url: "/v1/consumer_experience/{externalReference}/brandings/{brandingId}/tracking_portals/{id}",
    method: "delete",
    ...variables,
    signal,
  });

/**
 * This endpoint will delete a tracking portal.
 */
export const useDeleteTrackingPortal = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteTrackingPortalError,
      DeleteTrackingPortalVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCxContext();
  return reactQuery.useMutation<
    undefined,
    DeleteTrackingPortalError,
    DeleteTrackingPortalVariables
  >({
    mutationFn: (variables: DeleteTrackingPortalVariables) =>
      fetchDeleteTrackingPortal({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetTrackingPortalPathParams = {
  /**
   * External reference of the configuration.
   *
   * @format uuid
   */
  externalReference: string;
  /**
   * Branding ID of the tracking portal.
   *
   * @format uuid
   */
  brandingId: string;
  /**
   * The tracking portal ID.
   *
   * @format uuid
   */
  id: string;
};

export type GetTrackingPortalError = Fetcher.ErrorWrapper<
  | {
      status: 403;
      payload: Schemas.JsonErrorResponse;
    }
  | {
      status: 404;
      payload:
        | {
            errors: {
              detail?: string;
            };
          }
        | {
            errors: {
              detail?: string;
            }[];
          };
    }
  | {
      status: 422;
      payload: Schemas.JsonErrorResponse;
    }
>;

export type GetTrackingPortalResponse = {
  data?: Schemas.TrackingPortal;
};

export type GetTrackingPortalVariables = {
  pathParams: GetTrackingPortalPathParams;
} & CxContext["fetcherOptions"];

/**
 * This endpoint will get a tracking portal.
 */
export const fetchGetTrackingPortal = (
  variables: GetTrackingPortalVariables,
  signal?: AbortSignal
) =>
  cxFetch<
    GetTrackingPortalResponse,
    GetTrackingPortalError,
    undefined,
    {},
    {},
    GetTrackingPortalPathParams
  >({
    url: "/v1/consumer_experience/{externalReference}/brandings/{brandingId}/tracking_portals/{id}",
    method: "get",
    ...variables,
    signal,
  });

/**
 * This endpoint will get a tracking portal.
 */
export const useGetTrackingPortal = <TData = GetTrackingPortalResponse>(
  variables: GetTrackingPortalVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetTrackingPortalResponse,
      GetTrackingPortalError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCxContext(options);
  return reactQuery.useQuery<
    GetTrackingPortalResponse,
    GetTrackingPortalError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/v1/consumer_experience/{externalReference}/brandings/{brandingId}/tracking_portals/{id}",
      operationId: "getTrackingPortal",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetTrackingPortal({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type UpdateTrackingPortalPathParams = {
  /**
   * External reference of the configuration.
   *
   * @format uuid
   */
  externalReference: string;
  /**
   * Branding ID of the tracking portal.
   *
   * @format uuid
   */
  brandingId: string;
  /**
   * The tracking portal ID.
   *
   * @format uuid
   */
  id: string;
};

export type UpdateTrackingPortalError = Fetcher.ErrorWrapper<
  | {
      status: 403;
      payload: Schemas.JsonErrorResponse;
    }
  | {
      status: 404;
      payload:
        | {
            errors: {
              detail?: string;
            };
          }
        | {
            errors: {
              detail?: string;
            }[];
          };
    }
>;

export type UpdateTrackingPortalResponse = {
  data: Schemas.TrackingPortal;
};

export type UpdateTrackingPortalRequestBody = {
  data: Schemas.UpdateTrackingPortal;
};

export type UpdateTrackingPortalVariables = {
  body: UpdateTrackingPortalRequestBody;
  pathParams: UpdateTrackingPortalPathParams;
} & CxContext["fetcherOptions"];

/**
 * This endpoint will update a tracking portal.
 */
export const fetchUpdateTrackingPortal = (
  variables: UpdateTrackingPortalVariables,
  signal?: AbortSignal
) =>
  cxFetch<
    UpdateTrackingPortalResponse,
    UpdateTrackingPortalError,
    UpdateTrackingPortalRequestBody,
    {},
    {},
    UpdateTrackingPortalPathParams
  >({
    url: "/v1/consumer_experience/{externalReference}/brandings/{brandingId}/tracking_portals/{id}",
    method: "patch",
    ...variables,
    signal,
  });

/**
 * This endpoint will update a tracking portal.
 */
export const useUpdateTrackingPortal = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      UpdateTrackingPortalResponse,
      UpdateTrackingPortalError,
      UpdateTrackingPortalVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCxContext();
  return reactQuery.useMutation<
    UpdateTrackingPortalResponse,
    UpdateTrackingPortalError,
    UpdateTrackingPortalVariables
  >({
    mutationFn: (variables: UpdateTrackingPortalVariables) =>
      fetchUpdateTrackingPortal({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type UpdateTrackingPortal2PathParams = {
  /**
   * External reference of the configuration.
   *
   * @format uuid
   */
  externalReference: string;
  /**
   * Branding ID of the tracking portal.
   *
   * @format uuid
   */
  brandingId: string;
  /**
   * The tracking portal ID.
   *
   * @format uuid
   */
  id: string;
};

export type UpdateTrackingPortal2Error = Fetcher.ErrorWrapper<
  | {
      status: 403;
      payload: Schemas.JsonErrorResponse;
    }
  | {
      status: 404;
      payload:
        | {
            errors: {
              detail?: string;
            };
          }
        | {
            errors: {
              detail?: string;
            }[];
          };
    }
>;

export type UpdateTrackingPortal2Response = {
  data: Schemas.TrackingPortal;
};

export type UpdateTrackingPortal2RequestBody = {
  data: Schemas.UpdateTrackingPortal;
};

export type UpdateTrackingPortal2Variables = {
  body: UpdateTrackingPortal2RequestBody;
  pathParams: UpdateTrackingPortal2PathParams;
} & CxContext["fetcherOptions"];

/**
 * This endpoint will update a tracking portal.
 */
export const fetchUpdateTrackingPortal2 = (
  variables: UpdateTrackingPortal2Variables,
  signal?: AbortSignal
) =>
  cxFetch<
    UpdateTrackingPortal2Response,
    UpdateTrackingPortal2Error,
    UpdateTrackingPortal2RequestBody,
    {},
    {},
    UpdateTrackingPortal2PathParams
  >({
    url: "/v1/consumer_experience/{externalReference}/brandings/{brandingId}/tracking_portals/{id}",
    method: "put",
    ...variables,
    signal,
  });

/**
 * This endpoint will update a tracking portal.
 */
export const useUpdateTrackingPortal2 = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      UpdateTrackingPortal2Response,
      UpdateTrackingPortal2Error,
      UpdateTrackingPortal2Variables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCxContext();
  return reactQuery.useMutation<
    UpdateTrackingPortal2Response,
    UpdateTrackingPortal2Error,
    UpdateTrackingPortal2Variables
  >({
    mutationFn: (variables: UpdateTrackingPortal2Variables) =>
      fetchUpdateTrackingPortal2({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DeleteBrandingPathParams = {
  /**
   * External reference of the branding.
   *
   * @format uuid
   */
  externalReference: string;
  /**
   * The branding ID.
   *
   * @format uuid
   */
  id: string;
};

export type DeleteBrandingError = Fetcher.ErrorWrapper<
  | {
      status: 403;
      payload: Schemas.JsonErrorResponse;
    }
  | {
      status: 404;
      payload:
        | {
            errors: {
              detail?: string;
            };
          }
        | {
            errors: {
              detail?: string;
            }[];
          };
    }
>;

export type DeleteBrandingVariables = {
  pathParams: DeleteBrandingPathParams;
} & CxContext["fetcherOptions"];

/**
 * This endpoint will delete a branding.
 */
export const fetchDeleteBranding = (
  variables: DeleteBrandingVariables,
  signal?: AbortSignal
) =>
  cxFetch<
    undefined,
    DeleteBrandingError,
    undefined,
    {},
    {},
    DeleteBrandingPathParams
  >({
    url: "/v1/consumer_experience/{externalReference}/brandings/{id}",
    method: "delete",
    ...variables,
    signal,
  });

/**
 * This endpoint will delete a branding.
 */
export const useDeleteBranding = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteBrandingError,
      DeleteBrandingVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCxContext();
  return reactQuery.useMutation<
    undefined,
    DeleteBrandingError,
    DeleteBrandingVariables
  >({
    mutationFn: (variables: DeleteBrandingVariables) =>
      fetchDeleteBranding({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetBrandingPathParams = {
  /**
   * External reference of the branding.
   *
   * @format uuid
   */
  externalReference: string;
  /**
   * The branding ID.
   *
   * @format uuid
   */
  id: string;
};

export type GetBrandingError = Fetcher.ErrorWrapper<
  | {
      status: 403;
      payload: Schemas.JsonErrorResponse;
    }
  | {
      status: 404;
      payload:
        | {
            errors: {
              detail?: string;
            };
          }
        | {
            errors: {
              detail?: string;
            }[];
          };
    }
>;

export type GetBrandingResponse = {
  data: Schemas.Branding;
};

export type GetBrandingVariables = {
  pathParams: GetBrandingPathParams;
} & CxContext["fetcherOptions"];

/**
 * This endpoint will get a branding.
 */
export const fetchGetBranding = (
  variables: GetBrandingVariables,
  signal?: AbortSignal
) =>
  cxFetch<
    GetBrandingResponse,
    GetBrandingError,
    undefined,
    {},
    {},
    GetBrandingPathParams
  >({
    url: "/v1/consumer_experience/{externalReference}/brandings/{id}",
    method: "get",
    ...variables,
    signal,
  });

/**
 * This endpoint will get a branding.
 */
export const useGetBranding = <TData = GetBrandingResponse>(
  variables: GetBrandingVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetBrandingResponse, GetBrandingError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCxContext(options);
  return reactQuery.useQuery<GetBrandingResponse, GetBrandingError, TData>({
    queryKey: queryKeyFn({
      path: "/v1/consumer_experience/{externalReference}/brandings/{id}",
      operationId: "getBranding",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetBranding({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type UpdateBrandingPathParams = {
  /**
   * External reference of the branding.
   *
   * @format uuid
   */
  externalReference: string;
  /**
   * The branding ID.
   *
   * @format uuid
   */
  id: string;
};

export type UpdateBrandingError = Fetcher.ErrorWrapper<
  | {
      status: 403;
      payload: Schemas.JsonErrorResponse;
    }
  | {
      status: 404;
      payload:
        | {
            errors: {
              detail?: string;
            };
          }
        | {
            errors: {
              detail?: string;
            }[];
          };
    }
  | {
      status: 422;
      payload: Schemas.JsonErrorResponse;
    }
>;

export type UpdateBrandingResponse = {
  data: {
    data?: Schemas.Branding;
  };
};

export type UpdateBrandingRequestBody = {
  data: Schemas.UpdateBranding;
};

export type UpdateBrandingVariables = {
  body: UpdateBrandingRequestBody;
  pathParams: UpdateBrandingPathParams;
} & CxContext["fetcherOptions"];

/**
 * This endpoint will update a branding.
 */
export const fetchUpdateBranding = (
  variables: UpdateBrandingVariables,
  signal?: AbortSignal
) =>
  cxFetch<
    UpdateBrandingResponse,
    UpdateBrandingError,
    UpdateBrandingRequestBody,
    {},
    {},
    UpdateBrandingPathParams
  >({
    url: "/v1/consumer_experience/{externalReference}/brandings/{id}",
    method: "patch",
    ...variables,
    signal,
  });

/**
 * This endpoint will update a branding.
 */
export const useUpdateBranding = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      UpdateBrandingResponse,
      UpdateBrandingError,
      UpdateBrandingVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCxContext();
  return reactQuery.useMutation<
    UpdateBrandingResponse,
    UpdateBrandingError,
    UpdateBrandingVariables
  >({
    mutationFn: (variables: UpdateBrandingVariables) =>
      fetchUpdateBranding({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type UpdateBranding2PathParams = {
  /**
   * External reference of the branding.
   *
   * @format uuid
   */
  externalReference: string;
  /**
   * The branding ID.
   *
   * @format uuid
   */
  id: string;
};

export type UpdateBranding2Error = Fetcher.ErrorWrapper<
  | {
      status: 403;
      payload: Schemas.JsonErrorResponse;
    }
  | {
      status: 404;
      payload:
        | {
            errors: {
              detail?: string;
            };
          }
        | {
            errors: {
              detail?: string;
            }[];
          };
    }
  | {
      status: 422;
      payload: Schemas.JsonErrorResponse;
    }
>;

export type UpdateBranding2Response = {
  data: {
    data?: Schemas.Branding;
  };
};

export type UpdateBranding2RequestBody = {
  data: Schemas.UpdateBranding;
};

export type UpdateBranding2Variables = {
  body: UpdateBranding2RequestBody;
  pathParams: UpdateBranding2PathParams;
} & CxContext["fetcherOptions"];

/**
 * This endpoint will update a branding.
 */
export const fetchUpdateBranding2 = (
  variables: UpdateBranding2Variables,
  signal?: AbortSignal
) =>
  cxFetch<
    UpdateBranding2Response,
    UpdateBranding2Error,
    UpdateBranding2RequestBody,
    {},
    {},
    UpdateBranding2PathParams
  >({
    url: "/v1/consumer_experience/{externalReference}/brandings/{id}",
    method: "put",
    ...variables,
    signal,
  });

/**
 * This endpoint will update a branding.
 */
export const useUpdateBranding2 = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      UpdateBranding2Response,
      UpdateBranding2Error,
      UpdateBranding2Variables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCxContext();
  return reactQuery.useMutation<
    UpdateBranding2Response,
    UpdateBranding2Error,
    UpdateBranding2Variables
  >({
    mutationFn: (variables: UpdateBranding2Variables) =>
      fetchUpdateBranding2({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetConfigurationsPathParams = {
  /**
   * External reference of the configuration.
   *
   * @format uuid
   */
  externalReference: string;
};

export type GetConfigurationsQueryParams = {
  /**
   * Number of records to return per page
   *
   * @minimum 1
   */
  limit?: number;
  /**
   * Page cursor to get the previous page
   */
  before?: string;
  /**
   * Page cursor to get the next page
   */
  after?: string;
  /**
   * Sort by a given property. Accepts fields: name. Sorts should be passed in the format of `&sort=property:asc,property2:desc`
   */
  sort?: string;
};

export type GetConfigurationsError = Fetcher.ErrorWrapper<
  | {
      status: 403;
      payload: Schemas.JsonErrorResponse;
    }
  | {
      status: 422;
      payload: Schemas.JsonErrorResponse;
    }
>;

export type GetConfigurationsResponse = {
  data: Schemas.Configuration[];
  metadata: Schemas.PaginationMetadata;
};

export type GetConfigurationsVariables = {
  pathParams: GetConfigurationsPathParams;
  queryParams?: GetConfigurationsQueryParams;
} & CxContext["fetcherOptions"];

/**
 * This endpoint will get configurations.
 */
export const fetchGetConfigurations = (
  variables: GetConfigurationsVariables,
  signal?: AbortSignal
) =>
  cxFetch<
    GetConfigurationsResponse,
    GetConfigurationsError,
    undefined,
    {},
    GetConfigurationsQueryParams,
    GetConfigurationsPathParams
  >({
    url: "/v1/consumer_experience/{externalReference}/configurations",
    method: "get",
    ...variables,
    signal,
  });

/**
 * This endpoint will get configurations.
 */
export const useGetConfigurations = <TData = GetConfigurationsResponse>(
  variables: GetConfigurationsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetConfigurationsResponse,
      GetConfigurationsError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCxContext(options);
  return reactQuery.useQuery<
    GetConfigurationsResponse,
    GetConfigurationsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/v1/consumer_experience/{externalReference}/configurations",
      operationId: "getConfigurations",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetConfigurations({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type CreateConfigurationPathParams = {
  /**
   * External reference of the configuration.
   *
   * @format uuid
   */
  externalReference: string;
};

export type CreateConfigurationError = Fetcher.ErrorWrapper<
  | {
      status: 403;
      payload: Schemas.JsonErrorResponse;
    }
  | {
      status: 422;
      payload: Schemas.JsonErrorResponse;
    }
>;

export type CreateConfigurationResponse = {
  data: {
    data?: Schemas.Configuration;
  };
};

export type CreateConfigurationRequestBody = {
  data: Schemas.CreateConfiguration;
};

export type CreateConfigurationVariables = {
  body: CreateConfigurationRequestBody;
  pathParams: CreateConfigurationPathParams;
} & CxContext["fetcherOptions"];

/**
 * This endpoint will create a configuration.
 */
export const fetchCreateConfiguration = (
  variables: CreateConfigurationVariables,
  signal?: AbortSignal
) =>
  cxFetch<
    CreateConfigurationResponse,
    CreateConfigurationError,
    CreateConfigurationRequestBody,
    {},
    {},
    CreateConfigurationPathParams
  >({
    url: "/v1/consumer_experience/{externalReference}/configurations",
    method: "post",
    ...variables,
    signal,
  });

/**
 * This endpoint will create a configuration.
 */
export const useCreateConfiguration = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      CreateConfigurationResponse,
      CreateConfigurationError,
      CreateConfigurationVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCxContext();
  return reactQuery.useMutation<
    CreateConfigurationResponse,
    CreateConfigurationError,
    CreateConfigurationVariables
  >({
    mutationFn: (variables: CreateConfigurationVariables) =>
      fetchCreateConfiguration({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DeleteConfigurationPathParams = {
  /**
   * External reference of the configuration.
   *
   * @format uuid
   */
  externalReference: string;
  /**
   * The configuration ID.
   *
   * @format uuid
   */
  id: string;
};

export type DeleteConfigurationError = Fetcher.ErrorWrapper<
  | {
      status: 403;
      payload: Schemas.JsonErrorResponse;
    }
  | {
      status: 404;
      payload:
        | {
            errors: {
              detail?: string;
            };
          }
        | {
            errors: {
              detail?: string;
            }[];
          };
    }
>;

export type DeleteConfigurationVariables = {
  pathParams: DeleteConfigurationPathParams;
} & CxContext["fetcherOptions"];

/**
 * This endpoint will delete a configuration.
 */
export const fetchDeleteConfiguration = (
  variables: DeleteConfigurationVariables,
  signal?: AbortSignal
) =>
  cxFetch<
    undefined,
    DeleteConfigurationError,
    undefined,
    {},
    {},
    DeleteConfigurationPathParams
  >({
    url: "/v1/consumer_experience/{externalReference}/configurations/{id}",
    method: "delete",
    ...variables,
    signal,
  });

/**
 * This endpoint will delete a configuration.
 */
export const useDeleteConfiguration = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteConfigurationError,
      DeleteConfigurationVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCxContext();
  return reactQuery.useMutation<
    undefined,
    DeleteConfigurationError,
    DeleteConfigurationVariables
  >({
    mutationFn: (variables: DeleteConfigurationVariables) =>
      fetchDeleteConfiguration({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetConfigurationPathParams = {
  /**
   * External reference of the configuration.
   *
   * @format uuid
   */
  externalReference: string;
  /**
   * The configuration ID.
   *
   * @format uuid
   */
  id: string;
};

export type GetConfigurationError = Fetcher.ErrorWrapper<
  | {
      status: 403;
      payload: Schemas.JsonErrorResponse;
    }
  | {
      status: 404;
      payload:
        | {
            errors: {
              detail?: string;
            };
          }
        | {
            errors: {
              detail?: string;
            }[];
          };
    }
>;

export type GetConfigurationResponse = {
  data: Schemas.Configuration;
};

export type GetConfigurationVariables = {
  pathParams: GetConfigurationPathParams;
} & CxContext["fetcherOptions"];

/**
 * This endpoint will get a configuration.
 */
export const fetchGetConfiguration = (
  variables: GetConfigurationVariables,
  signal?: AbortSignal
) =>
  cxFetch<
    GetConfigurationResponse,
    GetConfigurationError,
    undefined,
    {},
    {},
    GetConfigurationPathParams
  >({
    url: "/v1/consumer_experience/{externalReference}/configurations/{id}",
    method: "get",
    ...variables,
    signal,
  });

/**
 * This endpoint will get a configuration.
 */
export const useGetConfiguration = <TData = GetConfigurationResponse>(
  variables: GetConfigurationVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetConfigurationResponse,
      GetConfigurationError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCxContext(options);
  return reactQuery.useQuery<
    GetConfigurationResponse,
    GetConfigurationError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/v1/consumer_experience/{externalReference}/configurations/{id}",
      operationId: "getConfiguration",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetConfiguration({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type UpdateConfigurationPathParams = {
  /**
   * External reference of the configuration.
   *
   * @format uuid
   */
  externalReference: string;
  /**
   * The configuration ID.
   *
   * @format uuid
   */
  id: string;
};

export type UpdateConfigurationError = Fetcher.ErrorWrapper<
  | {
      status: 403;
      payload: Schemas.JsonErrorResponse;
    }
  | {
      status: 404;
      payload:
        | {
            errors: {
              detail?: string;
            };
          }
        | {
            errors: {
              detail?: string;
            }[];
          };
    }
  | {
      status: 422;
      payload: Schemas.JsonErrorResponse;
    }
>;

export type UpdateConfigurationResponse = {
  data: {
    data?: Schemas.Configuration;
  };
};

export type UpdateConfigurationRequestBody = {
  data: Schemas.UpdateConfiguration;
};

export type UpdateConfigurationVariables = {
  body: UpdateConfigurationRequestBody;
  pathParams: UpdateConfigurationPathParams;
} & CxContext["fetcherOptions"];

/**
 * This endpoint will update a configuration.
 */
export const fetchUpdateConfiguration = (
  variables: UpdateConfigurationVariables,
  signal?: AbortSignal
) =>
  cxFetch<
    UpdateConfigurationResponse,
    UpdateConfigurationError,
    UpdateConfigurationRequestBody,
    {},
    {},
    UpdateConfigurationPathParams
  >({
    url: "/v1/consumer_experience/{externalReference}/configurations/{id}",
    method: "patch",
    ...variables,
    signal,
  });

/**
 * This endpoint will update a configuration.
 */
export const useUpdateConfiguration = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      UpdateConfigurationResponse,
      UpdateConfigurationError,
      UpdateConfigurationVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCxContext();
  return reactQuery.useMutation<
    UpdateConfigurationResponse,
    UpdateConfigurationError,
    UpdateConfigurationVariables
  >({
    mutationFn: (variables: UpdateConfigurationVariables) =>
      fetchUpdateConfiguration({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type UpdateConfiguration2PathParams = {
  /**
   * External reference of the configuration.
   *
   * @format uuid
   */
  externalReference: string;
  /**
   * The configuration ID.
   *
   * @format uuid
   */
  id: string;
};

export type UpdateConfiguration2Error = Fetcher.ErrorWrapper<
  | {
      status: 403;
      payload: Schemas.JsonErrorResponse;
    }
  | {
      status: 404;
      payload:
        | {
            errors: {
              detail?: string;
            };
          }
        | {
            errors: {
              detail?: string;
            }[];
          };
    }
  | {
      status: 422;
      payload: Schemas.JsonErrorResponse;
    }
>;

export type UpdateConfiguration2Response = {
  data: {
    data?: Schemas.Configuration;
  };
};

export type UpdateConfiguration2RequestBody = {
  data: Schemas.UpdateConfiguration;
};

export type UpdateConfiguration2Variables = {
  body: UpdateConfiguration2RequestBody;
  pathParams: UpdateConfiguration2PathParams;
} & CxContext["fetcherOptions"];

/**
 * This endpoint will update a configuration.
 */
export const fetchUpdateConfiguration2 = (
  variables: UpdateConfiguration2Variables,
  signal?: AbortSignal
) =>
  cxFetch<
    UpdateConfiguration2Response,
    UpdateConfiguration2Error,
    UpdateConfiguration2RequestBody,
    {},
    {},
    UpdateConfiguration2PathParams
  >({
    url: "/v1/consumer_experience/{externalReference}/configurations/{id}",
    method: "put",
    ...variables,
    signal,
  });

/**
 * This endpoint will update a configuration.
 */
export const useUpdateConfiguration2 = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      UpdateConfiguration2Response,
      UpdateConfiguration2Error,
      UpdateConfiguration2Variables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCxContext();
  return reactQuery.useMutation<
    UpdateConfiguration2Response,
    UpdateConfiguration2Error,
    UpdateConfiguration2Variables
  >({
    mutationFn: (variables: UpdateConfiguration2Variables) =>
      fetchUpdateConfiguration2({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetDeliveryOptionsPathParams = {
  /**
   * External reference of the delivery option.
   *
   * @format uuid
   */
  externalReference: string;
};

export type GetDeliveryOptionsQueryParams = {
  /**
   * Number of records to return per page
   *
   * @minimum 1
   */
  limit?: number;
  /**
   * Page cursor to get the previous page
   */
  before?: string;
  /**
   * Page cursor to get the next page
   */
  after?: string;
  /**
   * Sort by a given property. Accepts fields: inserted_at, updated_at. Sorts should be passed in the format of `&sort=property:asc,property2:desc`
   */
  sort?: string;
};

export type GetDeliveryOptionsError = Fetcher.ErrorWrapper<
  | {
      status: 403;
      payload: Schemas.JsonErrorResponse;
    }
  | {
      status: 422;
      payload: Schemas.JsonErrorResponse;
    }
>;

export type GetDeliveryOptionsResponse = {
  data: Schemas.DeliveryOption[];
  metadata: Schemas.PaginationMetadata;
};

export type GetDeliveryOptionsVariables = {
  pathParams: GetDeliveryOptionsPathParams;
  queryParams?: GetDeliveryOptionsQueryParams;
} & CxContext["fetcherOptions"];

/**
 * This endpoint will get delivery options.
 */
export const fetchGetDeliveryOptions = (
  variables: GetDeliveryOptionsVariables,
  signal?: AbortSignal
) =>
  cxFetch<
    GetDeliveryOptionsResponse,
    GetDeliveryOptionsError,
    undefined,
    {},
    GetDeliveryOptionsQueryParams,
    GetDeliveryOptionsPathParams
  >({
    url: "/v1/consumer_experience/{externalReference}/delivery_options",
    method: "get",
    ...variables,
    signal,
  });

/**
 * This endpoint will get delivery options.
 */
export const useGetDeliveryOptions = <TData = GetDeliveryOptionsResponse>(
  variables: GetDeliveryOptionsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetDeliveryOptionsResponse,
      GetDeliveryOptionsError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCxContext(options);
  return reactQuery.useQuery<
    GetDeliveryOptionsResponse,
    GetDeliveryOptionsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/v1/consumer_experience/{externalReference}/delivery_options",
      operationId: "getDeliveryOptions",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetDeliveryOptions({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type CreateDeliveryOptionPathParams = {
  /**
   * External reference of the delivery option.
   *
   * @format uuid
   */
  externalReference: string;
};

export type CreateDeliveryOptionError = Fetcher.ErrorWrapper<
  | {
      status: 403;
      payload: Schemas.JsonErrorResponse;
    }
  | {
      status: 422;
      payload: Schemas.JsonErrorResponse;
    }
>;

export type CreateDeliveryOptionResponse = {
  data: {
    data?: Schemas.DeliveryOption;
  };
};

export type CreateDeliveryOptionRequestBody = {
  data: Schemas.CreateDeliveryOption;
};

export type CreateDeliveryOptionVariables = {
  body: CreateDeliveryOptionRequestBody;
  pathParams: CreateDeliveryOptionPathParams;
} & CxContext["fetcherOptions"];

/**
 * This endpoint will create a delivery option.
 */
export const fetchCreateDeliveryOption = (
  variables: CreateDeliveryOptionVariables,
  signal?: AbortSignal
) =>
  cxFetch<
    CreateDeliveryOptionResponse,
    CreateDeliveryOptionError,
    CreateDeliveryOptionRequestBody,
    {},
    {},
    CreateDeliveryOptionPathParams
  >({
    url: "/v1/consumer_experience/{externalReference}/delivery_options",
    method: "post",
    ...variables,
    signal,
  });

/**
 * This endpoint will create a delivery option.
 */
export const useCreateDeliveryOption = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      CreateDeliveryOptionResponse,
      CreateDeliveryOptionError,
      CreateDeliveryOptionVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCxContext();
  return reactQuery.useMutation<
    CreateDeliveryOptionResponse,
    CreateDeliveryOptionError,
    CreateDeliveryOptionVariables
  >({
    mutationFn: (variables: CreateDeliveryOptionVariables) =>
      fetchCreateDeliveryOption({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DeleteDeliveryOptionPathParams = {
  /**
   * External reference of the delivery option.
   *
   * @format uuid
   */
  externalReference: string;
  /**
   * ID of the delivery option.
   *
   * @format uuid
   */
  id: string;
};

export type DeleteDeliveryOptionError = Fetcher.ErrorWrapper<
  | {
      status: 403;
      payload: Schemas.JsonErrorResponse;
    }
  | {
      status: 404;
      payload:
        | {
            errors: {
              detail?: string;
            };
          }
        | {
            errors: {
              detail?: string;
            }[];
          };
    }
>;

export type DeleteDeliveryOptionVariables = {
  pathParams: DeleteDeliveryOptionPathParams;
} & CxContext["fetcherOptions"];

/**
 * This endpoint will delete a delivery option.
 */
export const fetchDeleteDeliveryOption = (
  variables: DeleteDeliveryOptionVariables,
  signal?: AbortSignal
) =>
  cxFetch<
    undefined,
    DeleteDeliveryOptionError,
    undefined,
    {},
    {},
    DeleteDeliveryOptionPathParams
  >({
    url: "/v1/consumer_experience/{externalReference}/delivery_options/{id}",
    method: "delete",
    ...variables,
    signal,
  });

/**
 * This endpoint will delete a delivery option.
 */
export const useDeleteDeliveryOption = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteDeliveryOptionError,
      DeleteDeliveryOptionVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCxContext();
  return reactQuery.useMutation<
    undefined,
    DeleteDeliveryOptionError,
    DeleteDeliveryOptionVariables
  >({
    mutationFn: (variables: DeleteDeliveryOptionVariables) =>
      fetchDeleteDeliveryOption({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetDeliveryOptionPathParams = {
  /**
   * External reference of the delivery option.
   *
   * @format uuid
   */
  externalReference: string;
  /**
   * ID of the delivery option.
   *
   * @format uuid
   */
  id: string;
};

export type GetDeliveryOptionError = Fetcher.ErrorWrapper<
  | {
      status: 403;
      payload: Schemas.JsonErrorResponse;
    }
  | {
      status: 404;
      payload:
        | {
            errors: {
              detail?: string;
            };
          }
        | {
            errors: {
              detail?: string;
            }[];
          };
    }
>;

export type GetDeliveryOptionResponse = {
  data: Schemas.DeliveryOption;
};

export type GetDeliveryOptionVariables = {
  pathParams: GetDeliveryOptionPathParams;
} & CxContext["fetcherOptions"];

/**
 * This endpoint will get a delivery option.
 */
export const fetchGetDeliveryOption = (
  variables: GetDeliveryOptionVariables,
  signal?: AbortSignal
) =>
  cxFetch<
    GetDeliveryOptionResponse,
    GetDeliveryOptionError,
    undefined,
    {},
    {},
    GetDeliveryOptionPathParams
  >({
    url: "/v1/consumer_experience/{externalReference}/delivery_options/{id}",
    method: "get",
    ...variables,
    signal,
  });

/**
 * This endpoint will get a delivery option.
 */
export const useGetDeliveryOption = <TData = GetDeliveryOptionResponse>(
  variables: GetDeliveryOptionVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetDeliveryOptionResponse,
      GetDeliveryOptionError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCxContext(options);
  return reactQuery.useQuery<
    GetDeliveryOptionResponse,
    GetDeliveryOptionError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/v1/consumer_experience/{externalReference}/delivery_options/{id}",
      operationId: "getDeliveryOption",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetDeliveryOption({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type UpdateDeliveryOptionPathParams = {
  /**
   * External reference of the delivery option.
   *
   * @format uuid
   */
  externalReference: string;
  /**
   * ID of the delivery option.
   *
   * @format uuid
   */
  id: string;
};

export type UpdateDeliveryOptionError = Fetcher.ErrorWrapper<
  | {
      status: 403;
      payload: Schemas.JsonErrorResponse;
    }
  | {
      status: 404;
      payload:
        | {
            errors: {
              detail?: string;
            };
          }
        | {
            errors: {
              detail?: string;
            }[];
          };
    }
  | {
      status: 422;
      payload: Schemas.JsonErrorResponse;
    }
>;

export type UpdateDeliveryOptionResponse = {
  data: Schemas.DeliveryOption;
};

export type UpdateDeliveryOptionRequestBody = {
  data: Schemas.UpdateDeliveryOption;
};

export type UpdateDeliveryOptionVariables = {
  body: UpdateDeliveryOptionRequestBody;
  pathParams: UpdateDeliveryOptionPathParams;
} & CxContext["fetcherOptions"];

/**
 * This endpoint will update a delivery option.
 */
export const fetchUpdateDeliveryOption = (
  variables: UpdateDeliveryOptionVariables,
  signal?: AbortSignal
) =>
  cxFetch<
    UpdateDeliveryOptionResponse,
    UpdateDeliveryOptionError,
    UpdateDeliveryOptionRequestBody,
    {},
    {},
    UpdateDeliveryOptionPathParams
  >({
    url: "/v1/consumer_experience/{externalReference}/delivery_options/{id}",
    method: "patch",
    ...variables,
    signal,
  });

/**
 * This endpoint will update a delivery option.
 */
export const useUpdateDeliveryOption = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      UpdateDeliveryOptionResponse,
      UpdateDeliveryOptionError,
      UpdateDeliveryOptionVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCxContext();
  return reactQuery.useMutation<
    UpdateDeliveryOptionResponse,
    UpdateDeliveryOptionError,
    UpdateDeliveryOptionVariables
  >({
    mutationFn: (variables: UpdateDeliveryOptionVariables) =>
      fetchUpdateDeliveryOption({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type UpdateDeliveryOption2PathParams = {
  /**
   * External reference of the delivery option.
   *
   * @format uuid
   */
  externalReference: string;
  /**
   * ID of the delivery option.
   *
   * @format uuid
   */
  id: string;
};

export type UpdateDeliveryOption2Error = Fetcher.ErrorWrapper<
  | {
      status: 403;
      payload: Schemas.JsonErrorResponse;
    }
  | {
      status: 404;
      payload:
        | {
            errors: {
              detail?: string;
            };
          }
        | {
            errors: {
              detail?: string;
            }[];
          };
    }
  | {
      status: 422;
      payload: Schemas.JsonErrorResponse;
    }
>;

export type UpdateDeliveryOption2Response = {
  data: Schemas.DeliveryOption;
};

export type UpdateDeliveryOption2RequestBody = {
  data: Schemas.UpdateDeliveryOption;
};

export type UpdateDeliveryOption2Variables = {
  body: UpdateDeliveryOption2RequestBody;
  pathParams: UpdateDeliveryOption2PathParams;
} & CxContext["fetcherOptions"];

/**
 * This endpoint will update a delivery option.
 */
export const fetchUpdateDeliveryOption2 = (
  variables: UpdateDeliveryOption2Variables,
  signal?: AbortSignal
) =>
  cxFetch<
    UpdateDeliveryOption2Response,
    UpdateDeliveryOption2Error,
    UpdateDeliveryOption2RequestBody,
    {},
    {},
    UpdateDeliveryOption2PathParams
  >({
    url: "/v1/consumer_experience/{externalReference}/delivery_options/{id}",
    method: "put",
    ...variables,
    signal,
  });

/**
 * This endpoint will update a delivery option.
 */
export const useUpdateDeliveryOption2 = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      UpdateDeliveryOption2Response,
      UpdateDeliveryOption2Error,
      UpdateDeliveryOption2Variables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCxContext();
  return reactQuery.useMutation<
    UpdateDeliveryOption2Response,
    UpdateDeliveryOption2Error,
    UpdateDeliveryOption2Variables
  >({
    mutationFn: (variables: UpdateDeliveryOption2Variables) =>
      fetchUpdateDeliveryOption2({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetShipmentProtectionClaimsPathParams = {
  /**
   * The reference of the entity that the claim belongs to
   *
   * @format uuid
   */
  externalReference: string;
};

export type GetShipmentProtectionClaimsQueryParams = {
  /**
   * Number of records to return per page
   *
   * @minimum 1
   */
  limit?: number;
  /**
   * Page cursor to get the previous page
   */
  before?: string;
  /**
   * Page cursor to get the next page
   */
  after?: string;
  /**
   * Sort by a given property. Accepts fields: inserted_at, updated_at. Sorts should be passed in the format of `&sort=property:asc,property2:desc`
   */
  sort?: string;
  /**
   * Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2`
   */
  filter?: {
    order_reference?: string;
    provider_status?: string;
    status?: string;
  };
  /**
   * Search by the following fields: order_reference, claim_id
   *
   * @minLength 1
   */
  search?: string;
};

export type GetShipmentProtectionClaimsError = Fetcher.ErrorWrapper<
  | {
      status: 403;
      payload: Schemas.JsonErrorResponse;
    }
  | {
      status: 422;
      payload: Schemas.JsonErrorResponse;
    }
>;

export type GetShipmentProtectionClaimsResponse = {
  data: Schemas.Claim[];
  metadata: Schemas.PaginationMetadata;
};

export type GetShipmentProtectionClaimsVariables = {
  pathParams: GetShipmentProtectionClaimsPathParams;
  queryParams?: GetShipmentProtectionClaimsQueryParams;
} & CxContext["fetcherOptions"];

/**
 * Returns a collection of claims
 */
export const fetchGetShipmentProtectionClaims = (
  variables: GetShipmentProtectionClaimsVariables,
  signal?: AbortSignal
) =>
  cxFetch<
    GetShipmentProtectionClaimsResponse,
    GetShipmentProtectionClaimsError,
    undefined,
    {},
    GetShipmentProtectionClaimsQueryParams,
    GetShipmentProtectionClaimsPathParams
  >({
    url: "/v1/consumer_experience/{externalReference}/shipment_protection/claims",
    method: "get",
    ...variables,
    signal,
  });

/**
 * Returns a collection of claims
 */
export const useGetShipmentProtectionClaims = <
  TData = GetShipmentProtectionClaimsResponse
>(
  variables: GetShipmentProtectionClaimsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetShipmentProtectionClaimsResponse,
      GetShipmentProtectionClaimsError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCxContext(options);
  return reactQuery.useQuery<
    GetShipmentProtectionClaimsResponse,
    GetShipmentProtectionClaimsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/v1/consumer_experience/{externalReference}/shipment_protection/claims",
      operationId: "getShipmentProtectionClaims",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetShipmentProtectionClaims(
        { ...fetcherOptions, ...variables },
        signal
      ),
    ...options,
    ...queryOptions,
  });
};

export type CreateShipmentProtectionClaimPathParams = {
  /**
   * The reference of the entity that the claim belongs to
   *
   * @format uuid
   */
  externalReference: string;
};

export type CreateShipmentProtectionClaimError = Fetcher.ErrorWrapper<
  | {
      status: 403;
      payload: Schemas.JsonErrorResponse;
    }
  | {
      status: 422;
      payload: Schemas.JsonErrorResponse;
    }
>;

export type CreateShipmentProtectionClaimResponse = {
  data: {
    data?: Schemas.Claim;
  };
};

export type CreateShipmentProtectionClaimRequestBody = {
  data: Schemas.CreateClaim;
};

export type CreateShipmentProtectionClaimVariables = {
  body: CreateShipmentProtectionClaimRequestBody;
  pathParams: CreateShipmentProtectionClaimPathParams;
} & CxContext["fetcherOptions"];

/**
 * This endpoint will create an internal record of claim for the given provider
 */
export const fetchCreateShipmentProtectionClaim = (
  variables: CreateShipmentProtectionClaimVariables,
  signal?: AbortSignal
) =>
  cxFetch<
    CreateShipmentProtectionClaimResponse,
    CreateShipmentProtectionClaimError,
    CreateShipmentProtectionClaimRequestBody,
    {},
    {},
    CreateShipmentProtectionClaimPathParams
  >({
    url: "/v1/consumer_experience/{externalReference}/shipment_protection/claims",
    method: "post",
    ...variables,
    signal,
  });

/**
 * This endpoint will create an internal record of claim for the given provider
 */
export const useCreateShipmentProtectionClaim = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      CreateShipmentProtectionClaimResponse,
      CreateShipmentProtectionClaimError,
      CreateShipmentProtectionClaimVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCxContext();
  return reactQuery.useMutation<
    CreateShipmentProtectionClaimResponse,
    CreateShipmentProtectionClaimError,
    CreateShipmentProtectionClaimVariables
  >({
    mutationFn: (variables: CreateShipmentProtectionClaimVariables) =>
      fetchCreateShipmentProtectionClaim({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetShipmentProtectionClaimSummaryPathParams = {
  /**
   * The reference of the entity that the claim belongs to
   *
   * @format uuid
   */
  externalReference: string;
};

export type GetShipmentProtectionClaimSummaryError = Fetcher.ErrorWrapper<{
  status: 403;
  payload: Schemas.JsonErrorResponse;
}>;

export type GetShipmentProtectionClaimSummaryResponse = {
  data: Schemas.ClaimSummary;
};

export type GetShipmentProtectionClaimSummaryVariables = {
  pathParams: GetShipmentProtectionClaimSummaryPathParams;
} & CxContext["fetcherOptions"];

/**
 * Returns aggregate counts of claims for a network
 */
export const fetchGetShipmentProtectionClaimSummary = (
  variables: GetShipmentProtectionClaimSummaryVariables,
  signal?: AbortSignal
) =>
  cxFetch<
    GetShipmentProtectionClaimSummaryResponse,
    GetShipmentProtectionClaimSummaryError,
    undefined,
    {},
    {},
    GetShipmentProtectionClaimSummaryPathParams
  >({
    url: "/v1/consumer_experience/{externalReference}/shipment_protection/claims/summary",
    method: "get",
    ...variables,
    signal,
  });

/**
 * Returns aggregate counts of claims for a network
 */
export const useGetShipmentProtectionClaimSummary = <
  TData = GetShipmentProtectionClaimSummaryResponse
>(
  variables: GetShipmentProtectionClaimSummaryVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetShipmentProtectionClaimSummaryResponse,
      GetShipmentProtectionClaimSummaryError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCxContext(options);
  return reactQuery.useQuery<
    GetShipmentProtectionClaimSummaryResponse,
    GetShipmentProtectionClaimSummaryError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/v1/consumer_experience/{externalReference}/shipment_protection/claims/summary",
      operationId: "getShipmentProtectionClaimSummary",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetShipmentProtectionClaimSummary(
        { ...fetcherOptions, ...variables },
        signal
      ),
    ...options,
    ...queryOptions,
  });
};

export type GetShipmentProtectionClaimPathParams = {
  /**
   * The reference of the entity that the claim belongs to
   *
   * @format uuid
   */
  externalReference: string;
  /**
   * The claim ID
   *
   * @format uuid
   */
  id: string;
};

export type GetShipmentProtectionClaimError = Fetcher.ErrorWrapper<
  | {
      status: 403;
      payload: Schemas.JsonErrorResponse;
    }
  | {
      status: 404;
      payload:
        | {
            errors: {
              detail?: string;
            };
          }
        | {
            errors: {
              detail?: string;
            }[];
          };
    }
>;

export type GetShipmentProtectionClaimResponse = {
  data: Schemas.Claim;
};

export type GetShipmentProtectionClaimVariables = {
  pathParams: GetShipmentProtectionClaimPathParams;
} & CxContext["fetcherOptions"];

/**
 * Return an individual claim
 */
export const fetchGetShipmentProtectionClaim = (
  variables: GetShipmentProtectionClaimVariables,
  signal?: AbortSignal
) =>
  cxFetch<
    GetShipmentProtectionClaimResponse,
    GetShipmentProtectionClaimError,
    undefined,
    {},
    {},
    GetShipmentProtectionClaimPathParams
  >({
    url: "/v1/consumer_experience/{externalReference}/shipment_protection/claims/{id}",
    method: "get",
    ...variables,
    signal,
  });

/**
 * Return an individual claim
 */
export const useGetShipmentProtectionClaim = <
  TData = GetShipmentProtectionClaimResponse
>(
  variables: GetShipmentProtectionClaimVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetShipmentProtectionClaimResponse,
      GetShipmentProtectionClaimError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCxContext(options);
  return reactQuery.useQuery<
    GetShipmentProtectionClaimResponse,
    GetShipmentProtectionClaimError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/v1/consumer_experience/{externalReference}/shipment_protection/claims/{id}",
      operationId: "getShipmentProtectionClaim",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetShipmentProtectionClaim(
        { ...fetcherOptions, ...variables },
        signal
      ),
    ...options,
    ...queryOptions,
  });
};

export type UpdateShipmentProtectionClaimPathParams = {
  /**
   * The reference of the entity that the claim belongs to
   *
   * @format uuid
   */
  externalReference: string;
  /**
   * The claim ID
   *
   * @format uuid
   */
  id: string;
};

export type UpdateShipmentProtectionClaimError = Fetcher.ErrorWrapper<
  | {
      status: 403;
      payload: Schemas.JsonErrorResponse;
    }
  | {
      status: 404;
      payload:
        | {
            errors: {
              detail?: string;
            };
          }
        | {
            errors: {
              detail?: string;
            }[];
          };
    }
  | {
      status: 422;
      payload: Schemas.JsonErrorResponse;
    }
>;

export type UpdateShipmentProtectionClaimResponse = {
  data: {
    data?: Schemas.Claim;
  };
};

export type UpdateShipmentProtectionClaimRequestBody = {
  data: Schemas.UpdateClaim;
};

export type UpdateShipmentProtectionClaimVariables = {
  body: UpdateShipmentProtectionClaimRequestBody;
  pathParams: UpdateShipmentProtectionClaimPathParams;
} & CxContext["fetcherOptions"];

/**
 * Update certain fields of a claim
 */
export const fetchUpdateShipmentProtectionClaim = (
  variables: UpdateShipmentProtectionClaimVariables,
  signal?: AbortSignal
) =>
  cxFetch<
    UpdateShipmentProtectionClaimResponse,
    UpdateShipmentProtectionClaimError,
    UpdateShipmentProtectionClaimRequestBody,
    {},
    {},
    UpdateShipmentProtectionClaimPathParams
  >({
    url: "/v1/consumer_experience/{externalReference}/shipment_protection/claims/{id}",
    method: "patch",
    ...variables,
    signal,
  });

/**
 * Update certain fields of a claim
 */
export const useUpdateShipmentProtectionClaim = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      UpdateShipmentProtectionClaimResponse,
      UpdateShipmentProtectionClaimError,
      UpdateShipmentProtectionClaimVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCxContext();
  return reactQuery.useMutation<
    UpdateShipmentProtectionClaimResponse,
    UpdateShipmentProtectionClaimError,
    UpdateShipmentProtectionClaimVariables
  >({
    mutationFn: (variables: UpdateShipmentProtectionClaimVariables) =>
      fetchUpdateShipmentProtectionClaim({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type UpdateShipmentProtectionClaim2PathParams = {
  /**
   * The reference of the entity that the claim belongs to
   *
   * @format uuid
   */
  externalReference: string;
  /**
   * The claim ID
   *
   * @format uuid
   */
  id: string;
};

export type UpdateShipmentProtectionClaim2Error = Fetcher.ErrorWrapper<
  | {
      status: 403;
      payload: Schemas.JsonErrorResponse;
    }
  | {
      status: 404;
      payload:
        | {
            errors: {
              detail?: string;
            };
          }
        | {
            errors: {
              detail?: string;
            }[];
          };
    }
  | {
      status: 422;
      payload: Schemas.JsonErrorResponse;
    }
>;

export type UpdateShipmentProtectionClaim2Response = {
  data: {
    data?: Schemas.Claim;
  };
};

export type UpdateShipmentProtectionClaim2RequestBody = {
  data: Schemas.UpdateClaim;
};

export type UpdateShipmentProtectionClaim2Variables = {
  body: UpdateShipmentProtectionClaim2RequestBody;
  pathParams: UpdateShipmentProtectionClaim2PathParams;
} & CxContext["fetcherOptions"];

/**
 * Update certain fields of a claim
 */
export const fetchUpdateShipmentProtectionClaim2 = (
  variables: UpdateShipmentProtectionClaim2Variables,
  signal?: AbortSignal
) =>
  cxFetch<
    UpdateShipmentProtectionClaim2Response,
    UpdateShipmentProtectionClaim2Error,
    UpdateShipmentProtectionClaim2RequestBody,
    {},
    {},
    UpdateShipmentProtectionClaim2PathParams
  >({
    url: "/v1/consumer_experience/{externalReference}/shipment_protection/claims/{id}",
    method: "put",
    ...variables,
    signal,
  });

/**
 * Update certain fields of a claim
 */
export const useUpdateShipmentProtectionClaim2 = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      UpdateShipmentProtectionClaim2Response,
      UpdateShipmentProtectionClaim2Error,
      UpdateShipmentProtectionClaim2Variables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCxContext();
  return reactQuery.useMutation<
    UpdateShipmentProtectionClaim2Response,
    UpdateShipmentProtectionClaim2Error,
    UpdateShipmentProtectionClaim2Variables
  >({
    mutationFn: (variables: UpdateShipmentProtectionClaim2Variables) =>
      fetchUpdateShipmentProtectionClaim2({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetShipmentProtectionClaimDocumentsPathParams = {
  /**
   * The reference of the entity that the claim belongs to
   *
   * @format uuid
   */
  externalReference: string;
  /**
   * The id of the claim
   *
   * @format uuid
   */
  id: string;
};

export type GetShipmentProtectionClaimDocumentsError = Fetcher.ErrorWrapper<{
  status: 403;
  payload: Schemas.JsonErrorResponse;
}>;

export type GetShipmentProtectionClaimDocumentsResponse = {
  data: {
    /**
     * @format uuid
     */
    document_id?: string;
    file_name?: string;
    mime_type?: string;
    signed_download_url?: string;
    signed_download_url_with_content_disposition?: string;
  }[];
};

export type GetShipmentProtectionClaimDocumentsVariables = {
  pathParams: GetShipmentProtectionClaimDocumentsPathParams;
} & CxContext["fetcherOptions"];

/**
 * This will get a list of signed urls for the documents attached to a claim.
 */
export const fetchGetShipmentProtectionClaimDocuments = (
  variables: GetShipmentProtectionClaimDocumentsVariables,
  signal?: AbortSignal
) =>
  cxFetch<
    GetShipmentProtectionClaimDocumentsResponse,
    GetShipmentProtectionClaimDocumentsError,
    undefined,
    {},
    {},
    GetShipmentProtectionClaimDocumentsPathParams
  >({
    url: "/v1/consumer_experience/{externalReference}/shipment_protection/claims/{id}/documents",
    method: "get",
    ...variables,
    signal,
  });

/**
 * This will get a list of signed urls for the documents attached to a claim.
 */
export const useGetShipmentProtectionClaimDocuments = <
  TData = GetShipmentProtectionClaimDocumentsResponse
>(
  variables: GetShipmentProtectionClaimDocumentsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetShipmentProtectionClaimDocumentsResponse,
      GetShipmentProtectionClaimDocumentsError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCxContext(options);
  return reactQuery.useQuery<
    GetShipmentProtectionClaimDocumentsResponse,
    GetShipmentProtectionClaimDocumentsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/v1/consumer_experience/{externalReference}/shipment_protection/claims/{id}/documents",
      operationId: "getShipmentProtectionClaimDocuments",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetShipmentProtectionClaimDocuments(
        { ...fetcherOptions, ...variables },
        signal
      ),
    ...options,
    ...queryOptions,
  });
};

export type ReopenShipmentProtectionClaimPathParams = {
  /**
   * The reference of the entity that the claim belongs to
   *
   * @format uuid
   */
  externalReference: string;
  /**
   * The claim ID
   *
   * @format uuid
   */
  id: string;
};

export type ReopenShipmentProtectionClaimError = Fetcher.ErrorWrapper<
  | {
      status: 403;
      payload: Schemas.JsonErrorResponse;
    }
  | {
      status: 404;
      payload:
        | {
            errors: {
              detail?: string;
            };
          }
        | {
            errors: {
              detail?: string;
            }[];
          };
    }
  | {
      status: 422;
      payload: Schemas.JsonErrorResponse;
    }
>;

export type ReopenShipmentProtectionClaimResponse = {
  data: {
    data?: Schemas.Claim;
  };
};

export type ReopenShipmentProtectionClaimVariables = {
  pathParams: ReopenShipmentProtectionClaimPathParams;
} & CxContext["fetcherOptions"];

/**
 * Sets the claim status to pending
 */
export const fetchReopenShipmentProtectionClaim = (
  variables: ReopenShipmentProtectionClaimVariables,
  signal?: AbortSignal
) =>
  cxFetch<
    ReopenShipmentProtectionClaimResponse,
    ReopenShipmentProtectionClaimError,
    undefined,
    {},
    {},
    ReopenShipmentProtectionClaimPathParams
  >({
    url: "/v1/consumer_experience/{externalReference}/shipment_protection/claims/{id}/reopen",
    method: "put",
    ...variables,
    signal,
  });

/**
 * Sets the claim status to pending
 */
export const useReopenShipmentProtectionClaim = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      ReopenShipmentProtectionClaimResponse,
      ReopenShipmentProtectionClaimError,
      ReopenShipmentProtectionClaimVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCxContext();
  return reactQuery.useMutation<
    ReopenShipmentProtectionClaimResponse,
    ReopenShipmentProtectionClaimError,
    ReopenShipmentProtectionClaimVariables
  >({
    mutationFn: (variables: ReopenShipmentProtectionClaimVariables) =>
      fetchReopenShipmentProtectionClaim({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ResolveShipmentProtectionClaimPathParams = {
  /**
   * The reference of the entity that the claim belongs to
   *
   * @format uuid
   */
  externalReference: string;
  /**
   * The claim ID
   *
   * @format uuid
   */
  id: string;
};

export type ResolveShipmentProtectionClaimQueryParams = {
  /**
   * Whether to send an alert to the claimant
   */
  alert?: boolean;
};

export type ResolveShipmentProtectionClaimError = Fetcher.ErrorWrapper<
  | {
      status: 403;
      payload: Schemas.JsonErrorResponse;
    }
  | {
      status: 404;
      payload:
        | {
            errors: {
              detail?: string;
            };
          }
        | {
            errors: {
              detail?: string;
            }[];
          };
    }
  | {
      status: 422;
      payload: Schemas.JsonErrorResponse;
    }
>;

export type ResolveShipmentProtectionClaimResponse = {
  data: {
    data?: Schemas.Claim;
  };
};

export type ResolveShipmentProtectionClaimRequestBody = {
  data: Schemas.ResolveClaim;
};

export type ResolveShipmentProtectionClaimVariables = {
  body: ResolveShipmentProtectionClaimRequestBody;
  pathParams: ResolveShipmentProtectionClaimPathParams;
  queryParams?: ResolveShipmentProtectionClaimQueryParams;
} & CxContext["fetcherOptions"];

/**
 * Marks a claim as resolved
 */
export const fetchResolveShipmentProtectionClaim = (
  variables: ResolveShipmentProtectionClaimVariables,
  signal?: AbortSignal
) =>
  cxFetch<
    ResolveShipmentProtectionClaimResponse,
    ResolveShipmentProtectionClaimError,
    ResolveShipmentProtectionClaimRequestBody,
    {},
    ResolveShipmentProtectionClaimQueryParams,
    ResolveShipmentProtectionClaimPathParams
  >({
    url: "/v1/consumer_experience/{externalReference}/shipment_protection/claims/{id}/resolve",
    method: "put",
    ...variables,
    signal,
  });

/**
 * Marks a claim as resolved
 */
export const useResolveShipmentProtectionClaim = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      ResolveShipmentProtectionClaimResponse,
      ResolveShipmentProtectionClaimError,
      ResolveShipmentProtectionClaimVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCxContext();
  return reactQuery.useMutation<
    ResolveShipmentProtectionClaimResponse,
    ResolveShipmentProtectionClaimError,
    ResolveShipmentProtectionClaimVariables
  >({
    mutationFn: (variables: ResolveShipmentProtectionClaimVariables) =>
      fetchResolveShipmentProtectionClaim({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetShipmentProtectionOrdersPathParams = {
  /**
   * The reference of the entity that the order belongs to
   *
   * @format uuid
   */
  externalReference: string;
};

export type GetShipmentProtectionOrdersQueryParams = {
  /**
   * Number of records to return per page
   *
   * @minimum 1
   */
  limit?: number;
  /**
   * Page cursor to get the previous page
   */
  before?: string;
  /**
   * Page cursor to get the next page
   */
  after?: string;
  /**
   * Sort by a given property. Accepts fields: inserted_at, updated_at. Sorts should be passed in the format of `&sort=property:asc,property2:desc`
   */
  sort?: string;
  /**
   * Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2`
   */
  filter?: {
    order_reference?: string;
    provider?: string;
  };
  /**
   * Search by the following fields: order_reference
   *
   * @minLength 1
   */
  search?: string;
};

export type GetShipmentProtectionOrdersError = Fetcher.ErrorWrapper<
  | {
      status: 403;
      payload: Schemas.JsonErrorResponse;
    }
  | {
      status: 422;
      payload: Schemas.JsonErrorResponse;
    }
>;

export type GetShipmentProtectionOrdersResponse = {
  data: Schemas.ShipmentProtectedOrder[];
  metadata: Schemas.PaginationMetadata;
};

export type GetShipmentProtectionOrdersVariables = {
  pathParams: GetShipmentProtectionOrdersPathParams;
  queryParams?: GetShipmentProtectionOrdersQueryParams;
} & CxContext["fetcherOptions"];

/**
 * Returns a collection of protected orders
 */
export const fetchGetShipmentProtectionOrders = (
  variables: GetShipmentProtectionOrdersVariables,
  signal?: AbortSignal
) =>
  cxFetch<
    GetShipmentProtectionOrdersResponse,
    GetShipmentProtectionOrdersError,
    undefined,
    {},
    GetShipmentProtectionOrdersQueryParams,
    GetShipmentProtectionOrdersPathParams
  >({
    url: "/v1/consumer_experience/{externalReference}/shipment_protection/orders",
    method: "get",
    ...variables,
    signal,
  });

/**
 * Returns a collection of protected orders
 */
export const useGetShipmentProtectionOrders = <
  TData = GetShipmentProtectionOrdersResponse
>(
  variables: GetShipmentProtectionOrdersVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetShipmentProtectionOrdersResponse,
      GetShipmentProtectionOrdersError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCxContext(options);
  return reactQuery.useQuery<
    GetShipmentProtectionOrdersResponse,
    GetShipmentProtectionOrdersError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/v1/consumer_experience/{externalReference}/shipment_protection/orders",
      operationId: "getShipmentProtectionOrders",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetShipmentProtectionOrders(
        { ...fetcherOptions, ...variables },
        signal
      ),
    ...options,
    ...queryOptions,
  });
};

export type CreateShipmentProtectionOrderPathParams = {
  /**
   * The reference of the entity that the order belongs to
   *
   * @format uuid
   */
  externalReference: string;
};

export type CreateShipmentProtectionOrderError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: {
        errors: Record<string, any>[];
      };
    }
  | {
      status: 403;
      payload: Schemas.JsonErrorResponse;
    }
  | {
      status: 422;
      payload: Schemas.JsonErrorResponse;
    }
>;

export type CreateShipmentProtectionOrderResponse = {
  data: {
    data?: Schemas.ShipmentProtectedOrder;
  };
};

export type CreateShipmentProtectionOrderRequestBody = {
  data: Schemas.CreateShipmentProtectedOrder;
};

export type CreateShipmentProtectionOrderVariables = {
  body: CreateShipmentProtectionOrderRequestBody;
  pathParams: CreateShipmentProtectionOrderPathParams;
} & CxContext["fetcherOptions"];

/**
 * This endpoint will create an internal record of order for the given external reference, order_reference and provider
 */
export const fetchCreateShipmentProtectionOrder = (
  variables: CreateShipmentProtectionOrderVariables,
  signal?: AbortSignal
) =>
  cxFetch<
    CreateShipmentProtectionOrderResponse,
    CreateShipmentProtectionOrderError,
    CreateShipmentProtectionOrderRequestBody,
    {},
    {},
    CreateShipmentProtectionOrderPathParams
  >({
    url: "/v1/consumer_experience/{externalReference}/shipment_protection/orders",
    method: "post",
    ...variables,
    signal,
  });

/**
 * This endpoint will create an internal record of order for the given external reference, order_reference and provider
 */
export const useCreateShipmentProtectionOrder = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      CreateShipmentProtectionOrderResponse,
      CreateShipmentProtectionOrderError,
      CreateShipmentProtectionOrderVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCxContext();
  return reactQuery.useMutation<
    CreateShipmentProtectionOrderResponse,
    CreateShipmentProtectionOrderError,
    CreateShipmentProtectionOrderVariables
  >({
    mutationFn: (variables: CreateShipmentProtectionOrderVariables) =>
      fetchCreateShipmentProtectionOrder({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetShipmentProtectionOrderShipmentsPathParams = {
  /**
   * The reference of the entity that the order belongs to
   *
   * @format uuid
   */
  externalReference: string;
  /**
   * The id of the shipment protected order the shipment belongs to
   *
   * @format uuid
   */
  shipmentProtectedOrderId: string;
};

export type GetShipmentProtectionOrderShipmentsQueryParams = {
  /**
   * Number of records to return per page
   *
   * @minimum 1
   */
  limit?: number;
  /**
   * Page cursor to get the previous page
   */
  before?: string;
  /**
   * Page cursor to get the next page
   */
  after?: string;
  /**
   * Sort by a given property. Accepts fields: inserted_at, updated_at. Sorts should be passed in the format of `&sort=property:asc,property2:desc`
   */
  sort?: string;
  /**
   * Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2`
   */
  filter?: {
    order_reference?: string;
    provider?: string;
  };
  /**
   * Search by the following fields: order_reference
   *
   * @minLength 1
   */
  search?: string;
};

export type GetShipmentProtectionOrderShipmentsError = Fetcher.ErrorWrapper<
  | {
      status: 403;
      payload: Schemas.JsonErrorResponse;
    }
  | {
      status: 422;
      payload: Schemas.JsonErrorResponse;
    }
>;

export type GetShipmentProtectionOrderShipmentsResponse = {
  data: Schemas.ShipmentProtectedOrderShipment[];
  metadata: Schemas.PaginationMetadata;
};

export type GetShipmentProtectionOrderShipmentsVariables = {
  pathParams: GetShipmentProtectionOrderShipmentsPathParams;
  queryParams?: GetShipmentProtectionOrderShipmentsQueryParams;
} & CxContext["fetcherOptions"];

/**
 * Returns a collection of shipments
 */
export const fetchGetShipmentProtectionOrderShipments = (
  variables: GetShipmentProtectionOrderShipmentsVariables,
  signal?: AbortSignal
) =>
  cxFetch<
    GetShipmentProtectionOrderShipmentsResponse,
    GetShipmentProtectionOrderShipmentsError,
    undefined,
    {},
    GetShipmentProtectionOrderShipmentsQueryParams,
    GetShipmentProtectionOrderShipmentsPathParams
  >({
    url: "/v1/consumer_experience/{externalReference}/shipment_protection/orders/{shipmentProtectedOrderId}/shipments",
    method: "get",
    ...variables,
    signal,
  });

/**
 * Returns a collection of shipments
 */
export const useGetShipmentProtectionOrderShipments = <
  TData = GetShipmentProtectionOrderShipmentsResponse
>(
  variables: GetShipmentProtectionOrderShipmentsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetShipmentProtectionOrderShipmentsResponse,
      GetShipmentProtectionOrderShipmentsError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCxContext(options);
  return reactQuery.useQuery<
    GetShipmentProtectionOrderShipmentsResponse,
    GetShipmentProtectionOrderShipmentsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/v1/consumer_experience/{externalReference}/shipment_protection/orders/{shipmentProtectedOrderId}/shipments",
      operationId: "getShipmentProtectionOrderShipments",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetShipmentProtectionOrderShipments(
        { ...fetcherOptions, ...variables },
        signal
      ),
    ...options,
    ...queryOptions,
  });
};

export type CreateShipmentProtectionOrderShipmentPathParams = {
  /**
   * The reference of the entity that the order belongs to
   *
   * @format uuid
   */
  externalReference: string;
  /**
   * The id of the shipment protected order the shipment belongs to
   *
   * @format uuid
   */
  shipmentProtectedOrderId: string;
};

export type CreateShipmentProtectionOrderShipmentError = Fetcher.ErrorWrapper<
  | {
      status: 403;
      payload: Schemas.JsonErrorResponse;
    }
  | {
      status: 422;
      payload: Schemas.JsonErrorResponse;
    }
>;

export type CreateShipmentProtectionOrderShipmentResponse = {
  data: {
    data?: Schemas.ShipmentProtectedOrderShipment;
  };
};

export type CreateShipmentProtectionOrderShipmentRequestBody = {
  data: Schemas.CreateShipmentProtectedOrderShipment;
};

export type CreateShipmentProtectionOrderShipmentVariables = {
  body: CreateShipmentProtectionOrderShipmentRequestBody;
  pathParams: CreateShipmentProtectionOrderShipmentPathParams;
} & CxContext["fetcherOptions"];

/**
 * This endpoint will create an internal record of a shipment for a given shipment protected order
 */
export const fetchCreateShipmentProtectionOrderShipment = (
  variables: CreateShipmentProtectionOrderShipmentVariables,
  signal?: AbortSignal
) =>
  cxFetch<
    CreateShipmentProtectionOrderShipmentResponse,
    CreateShipmentProtectionOrderShipmentError,
    CreateShipmentProtectionOrderShipmentRequestBody,
    {},
    {},
    CreateShipmentProtectionOrderShipmentPathParams
  >({
    url: "/v1/consumer_experience/{externalReference}/shipment_protection/orders/{shipmentProtectedOrderId}/shipments",
    method: "post",
    ...variables,
    signal,
  });

/**
 * This endpoint will create an internal record of a shipment for a given shipment protected order
 */
export const useCreateShipmentProtectionOrderShipment = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      CreateShipmentProtectionOrderShipmentResponse,
      CreateShipmentProtectionOrderShipmentError,
      CreateShipmentProtectionOrderShipmentVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCxContext();
  return reactQuery.useMutation<
    CreateShipmentProtectionOrderShipmentResponse,
    CreateShipmentProtectionOrderShipmentError,
    CreateShipmentProtectionOrderShipmentVariables
  >({
    mutationFn: (variables: CreateShipmentProtectionOrderShipmentVariables) =>
      fetchCreateShipmentProtectionOrderShipment({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type ListStorefrontsPathParams = {
  /**
   * External reference of the storefront.
   *
   * @format uuid
   */
  externalReference: string;
};

export type ListStorefrontsQueryParams = {
  /**
   * Number of records to return per page
   *
   * @minimum 1
   */
  limit?: number;
  /**
   * Page cursor to get the previous page
   */
  before?: string;
  /**
   * Page cursor to get the next page
   */
  after?: string;
  /**
   * Sort by a given property. Accepts fields: inserted_at, updated_at. Sorts should be passed in the format of `&sort=property:asc,property2:desc`
   */
  sort?: string;
  /**
   * Search by the following fields: name
   *
   * @minLength 1
   */
  search?: string;
};

export type ListStorefrontsError = Fetcher.ErrorWrapper<
  | {
      status: 403;
      payload: Schemas.JsonErrorResponse;
    }
  | {
      status: 422;
      payload: Schemas.JsonErrorResponse;
    }
>;

export type ListStorefrontsResponse = {
  data: Schemas.Storefront[];
  metadata: Schemas.PaginationMetadata;
};

export type ListStorefrontsVariables = {
  pathParams: ListStorefrontsPathParams;
  queryParams?: ListStorefrontsQueryParams;
} & CxContext["fetcherOptions"];

/**
 * This endpoint will list storefronts.
 */
export const fetchListStorefronts = (
  variables: ListStorefrontsVariables,
  signal?: AbortSignal
) =>
  cxFetch<
    ListStorefrontsResponse,
    ListStorefrontsError,
    undefined,
    {},
    ListStorefrontsQueryParams,
    ListStorefrontsPathParams
  >({
    url: "/v1/consumer_experience/{externalReference}/storefronts",
    method: "get",
    ...variables,
    signal,
  });

/**
 * This endpoint will list storefronts.
 */
export const useListStorefronts = <TData = ListStorefrontsResponse>(
  variables: ListStorefrontsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ListStorefrontsResponse,
      ListStorefrontsError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCxContext(options);
  return reactQuery.useQuery<
    ListStorefrontsResponse,
    ListStorefrontsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/v1/consumer_experience/{externalReference}/storefronts",
      operationId: "listStorefronts",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchListStorefronts({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type CreateStorefrontPathParams = {
  /**
   * External reference of the storefront.
   *
   * @format uuid
   */
  externalReference: string;
};

export type CreateStorefrontError = Fetcher.ErrorWrapper<
  | {
      status: 403;
      payload: Schemas.JsonErrorResponse;
    }
  | {
      status: 422;
      payload: Schemas.JsonErrorResponse;
    }
>;

export type CreateStorefrontResponse = {
  data: {
    data?: Schemas.Storefront;
  };
};

export type CreateStorefrontRequestBody = {
  data: Schemas.CreateStorefront;
};

export type CreateStorefrontVariables = {
  body: CreateStorefrontRequestBody;
  pathParams: CreateStorefrontPathParams;
} & CxContext["fetcherOptions"];

/**
 * This endpoint will create a storefront.
 */
export const fetchCreateStorefront = (
  variables: CreateStorefrontVariables,
  signal?: AbortSignal
) =>
  cxFetch<
    CreateStorefrontResponse,
    CreateStorefrontError,
    CreateStorefrontRequestBody,
    {},
    {},
    CreateStorefrontPathParams
  >({
    url: "/v1/consumer_experience/{externalReference}/storefronts",
    method: "post",
    ...variables,
    signal,
  });

/**
 * This endpoint will create a storefront.
 */
export const useCreateStorefront = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      CreateStorefrontResponse,
      CreateStorefrontError,
      CreateStorefrontVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCxContext();
  return reactQuery.useMutation<
    CreateStorefrontResponse,
    CreateStorefrontError,
    CreateStorefrontVariables
  >({
    mutationFn: (variables: CreateStorefrontVariables) =>
      fetchCreateStorefront({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DeleteStorefrontPathParams = {
  /**
   * External reference of the storefront.
   *
   * @format uuid
   */
  externalReference: string;
  /**
   * The storefront ID.
   *
   * @format uuid
   */
  id: string;
};

export type DeleteStorefrontError = Fetcher.ErrorWrapper<
  | {
      status: 403;
      payload: Schemas.JsonErrorResponse;
    }
  | {
      status: 404;
      payload:
        | {
            errors: {
              detail?: string;
            };
          }
        | {
            errors: {
              detail?: string;
            }[];
          };
    }
>;

export type DeleteStorefrontVariables = {
  pathParams: DeleteStorefrontPathParams;
} & CxContext["fetcherOptions"];

/**
 * This endpoint will delete a storefront.
 */
export const fetchDeleteStorefront = (
  variables: DeleteStorefrontVariables,
  signal?: AbortSignal
) =>
  cxFetch<
    undefined,
    DeleteStorefrontError,
    undefined,
    {},
    {},
    DeleteStorefrontPathParams
  >({
    url: "/v1/consumer_experience/{externalReference}/storefronts/{id}",
    method: "delete",
    ...variables,
    signal,
  });

/**
 * This endpoint will delete a storefront.
 */
export const useDeleteStorefront = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteStorefrontError,
      DeleteStorefrontVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCxContext();
  return reactQuery.useMutation<
    undefined,
    DeleteStorefrontError,
    DeleteStorefrontVariables
  >({
    mutationFn: (variables: DeleteStorefrontVariables) =>
      fetchDeleteStorefront({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetStorefrontPathParams = {
  /**
   * External reference of the storefront.
   *
   * @format uuid
   */
  externalReference: string;
  /**
   * The storefront ID.
   *
   * @format uuid
   */
  id: string;
};

export type GetStorefrontError = Fetcher.ErrorWrapper<
  | {
      status: 403;
      payload: Schemas.JsonErrorResponse;
    }
  | {
      status: 404;
      payload:
        | {
            errors: {
              detail?: string;
            };
          }
        | {
            errors: {
              detail?: string;
            }[];
          };
    }
>;

export type GetStorefrontResponse = {
  data: Schemas.Storefront;
};

export type GetStorefrontVariables = {
  pathParams: GetStorefrontPathParams;
} & CxContext["fetcherOptions"];

/**
 * This endpoint will get a storefront.
 */
export const fetchGetStorefront = (
  variables: GetStorefrontVariables,
  signal?: AbortSignal
) =>
  cxFetch<
    GetStorefrontResponse,
    GetStorefrontError,
    undefined,
    {},
    {},
    GetStorefrontPathParams
  >({
    url: "/v1/consumer_experience/{externalReference}/storefronts/{id}",
    method: "get",
    ...variables,
    signal,
  });

/**
 * This endpoint will get a storefront.
 */
export const useGetStorefront = <TData = GetStorefrontResponse>(
  variables: GetStorefrontVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetStorefrontResponse,
      GetStorefrontError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCxContext(options);
  return reactQuery.useQuery<GetStorefrontResponse, GetStorefrontError, TData>({
    queryKey: queryKeyFn({
      path: "/v1/consumer_experience/{externalReference}/storefronts/{id}",
      operationId: "getStorefront",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetStorefront({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type UpdateStorefrontPathParams = {
  /**
   * External reference of the storefront.
   *
   * @format uuid
   */
  externalReference: string;
  /**
   * The storefront ID.
   *
   * @format uuid
   */
  id: string;
};

export type UpdateStorefrontError = Fetcher.ErrorWrapper<
  | {
      status: 403;
      payload: Schemas.JsonErrorResponse;
    }
  | {
      status: 404;
      payload:
        | {
            errors: {
              detail?: string;
            };
          }
        | {
            errors: {
              detail?: string;
            }[];
          };
    }
  | {
      status: 422;
      payload: Schemas.JsonErrorResponse;
    }
>;

export type UpdateStorefrontResponse = {
  data: {
    data?: Schemas.Storefront;
  };
};

export type UpdateStorefrontRequestBody = {
  data: Schemas.UpdateStorefront;
};

export type UpdateStorefrontVariables = {
  body: UpdateStorefrontRequestBody;
  pathParams: UpdateStorefrontPathParams;
} & CxContext["fetcherOptions"];

/**
 * This endpoint will update a storefront.
 */
export const fetchUpdateStorefront = (
  variables: UpdateStorefrontVariables,
  signal?: AbortSignal
) =>
  cxFetch<
    UpdateStorefrontResponse,
    UpdateStorefrontError,
    UpdateStorefrontRequestBody,
    {},
    {},
    UpdateStorefrontPathParams
  >({
    url: "/v1/consumer_experience/{externalReference}/storefronts/{id}",
    method: "patch",
    ...variables,
    signal,
  });

/**
 * This endpoint will update a storefront.
 */
export const useUpdateStorefront = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      UpdateStorefrontResponse,
      UpdateStorefrontError,
      UpdateStorefrontVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCxContext();
  return reactQuery.useMutation<
    UpdateStorefrontResponse,
    UpdateStorefrontError,
    UpdateStorefrontVariables
  >({
    mutationFn: (variables: UpdateStorefrontVariables) =>
      fetchUpdateStorefront({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type UpdateStorefront2PathParams = {
  /**
   * External reference of the storefront.
   *
   * @format uuid
   */
  externalReference: string;
  /**
   * The storefront ID.
   *
   * @format uuid
   */
  id: string;
};

export type UpdateStorefront2Error = Fetcher.ErrorWrapper<
  | {
      status: 403;
      payload: Schemas.JsonErrorResponse;
    }
  | {
      status: 404;
      payload:
        | {
            errors: {
              detail?: string;
            };
          }
        | {
            errors: {
              detail?: string;
            }[];
          };
    }
  | {
      status: 422;
      payload: Schemas.JsonErrorResponse;
    }
>;

export type UpdateStorefront2Response = {
  data: {
    data?: Schemas.Storefront;
  };
};

export type UpdateStorefront2RequestBody = {
  data: Schemas.UpdateStorefront;
};

export type UpdateStorefront2Variables = {
  body: UpdateStorefront2RequestBody;
  pathParams: UpdateStorefront2PathParams;
} & CxContext["fetcherOptions"];

/**
 * This endpoint will update a storefront.
 */
export const fetchUpdateStorefront2 = (
  variables: UpdateStorefront2Variables,
  signal?: AbortSignal
) =>
  cxFetch<
    UpdateStorefront2Response,
    UpdateStorefront2Error,
    UpdateStorefront2RequestBody,
    {},
    {},
    UpdateStorefront2PathParams
  >({
    url: "/v1/consumer_experience/{externalReference}/storefronts/{id}",
    method: "put",
    ...variables,
    signal,
  });

/**
 * This endpoint will update a storefront.
 */
export const useUpdateStorefront2 = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      UpdateStorefront2Response,
      UpdateStorefront2Error,
      UpdateStorefront2Variables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCxContext();
  return reactQuery.useMutation<
    UpdateStorefront2Response,
    UpdateStorefront2Error,
    UpdateStorefront2Variables
  >({
    mutationFn: (variables: UpdateStorefront2Variables) =>
      fetchUpdateStorefront2({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type QueryOperation =
  | {
      path: "/public_portal/v1/consumer_experience/orders/{orderId}/shipments";
      operationId: "ordersServiceWebPublicPortalConsumerExperienceV1ShipmentControllerListOrderShipments";
      variables: OrdersServiceWebPublicPortalConsumerExperienceV1ShipmentControllerListOrderShipmentsVariables;
    }
  | {
      path: "/public_portal/v1/consumer_experience/orders/{orderId}/shipments/{shipmentId}";
      operationId: "ordersServiceWebPublicPortalConsumerExperienceV1ShipmentControllerGetOrderShipmentDetails";
      variables: OrdersServiceWebPublicPortalConsumerExperienceV1ShipmentControllerGetOrderShipmentDetailsVariables;
    }
  | {
      path: "/v1/consumer_experience/{externalReference}/brandings";
      operationId: "getBrandings";
      variables: GetBrandingsVariables;
    }
  | {
      path: "/v1/consumer_experience/{externalReference}/brandings/{brandingId}/alert_types";
      operationId: "getAlertTypes";
      variables: GetAlertTypesVariables;
    }
  | {
      path: "/v1/consumer_experience/{externalReference}/brandings/{brandingId}/alert_types/{alertTypeId}/alert_channels";
      operationId: "getAlertChannels";
      variables: GetAlertChannelsVariables;
    }
  | {
      path: "/v1/consumer_experience/{externalReference}/brandings/{brandingId}/alert_types/{alertTypeId}/alert_channels/{id}";
      operationId: "getAlertChannel";
      variables: GetAlertChannelVariables;
    }
  | {
      path: "/v1/consumer_experience/{externalReference}/brandings/{brandingId}/alert_types/{alertTypeId}/templates/{id}";
      operationId: "getTemplate";
      variables: GetTemplateVariables;
    }
  | {
      path: "/v1/consumer_experience/{externalReference}/brandings/{brandingId}/alert_types/{alertTypeId}/templates/{id}/latest_revision";
      operationId: "getLatestRevision";
      variables: GetLatestRevisionVariables;
    }
  | {
      path: "/v1/consumer_experience/{externalReference}/brandings/{brandingId}/alert_types/{alertTypeId}/templates/{type}/default";
      operationId: "getDefaultTemplate";
      variables: GetDefaultTemplateVariables;
    }
  | {
      path: "/v1/consumer_experience/{externalReference}/brandings/{brandingId}/alert_types/{id}";
      operationId: "getAlertType";
      variables: GetAlertTypeVariables;
    }
  | {
      path: "/v1/consumer_experience/{externalReference}/brandings/{brandingId}/tracking_portals";
      operationId: "getTrackingPortals";
      variables: GetTrackingPortalsVariables;
    }
  | {
      path: "/v1/consumer_experience/{externalReference}/brandings/{brandingId}/tracking_portals/{id}";
      operationId: "getTrackingPortal";
      variables: GetTrackingPortalVariables;
    }
  | {
      path: "/v1/consumer_experience/{externalReference}/brandings/{id}";
      operationId: "getBranding";
      variables: GetBrandingVariables;
    }
  | {
      path: "/v1/consumer_experience/{externalReference}/configurations";
      operationId: "getConfigurations";
      variables: GetConfigurationsVariables;
    }
  | {
      path: "/v1/consumer_experience/{externalReference}/configurations/{id}";
      operationId: "getConfiguration";
      variables: GetConfigurationVariables;
    }
  | {
      path: "/v1/consumer_experience/{externalReference}/delivery_options";
      operationId: "getDeliveryOptions";
      variables: GetDeliveryOptionsVariables;
    }
  | {
      path: "/v1/consumer_experience/{externalReference}/delivery_options/{id}";
      operationId: "getDeliveryOption";
      variables: GetDeliveryOptionVariables;
    }
  | {
      path: "/v1/consumer_experience/{externalReference}/shipment_protection/claims";
      operationId: "getShipmentProtectionClaims";
      variables: GetShipmentProtectionClaimsVariables;
    }
  | {
      path: "/v1/consumer_experience/{externalReference}/shipment_protection/claims/summary";
      operationId: "getShipmentProtectionClaimSummary";
      variables: GetShipmentProtectionClaimSummaryVariables;
    }
  | {
      path: "/v1/consumer_experience/{externalReference}/shipment_protection/claims/{id}";
      operationId: "getShipmentProtectionClaim";
      variables: GetShipmentProtectionClaimVariables;
    }
  | {
      path: "/v1/consumer_experience/{externalReference}/shipment_protection/claims/{id}/documents";
      operationId: "getShipmentProtectionClaimDocuments";
      variables: GetShipmentProtectionClaimDocumentsVariables;
    }
  | {
      path: "/v1/consumer_experience/{externalReference}/shipment_protection/orders";
      operationId: "getShipmentProtectionOrders";
      variables: GetShipmentProtectionOrdersVariables;
    }
  | {
      path: "/v1/consumer_experience/{externalReference}/shipment_protection/orders/{shipmentProtectedOrderId}/shipments";
      operationId: "getShipmentProtectionOrderShipments";
      variables: GetShipmentProtectionOrderShipmentsVariables;
    }
  | {
      path: "/v1/consumer_experience/{externalReference}/storefronts";
      operationId: "listStorefronts";
      variables: ListStorefrontsVariables;
    }
  | {
      path: "/v1/consumer_experience/{externalReference}/storefronts/{id}";
      operationId: "getStorefront";
      variables: GetStorefrontVariables;
    };
