import type { FlexProps } from "@chakra-ui/react";
import { Flex } from "@chakra-ui/react";
import type { SVGProps } from "react";

import { StordEmblem, STORD_EMBLEM_WIDTH } from "./StordEmblem";
import { StordLogo, STORD_LOGO_HEIGHT, STORD_LOGO_WIDTH } from "./StordLogo";

export const StordEmblemAndLogo = ({
  widthInPx,
  heightInPx,
  flexProps,
  ...props
}: Omit<SVGProps<SVGSVGElement>, "width" | "height"> & {
  flexProps?: FlexProps;
} & (
    | {
        widthInPx: number;
        heightInPx?: never;
      }
    | {
        widthInPx?: never;
        heightInPx: number;
      }
  )) => {
  const scaleFactor =
    widthInPx !== undefined
      ? widthInPx / (STORD_LOGO_WIDTH + STORD_EMBLEM_WIDTH + 4) // (StordEmblem width * scaleFactor) + (StordLogo width * scaleFactor) + (Flex gap of 4 * scaleFactor) = widthInPx
      : heightInPx / STORD_LOGO_HEIGHT; // Note: StordLogo and StordEmblem have the same heights, so it doesn't matter which one we use here

  return (
    <Flex gap={`${4 * scaleFactor}px`} {...flexProps}>
      <StordEmblem width={`${STORD_EMBLEM_WIDTH * scaleFactor}px`} {...props} />
      <StordLogo width={`${STORD_LOGO_WIDTH * scaleFactor}px`} {...props} />
    </Flex>
  );
};
