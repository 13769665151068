import type * as React from "react";
import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  VisuallyHidden,
} from "@chakra-ui/react";
import type {
  FormControlProps,
  NumberInputProps as ChakraNumberInputProps,
  FormLabelProps,
  InputRightAddonProps,
} from "@chakra-ui/react";
import { useController } from "react-hook-form";
import type {
  FieldPath,
  FieldValues,
  UseControllerProps,
} from "react-hook-form";
import { faAngleUp, faAngleDown } from "@fortawesome/pro-regular-svg-icons";

import { ConditionalWrapper } from "../ConditionalWrapper";
import { FaIcon } from "../FaIcon";

import { getFieldErrorMessage } from "./utils";

export function RHFNumberInput<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  label,
  hideLabel = false,
  isDisabled,
  isRequired,
  hideErrorMessage,
  formControlStyles,
  inputLeftAddon,
  inputRightAddon,
  inputRightAddonProps,
  helperText,
  // Controller props
  name,
  rules,
  shouldUnregister,
  defaultValue,
  control,
  labelProps,
  ...inputProps
}: UseControllerProps<TFieldValues, TName> &
  Pick<FormControlProps, "isDisabled" | "isRequired" | "label"> &
  ChakraNumberInputProps & {
    hideLabel?: boolean;
    hideErrorMessage?: boolean;
    formControlStyles?: Object;
    inputLeftAddon?: React.ReactNode;
    inputRightAddon?: React.ReactNode;
    inputRightAddonProps?: InputRightAddonProps;
    helperText?: string | null;
    labelProps?: Omit<FormLabelProps, "htmlFor">;
  }) {
  const { field, formState } = useController({
    defaultValue: defaultValue ?? ("" as any),
    name,
    rules,
    shouldUnregister,
    control,
  });

  const errorMessage = getFieldErrorMessage(formState, field.name);
  const canShowHelperText = !errorMessage && !!helperText;

  // Note: This approach relies on _not_ validating on blur. If you were to desire that to be the behavior,
  // we'd most likely want to check for `touched` on `isInvalid` as well.
  // Extra Note: drop `field` any cast after upgrading Typescript to 4.4+
  return (
    <FormControl
      {...formControlStyles}
      isDisabled={isDisabled}
      isRequired={isRequired}
      isInvalid={!!errorMessage}
      id={field.name}
    >
      <ConditionalWrapper
        condition={hideLabel}
        wrapper={(children) => <VisuallyHidden>{children}</VisuallyHidden>}
      >
        <FormLabel htmlFor={field.name} {...labelProps}>
          {label}
        </FormLabel>
      </ConditionalWrapper>

      <InputGroup>
        {inputLeftAddon && <InputLeftAddon>{inputLeftAddon}</InputLeftAddon>}
        <NumberInput {...field} {...inputProps}>
          <NumberInputField
            {...(inputLeftAddon && { borderLeftRadius: "none" })}
            {...(inputRightAddon && { borderRightRadius: "none" })}
          />
          <NumberInputStepper>
            <NumberIncrementStepper>
              <FaIcon icon={faAngleUp} size="xs" />
            </NumberIncrementStepper>

            <NumberDecrementStepper>
              <FaIcon icon={faAngleDown} size="xs" />
            </NumberDecrementStepper>
          </NumberInputStepper>
        </NumberInput>
        {inputRightAddon && (
          <InputRightAddon {...inputRightAddonProps}>
            {inputRightAddon}
          </InputRightAddon>
        )}
      </InputGroup>

      {canShowHelperText && <FormHelperText>{helperText}</FormHelperText>}

      {!hideErrorMessage && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
    </FormControl>
  );
}
