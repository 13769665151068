import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system";
import { statAnatomy } from "@chakra-ui/anatomy";
import typography from "../foundations/typography";

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  statAnatomy.keys,
);

export default defineMultiStyleConfig({
  baseStyle: {
    helpText: {
      mb: 0,
      opacity: 1,
      textStyle: "paragraph",
      color: "gray.800",
      _dark: {
        color: "gray.200",
      },
    },
  },
  sizes: {
    lg: {
      number: {
        fontSize: "3xl",
        lineHeight: 10,
      },
    },
    md: {
      // NOTE: using textStyle directly will also apply `fontSize`
      number: typography.textStyles.title,
    },
    sm: {
      number: {
        textStyle: "subtitle",
      },
    },
  },
});
