import { Badge } from "@chakra-ui/react";
import type { BadgeProps } from "@chakra-ui/react";

import type { KnownOrderStatus } from "../../lib/status";
import { getStatusColorScheme, statusToDisplayName } from "../../lib/status";

interface StatusBadgeProps extends BadgeProps {
  status: KnownOrderStatus;
}

export const StatusBadge = ({ status, ...badgeProps }: StatusBadgeProps) => {
  return (
    <Badge
      variant="subtle"
      colorScheme={getStatusColorScheme(status)}
      {...badgeProps}
    >
      {statusToDisplayName[status]}
    </Badge>
  );
};

export default StatusBadge;
