import { Fragment, type ReactNode } from "react";
import type { StackProps } from "@chakra-ui/react";
import { Link, Text, Stack, Skeleton } from "@chakra-ui/react";
import type { LinkProps } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";

import { ConditionalWrapper } from "../ConditionalWrapper";
import { isStringElement } from "../../utils";

export interface StackedCellProps extends StackProps {
  lines: ReactNode[];
  to?: LinkProps["to"];
  isLoading?: boolean;
}

/**
 * Creates a stacked cell with multiple lines.
 *
 * **Important**: When using this cell, `minWidth` on the column definition _should be_
 * set to `48` unless otherwise noted by design.
 */
export const StackedCell = ({
  lines,
  to,
  isLoading = false,
  ...props
}: StackedCellProps) => {
  if (isLoading) {
    return (
      <Stack spacing={0.5} {...props}>
        <Skeleton height={4} width="50%" />
        {lines?.length > 1 ? <Skeleton height={4} width="50%" /> : null}
      </Stack>
    );
  }

  return (
    <Stack spacing={0.5} {...props}>
      {lines.map((line, index) => {
        const isFirstLine = index === 0;
        const hasLink = !!to;

        if (!isStringElement(line)) {
          return <Fragment key={index}>{line}</Fragment>;
        }

        return (
          <ConditionalWrapper
            key={index}
            condition={isFirstLine && hasLink}
            wrapper={(children) => (
              <Link as={RouterLink} to={to}>
                {children}
              </Link>
            )}
          >
            <Text
              as={hasLink && isFirstLine ? "span" : undefined}
              noOfLines={1}
              whiteSpace="normal"
              textStyle={isFirstLine ? "paragraph" : "caption"}
              wordBreak="break-all"
              title={line}
            >
              {line}
            </Text>
          </ConditionalWrapper>
        );
      })}
    </Stack>
  );
};
