import { Suspense } from "react";
import { createRoot } from "react-dom/client";

import { PageLoader } from "@stordco/fe-components";

import { App } from "./components/App";

import "./index.css";

// https://vite.dev/guide/build#load-error-handling
window.addEventListener("vite:preloadError", () => {
  window.location.reload();
});

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <Suspense fallback={<PageLoader />}>
    <App />
  </Suspense>,
);
