import { LinkBox, type CardProps, useColorModeValue } from "@chakra-ui/react";

import { Card } from ".";

export const LinkBoxCard = ({
  isDisabled,
  ...props
}: { isDisabled?: boolean } & CardProps) => {
  const hoverBackgroundColor = useColorModeValue(undefined, "whiteAlpha.300");

  return (
    <LinkBox
      as={Card}
      aria-disabled={isDisabled}
      _disabled={{
        opacity: 0.4,
        pointerEvents: "none",
      }}
      _hover={{
        backgroundColor: hoverBackgroundColor,
        boxShadow: "md",
      }}
      transitionProperty="common"
      transitionDuration="normal"
      transitionTimingFunction="ease-in-out"
      _focusWithin={{
        backgroundColor: hoverBackgroundColor,
        boxShadow: "md",
      }}
      _focusVisible={{
        backgroundColor: hoverBackgroundColor,
      }}
      {...props}
    />
  );
};
