import type {
  ActionMeta,
  GroupBase,
  OptionsOrGroups,
  OnChangeValue,
} from "react-select";

import type { SelectOption } from "../../Select";

export const isGroupType = <
  OptionType extends SelectOption,
  GroupType extends GroupBase<OptionType>,
>(
  array: OptionsOrGroups<OptionType, GroupType>,
): array is GroupType[] => {
  const firstVal = array[0];

  // This is technically possible for `OptionType`, but I'm going to go out on a limb and assume this wouldn't be the case
  return firstVal && "options" in firstVal;
};

export const getValues = <
  OptionType extends SelectOption,
  GroupType extends GroupBase<OptionType>,
>(
  isMulti: boolean,
  options: OptionType[] | GroupType[],
  selected: OptionType["value"] | Array<OptionType["value"]>,
) => {
  if (isMulti) {
    if (!isGroupType(options)) {
      return options.filter((option) =>
        ((selected as string[]) || []).some((v: string) => v === option.value),
      );
    }

    // TODO - handle isMulti === true && isGroupType === true
  }

  if (isGroupType(options)) {
    const optionsFlatMap = options
      .map((option) => option.options)
      .flatMap((option) => option);

    return optionsFlatMap.find((option) => option.value === selected) ?? "";
  }

  return options.find((option) => option.value === selected) ?? ""; // We need to fallback to the empty string to allow the select field to be reset
};

type GetOnSelectParams<
  OptionType,
  IsMulti extends boolean,
  GroupType extends GroupBase<OptionType>,
> = {
  option: OnChangeValue<OptionType | OptionType[], IsMulti>;
  state: ActionMeta<OptionType | OptionType[]>;
  isMulti: IsMulti;
  options: OptionType[] | GroupType[];
  onChange: (...event: any[]) => void;
  parentOnChange?: (
    value: OnChangeValue<OptionType | OptionType[], IsMulti>,
  ) => void | null;
};
export const getOnChange = <
  OptionType extends SelectOption,
  IsMulti extends boolean,
  GroupType extends GroupBase<OptionType>,
>({
  option,
  state,
  isMulti,
  options,
  onChange,
  parentOnChange,
}: GetOnSelectParams<OptionType, IsMulti, GroupType>) => {
  // TODO later - there are a lot of combination of options that go unaccounted in here (e.g., clearing assumes all `removedValues` are `OptionType`s, when they
  // could also be `OptionType[]`). All of the type casting in here is a side effect of this. However, it's not an issue at the moment because all th
  // combinations of options used in our application are currently accounted for.
  if (isMulti) {
    if (process.env.NODE_ENV !== "production") {
      for (const option of options) {
        if (!("value" in option)) {
          console.warn(
            `[RHFSelect] When using isMulti, the option must have a value property. Received ${JSON.stringify(
              option,
            )}`,
          );
        }
      }
    }

    if (state.action === "clear") {
      onChange(
        state.removedValues
          .filter((o) => (o as OptionType).isFixed)
          .map((o) => (o as OptionType).value),
      );
      return;
    }

    if (
      (state.action === "remove-value" || state.action === "pop-value") &&
      (state.removedValue as OptionType).isFixed
    ) {
      return;
    }

    onChange(
      Array.isArray(option)
        ? option.map((o) => o.value)
        : (option as SelectOption).value,
    );
  } else if (state.action === "select-option" && parentOnChange) {
    parentOnChange(option);
  } else {
    onChange((option as OptionType)?.value || "");
  }
};
