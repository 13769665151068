import { ErrorPage } from "./ErrorPage";

export const MaintenanceErrorPage = () => (
  <ErrorPage
    badgeText="Scheduled maintenance"
    title="We'll be back soon!"
    description="Our software is undergoing scheduled maintenance to fine-tune supply chain operations. We'll resume orchestrating seamless logistics shortly. Thank you for your patience."
    outsideNavigation
  />
);
