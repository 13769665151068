// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
import type { CloudContext } from "./cloudContext";

export type ErrorWrapper<TError> =
  | TError
  | { status: "unknown"; payload: string };

export type CloudFetcherOptions<TBody, _THeaders, TQueryParams, TPathParams> = {
  url: string;
  method: string;
  body?: TBody;
  queryParams?: TQueryParams;
  pathParams?: TPathParams;
  signal?: AbortSignal;
} & CloudContext["fetcherOptions"];

export async function cloudFetch<
  TData,
  _TError,
  TBody extends {} | FormData | undefined | null,
  THeaders extends {},
  TQueryParams extends {},
  TPathParams extends {},
>({
  url,
  method,
  body,
  pathParams,
  queryParams,
  cloudApiFetch,
  signal,
}: CloudFetcherOptions<
  TBody,
  THeaders,
  TQueryParams,
  TPathParams
>): Promise<TData> {
  const { filter = {}, ...restQueryParams } = (queryParams || {}) as any;

  return cloudApiFetch<TData>({
    url: resolveUrl(url, pathParams),
    signal,
    method: method.toUpperCase(),
    params: {
      ...restQueryParams,
      ...(filter ? createFilterParams(filter) : {}),
    },
    body,
  });
}

const resolveUrl = (url: string, pathParams: Record<string, string> = {}) => {
  return url.replace(/\{\w*\}/g, (key) => pathParams[key.slice(1, -1)]);
};

const createFilterParams = (filter: unknown) => {
  if (!filter || typeof filter !== "object") return {};
  return Object.entries(filter).reduce((acc, [key, value]) => {
    if (!value) return acc;

    if (Array.isArray(value)) {
      value.forEach((val) => {
        acc[`filter[${key}][]`] = acc[`filter[${key}][]`] || [];
        acc[`filter[${key}][]`].push(val);
      });
    } else {
      acc[`filter[${key}]`] = value;
    }

    return acc;
  }, {});
};
