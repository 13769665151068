import { mode, type StyleFunctionProps } from "@chakra-ui/theme-tools";

export const userInteractivesDefaults = ({
  addOnDirection,
  ...props
}: StyleFunctionProps) => {
  const borderColor = mode("gray.300", "gray.700")(props);

  return {
    borderColor,
    background: mode("white", "transparent")(props),
    boxShadow: "none",
    borderTopLeftRadius: addOnDirection === "right" ? 0 : "base",
    borderBottomLeftRadius: addOnDirection === "right" ? 0 : "base",
    borderTopRightRadius: addOnDirection === "left" ? 0 : "base",
    borderBottomRightRadius: addOnDirection === "left" ? 0 : "base",
    _placeholder: {
      color: "gray.500",
    },
    _hover: {
      borderColor: mode("gray.400", "gray.600")(props),
      boxShadow: "none",
    },
    _focus: {
      borderColor: "blue.300",
      boxShadow: "outline",
    },
    _disabled: {
      bgColor: mode("gray.100", "whiteAlpha.200")(props),
      _hover: {
        borderColor,
      },
    },
    _invalid: {
      borderColor: mode("red.500", "red.300")(props),
      boxShadow: "none",
      _hover: {
        borderColor: "red.600",
      },
      _focus: {
        borderColor: mode("red.500", "red.300")(props),
        boxShadow: mode(
          "0 0 0 1px var(--chakra-colors-red-500)",
          "0 0 0 1px var(--chakra-colors-red-300)",
        )(props),
      },
    },
    _readOnly: {
      bg: mode("gray.100", "gray.800")(props),
      color: mode("black", "white")(props),
    },
  };
};
