import * as React from "react";
import type {
  Props as ReactSelectProps,
  GroupBase,
  StylesConfig,
  CSSObjectWithLabel,
  SelectInstance,
} from "react-select";
import { useFormControlContext } from "@chakra-ui/react";
import { type SelectProps as ChakraSelectProps } from "@chakra-ui/react";
import ReactSelect from "react-select";
import type { ReactNode } from "react";

import { noop } from "../../utils";

import {
  NullComponent,
  OurClearIndicator,
  OurGroup,
  OurGroupHeading,
  OurMultiValue,
  SelectDropdownIndicator,
  OurSelectOption,
  useGetStyles,
} from "./select-components";

export { OurSelectOption } from "./select-components";

export type SelectOption = {
  value: string | number | boolean;
  label: string | ReactNode;
  isFixed?: boolean;
};

export interface SelectProps<
  OptionType = SelectOption,
  IsMulti extends boolean = false,
  GroupType extends GroupBase<OptionType> = GroupBase<OptionType>,
> extends ReactSelectProps<OptionType, IsMulti, GroupType> {
  /**
   * Indicate that the input is currently invalid
   */
  isInvalid?: boolean;
  /**
   * Indicate that there was a network error loading options
   */
  hasError?: boolean;
  /**
   * When provided and `hasError` is true, this will be used as a callback when `Retry` is clicked in the select menu list.
   */
  onErrorRetry?: () => void;
  size?: ChakraSelectProps["size"];
  hideCheckIcon?: boolean;
  onScrollEnd?: () => void;
  isRequired?: boolean;
  closeOnSelect?: boolean;
  addOnDirection?: "right" | "left";
  menuStyles?: CSSObjectWithLabel;
}

const SelectInner = <
  OptionType extends SelectOption = SelectOption,
  IsMulti extends boolean = false,
  GroupType extends GroupBase<OptionType> = GroupBase<OptionType>,
>(
  {
    closeOnSelect = true,
    isSearchable = false,
    options = [],
    components,
    ...props
  }: SelectProps<OptionType, IsMulti, GroupType>,
  ref: React.Ref<any>,
) => {
  const formControlContext = useFormControlContext();
  // This exists because the `formControlContext` may be undefined when this is used outside of an RHF* wrapped component from this library.
  // For manual usage, a consumer should pass the `isInvalid` prop.
  const isInvalid = props.isInvalid ?? formControlContext?.isInvalid;

  const styles = useGetStyles(props, isInvalid) as StylesConfig<
    OptionType,
    IsMulti,
    GroupType
  >;

  return (
    <ReactSelect
      ref={ref}
      components={{
        DropdownIndicator: SelectDropdownIndicator,
        IndicatorSeparator: NullComponent,
        LoadingMessage: NullComponent,
        Option: OurSelectOption,
        MultiValue: OurMultiValue,
        ClearIndicator: OurClearIndicator,
        GroupHeading: OurGroupHeading,
        Group: OurGroup,
        ...components,
      }}
      closeMenuOnSelect={closeOnSelect}
      isSearchable={isSearchable}
      menuPlacement="auto"
      onMenuScrollToBottom={props.onScrollEnd || noop}
      styles={styles}
      options={options}
      menuPortalTarget={document.body}
      // Use dividers if there are no labels on our option groups
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      useDividers={!options.some((options) => options.label)}
      required={props.isRequired}
      {...props}
    />
  );
};

export const Select = React.forwardRef(SelectInner) as <
  OptionType = SelectOption,
  IsMulti extends boolean = false,
  GroupType extends GroupBase<OptionType> = GroupBase<OptionType>,
>(
  props: SelectProps<OptionType, IsMulti, GroupType> & {
    ref?: React.Ref<SelectInstance<OptionType, IsMulti, GroupType>>;
  },
) => React.ReactElement;
