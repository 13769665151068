import { useQuery } from "@tanstack/react-query";

import { useApi } from "@stordco/fe-components";

import { useNetworkId } from "../components/Network";

export interface Channel {
  category: "D2C" | "B2B" | null;
  channel_id: string;
  display_name: string;
  network_id: string;
  type: string;
  status: "active" | "inactive";
  default_priority: number;
  integration_metadata: Record<string, any>;
}

interface ChannelsResponse {
  data: Channel[];
  metadata: {
    after: string | null;
    before: string | null;
    limit: number;
    total_count: number;
  };
}

export function useNetworkChannels(
  networkId: string,
  opts?: { enabled?: boolean },
) {
  const api = useApi();

  return useQuery({
    queryKey: ["Channels", { networkId }],
    queryFn: () =>
      api<ChannelsResponse>({
        url: "/v1/channels",
        params: {
          limit: 500,
          status: "active",
          network_id: networkId,
        },
      }),
    select: (response) =>
      response.data.sort(function (a, b) {
        return (
          a.default_priority - b.default_priority ||
          a.display_name.localeCompare(b.display_name)
        );
      }),
    ...opts,
  });
}

export const useChannels = (opts?: { enabled?: boolean }) => {
  const networkId = useNetworkId();

  return useNetworkChannels(networkId, opts);
};
