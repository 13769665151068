import { useEffect, type ReactNode } from "react";

import { useAuthentication } from "../../hooks/useAuthentication";
import { PageLoader } from "../PageLoader";

export function RequireAuth({ children }: { children: ReactNode }) {
  const { user, isAuthenticated, isLoading, loginWithRedirect } =
    useAuthentication();

  useEffect(() => {
    if (isLoading || isAuthenticated) {
      return;
    }

    loginWithRedirect({
      appState: {
        returnTo: `${window.location.pathname}${window.location.search}`,
      },
    });
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  if (!user) {
    // TODO: suspend
    return <PageLoader />;
  }

  return children;
}
