import type { SelectProps as ChakraSelectProps } from "@chakra-ui/react";
import { useFormControlContext } from "@chakra-ui/react";
import * as React from "react";
import type { GroupBase } from "react-select";
import ReactSelectCreatable, {
  type CreatableProps as ReactSelectCreatableProps,
} from "react-select/creatable";

import { noop } from "../../utils";

import {
  useGetStyles,
  NullComponent,
  OurClearIndicator,
  OurMultiValue,
  SelectDropdownIndicator,
  OurSelectOption,
} from "./select-components";

export type CreatableOption = {
  value: string;
  label: string;
};

export interface CreatableProps<
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
> extends ReactSelectCreatableProps<Option, IsMulti, Group> {
  /**
   * Indicate that the input is currently invalid
   */
  isInvalid?: boolean;
  /**
   * Indicate that there was a network error loading options
   */
  hasError?: boolean;
  hideCheckIcon?: boolean;
  hideDropdownIndicator?: boolean;
  isRequired?: boolean;
  /**
   * When provided and `hasError` is true, this will be used as a callback when `Retry` is clicked in the select menu list.
   */
  onErrorRetry?: () => void;
  size?: ChakraSelectProps["size"];
  showDropdownIndicator?: boolean;
  onScrollEnd?: () => void;
  closeOnSelect?: boolean;
}

const CreatableInner = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
>(
  {
    closeOnSelect = true,
    hideDropdownIndicator = false,
    isRequired,
    ...props
  }: CreatableProps<Option, IsMulti, Group>,
  ref: React.Ref<any>,
) => {
  const formControlContext = useFormControlContext();
  // This exists because the `formControlContext` may be undefined when this is used outside of an RHF* wrapped component from this library.
  // For manual usage, a consumer should pass the `isInvalid` prop.
  const isInvalid = props.isInvalid ?? formControlContext?.isInvalid;

  const styles = useGetStyles<Option, IsMulti, Group>(props, isInvalid);

  return (
    <ReactSelectCreatable
      ref={ref}
      components={{
        DropdownIndicator: hideDropdownIndicator
          ? NullComponent
          : SelectDropdownIndicator,
        IndicatorSeparator: NullComponent,
        LoadingMessage: NullComponent,
        Option: OurSelectOption,
        MultiValue: OurMultiValue,
        ClearIndicator: OurClearIndicator,
      }}
      required={isRequired}
      closeMenuOnSelect={closeOnSelect}
      onMenuScrollToBottom={props.onScrollEnd || noop}
      styles={styles}
      {...props}
    />
  );
};

export const Creatable = React.forwardRef(CreatableInner) as <
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: CreatableProps<Option, IsMulti, Group> & {
    ref?: React.Ref<any>;
  },
) => React.ReactElement;
