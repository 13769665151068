import { useBreakpointValue } from "@chakra-ui/react";

/** Note: This hook is *not* checking for whether the device is a desktop, but whether the screen size
 * is over the `lg` breakpoint. This could, in theory, include non-desktops with large screens. */
export const useIsDesktop = () =>
  useBreakpointValue(
    { base: false, lg: true },
    {
      ssr: false,
    },
  );
