import { popoverAnatomy as parts } from "@chakra-ui/anatomy";
import {
  createMultiStyleConfigHelpers,
  defineStyle,
} from "@chakra-ui/styled-system";
import { cssVar } from "@chakra-ui/theme-tools";

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(parts.keys);

const popperBgVar = cssVar("popper-bg");
const popperArrowBgVar = cssVar("popper-arrow-bg");
const popperArrowShadowColorVar = cssVar("popper-arrow-shadow-color");

export default defineMultiStyleConfig({
  baseStyle: definePartsStyle({
    popper: defineStyle({ zIndex: 1325 }), // This should match the z-index in `theme.ts`
    content: defineStyle({
      _dark: {
        borderColor: "gray.800",
        [popperBgVar.variable]: "colors.gray.800",
        [popperArrowBgVar.variable]: "colors.gray.800",
        [popperArrowShadowColorVar.variable]: "colors.gray.800",
      },
    }),
  }),
});
