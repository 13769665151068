import type {
  FieldError,
  FieldValues,
  FieldPath,
  UseFormStateReturn,
} from "react-hook-form";
import get from "lodash/get.js";

export const isFieldError = (error: any): error is FieldError =>
  error && "message" in error;
export const isFieldErrorArray = (
  errorArray: any,
): errorArray is FieldError[] =>
  errorArray &&
  Array.isArray(errorArray) &&
  errorArray.filter(
    (error) => error && typeof error === "object" && "message" in error,
  ).length > 0;

/**
 * Checks to see if the given field name exists in the errors object,
 * and will return the error message if it does.
 */
export const getFieldErrorMessage = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
  formState: UseFormStateReturn<TFieldValues>,
  name: TName,
) => {
  const error = get(formState.errors, name.split("."));
  if (isFieldError(error)) {
    return error.message;
  }

  if (isFieldErrorArray(error)) {
    let msg = "";
    error.forEach((error) => {
      if (error?.message) {
        if (!msg) {
          msg = error.message;
        } else {
          msg += `; ${error.message}`;
        }
      }
    });
    return msg;
  }

  return undefined;
};
