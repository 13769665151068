/**
 * Calculate the percent that `dividend` is of `divisor`, avoiding division by 0
 * Returns 0 when `divisor` is 0 so we don't get `NaN`
 */
export function safePercent(dividend: number, divisor: number) {
  if (divisor === 0) {
    return 0;
  }

  return dividend / divisor;
}
