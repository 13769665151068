import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { useApi } from "@stordco/fe-components";

import { SUPPORTED_CONNECTION_TYPES, type Connection } from "./commonTypes";
import { useNetworkId } from "../../../../components/Network";

export const useConnections = () => {
  const api = useApi();

  return useQuery({
    queryKey: ["connections"],
    queryFn: () =>
      api.get<
        Array<{
          connector_id: string;
          display_name: string;
          id: string;
          source: (typeof SUPPORTED_CONNECTION_TYPES)[number];
        }>
      >({
        url: `/v2/connections`,
      }),
    // Only return supported connections
    select: (data) =>
      data.filter((connection) =>
        SUPPORTED_CONNECTION_TYPES.includes(connection.source),
      ),
  });
};

export const useConnection = (id: string) => {
  const api = useApi();

  return useQuery({
    queryKey: ["connections", id],
    queryFn: () =>
      api.get<Connection>({
        url: `/v2/connections/${id}`,
      }),
  });
};

export function useStorefront(
  storefrontId: string,
  opts?: { enabled?: boolean },
) {
  const api = useApi();
  const networkId = useNetworkId();

  return useQuery({
    queryKey: ["Storefronts", { networkId, storefrontId }],
    queryFn: () =>
      api.get<{
        data: {
          storefront_id: string;
          name: string;
          description: string;
          // TODO: other properties
        };
      }>({
        url: `/v1/consumer_experience/${networkId}/storefronts/${storefrontId}`,
      }),
    select: (response) => response.data,
    ...opts,
  });
}

export function useStorefronts() {
  const api = useApi();
  const networkId = useNetworkId();

  return useQuery({
    queryKey: ["Storefronts", { networkId }],
    queryFn: () =>
      api.get<{ data: Array<{ storefront_id: string; name: string }> }>({
        url: `/v1/consumer_experience/${networkId}/storefronts`,
        params: { limit: 100 },
      }),
    select: (response) => response.data,
  });
}

export const useRetryFailedOrders = (connectionId: string) => {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () =>
      api.post<void>({
        url: `/v2/connections/${connectionId}/dispatch_action/retry_failed_orders`,
        body: { params: {} },
      }),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ["connections", connectionId],
      }),
  });
};

export const useEnableConnection = () => {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ connectionId }: { connectionId: string }) =>
      api.post<void>({
        url: `/v2/connections/${connectionId}/enable`,
      }),
    onSuccess: (_, { connectionId }) =>
      queryClient.invalidateQueries({
        queryKey: ["connections", connectionId],
      }),
  });
};

export const useDisableConnection = () => {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ connectionId }: { connectionId: string }) =>
      api.post<void>({
        url: `/v2/connections/${connectionId}/disable`,
      }),
    onSuccess: (_, { connectionId }) =>
      queryClient.invalidateQueries({
        queryKey: ["connections", connectionId],
      }),
  });
};

export const usePullOrders = ({ connectionId }: { connectionId: string }) => {
  const api = useApi();

  return useMutation({
    mutationFn: ({ dateTime }: { dateTime: string }) =>
      api.post<void>({
        url: `/v2/connections/${connectionId}/dispatch_action/pull_missing_orders`,
        body: {
          params: {
            orders_created_since: dateTime,
          },
        },
      }),
  });
};

export const useReprocessOrders = ({
  connectionId,
}: {
  connectionId: string;
}) => {
  const api = useApi();

  return useMutation({
    mutationFn: ({ orderNumbers }: { orderNumbers: string[] }) =>
      api.post<void>({
        url: `/v2/connections/${connectionId}/dispatch_action/reprocess_orders`,
        body: {
          params: {
            order_numbers: orderNumbers,
          },
        },
      }),
  });
};

export const usePushInventory = ({
  connectionId,
}: {
  connectionId: string;
}) => {
  const api = useApi();

  return useMutation({
    mutationFn: () =>
      api.post<void>({
        url: `/v2/connections/${connectionId}/dispatch_action/push_inventory`,
        body: { params: {} },
      }),
  });
};

export const usePullItems = ({ connectionId }: { connectionId: string }) => {
  const api = useApi();

  return useMutation({
    mutationFn: () =>
      api.post<void>({
        url: `/v2/connections/${connectionId}/dispatch_action/pull_items`,
        body: { params: {} },
      }),
  });
};

export const usePullBundles = ({ connectionId }: { connectionId: string }) => {
  const api = useApi();

  return useMutation({
    mutationFn: () =>
      api.post<void>({
        url: `/v2/connections/${connectionId}/dispatch_action/pull_kits`,
        body: { params: {} },
      }),
  });
};

export const usePushBundles = ({ connectionId }: { connectionId: string }) => {
  const api = useApi();

  return useMutation({
    mutationFn: () =>
      api.post<void>({
        url: `/v2/connections/${connectionId}/dispatch_action/push_kits`,
        body: { params: {} },
      }),
  });
};

export const useSaveConnection = () => {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      connectionId,
      body,
    }: {
      connectionId: string;
      body: object;
    }) =>
      api.patch({
        url: `/v2/connections/${connectionId}`,
        body,
      }),
    onSuccess: (response, { connectionId }) =>
      queryClient.setQueryData(["connections", connectionId], response),
  });
};
