import { mode } from "@chakra-ui/theme-tools";
import { type ComponentSingleStyleConfig } from "@chakra-ui/react";

const config: ComponentSingleStyleConfig = {
  baseStyle: (props) => ({
    item: {
      fontSize: "sm",
      color: mode("gray.800", "white")(props),
      lineHeight: 4,
      py: 2,
      px: 4,

      "> [class*=icon-wrapper]": {
        fontSize: "1.125em",
      },
      ...(props.colorMode === "dark" && {
        bg: "gray.800",
        _hover: { bg: "gray.700" },
      }),
    },
    groupTitle: {
      ...(props.colorMode === "dark" && {
        color: "gray.100",
      }),
      lineHeight: "0.625rem",
      fontSize: "0.625rem",
      fontWeight: "medium",
      m: 0,
      py: 2,
      px: 4,
    },
    list: {
      borderStyle: "none",
      boxShadow: "md",
      minWidth: "160px",
      borderRadius: "base",
      ...(props.colorMode === "dark" && {
        bg: "gray.800",
      }),
      zIndex: 10,
    },
  }),
};

export default config;
