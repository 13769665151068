import { useIdentity } from "../components/IdentityProvider";

export function useUser() {
  const identity = useIdentity();

  if (identity.type === "auth0") {
    throw new Error("Got user of type 'auth0'");
  }

  const { user, organizations, roles } = identity;

  return {
    ...user,
    organizations,
    roles,
  };
}
