import type { ReactNode } from "react";

import { NavigationProvider } from "@stordco/fe-components";

import { useNetwork } from "../Network";

import { useMainNav } from "./hooks/useMainNav";
import { useUserMenu } from "./hooks/useUserMenu";

export function NetworkNavigation({ children }: { children: ReactNode }) {
  const network = useNetwork();

  const main = useMainNav();
  const userMenu = useUserMenu();

  return (
    <NavigationProvider
      companyName={network.name}
      userMenu={userMenu}
      links={main}
    >
      {children}
    </NavigationProvider>
  );
}
