import {
  useLocation,
  Navigate,
  useNavigate,
  useBeforeUnload,
} from "react-router-dom";
import { useMemo, useState, useEffect, useCallback } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Flex,
  Divider,
  ModalFooter,
  Button,
  FormLabel,
  FormErrorMessage,
  FormControl,
  useToast,
  Text,
} from "@chakra-ui/react";
import {
  RHFInput,
  RHFSelect,
  useApi,
  useUser,
  useIsAdmin,
} from "@stordco/fe-components";
import type { Control } from "react-hook-form";
import { useController } from "react-hook-form";
import { Controller } from "react-hook-form";
import { useForm } from "react-hook-form";
import { useNetworkChannels } from "../hooks";
import { useQuery, useMutation } from "@tanstack/react-query";
import { NetworkSelect } from "../features/Admin/NetworkSelect";

interface FieldValues {
  network_id: string;
  display_name: string;
  source_location_id: string;
  sales_channel: string;
}

export function ConnectShopify() {
  const location = useLocation();
  const params = useMemo(
    () => new URLSearchParams(location.search),
    [location.search],
  );

  const nonce = params.get("nonce");

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FieldValues>();

  const networkId = watch("network_id");

  const channelsQuery = useNetworkChannels(networkId, {
    enabled: Boolean(nonce) && Boolean(networkId),
  });

  const locationsQuery = useShopifyLocations(nonce);

  const locationOptions = useMemo(
    () =>
      locationsQuery.data?.map((location) => ({
        label: location.name,
        value: location.id,
      })) ?? [],
    [locationsQuery.data],
  );

  const isAdmin = useIsAdmin();

  const createShopifyConnectionMutation = useCreateShopifyConnection();

  const navigate = useNavigate();

  const [selectedNetwork, setSelectedNetwork] = useState<{
    network_id: string;
    name: string;
    tenant_id: string;
  } | null>(null);

  const toast = useToast();

  useBeforeUnload(
    useCallback((event) => {
      // Recommended
      event.preventDefault();

      // Included for legacy support, e.g. Chrome/Edge < 119
      event.returnValue = true;
    }, []),
  );

  if (!nonce) {
    return <Navigate to="/" />;
  }

  const formId = "connect-shopify-form";

  return (
    <Modal
      isOpen
      onClose={() => {}}
      closeOnEsc={false}
      closeOnOverlayClick={false}
    >
      <form
        id={formId}
        onSubmit={handleSubmit((values) => {
          createShopifyConnectionMutation.mutate(
            {
              nonce,
              tenant_id: selectedNetwork!.tenant_id,
              ...values,
            },
            {
              onSuccess({ id }) {
                navigate(
                  `/distribution_networks/${networkId}/connections/${id}`,
                );
              },
              onError(error) {
                console.log("Error creating shopify connection", error);

                toast({
                  status: "error",
                  title:
                    "Sorry, there was an error. Please try again in a few minutes.",
                });
              },
            },
          );
        })}
        noValidate
      />

      <ModalOverlay />

      <ModalContent>
        <ModalHeader>Shopify is almost connected!</ModalHeader>

        <ModalBody>
          <Flex direction="column" gap={4}>
            <Text>
              To complete your connection to Shopify, please provide the
              following details.
            </Text>

            {isAdmin ? (
              <Controller
                control={control}
                name="network_id"
                render={({ field, fieldState }) => (
                  <FormControl isInvalid={fieldState.invalid}>
                    <FormLabel>Network</FormLabel>

                    <NetworkSelect
                      defaultOptions
                      value={selectedNetwork}
                      onChange={(newValue) => {
                        setSelectedNetwork(newValue);
                        field.onChange(newValue!.network_id);
                      }}
                      menuPortalTarget={document.body}
                    />

                    <FormErrorMessage>
                      {errors["network_id"]?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
                rules={{
                  required: "This field is required",
                }}
              />
            ) : (
              <NonAdminNetworkSelect control={control} />
            )}

            <RHFInput
              control={control}
              name="display_name"
              label="Connection name"
              isRequired
              rules={{
                required: "This field is required",
              }}
            />

            <RHFSelect
              name="source_location_id"
              label="Shopify inventory totals location"
              control={control}
              options={locationOptions}
              rules={{
                required: "This field is required",
              }}
              helperText="Stord will send all inventory data to this location in Shopify."
              placeholder="Select a Shopify location"
              isLoading={locationsQuery.isPending}
              isRequired
            />

            <Divider />

            <RHFSelect
              name="sales_channel"
              label="Map to sales channel"
              control={control}
              options={
                channelsQuery.data?.map((channel) => ({
                  label: channel.display_name,
                  value: channel.channel_id,
                })) ?? []
              }
              rules={{
                required: "This field is required",
              }}
              placeholder="Select an existing sales channel"
              helperText="This will replace any existing connection for this channel."
              isLoading={channelsQuery.isFetching}
              isDisabled={channelsQuery.isPending}
              isRequired
            />
          </Flex>
        </ModalBody>

        <ModalFooter gap={2}>
          <Button
            form={formId}
            type="submit"
            isLoading={createShopifyConnectionMutation.isPending}
          >
            Connect
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

function NonAdminNetworkSelect({ control }: { control: Control<FieldValues> }) {
  const user = useUser();

  const networkOptions = useMemo(() => {
    const networkOptions: Array<{ label: string; value: string }> = [];

    for (const org of user.organizations) {
      for (const app of org.apps!) {
        for (const realm of app.realms) {
          if (app.alias === "oms") {
            networkOptions.push({
              value: realm.resource_id,
              label: realm.name,
            });
          }
        }
      }
    }

    return networkOptions;
  }, [user]);

  if (networkOptions.length === 1) {
    return (
      <InvisibleNetworkSetter control={control} option={networkOptions[0]} />
    );
  }

  return (
    <RHFSelect
      control={control}
      name="network_id"
      label="Network"
      options={networkOptions}
      rules={{
        required: "This field is required",
      }}
    />
  );
}

function InvisibleNetworkSetter({
  control,
  option,
}: {
  control: Control<FieldValues>;
  option: { label: string; value: string };
}) {
  const { field } = useController({
    control,
    name: "network_id",
  });

  const value = option.value;

  useEffect(() => {
    field.onChange(value);
  }, [field, value]);

  return null;
}

function useShopifyLocations(nonce: string | null) {
  const api = useApi();

  return useQuery({
    queryKey: ["ShopifyLocations", { nonce }],
    queryFn: () =>
      api<{
        data: Array<{
          id: number;
          name: string;
        }>;
      }>({
        url: `/v1/connections/shopify/locations`,
        params: {
          nonce,
        },
      }),
    select: (response) => response.data,
    enabled: Boolean(nonce),
  });
}

type ShopifyLocation = {
  location_name: string;
  source_location_id: string;
};

type ShopifyReturnType = {
  connector_id: string;
  details: {
    shop_name: string;
    shopify_connection_id: string;
    shopify_locations: ShopifyLocation[];
  };
  display_name: string;
  id: string;
  source: string;
};

function useCreateShopifyConnection() {
  const api = useApi();

  return useMutation({
    async mutationFn({
      nonce,
      tenant_id,
      network_id,
      display_name,
      source_location_id,
      sales_channel,
    }: {
      nonce: string;
      tenant_id: string;
      network_id: string;
      display_name: string;
      source_location_id: string;
      sales_channel: string;
    }) {
      // TODO: Sort out this madness. Why two api calls when one will do?
      const connection = await api.post<ShopifyReturnType>({
        url: "/v2/connections/shopify",
        body: {
          display_name,
          network_id,
          config: {},
          opts: {
            nonce,
          },
        },
        headers: {
          "tenant-id": tenant_id,
        },
      });

      return api.patch<ShopifyReturnType>({
        url: `/v2/connections/${connection.id}`,
        body: {
          network_id,
          config: {
            location: { source_location_id },
            sales_channel: { channel_id: sales_channel },
          },
        },
        headers: {
          "tenant-id": tenant_id,
        },
      });
    },
  });
}
