import { type ReactNode } from "react";
import { Flex, type FlexProps } from "@chakra-ui/react";

/**
 * A little container for your ghost, icon button actions on cards.
 * Compensates for the ghost appearance by nudging the buttons up a bit to align better with the header.
 *
 * @example
 *
 * ```ts
 * <Card>
 *   <Card.PaddedContent>
 *     <Card.Header
 *       title="Foo"
 *       rightElement={
 *         <Card.Actions>
 *           <IconButton
 *             aria-label="Edit"
 *             variant="ghost"
 *             colorScheme="gray"
 *             icon={<FaIcon icon={faPen} />}
 *             onClick={doSomething}
 *           />
 *
 *           <IconButton
 *             aria-label="Refresh"
 *             variant="ghost"
 *             colorScheme="gray"
 *             icon={<FaIcon icon={faArrowsRotate} />}
 *             onClick={refresh}
 *           />
 *         </Card.Actions>
 *       }
 *     />
 *   </Card.PaddedContent>
 * </Card>
 * ```
 */
export function CardActions({
  children,
  ...props
}: Omit<FlexProps, "children"> & { children: ReactNode }) {
  return (
    <Flex gap={1} my={-1} {...props}>
      {children}
    </Flex>
  );
}
