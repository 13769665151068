import type { ComponentMultiStyleConfig } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

export default {
  parts: [
    "overlay",
    "dialogContainer",
    "dialog",
    "header",
    "closeButton",
    "body",
    "footer",
  ],
  baseStyle: (props) => ({
    dialog: {
      backgroundColor: mode(null, "gray.900")(props),
    },
    header: {
      boxShadow: mode("md", "md")(props),
      zIndex: 1, // without this, `boxShadow` doesn't appear
      backgroundColor: mode(null, "whiteAlpha.200")(props),
    },
    closeButton: {
      top: "16px",
      zIndex: 1,
    },
    body: {
      paddingY: 4,
      backgroundColor: mode("gray.50", "gray.900")(props),
    },
    footer: {
      shadow: "md",
      backgroundColor: mode(null, "whiteAlpha.200")(props),
      zIndex: 10,
    },
  }),
  sizes: {
    "2xs": {
      dialog: { maxWidth: "240px" },
    },
    sm: {
      dialog: { maxWidth: "448px" },
    },
    md: {
      dialog: { maxWidth: "576px" },
    },
    lg: {
      dialog: { maxWidth: "768px" },
    },
    xl: {
      dialog: { maxWidth: "960px" },
    },
    "2xl": {
      dialog: { maxWidth: "1152px" },
    },
  },
} as ComponentMultiStyleConfig;
