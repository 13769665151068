import { useFlag } from "@stordco/fe-components";
import { useNetworkConfigurationFlag } from "../../components/Network";

/**
 * Checks if the user can use lot controls. This is primarily available for Stord internal users as of 9/17/2024.
 *
 * @returns true if the user can use lot controls, false otherwise
 */
export function useCanUseLotControls() {
  const lotControlsEnabled = useFlag("enable-lot-controls");
  const hasAccessToLotApprovals = useNetworkConfigurationFlag("lot_approvals");

  return lotControlsEnabled && hasAccessToLotApprovals;
}
