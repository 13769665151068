import { userInteractivesDefaults } from "../defaultStylings";
import { ComponentSingleStyleConfig } from "@chakra-ui/react";

const config: ComponentSingleStyleConfig = {
  variants: {
    outline: (props) => ({
      ...userInteractivesDefaults(props),
      fontSize: "sm",
    }),
  },
  defaultProps: {
    size: "sm",
  },
};

export default config;
