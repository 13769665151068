import { Box, Button, Flex, SimpleGrid } from "@chakra-ui/react";
import {
  Filter,
  useBaseFilter,
  useDatadogRUM,
  useMultiFilter,
  useSearchParams,
} from "@stordco/fe-components";
import { Exceptions } from "./Widgets/Exceptions";
import { OpenOrders } from "./Widgets/OpenOrders";
import { ShippedOrders } from "./Widgets/ShippedOrders";
import { AcceptedOrders } from "./Widgets/AcceptedOrders";
import { OriginsAndDestinations } from "./Widgets/OriginsAndDestinations";
import { DeliveryTracking } from "./Widgets/DeliveryTracking";
import { Connections } from "./Widgets/Connections";
import { useNetworkConfigurationFlag } from "../../../components/Network/useNetwork";
import {
  getFacilityDisplayName,
  useTradeConnections,
} from "../../../hooks/queries/useTradeConnections";
import { useChannels } from "../../../hooks";

export function FulfillmentDashboard() {
  const { data: tradeConnections } = useTradeConnections();
  const { data: channels } = useChannels();

  const datadogRUM = useDatadogRUM();

  const facilityFilter = useMultiFilter({
    options:
      tradeConnections?.map((tradeConnection) => ({
        label: getFacilityDisplayName(tradeConnection),
        id: tradeConnection.facility.facility_id,
      })) ?? [],
    scope: "Facility",
    filterKey: "facility_id",
    onSelect(selection) {
      datadogRUM?.addAction("dashboard:filter_applied", {
        filter: "facility",
        value: selection,
      });
    },
    onClear() {
      datadogRUM?.addAction("dashboard:filter_cleared", {
        filter: "facility",
      });
    },
  });

  const categoryFilter = useBaseFilter({
    scope: "Category",
    options: [
      { value: "D2C", label: "D2C" },
      { value: "B2B", label: "B2B" },
    ],
    queryParam: "channel_category",
    onSelect(selection) {
      datadogRUM?.addAction("dashboard:filter_applied", {
        filter: "category",
        value: selection,
      });
    },
    onClear() {
      datadogRUM?.addAction("dashboard:filter_cleared", {
        filter: "facility",
      });
    },
  });

  const channelFilter = useMultiFilter({
    options:
      channels?.map((channel) => ({
        label: channel.display_name,
        id: channel.channel_id,
      })) ?? [],
    scope: "Channel",
    filterKey: "channel_id",
    onSelect(selection) {
      datadogRUM?.addAction("dashboard:filter_applied", {
        filter: "channel",
        value: selection,
      });
    },
    onClear() {
      datadogRUM?.addAction("dashboard:filter_cleared", {
        filter: "facility",
      });
    },
  });

  const shipmentTypeFilter = useBaseFilter({
    scope: "Shipment type",
    options: [
      { value: "parcel", label: "Parcel" },
      { value: "freight", label: "Freight" },
    ],
    queryParam: "shipment_type",
    onSelect(selection) {
      datadogRUM?.addAction("dashboard:filter_applied", {
        filter: "shipment_type",
        value: selection,
      });
    },
    onClear() {
      datadogRUM?.addAction("dashboard:filter_cleared", {
        filter: "facility",
      });
    },
  });

  const [params, { clear }] = useSearchParams();

  const networkDeliveryTrackingFlag = useNetworkConfigurationFlag(
    "network_delivery_tracking",
  );

  return (
    <>
      <Flex gap={2} mb={4} wrap="wrap">
        <Filter {...facilityFilter} />
        <Filter {...categoryFilter} />
        <Filter {...channelFilter} />
        <Filter {...shipmentTypeFilter} />

        {params["facility_id"] ||
        params["channel_category"] ||
        params["channel_id"] ||
        params["shipment_type"] ? (
          <Button
            aria-label="Clear all filters"
            variant="ghost"
            colorScheme="gray"
            onClick={() => {
              datadogRUM?.addAction("dashboard:clear_all_filters");

              clear();
            }}
          >
            Clear filters
          </Button>
        ) : null}
      </Flex>

      <SimpleGrid columns={{ base: 1, md: 3 }} gap={6}>
        <Box gridColumn={{ base: "span 1", md: "span 3" }}>
          <Exceptions />
        </Box>

        <OpenOrders />
        <ShippedOrders />
        <AcceptedOrders />

        <Box
          gridColumn={{
            base: "span 1",
            md: "span 2",
          }}
        >
          <OriginsAndDestinations />
        </Box>

        <Flex direction="column" gap={6}>
          {networkDeliveryTrackingFlag ? <DeliveryTracking /> : null}
          <Connections />
        </Flex>
      </SimpleGrid>
    </>
  );
}
