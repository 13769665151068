import { radioAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system";
import { mode } from "@chakra-ui/theme-tools";
import { typography } from "../foundations";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(radioAnatomy.keys);

const baseStyle = definePartsStyle((props) => ({
  control: {
    borderWidth: "0.125rem",
    borderColor: mode("gray.300", "gray.700")(props),
    _checked: {
      color: mode(null, "gray.900")(props),
    },
    _hover: {
      borderColor: "gray.400",
    },
    _disabled: {
      borderColor: mode("gray.300", "gray.700")(props),
      bg: mode("gray.200", "gray.800")(props),
      _checked: {
        borderColor: mode("gray.400", "gray.600")(props),
        bg: mode("gray.400", "gray.600")(props),
        color: "gray.700",
      },
    },
  },
}));

const variants = {
  button: definePartsStyle({
    container: {
      borderWidth: 1,
      borderColor: "gray.200",
      rounded: "base",
      px: 4,
      py: 2,
      bg: "bg-surface",
      _dark: {
        borderColor: "gray.700",
        bg: "gray.800",
        _hover: {
          borderColor: "gray.600",
          _disabled: {
            borderColor: "gray.700",
          },
        },
      },
      _hover: {
        borderColor: "gray.400",
        _disabled: {
          borderColor: "gray.200",
        },
      },
      transitionProperty: "common",
      transitionDuration: "normal",
      _checked: {
        borderColor: "blue.500",
        _dark: {
          borderColor: "blue.200",
          _disabled: {
            borderColor: "gray.700",
          },
        },
        _disabled: {
          borderColor: "gray.200",
        },
      },
    },
    label: {
      flex: "1",
    },
  }),
};

const sizes = {
  md: definePartsStyle({
    label: {
      ...typography.textStyles.paragraph,
    },
  }),
};

export default defineMultiStyleConfig({ baseStyle, sizes, variants });
