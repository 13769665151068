export function setCookie({
  name,
  value,
  domain,
  expiresAt,
}: {
  name: string;
  value: string;
  domain?: string;
  expiresAt?: Date;
}) {
  let expirationDate = expiresAt;

  if (!expirationDate) {
    const farFutureDate = new Date();
    farFutureDate.setFullYear(farFutureDate.getFullYear() + 10);

    expirationDate = farFutureDate;
  }

  const expires = `expires=${expirationDate.toUTCString()}`;

  // Cookies will work between admin and shipper, but only for the same domain;
  // localhost will share cookies and staging & prod will share cookies. There was no way
  // to get review pods to share cookies, unfortunately.
  document.cookie = `${name}=${value}; ${expires}; domain=${
    domain ??
    (window.location.host.includes("localhost")
      ? "localhost"
      : window.location.host.includes("stord.com")
      ? ".stord.com"
      : `${window.location.host}`)
  }; path=/`;
}

export function getCookie(name: string) {
  const cookies = decodeURIComponent(document.cookie).split("; ");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].split("=");
    if (cookie[0] === name) {
      return cookie[1];
    }
  }
  return null; // Cookie not found
}
