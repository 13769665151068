import type { TagProps } from "@chakra-ui/react";
import { Box, HStack, Text, useFormControlContext } from "@chakra-ui/react";
import { faTriangleExclamation } from "@fortawesome/pro-regular-svg-icons";
import * as React from "react";
import type {
  CSSObjectWithLabel,
  GroupBase,
  MenuListProps,
} from "react-select";
import { components } from "react-select";
import ReactSelectAsync, { type AsyncProps } from "react-select/async";

import { FaIcon } from "../FaIcon";
import { Loader } from "../Loader";
import {
  SelectDropdownIndicator,
  OurSelectOption,
  useGetStyles,
  OurMultiValue,
  OurClearIndicator,
} from "../Select";

export { components } from "react-select";

type AsyncSelectType = { label: string; value: string };

export type AsyncSelectProps<
  OptionType,
  IsMulti extends boolean = false,
  GroupType extends GroupBase<OptionType> = GroupBase<OptionType>,
> = AsyncProps<OptionType, IsMulti, GroupType> & {
  hasError?: boolean;
  isInvalid?: boolean;
  hideCheckIcon?: boolean;
  getValueIsFixed?: (value: OptionType) => boolean;
  menuStyles?: CSSObjectWithLabel;
};

const NullComponent = (): null => null;

const AsyncSelectComponent = <
  OptionType = AsyncSelectType,
  IsMulti extends boolean = false,
  GroupType extends GroupBase<OptionType> = GroupBase<OptionType>,
>(
  { components, ...props }: AsyncSelectProps<OptionType, IsMulti, GroupType>,
  ref: any,
) => {
  const formControlContext = useFormControlContext();
  const isInvalid = formControlContext?.isInvalid || props.isInvalid;

  const styles = useGetStyles<OptionType, IsMulti, GroupType>(props, isInvalid);

  return (
    <ReactSelectAsync
      ref={ref}
      components={{
        DropdownIndicator: SelectDropdownIndicator,
        IndicatorSeparator: NullComponent,
        LoadingIndicator: NullComponent,
        LoadingMessage: NullComponent,
        MenuList: CustomMenuList,
        Option: OurSelectOption,
        MultiValue: OurMultiValue,
        ClearIndicator: OurClearIndicator,
        ...components,
      }}
      {...props}
      onChange={(newValue, actionMeta) => {
        if (
          (actionMeta.action === "remove-value" ||
            actionMeta.action === "pop-value") &&
          ((actionMeta.removedValue as any).isFixed ||
            props.getValueIsFixed?.(actionMeta.removedValue))
        ) {
          return;
        }

        if (actionMeta.action === "clear") {
          newValue = actionMeta.removedValues.filter(
            (o: any) => o.isFixed || props.getValueIsFixed?.(o),
          ) as any;
        }

        return props.onChange?.(newValue, actionMeta);
      }}
      styles={styles}
    />
  );
};

const CustomMenuList = <
  OptionType,
  IsMulti extends boolean,
  GroupType extends GroupBase<OptionType>,
>(
  props: MenuListProps<OptionType, IsMulti, GroupType>,
) => {
  if ((props.selectProps as any).hasError)
    return (
      <HStack justify="center" my={2}>
        <FaIcon icon={faTriangleExclamation} colorScheme="red" />
        <Text>Unable to load results</Text>
      </HStack>
    );

  return (
    <components.MenuList {...props}>
      {props.children}

      {props.selectProps.isLoading && (
        <Box textAlign="center" my={props.options.length && 2.5}>
          <Loader size="sm" />
        </Box>
      )}
    </components.MenuList>
  );
};

export const AsyncSelect = React.forwardRef(AsyncSelectComponent) as <
  OptionType = AsyncSelectType,
  IsMulti extends boolean = false,
  GroupType extends GroupBase<OptionType> = GroupBase<OptionType>,
>(
  props: AsyncSelectProps<OptionType, IsMulti, GroupType> & {
    ref?: React.ForwardedRef<HTMLElement>;
    tagProps?: TagProps;
  },
) => ReturnType<typeof AsyncSelectComponent>;
