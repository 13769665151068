import type { ComponentSingleStyleConfig } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

import { pickSpecificColor } from "../utils";

export default {
  baseStyle: {
    fontSize: "sm",
    fontWeight: "medium",
    borderRadius: "base",
  },
  variants: {
    link: (props) => {
      const restOfProps = { lineHeight: 5 };

      const { colorScheme } = props;

      if (props.colorMode === "dark") return { ...restOfProps };

      return {
        ...restOfProps,
        color: pickSpecificColor(colorScheme, {
          gray: 700,
        }),
        _hover: {
          color: pickSpecificColor(colorScheme, {
            blue: 600,
            gray: 800,
          }),
        },
        _active: {
          color: pickSpecificColor(colorScheme, {
            gray: 900,
          }),
        },
      };
    },
    outline: (props) => {
      const { colorScheme } = props;
      if (props.colorMode === "dark") {
        return {
          bg: "whiteAlpha.50",
          borderColor: pickSpecificColor(colorScheme, {
            gray: 800,
            blue: 200,
          }),
          _hover: {
            bg: "whiteAlpha.200",
          },
        };
      }

      return {
        bg: "white",
        color: pickSpecificColor(colorScheme, {
          gray: 800,
          blue: 500,
        }),
        borderColor: pickSpecificColor(colorScheme, {
          gray: 300,
          blue: 500,
        }),
        _hover: {
          color: pickSpecificColor(colorScheme, {
            gray: 800,
            blue: 600,
          }),
          borderColor: pickSpecificColor(colorScheme, {
            gray: 300,
            blue: 600,
          }),
          bg: `${colorScheme}.100`,
        },
        _active: {
          color: pickSpecificColor(colorScheme, {
            gray: 800,
            blue: 600,
          }),
          borderColor: pickSpecificColor(colorScheme, {
            gray: 300,
            blue: 600,
          }),
          bg: `${colorScheme}.100`,
        },
      };
    },
    solid: (props) => {
      const { colorScheme } = props;

      return {
        color: mode("white", "gray.900")(props),
        _hover: { bg: mode(`${colorScheme}.600`, `${colorScheme}.100`)(props) },
        _active: {
          bg: `${colorScheme}.300`,
        },
      };
    },
    ghost: (props) => {
      const { colorScheme } = props;

      return {
        color: pickSpecificColor(colorScheme, {
          whiteAlpha: 700,
        }),
        _hover: {
          color: colorScheme === "whiteAlpha" ? "white" : undefined,
          bg: pickSpecificColor(colorScheme, {
            whiteAlpha: 200,
          }),
        },
        _active: {
          color: colorScheme === "whiteAlpha" ? "white" : undefined,
          bg: pickSpecificColor(colorScheme, {
            whiteAlpha: 200,
          }),
        },
      };
    },
  },
  defaultProps: {
    colorScheme: "blue",
    size: "sm",
  },
  sizes: {
    xs: {
      fontSize: "sm",
    },
  },
} as ComponentSingleStyleConfig;
