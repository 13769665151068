import {
  type ClearIndicatorProps,
  components,
  type DropdownIndicatorProps,
  type GroupBase,
  type GroupHeadingProps,
  type GroupProps,
  type MultiValueProps,
  type OptionProps,
  type StylesConfig,
} from "react-select";
import {
  Box,
  chakra,
  Divider,
  Grid,
  GridItem,
  IconButton,
  Tag,
  TagCloseButton,
  TagLabel,
  useColorModeValue,
  useMultiStyleConfig,
  useTheme,
} from "@chakra-ui/react";
import {
  faAngleDown,
  faCheck,
  faXmark,
} from "@fortawesome/pro-regular-svg-icons";

import { FaIcon } from "../FaIcon";

import type { SelectOption } from "./Select";
import { ConditionalWrapper } from "../ConditionalWrapper";

export function NullComponent(): null {
  return null;
}

export const OurSelectOption = <
  OptionType = SelectOption,
  IsMulti extends boolean = false,
  GroupType extends GroupBase<OptionType> = GroupBase<OptionType>,
>(
  props: OptionProps<OptionType, IsMulti, GroupType> & {
    selectProps: { hideCheckIcon?: boolean };
  },
) => {
  return (
    <ConditionalWrapper
      condition={props.isDisabled}
      wrapper={(children) => <Box position="relative">{children}</Box>}
    >
      {props.isDisabled ? (
        <Box
          display="block"
          position="absolute"
          top={0}
          right={0}
          bottom={0}
          left={0}
          opacity={0.5}
          backgroundColor="gray.50"
          cursor={"not-allowed"}
        />
      ) : null}
      <components.Option {...props}>
        <Grid
          templateColumns={
            props.selectProps.hideCheckIcon ? "auto" : "1.75rem auto"
          }
          alignItems="center"
          role="option"
        >
          <GridItem>
            {!props.selectProps.hideCheckIcon && props.isSelected && (
              <FaIcon icon={faCheck} />
            )}
          </GridItem>

          <GridItem overflow={"hidden"}>{props.children}</GridItem>
        </Grid>
      </components.Option>
    </ConditionalWrapper>
  );
};

export const OurGroupHeading = <
  OptionType = SelectOption,
  IsMulti extends boolean = false,
  GroupType extends GroupBase<OptionType> = GroupBase<OptionType>,
>(
  props: GroupHeadingProps<OptionType, IsMulti, GroupType>,
) => {
  if ((props.selectProps as any).useDividers) {
    // react-select specifies the index of the group heading via `id`;
    // do not display a divider if it is the first heading
    if (props.id.includes("group-0-heading")) {
      return null;
    }

    return <Divider h={0} my={2} />;
  }

  return <components.GroupHeading {...props} />;
};

export const OurGroup = <
  OptionType = SelectOption,
  IsMulti extends boolean = false,
  GroupType extends GroupBase<OptionType> = GroupBase<OptionType>,
>(
  props: GroupProps<OptionType, IsMulti, GroupType>,
) => (
  // Remove padding if we're using dividers
  <components.Group
    {...props}
    {...((props.selectProps as any).useDividers
      ? { innerProps: { style: { paddingTop: 0, paddingBottom: 0 } } }
      : {})}
  />
);

export const SelectDropdownIndicator = <
  OptionType = SelectOption,
  IsMulti extends boolean = false,
  GroupType extends GroupBase<OptionType> = GroupBase<OptionType>,
>(
  props: DropdownIndicatorProps<OptionType, IsMulti, GroupType>,
) => (
  <components.DropdownIndicator {...props}>
    <FaIcon icon={faAngleDown} />
  </components.DropdownIndicator>
);

export const OurMultiValue = <
  Option = unknown,
  IsMulti extends boolean = boolean,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  children,
  data,
  removeProps,
  isDisabled,
  selectProps,
}: MultiValueProps<Option, IsMulti, Group>) => {
  const isFixed =
    (data as any).isFixed || (selectProps as any).getValueIsFixed?.(data);

  return (
    <Tag
      colorScheme="gray"
      borderRadius="full"
      size="sm"
      flexShrink={0}
      opacity={isFixed && !isDisabled ? 0.5 : 1}
      fontWeight="semibold"
      border="1px solid"
      borderColor={isDisabled ? "gray.500" : "transparent"}
      {...(selectProps as any).tagProps}
    >
      <TagLabel>{children}</TagLabel>

      {!isFixed ? (
        <chakra.div {...removeProps}>
          <TagCloseButton aria-label="remove">
            <FaIcon icon={faXmark} />
          </TagCloseButton>
        </chakra.div>
      ) : null}
    </Tag>
  );
};

export const OurClearIndicator = <
  OptionType = SelectOption,
  IsMulti extends boolean = false,
  GroupType extends GroupBase<OptionType> = GroupBase<OptionType>,
>(
  props: ClearIndicatorProps<OptionType, IsMulti, GroupType>,
) => {
  const { innerProps } = props;

  return (
    <chakra.div {...innerProps}>
      <IconButton
        variant="ghost"
        colorScheme="gray"
        aria-label="Clear items"
        icon={<FaIcon icon={faXmark} />}
        size="xs"
      />
    </chakra.div>
  );
};

export const useGetStyles = <
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: any,
  isInvalid: boolean | undefined,
): StylesConfig<Option, IsMulti, Group> => {
  const theme = useTheme();
  const { field: selectFieldStyles } = useMultiStyleConfig("Select", props);

  const resolveThemeValue = (path: string, val: any) => {
    // NOTE: Checks that `val` is a cssVar
    const matches = String(val).match(/var\((?<cssvar>--[\w-]+)\)/);

    if (matches?.length && matches.groups?.cssvar) {
      const cssVarValue = (selectFieldStyles as any)[matches.groups.cssvar];
      return theme.__cssMap[cssVarValue]?.value;
    }

    return theme.__cssMap[`${path}.${val}`]?.value;
  };

  const hoverNotFocusedControlBorderColor = useColorModeValue(
    theme.colors.gray[400],
    theme.colors.gray[600],
  );
  const singleValueFontColor = useColorModeValue(
    theme.colors.gray[800],
    theme.colors.gray[200],
  );
  const inputFontColor = useColorModeValue(
    theme.colors.gray[800],
    theme.colors.gray[200],
  );
  const noOptionsMessageColor = useColorModeValue(
    theme.colors.gray[800],
    theme.colors.gray[200],
  );
  const placeholderFontColor = useColorModeValue(null, theme.colors.gray[600]);
  const focusedOptionBackgroundColor = useColorModeValue(
    theme.colors.gray[100],
    theme.colors.gray[700],
  );
  const unfocusedOptionBackgroundColor = useColorModeValue(
    theme.colors.white,
    theme.colors.gray[800],
  );
  const optionFontColor = useColorModeValue(
    theme.colors.gray[900],
    theme.colors.gray[100],
  );
  const invalidBorderColor = useColorModeValue(
    theme.colors.red[500],
    theme.colors.red[300],
  );
  const dropdownIndicatorColor = useColorModeValue(
    theme.colors.gray[800],
    theme.colors.gray[200],
  );
  const controlBackgroundColor = useColorModeValue(
    theme.colors.white,
    "transparent",
  );

  return {
    control: (provided: any, state) => ({
      ...provided,
      fontSize: resolveThemeValue("fontSizes", selectFieldStyles.fontSize),
      borderTopLeftRadius:
        resolveThemeValue("radii", selectFieldStyles.borderTopLeftRadius) ?? 0,
      borderBottomLeftRadius:
        resolveThemeValue("radii", selectFieldStyles.borderBottomLeftRadius) ??
        0,
      borderTopRightRadius:
        resolveThemeValue("radii", selectFieldStyles.borderTopRightRadius) ?? 0,
      borderBottomRightRadius:
        resolveThemeValue("radii", selectFieldStyles.borderBottomRightRadius) ??
        0,
      borderColor: resolveThemeValue("colors", selectFieldStyles.borderColor),
      minHeight: resolveThemeValue("sizes", selectFieldStyles.height),
      backgroundColor: controlBackgroundColor,
      "&:hover": {
        ...provided["&:hover"],
        borderColor: hoverNotFocusedControlBorderColor,
        ...(state.isFocused && {
          borderColor: theme.colors.blue[300],
        }),
        ...(isInvalid && {
          borderColor: invalidBorderColor,
        }),
      },
      ...(state.isFocused && {
        boxShadow: "var(--chakra-shadows-outline)",
        borderColor: theme.colors.blue[300],
      }),
      ...(isInvalid && {
        borderColor: invalidBorderColor,
        ...(state.isFocused && {
          borderColor: invalidBorderColor,
          boxShadow: `0 0 0 1px ${invalidBorderColor}`,
          outline: "none",
        }),
      }),
      ...(state.isDisabled && (selectFieldStyles as any)._disabled),
      transitionProperty: "var(--chakra-transition-property-common)",
      transitionDuration: "var(--chakra-transition-duration-normal)",
    }),
    input: (provided) => ({
      ...provided,
      margin: 0,
      color: inputFontColor,
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: unfocusedOptionBackgroundColor,
      overflow: "hidden",
      fontSize: resolveThemeValue("fontSizes", selectFieldStyles.fontSize),
      ...props.menuStyles,
    }),
    menuPortal: (base) => {
      return {
        ...base,
        zIndex: 9001,
      };
    },
    option: (provided, state) => ({
      ...provided,
      whiteSpace: "nowrap",
      color: optionFontColor,
      fontSize: theme.fontSizes["sm"],
      backgroundColor: state.isFocused
        ? focusedOptionBackgroundColor
        : unfocusedOptionBackgroundColor,
      ":active": {
        ...provided[":active"],
        backgroundColor: "none",
      },
    }),
    noOptionsMessage: (provided) => ({
      ...provided,
      color: noOptionsMessageColor,
    }),
    placeholder: (provided, state) => ({
      ...provided,
      margin: 0,
      color: placeholderFontColor,
      opacity: state.isDisabled ? 0.4 : 1,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    }),
    singleValue: (provided) => ({
      ...provided,
      margin: 0,
      color: singleValueFontColor,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      paddingLeft: resolveThemeValue("space", selectFieldStyles.px),
      paddingRight: resolveThemeValue("space", selectFieldStyles.px),
      gap: state.isMulti ? 2 : 0,
      input: {
        height: "initial",
        boxShadow: "none",
      },
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      gap: 4,
      padding: 0,
      paddingRight: theme.space[2],
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: 0,
      color: dropdownIndicatorColor,
      ":hover": { color: dropdownIndicatorColor },
      ":active": { color: dropdownIndicatorColor },
    }),
    clearIndicator: (provided) => ({
      ...provided,
      cursor: "pointer",
      padding: 0,
    }),
  };
};
