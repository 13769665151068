import {
  useEffect,
  useState,
  createContext,
  useContext,
  type ReactNode,
  type ComponentType,
} from "react";
import {
  asyncWithLDProvider,
  useLDClient,
} from "launchdarkly-react-client-sdk";

import { PageLoader } from "../PageLoader";

type Overrides = Record<string, any>;

const FeatureFlagContext = createContext<{ overrides: Overrides } | null>(null);

export const FeatureFlagProvider = ({
  children,
  clientId,
  overrides = {},
}: {
  children: ReactNode;
  clientId: string;
  overrides?: Record<string, any>;
}) => {
  const [LDProvider, setLDProvider] = useState<ComponentType<{
    children: ReactNode;
  }> | null>(null);

  useEffect(() => {
    if (LDProvider) {
      return;
    }

    asyncWithLDProvider({
      clientSideID: clientId,
    })
      .then((provider) => {
        setLDProvider(() => provider);
      })
      .catch(console.log);
  }, [clientId, LDProvider]);

  if (!LDProvider) {
    // TODO: suspend
    return <PageLoader />;
  }

  return (
    <FeatureFlagContext.Provider value={{ overrides }}>
      <LDProvider>
        <LDInitializer>{children}</LDInitializer>
      </LDProvider>
    </FeatureFlagContext.Provider>
  );
};

function LDInitializer({ children }: { children: ReactNode }) {
  const [initialized, setInitialized] = useState(false);
  const ldClient = useLDClient();

  useEffect(() => {
    ldClient!
      .waitForInitialization()
      .catch(console.log)
      .finally(() => setInitialized(true));
  }, [ldClient]);

  if (!initialized) {
    // TODO: suspend
    return <PageLoader />;
  }

  return children;
}

export function useFeatureFlagContext() {
  return useContext(FeatureFlagContext);
}
