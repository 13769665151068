import type { ComponentMultiStyleConfig } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

export default {
  parts: ["tabpanel", "tab", "indicator", "tablist"],
  baseStyle: (props) => ({
    tab: {
      color: mode("gray.700", "gray.300")(props),
      fontWeight: 600,
      whiteSpace: "nowrap",
    },
    tabpanel: {
      px: 0,
    },
  }),
  variants: {
    line: (props) => {
      const textColor = mode("gray.700", "gray.300")(props);

      return {
        tablist: {
          width: "full",
          borderBottomWidth: "1px",
          borderBottomColor: mode("gray.200", "gray.800")(props),
          lineHeight: 1.5,
        },
        tab: {
          color: textColor,
          fontSize: "1rem",
          lineHeight: "1.5rem",
          _hover: {
            color: mode("gray.900", null)(props),
            _disabled: { color: textColor },
          },
          _active: { bg: mode("gray.100", null)(props) },
          _selected: {
            marginBottom: "-2px",
            borderBottomWidth: "2px",
            borderColor: mode("blue.500", "volt")(props),
            color: mode("gray.900", "gray.100")(props),
          },
        },
      };
    },
  },
} as ComponentMultiStyleConfig;
