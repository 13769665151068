import type { ReactNode } from "react";

export const formatNumber = (value: any, fallback: ReactNode = "-") => {
  if (!Number.isFinite(value)) {
    return fallback;
  }

  const formatter = new Intl.NumberFormat("en-US");
  return formatter.format(value);
};
