import type { ReactNode } from "react";
import { isValidElement } from "react";
import { Link as RouterLink, type To } from "react-router-dom";
import type { ButtonProps, StatProps } from "@chakra-ui/react";
import { Button, Stat, StatHelpText, StatNumber } from "@chakra-ui/react";

import { ConditionalWrapper } from "@stordco/fe-components";

export function StatButtonLink({
  number,
  helpText,
  to,
  statProps,
  ...props
}: {
  number: ReactNode;
  helpText: ReactNode;
  to: To;
  statProps?: Omit<StatProps, "children">;
} & ButtonProps) {
  return (
    <Button
      as={RouterLink}
      to={to}
      variant="ghost"
      colorScheme="gray"
      textAlign="left"
      height="auto"
      px={2}
      mx={-2}
      py={1}
      my={-1}
      color="gray.900"
      _dark={{ color: "gray.100" }}
      {...props}
    >
      <Stat {...statProps}>
        <StatNumber>{number}</StatNumber>

        <ConditionalWrapper
          condition={!isValidElement(helpText)}
          wrapper={(children) => (
            <StatHelpText overflow="hidden" textOverflow="ellipsis">
              {children}
            </StatHelpText>
          )}
        >
          {helpText}
        </ConditionalWrapper>
      </Stat>
    </Button>
  );
}
