import { useHotkeys } from "react-hotkeys-hook";

import { useDatadogRUM, useIsAdmin } from "@stordco/fe-components";
import { useNavigate } from "react-router-dom";

export const AdminKeyboardShortcutController = () => {
  const isAdmin = useIsAdmin();
  const datadogRum = useDatadogRUM();
  const navigate = useNavigate();

  useHotkeys("mod+shift+o", () => {
    if (isAdmin) {
      datadogRum?.addAction("hotkey:open_admin");
      navigate("/admin");
    }
  });

  return null;
};
