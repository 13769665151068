import * as React from "react";
import { datadogRum } from "@datadog/browser-rum";
import type { RumGlobal } from "@datadog/browser-rum";

export type DatadogProviderContextValue = {
  instance: null | RumGlobal;
};
export const DatadogProviderContext =
  React.createContext<DatadogProviderContextValue>({
    instance: null,
  });

export interface DataDogProviderProps {
  children: React.ReactNode;
  version?: string;
  applicationId: string;
  clientToken: string;
  service: string;
  env: string;
}

// Note: it's not really necessary to throw `datadogRum` into state in the provider, but we're doing this for ease of use if the underlying implementation changes.
export const DatadogProvider = ({
  children,
  version,
  applicationId,
  clientToken,
  service = "unlabeled-service",
  env = "nonprod",
}: DataDogProviderProps) => {
  const [instance, setInstance] = React.useState<RumGlobal | null>(null);

  React.useEffect(() => {
    if (!applicationId || !clientToken) return;

    if (!instance) {
      const newInstance = datadogRum;
      newInstance.init({
        allowedTracingUrls: [
          {
            match: /stord\.com/i,
            propagatorTypes: ["tracecontext"],
          },
        ],
        applicationId,
        clientToken,
        site: "datadoghq.com",
        service,
        env,
        sessionReplaySampleRate: 100,
        sessionSampleRate: 100,
        trackFrustrations: true,
        trackSessionAcrossSubdomains: true,
        trackUserInteractions: true,
        trackResources: true,
        version,
      });
      newInstance?.startSessionReplayRecording();
      setInstance(newInstance);
    }
  }, [instance, version, applicationId, clientToken, service, env]);

  return (
    <DatadogProviderContext.Provider value={{ instance }}>
      {children}
    </DatadogProviderContext.Provider>
  );
};

export function useDatadogRUM(): RumGlobal | null {
  const context = React.useContext(DatadogProviderContext);
  if (context === undefined) {
    throw new Error("useDatadogRUM must be used within a DatadogProvider");
  }
  return context.instance;
}
