import {
  Flex,
  SimpleGrid,
  Skeleton,
  Stat,
  StatHelpText,
  StatNumber,
  Tooltip,
} from "@chakra-ui/react";
import { faCircleInfo } from "@fortawesome/pro-regular-svg-icons";

import { Card, FaIcon } from "@stordco/fe-components";

import { formatNumber } from "../../../../lib/numbers";

import { useWidgetData } from "./use-widget-data";
import { WidgetErrorFetchingData } from "../../WidgetErrorFetchingData";
import { LoadingOverlay } from "../../LoadingOverlay";

export function DeliveryTracking() {
  const { data, isPending, isError, refetch } =
    useWidgetData("delivery-tracking");

  return (
    <Card minHeight={200}>
      <Card.PaddedContent display="flex" flexDirection="column" gap={4}>
        <Flex alignItems="center" gap={2}>
          <Card.Title>Delivery tracking</Card.Title>

          <Tooltip
            label="Shipped sales orders on the way to their final destinations. Delivery tracking is not affected by page-level filtering."
            placement="top"
          >
            <FaIcon icon={faCircleInfo} boxSize={3} p={1} m={-1} />
          </Tooltip>
        </Flex>

        {isError ? (
          <WidgetErrorFetchingData onRetryClick={refetch} />
        ) : (
          <>
            <Stat color="gray.900" _dark={{ color: "gray.100" }}>
              <StatNumberWithLoading
                isLoading={isPending}
                count={data?.in_transit_count}
              />

              <StatHelpText>In transit</StatHelpText>
            </Stat>

            <SimpleGrid columns={2}>
              <Stat color="gray.900" _dark={{ color: "gray.100" }} size="sm">
                <StatNumberWithLoading
                  isLoading={isPending}
                  count={data?.delivered_today_count}
                />

                <StatHelpText>Delivered today</StatHelpText>
              </Stat>

              <Stat color="gray.900" _dark={{ color: "gray.100" }} size="sm">
                <StatNumberWithLoading
                  isLoading={isPending}
                  count={data?.out_for_delivery_count}
                />

                <StatHelpText>Out for delivery</StatHelpText>
              </Stat>
            </SimpleGrid>
          </>
        )}

        {isPending ? <LoadingOverlay /> : null}
      </Card.PaddedContent>
    </Card>
  );
}

function StatNumberWithLoading({
  isLoading,
  count,
}: {
  isLoading: boolean;
  count: number | undefined;
}) {
  return (
    <Skeleton isLoaded={!isLoading} fitContent>
      <StatNumber>{isLoading ? "123" : formatNumber(count)}</StatNumber>
    </Skeleton>
  );
}
