import * as React from "react";
import { Icon, type ThemingProps, useStyleConfig } from "@chakra-ui/react";
import type { IconProps } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import type { FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import type { IconDefinition } from "@fortawesome/fontawesome-svg-core";

export type FaIconSize = "xs" | "sm" | "md" | "lg" | "xl";
export type FaIconProps = Omit<
  FontAwesomeIconProps & IconProps,
  "icon" | "size"
> & {
  icon: IconDefinition;
  colorScheme?: ThemingProps["colorScheme"];
  size?: FaIconSize;
};

/**
 * A wrapper around `FontAwesomeIcon` and chakra-ui's `Icon` component. This component allows you to pass Chakra's
 * styling props to the icon. You must first import the icon and then pass that into the `FaIcon` component.
 *
 * @example
 * ```ts
 * import { faAngleDown } from "@fortawesome/pro-regular-svg-icons";
 *
 * const SomeIcon = () => <FaIcon icon={faAngleDown} size="md" />
 * ```
 *
 * @example
 * ```ts
 * import { faAngleDown } from "@fortawesome/pro-light-svg-icons";
 *
 * const SomeLargerIcon = () => <FaIcon icon={faAngleDown} size="md" />
 * ```
 */
export const FaIcon = React.forwardRef<SVGSVGElement, FaIconProps>(
  function FaIcon(
    { colorScheme, color, icon, fontSize, size = "sm", ...props },
    ref,
  ) {
    const styles = useStyleConfig("Icon", {
      color,
      colorScheme,
      size,
    });
    if (fontSize) {
      styles.fontSize = fontSize;
    }

    return (
      <Icon ref={ref} as={FontAwesomeIcon} icon={icon} sx={styles} {...props} />
    );
  },
);
