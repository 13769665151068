import { Box, Button, chakra, Flex, Heading, Table } from "@chakra-ui/react";
import type { DateDuration, DateValue } from "@internationalized/date";
import {
  endOfMonth,
  getLocalTimeZone,
  isSameDay,
  today,
} from "@internationalized/date";
import {
  createCalendar,
  getWeeksInMonth,
  isSameMonth,
} from "@internationalized/date";
import type { AriaButtonProps } from "@react-aria/button";
import { useButton } from "@react-aria/button";
import type { AriaCalendarCellProps } from "@react-aria/calendar";
import {
  useCalendar,
  useCalendarCell,
  useCalendarGrid,
} from "@react-aria/calendar";
import { useLocale } from "@react-aria/i18n";
import type {
  CalendarState,
  RangeCalendarState,
} from "@react-stately/calendar";
import type { DatePickerAria } from "@react-aria/datepicker";
import { useCalendarState } from "@react-stately/calendar";
import type {
  DatePickerState,
  DateRangePickerState,
} from "@react-stately/datepicker";
import * as React from "react";
import type { ComponentProps } from "react";
import {
  faAnglesLeft,
  faAnglesRight,
  faAngleLeft,
  faAngleRight,
} from "@fortawesome/pro-regular-svg-icons";

import { FaIcon } from "../FaIcon";

export function Calendar(props: DatePickerAria["calendarProps"]) {
  const { locale } = useLocale();
  const state = useCalendarState({
    ...props,
    locale,
    createCalendar,
  });

  const ref = React.useRef(null);
  const { calendarProps, prevButtonProps, nextButtonProps, title } =
    useCalendar(props, state);

  return (
    <Box {...calendarProps} ref={ref} px={1}>
      <CalendarHeader>
        <CalendarHeader.PrevYear state={state} />
        <CalendarHeader.PrevMonth {...prevButtonProps} />
        <CalendarHeader.Title title={title} size="sm" />
        <CalendarHeader.NextMonth {...nextButtonProps} />
        <CalendarHeader.NextYear state={state} />
      </CalendarHeader>
      <CalendarGrid state={state} />
    </Box>
  );
}

export function CalendarGrid({
  state,
  offset = {},
}: {
  state: CalendarState | RangeCalendarState;
  offset?: DateDuration;
}) {
  const { locale } = useLocale();
  const startDate = state.visibleRange.start.add(offset);
  const endDate = endOfMonth(startDate);
  const { gridProps, headerProps, weekDays } = useCalendarGrid(
    {
      startDate,
      endDate,
    },
    state,
  );

  // Get the number of weeks in the month so we can render the proper number of rows.
  const weeksInMonth = getWeeksInMonth(state.visibleRange.start, locale);

  return (
    <Table {...gridProps} width="100%">
      <thead {...headerProps}>
        <tr>
          {weekDays.map((day, index) => (
            <chakra.th
              key={index}
              textStyle="tooltip"
              color="gray.700"
              p={0}
              _dark={{ color: "gray.300" }}
            >
              {day}
            </chakra.th>
          ))}
        </tr>
      </thead>
      <tbody>
        {[...new Array(weeksInMonth).keys()].map((weekIndex) => (
          <tr key={weekIndex}>
            {state
              .getDatesInWeek(weekIndex, startDate)
              .map((date: any, i: number) =>
                date ? (
                  <CalendarCell
                    key={i}
                    state={state}
                    date={date}
                    currentMonth={startDate}
                  />
                ) : (
                  <td key={i} />
                ),
              )}
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

function stateIsARange(
  state: CalendarState | RangeCalendarState,
): state is RangeCalendarState {
  return "highlightedRange" in state;
}

export function CalendarCell({
  state,
  date,
  currentMonth,
}: {
  state: CalendarState | RangeCalendarState;
  date: AriaCalendarCellProps["date"];
  currentMonth: DateValue;
}) {
  const ref = React.useRef(null);
  const { cellProps, buttonProps, isSelected, isInvalid, formattedDate } =
    useCalendarCell({ date }, state, ref);

  const isSelectionStart =
    stateIsARange(state) && state.highlightedRange?.start
      ? isSameDay(date, state.highlightedRange.start)
      : isSelected;

  const isSelectionEnd =
    stateIsARange(state) && state.highlightedRange?.end
      ? isSameDay(date, state.highlightedRange.end)
      : isSelected;

  const isOutsideMonth = !isSameMonth(currentMonth, date);

  return (
    <chakra.td {...cellProps} textAlign="center" boxSize={8}>
      <Button
        {...buttonProps}
        ref={ref}
        hidden={isOutsideMonth}
        colorScheme={isInvalid ? "red" : isSelected ? "blue" : "gray"}
        backgroundColor={
          isSelected && !isSelectionStart && !isSelectionEnd
            ? "blue.50"
            : undefined
        }
        color={
          isSelected && !isSelectionStart && !isSelectionEnd
            ? "gray.800"
            : undefined
        }
        _dark={{
          backgroundColor: undefined,
          color: undefined,
        }}
        variant={isSelected ? "solid" : "ghost"}
        textStyle="paragraph"
        boxSize={8}
        padding={0}
      >
        {formattedDate}
      </Button>
    </chakra.td>
  );
}

export function CalendarButton(props: AriaButtonProps<"button">) {
  const ref = React.useRef(null);
  const { buttonProps } = useButton(props, ref);
  return (
    <Button
      {...buttonProps}
      ref={ref}
      size="xs"
      boxSize={8}
      variant="ghost"
      colorScheme="gray"
    >
      {props.children}
    </Button>
  );
}

export function CalendarHeader(props: ComponentProps<typeof Flex>) {
  return <Flex {...props} alignItems="center" paddingBottom="4" width="full" />;
}

CalendarHeader.Title = function Title({
  size = "sm",
  title,
}: {
  size: "sm" | "lg";
  title: string;
}) {
  return size === "sm" ? (
    <chakra.h2
      textStyle="sectionTitle"
      flex="1"
      textAlign="center"
      minWidth={32}
      color="gray.900"
      fontSize="sm"
      _dark={{
        color: "gray.100",
      }}
    >
      {title}
    </chakra.h2>
  ) : (
    <Heading as="h2" size="md" flex="1" textAlign="center">
      {title}
    </Heading>
  );
};

CalendarHeader.PrevYear = function PrevYear({
  state,
}: {
  state: CalendarState | RangeCalendarState;
}) {
  return (
    <CalendarButton
      aria-label="Previous year"
      onPress={() =>
        state.setFocusedDate(state.focusedDate.subtract({ years: 1 }))
      }
    >
      <FaIcon icon={faAnglesLeft} />
    </CalendarButton>
  );
};

CalendarHeader.NextYear = function NextYear({
  state,
}: {
  state: CalendarState | RangeCalendarState;
}) {
  return (
    <CalendarButton
      aria-label="Next year"
      onPress={() => state.setFocusedDate(state.focusedDate.add({ years: 1 }))}
    >
      <FaIcon icon={faAnglesRight} />
    </CalendarButton>
  );
};

CalendarHeader.PrevMonth = function PrevMonth(
  prevButtonProps: AriaButtonProps<"button">,
) {
  return (
    <CalendarButton aria-label="Previous Month" {...prevButtonProps}>
      <FaIcon icon={faAngleLeft} />
    </CalendarButton>
  );
};

CalendarHeader.NextMonth = function NextMonth(
  nextButtonProps: AriaButtonProps<"button">,
) {
  return (
    <CalendarButton aria-label="Next Month" {...nextButtonProps}>
      <FaIcon icon={faAngleRight} />
    </CalendarButton>
  );
};

export function CalendarFooter(props: ComponentProps<typeof Flex>) {
  return (
    <Flex
      {...props}
      width="full"
      justifyContent="space-between"
      gap={2}
      mt={2}
    />
  );
}

CalendarFooter.ClearButton = function ClearButton({
  isClearable,
  pickerState,
  calendarState,
}: {
  isClearable: boolean;
  pickerState: DatePickerState | DateRangePickerState;
  calendarState?: RangeCalendarState;
}) {
  return (
    <Button
      aria-label="clear date"
      variant="outline"
      colorScheme="gray"
      flex="1"
      onClick={() => {
        pickerState.setValue(null);
        calendarState?.setFocusedDate(today(getLocalTimeZone()));
      }}
      isDisabled={!isClearable}
    >
      Clear
    </Button>
  );
};
