export default {
  parts: ["container", "link"],
  baseStyle: {
    container: {
      display: "inline-flex",
      alignItems: "center",
      // minHeight: 5,
    },
    link: {
      textStyle: "label",
      color: "gray.700",
      _dark: { color: "gray.300" },
    },
  },
};
