import { checkboxAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system";
import { typography } from "../foundations";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const sizes = {
  md: definePartsStyle({
    label: {
      ...typography.textStyles.paragraph,
    },
  }),
};

export default defineMultiStyleConfig({ sizes });
