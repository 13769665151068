import {
  Box,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  useDisclosure,
} from "@chakra-ui/react";

import { StordEmblemAndLogo } from "../StordLogo";

import { Sidebar } from "./Sidebar";
import { ToggleButton } from "./ToggleButton";

export const Navbar = () => {
  const { isOpen, onToggle, onClose } = useDisclosure();

  return (
    <Box
      width="full"
      py={3}
      px={6}
      bg="#111111"
      boxShadow="base"
      _dark={{
        bg: "#242424",
        boxShadow: "base",
      }}
    >
      <Flex justify="space-between" alignItems="center">
        <StordEmblemAndLogo fill="white" heightInPx={20} />

        <ToggleButton
          isOpen={isOpen}
          aria-label="Open Menu"
          onClick={onToggle}
        />
        <Drawer
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          isFullHeight
          preserveScrollBarGap
        >
          <DrawerOverlay />
          <DrawerContent>
            <Sidebar closeNavbarDrawerAction={onClose} />
          </DrawerContent>
        </Drawer>
      </Flex>
    </Box>
  );
};
