import type { ElementRef, ComponentProps, ReactNode } from "react";
import {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import debounce from "lodash/debounce";
import type { LinkProps } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import { useCombobox } from "downshift";
import type { ButtonProps } from "@chakra-ui/react";
import {
  Box,
  Button,
  CloseButton,
  Flex,
  Highlight,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightElement,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tag,
  Text,
} from "@chakra-ui/react";
import {
  faAngleDown,
  faCheck,
  faClock,
  faReceipt,
  faMagnifyingGlass,
  faXmark,
} from "@fortawesome/pro-regular-svg-icons";
import { faMagnifyingGlass as faMagnifyingGlassLight } from "@fortawesome/pro-light-svg-icons";

import {
  ConditionalWrapper,
  EmptyData,
  EmptyDataDescription,
  FaIcon,
  IconBadge,
  useFlag,
  useLocalStorage,
} from "@stordco/fe-components";

import { useOrders } from "../../hooks/useOrders";
import { useNetworkId } from "../Network";
import type { Order } from "../../features/Orders/types";
import { StatusBadge } from "../StatusBadge";

type RecentSearch = { attribute: string; term: string };

type RecentSearchItem = { type: "recent-search"; search: RecentSearch };

type SearchResultItem = { type: "order"; order: Order; url: string };

type Item =
  | RecentSearchItem
  | SearchResultItem
  | { type: "view-results"; totalCount: number; url: string };

type AttributeOption = {
  label: string;
  subLabel?: string;
  selectedLabel?: string;
  value: string;
};

export function GlobalSearchModal({ onClose }: { onClose: () => void }) {
  const [attribute, setAttribute] = useState("order_number");
  const [searchValue, setSearchValue] = useState("");
  const networkId = useNetworkId();

  const customerPhoneNumberSearchFlagIsEnabled = useFlag(
    "enable-search-by-phone-number",
  );

  const attributeOptions = useMemo(
    (): AttributeOption[] => [
      {
        label: "Order number",
        subLabel: "Number, custom reference number",
        value: "order_number",
        selectedLabel: "Order no.",
      },
      { label: "SKU", value: "sku" },
      { label: "Brand", value: "brand" },
      { label: "Customer name", value: "name" },
      { label: "Customer email", value: "email" },
      ...(customerPhoneNumberSearchFlagIsEnabled
        ? [{ label: "Customer phone", value: "contact_phone" }]
        : []),
      {
        label: "Tracking number",
        value: "tracking_number",
        selectedLabel: "Tracking No.",
      },
    ],
    [customerPhoneNumberSearchFlagIsEnabled],
  );

  const [recentSearches, setRecentSearches] = useLocalStorage(
    "global-search-recent-searches",
    [] as RecentSearch[],
  );

  const { data } = useOrders({
    params: {
      search_field: attribute,
      search_term: searchValue,
      limit: 10,
      sort: "-inserted_at",
    },
    queryOptions: {
      enabled: Boolean(searchValue),
    },
  });

  useEffect(() => {
    if (!searchValue) {
      return;
    }

    setRecentSearches((recentSearches) => {
      const existingIndex = recentSearches.findIndex(
        (search) =>
          search.attribute === attribute && search.term === searchValue,
      );

      const newRecentSearches = recentSearches.slice();

      if (existingIndex !== -1) {
        newRecentSearches.splice(existingIndex, 1);
      }

      return [{ attribute, term: searchValue }, ...newRecentSearches].slice(
        0,
        5,
      );
    });
  }, [attribute, searchValue, setRecentSearches]);

  const debouncedSetSearch = useMemo(() => debounce(setSearchValue, 250), []);

  // Assume we always find an attributeOption for `attribute`
  const selectedAttributeOption = attributeOptions.find(
    ({ value }) => attribute === value,
  )!;

  const items = useMemo((): Item[] => {
    if (!searchValue) {
      return recentSearches.map((search) => ({
        type: "recent-search",
        search,
      }));
    }

    if (!data) {
      return [];
    }

    return [
      ...data.data.map(
        (order) =>
          ({
            type: "order",
            order,
            url: `/distribution_networks/${networkId}/orders/${order.type}/${order.order_id}`,
          }) as const,
      ),
      ...(data.metadata.total_count > 0
        ? [
            {
              type: "view-results",
              totalCount: data.metadata.total_count,
              url: `/distribution_networks/${networkId}/orders?${new URLSearchParams(
                { search_term: searchValue, search_field: attribute },
              )}`,
            } as const,
          ]
        : []),
    ];
  }, [data, searchValue, networkId, attribute, recentSearches]);

  const navigate = useNavigate();

  const { getMenuProps, getInputProps, getItemProps, reset } = useCombobox({
    items,
    onInputValueChange({ inputValue }) {
      if (!inputValue) {
        setSearchValue("");
        debouncedSetSearch.cancel();
      } else {
        debouncedSetSearch(inputValue);
      }
    },
    defaultIsOpen: true,
    defaultHighlightedIndex: 0,
    onSelectedItemChange({ selectedItem }) {
      if (!selectedItem) {
        return;
      }

      switch (selectedItem.type) {
        case "recent-search":
          setAttribute(selectedItem.search.attribute);
          setSearchValue(selectedItem.search.term);

          inputRef.current?.focus();

          break;
        case "order":
        case "view-results":
          navigate(selectedItem.url);
          onClose();

          break;
      }
    },
    onStateChange(changes) {
      if (changes.type === useCombobox.stateChangeTypes.InputKeyDownEscape) {
        onClose();
      }
    },
    stateReducer(state, action) {
      // If the action caused item selection
      if (
        action.changes.selectedItem &&
        action.changes.selectedItem !== state.selectedItem
      ) {
        // If the selected item was a recent search
        if (action.changes.selectedItem.type === "recent-search") {
          return {
            ...action.changes,
            inputValue: action.changes.selectedItem.search.term,
          };
        }

        // Don't change the input value
        return {
          ...action.changes,
          inputValue: state.inputValue,
        };
      }

      return action.changes;
    },
  });

  const inputRef = useRef<ElementRef<"input">>(null);

  const inputProps = getInputProps(
    {
      ref: inputRef,
      placeholder: (() => {
        switch (selectedAttributeOption.value) {
          case "order_number":
            return "Search order number or custom reference";
          default:
            return `Search included ${selectedAttributeOption.label}`;
        }
      })(),
      autoFocus: true,
    },
    {
      // Chakra Portal wackiness requires `suppressRefError` ¯\_(ツ)_/¯
      suppressRefError: true,
    },
  );

  const onRemoveRecentSearchClick = useCallback(
    (index: number) => {
      setRecentSearches((recentSearches) => [
        ...recentSearches.slice(0, index),
        ...recentSearches.slice(index + 1),
      ]);
    },
    [setRecentSearches],
  );

  return (
    <Modal isOpen onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Find any order</ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          <Flex direction="column" gap={6} mb={2}>
            <InputGroup
              border="1px solid"
              borderRadius="base"
              borderColor="gray.300"
              size="sm"
              _hover={{
                borderColor: "gray.400",
              }}
              _focusWithin={{
                borderColor: "blue.300",
                boxShadow: "outline",
                _hover: {
                  borderColor: "blue.300",
                },
              }}
              _dark={{
                borderColor: "gray.700",
                _hover: {
                  borderColor: "gray.600",
                },
              }}
              transitionProperty="common"
              transitionDuration="normal"
              isolation="auto"
            >
              <InputLeftAddon
                p={0}
                border="none"
                borderRight="1px solid"
                borderRightColor="gray.300"
                // remove 2px to account for additional border
                h="calc(var(--chakra-sizes-8) - 2px)"
                bg="none"
                m={0}
                width="min-content"
                overflow="hidden"
                _dark={{
                  borderRightColor: "gray.700",
                }}
              >
                <Menu>
                  <MenuButton
                    as={Button}
                    textAlign="left"
                    variant="outline"
                    colorScheme="gray"
                    border="none"
                    borderRadius="none"
                    rightIcon={<FaIcon icon={faAngleDown} />}
                    leftIcon={<FaIcon icon={faMagnifyingGlass} />}
                  >
                    {selectedAttributeOption?.selectedLabel ??
                      selectedAttributeOption?.label ??
                      ""}
                  </MenuButton>

                  <MenuList>
                    <MenuOptionGroup
                      type="radio"
                      onChange={(value) => {
                        // Since `type` is "radio", `value` will definitely be a `string` and not a `string[]`
                        setAttribute(value as string);

                        // Focus the input
                        inputRef.current?.focus();
                      }}
                      value={attribute}
                    >
                      {attributeOptions.map(
                        ({ label, subLabel, value }, index) => (
                          <MenuItemOption
                            icon={<FaIcon icon={faCheck} />}
                            key={index}
                            value={value}
                          >
                            <Text>{label}</Text>

                            {subLabel ? (
                              <Text mt={0.5} textStyle="caption">
                                {subLabel}
                              </Text>
                            ) : null}
                          </MenuItemOption>
                        ),
                      )}
                    </MenuOptionGroup>
                  </MenuList>
                </Menu>
              </InputLeftAddon>

              <Input
                {...inputProps}
                size="sm"
                p={1.5}
                pr={7}
                // remove 2px to account for additional border
                h="calc(var(--chakra-sizes-8) - 2px)"
                pl={3}
                border="none"
                _focus={{
                  border: "none",
                }}
                _focusVisible={{
                  border: "none",
                }}
              />

              {inputProps.value ? (
                <InputRightElement zIndex={0}>
                  <IconButton
                    aria-label="clear"
                    variant="unstyled"
                    size="xs"
                    p={0}
                    m={0}
                    icon={<FaIcon icon={faXmark} />}
                    onClick={() => {
                      reset();
                    }}
                  />
                </InputRightElement>
              ) : null}
            </InputGroup>

            {(() => {
              if (data) {
                return (
                  <ResultsHeading>{`Results (${data?.metadata.total_count})`}</ResultsHeading>
                );
              }

              if (searchValue) {
                return <ResultsHeading>Loading...</ResultsHeading>;
              }

              if (recentSearches.length) {
                return <ResultsHeading>Recent searches</ResultsHeading>;
              }

              return null;
            })()}
          </Flex>

          {data?.data.length === 0 ? (
            <EmptyData
              icon={faMagnifyingGlassLight}
              title="No results found"
              secondaryAction={{
                label: "Clear search",
                onClick: reset,
              }}
            >
              <EmptyDataDescription>
                Try updating your query.
              </EmptyDataDescription>
            </EmptyData>
          ) : null}

          <Flex
            direction="column"
            gap={1}
            // Chakra Portal wackiness requires `suppressRefError` ¯\_(ツ)_/¯
            {...getMenuProps({}, { suppressRefError: true })}
          >
            {items.map((item, index) => {
              switch (item.type) {
                case "recent-search":
                  return (
                    <RecentSearchItem
                      key={index}
                      item={item}
                      onRemoveClick={() => {
                        onRemoveRecentSearchClick(index);
                      }}
                      attributeOptions={attributeOptions}
                      {...getItemProps({ item, index })}
                    />
                  );
                case "order":
                  return (
                    <SearchItemLink
                      key={index}
                      to={item.url}
                      {...getItemProps({ item, index })}
                    >
                      <IconBadge
                        icon={faReceipt}
                        variant="square"
                        colorScheme="gray"
                        mr={3}
                      />

                      <Box flex={1} my={-2}>
                        <Flex alignItems="center" gap={2} mb={0.5}>
                          <Text textStyle="label">
                            <ConditionalWrapper
                              condition={attribute === "order_number"}
                              wrapper={(children) => (
                                <Highlight
                                  query={searchValue}
                                  styles={{
                                    bg: "rgba(107, 255, 41, 0.24)",
                                    color: "inherit",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {children}
                                </Highlight>
                              )}
                            >
                              {item.order.order_number}
                            </ConditionalWrapper>
                          </Text>

                          <StatusBadge status={item.order.status} />
                        </Flex>

                        <SearchResultSubtext
                          searchValue={searchValue}
                          item={item}
                          attribute={attribute}
                        />
                      </Box>
                    </SearchItemLink>
                  );
                case "view-results":
                  return (
                    <SearchItemLink
                      key={index}
                      to={item.url}
                      {...getItemProps({ item, index })}
                    >
                      <IconBadge
                        icon={faMagnifyingGlass}
                        variant="square"
                        colorScheme="gray"
                        mr={3}
                      />

                      <Box flex={1}>
                        <Text>
                          View <b>{item.totalCount}</b> results in Orders table
                        </Text>
                      </Box>
                    </SearchItemLink>
                  );
              }
            })}
          </Flex>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" colorScheme="gray" onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

const ItemButton = forwardRef<
  ElementRef<typeof Button>,
  ComponentProps<typeof Button>
>(function ItemButton({ children, ...props }, ref) {
  return (
    <Button
      ref={ref}
      variant="ghost"
      colorScheme="gray"
      height="auto"
      textAlign="left"
      py={1}
      px={2}
      _hover={{
        bg: "gray.100",
      }}
      _selected={{
        bg: "gray.100",
      }}
      _dark={{
        _hover: { bg: "whiteAlpha.200" },
        _selected: { bg: "whiteAlpha.200" },
      }}
      {...props}
    >
      {children}
    </Button>
  );
});

const SearchItemLink = forwardRef<
  ElementRef<typeof Button>,
  ButtonProps & LinkProps
>(function SearchItemLink({ children, ...props }, ref) {
  return (
    <ItemButton ref={ref} as={Link} p={2} {...props}>
      {children}
    </ItemButton>
  );
});

function ResultsHeading({ children }: { children: ReactNode }) {
  return (
    <Text textStyle="caption" fontWeight="medium">
      {children}
    </Text>
  );
}

const RecentSearchItem = forwardRef<
  ElementRef<typeof ItemButton>,
  {
    item: RecentSearchItem;
    onRemoveClick: () => void;
    attributeOptions: AttributeOption[];
  } & ComponentProps<typeof ItemButton>
>(function RecentSearchItem(
  { item, onRemoveClick, attributeOptions, ...props },
  ref,
) {
  return (
    <ItemButton ref={ref} as={Box} cursor="pointer" data-group {...props}>
      <FaIcon icon={faClock} color="gray.500" mr={3} />

      <Tag
        colorScheme="gray"
        _light={{
          bg: "blackAlpha.100",
        }}
        rounded="full"
        size="sm"
        mr={2}
      >
        {
          attributeOptions.find(
            (attributeOption) =>
              attributeOption.value === item.search.attribute,
          )!.label
        }
      </Tag>

      <Text flex={1}>{item.search.term}</Text>

      <CloseButton
        size="sm"
        onClick={(event) => {
          event.stopPropagation();

          onRemoveClick();
        }}
        opacity={0}
        _groupHover={{
          opacity: 1,
        }}
      />
    </ItemButton>
  );
});

function SearchResultSubtext({
  attribute,
  item,
  searchValue,
}: {
  attribute: string;
  item: SearchResultItem;
  searchValue: string;
}) {
  const subtext = {
    order_number: {
      label: "Custom reference",
      value: item.order.custom_reference,
    },
    name: {
      label: "Customer",
      value: item.order.customer,
    },
  }[attribute];

  if (!subtext?.value) {
    return null;
  }

  return (
    <Text textStyle="caption">
      {subtext.label}:{" "}
      <Highlight
        query={searchValue}
        styles={{
          bg: "rgba(107, 255, 41, 0.24)",
          color: "inherit",
          fontWeight: "bold",
        }}
      >
        {subtext.value}
      </Highlight>
    </Text>
  );
}
