import { createContext } from "react";

import type { Network } from "./queries";

export type NetworkContextValue = Omit<Network, "timezone"> & {
  timezone: NonNullable<Network["timezone"]>;
};

const NetworkContext = createContext<NetworkContextValue | null>(null);

export default NetworkContext;
