import {
  extendTheme,
  withDefaultVariant,
  withDefaultProps,
} from "@chakra-ui/react";

import { colors, typography, semanticTokens, shadows } from "./foundations";
import {
  Avatar,
  Input,
  Radio,
  Table,
  Tabs,
  Form,
  FormError,
  FormLabel,
  Button,
  Badge,
  Menu,
  Spinner,
  Select,
  StordCard,
  Breadcrumb,
  Text,
  Alert,
  EmptyData,
  Divider,
  Tooltip,
  Textarea,
  NumberInput,
  Link,
  Icon,
  Drawer,
  IconBadge,
  Card,
  Modal,
  Tag,
  Popover,
  Checkbox,
  Container,
  Kbd,
  Stat,
} from "./components";

const overrides = {
  styles: {
    global: {
      body: {
        lineHeight: "shorter",
        bg: "body",
        color: "text",
      },
    },
  },
  colors,
  semanticTokens,
  ...typography,
  shadows,
  components: {
    Alert,
    Avatar,
    EmptyData,
    Breadcrumb,
    StordCard,
    Form,
    Drawer,
    FormError,
    FormLabel,
    Input,
    Radio,
    Table,
    Tabs,
    Button,
    Badge,
    Menu,
    Icon,
    IconBadge,
    Spinner,
    Select,
    Text,
    Textarea,
    Divider,
    Tooltip,
    NumberInput,
    Link,
    Card,
    Modal,
    Tag,
    Popover,
    Checkbox,
    Container,
    Kbd,
    Stat,
  },
};

// These are the default breakpoints
const breakpoints = {
  sm: "30em",
  md: "48em",
  lg: "62em",
  xl: "80em",
  "2xl": "96em",
};

const zIndices = {
  zIndices: {
    hide: -1, // Chakra default
    auto: "auto", // Chakra default
    base: 0, // Chakra default
    docked: 10, // Chakra default
    dropdown: 1000, // Chakra default
    sticky: 1100, // Chakra default
    banner: 1200, // Chakra default
    popover: 1325, // This should match the z-index in `popover.ts`
    sidebar: 1350,
    overlay: 1375,
    modal: 1400, // Chakra default
    modalPopovers: 1450,
    skipLink: 1600, // Chakra default
    toast: 1700, // Chakra default
    tooltip: 1800, // Chakra default
  },
};

export const theme = extendTheme(
  breakpoints,
  zIndices,
  overrides,
  withDefaultVariant({
    variant: "solid",
    components: ["Button"],
  }),
  withDefaultProps({
    defaultProps: {
      color: "gray.800",
    },
  }),
);
