import type { CenterProps } from "@chakra-ui/react";
import { Center } from "@chakra-ui/react";

import { Loader } from "@stordco/fe-components";

export function LoadingOverlay(props: CenterProps) {
  return (
    <Center
      bg="whiteAlpha.600"
      _dark={{
        bg: "blackAlpha.600",
      }}
      position="absolute"
      inset={0}
      rounded="base"
      {...props}
    >
      <Loader />
    </Center>
  );
}
