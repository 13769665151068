import {
  Button,
  Center,
  chakra,
  HStack,
  StylesProvider,
  Text,
  useMultiStyleConfig,
  useStyles,
  VStack,
  type LinkProps,
} from "@chakra-ui/react";
import type { CenterProps, StackProps } from "@chakra-ui/react";
import { isValidElement, type ReactElement, type ReactNode } from "react";
import { Link as ReactRouterLink } from "react-router-dom";

import { FaIcon, type FaIconProps } from "../FaIcon";

type Action = {
  onClick?: () => void;
  label: string;
  icon?: ReactElement;
  to?: string;
  href?: LinkProps["href"];
  target?: LinkProps["target"];
  isDisabled?: boolean;
};

interface EmptyDataBase {
  icon?: FaIconProps["icon"];
  children?: ReactNode;
  title?: ReactNode;
  size?: "lg" | "md" | "sm";
  containerOverride?: CenterProps;
  badge?: ReactNode;
}

type ActionOrComponent = Action | ReactNode;

interface EmptyDataMultipleSecondary extends EmptyDataBase {
  primaryAction?: never;
  secondaryAction: [ActionOrComponent, ActionOrComponent];
}
interface EmptyDataSingleSecondary extends EmptyDataBase {
  primaryAction?: ActionOrComponent | null;
  secondaryAction?: ActionOrComponent | null;
}

export type EmptyDataProps = (
  | EmptyDataMultipleSecondary
  | EmptyDataSingleSecondary
) &
  CenterProps & { containerProps?: StackProps };

function isAction(action: ActionOrComponent): action is Action {
  return !isValidElement(action);
}

export const EmptyData = ({
  icon,
  title,
  children,
  primaryAction,
  secondaryAction,
  size = "lg",
  containerOverride = {},
  badge,
  containerProps,
  ...props
}: EmptyDataProps) => {
  const styles = useMultiStyleConfig("EmptyData", { size });

  const hasSingleSecondary =
    !!secondaryAction && !Array.isArray(secondaryAction);

  const hasMultipleSecondary =
    !!secondaryAction && Array.isArray(secondaryAction);

  return (
    <Center __css={{ ...styles.wrapper, ...containerOverride }} {...props}>
      <VStack spacing={4} __css={styles.container} {...containerProps}>
        {icon && size !== "sm" ? <FaIcon icon={icon} sx={styles.icon} /> : null}
        <VStack spacing={2}>
          {badge}
          {(size !== "sm" || !title) && (
            <chakra.h2 sx={styles.title}>{title}</chakra.h2>
          )}
          <StylesProvider value={styles}>{children}</StylesProvider>
        </VStack>
        <HStack>
          {primaryAction && !hasMultipleSecondary ? (
            isAction(primaryAction) ? (
              <Button
                size="sm"
                colorScheme="blue"
                variant={size !== "lg" ? "link" : "solid"}
                onClick={primaryAction.onClick}
                leftIcon={primaryAction.icon}
                {...(primaryAction.to && {
                  as: ReactRouterLink,
                  to: primaryAction.to,
                })}
                {...(primaryAction.href && {
                  as: "a",
                  href: primaryAction.href,
                  target: primaryAction.target,
                })}
              >
                {primaryAction.label}
              </Button>
            ) : (
              primaryAction
            )
          ) : null}
          {hasSingleSecondary ? (
            isAction(secondaryAction) ? (
              <Button
                size="sm"
                colorScheme="gray"
                variant="outline"
                onClick={secondaryAction.onClick}
                leftIcon={secondaryAction.icon}
                {...(secondaryAction.to && {
                  as: ReactRouterLink,
                  to: secondaryAction.to,
                })}
                isDisabled={secondaryAction.isDisabled}
              >
                {secondaryAction.label}
              </Button>
            ) : (
              secondaryAction
            )
          ) : null}
          {hasMultipleSecondary &&
            size === "lg" &&
            secondaryAction.map((action, index) => {
              if (isAction(action)) {
                return (
                  <Button
                    key={index}
                    size="sm"
                    colorScheme="gray"
                    variant="outline"
                    onClick={action.onClick}
                    leftIcon={action.icon}
                    {...(action.to && {
                      as: ReactRouterLink,
                      to: action.to,
                    })}
                    isDisabled={action.isDisabled}
                  >
                    {action.label}
                  </Button>
                );
              }

              return action;
            })}
        </HStack>
      </VStack>
    </Center>
  );
};

export const EmptyDataDescription = ({ children }: { children: ReactNode }) => {
  const styles = useStyles();
  return (
    <Text sx={styles.description} textAlign="center">
      {children}
    </Text>
  );
};
