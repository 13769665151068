import { useQuery } from "@tanstack/react-query";

import { useApi } from "@stordco/fe-components";

import { useNetworkId } from "../../components/Network";
import type { DayOfWeek } from "../../features/NetworkDetails/Facilities/constants";

type UseTradeConnectionsResponse = { data: TradeConnection[] };
export function useTradeConnections(options?: { enabled?: boolean }) {
  const networkId = useNetworkId();

  const api = useApi();

  return useQuery({
    queryKey: ["tradeConnections", { networkId }],
    queryFn: () =>
      api.get<UseTradeConnectionsResponse>({
        url: `/bff/v1/networks/${networkId}/trade_connections`,
      }),
    select: (response) => response.data,
    staleTime: 5 * 60 * 1000,
    ...options,
  });
}

export interface TradeConnection {
  allocated_square_feet: null | string;
  facility: Facility;
  facility_capacities: FacilityCapacity[];
  facility_display_name: null | string;
  facility_id: string;
  disabled: boolean;
  network_id: string;
  rank: number;
  routing_adder: null | string;
  cost_routing_adder: null | string;
  service_level_agreements: ServiceLevelAgreement[];
  trade_connection_id: string;
  wms_id: null | string;
  wms?: Wms;
}

interface FacilityCapacity {
  capacity: null | number;
  day: DayOfWeek;
  facility_capacity_id: string;
  tenant_id: string;
  trade_connection_id: string;
  updated_by: string;
}

interface Facility {
  address: Address;
  alias: string;
  company_id: string;
  email: null | string;
  facility_display_name: null;
  facility_id: string;
  holidays: string[] | null;
  holidays_dates: HolidaysDate[];
  inbound_docks: number | null;
  location_description: null | string;
  name: string;
  operation_schedules: OperationSchedule[];
  outbound_docks: number | null;
  tenant_id: string;
  timezone: string;
  total_square_feet: null | string;
  type: FacilityType;
  warehouse_management_system_id: null | string;
  wifi_enabled: boolean | null;
  warehouse_management_system?: WarehouseManagementSystem;
}

interface Address {
  address_id: string;
  address_type: null;
  company: null;
  country_code: string;
  country_subdivision_code: null | string;
  latitude: null | string;
  line1: string;
  line2: string | null;
  line3: null;
  locality: string;
  longitude: null | string;
  name: string;
  normalized_address: null;
  normalized_country_code: string | null;
  normalized_country_subdivision_code: null | string;
  normalized_line1: null | string;
  normalized_line2: null;
  normalized_locality: null | string;
  normalized_name: null | string;
  normalized_postal_code: null | string;
  postal_code: string;
  verification_status: null | string;
}

interface HolidaysDate {
  date: string;
  name: string;
  observed_date: string;
}

interface OperationSchedule {
  day: DayOfWeek;
  end_time: string;
  facility_id: string;
  operation_schedule_id: string;
  start_time: string;
  tenant_id: string;
}

type FacilityType = "warehouse" | "spn" | "sfn";

interface WarehouseManagementSystem {
  display_name: string;
  name: string;
  warehouse_management_system_id: string;
}

interface ServiceLevelAgreement {
  channel_id: string | null;
  cutoff_time: string;
  days_to_complete: number;
  fulfillment_type: "D2C" | "B2B";
  service_level_agreement_id: string;
  tenant_id: string;
  trade_connection_id: string;
  updated_by: string;
}

interface Wms {
  display_name: string;
  inserted_at: string;
  name: string;
  updated_at: string;
  wms_id: string;
}

/** Get the name to display to the user for a trade connection facility. */
export function getFacilityDisplayName(
  tradeConnection:
    | {
        facility_display_name: string | null;
        facility: {
          alias: string;
        };
      }
    | undefined,
) {
  return (
    tradeConnection?.facility_display_name ||
    tradeConnection?.facility.alias ||
    ""
  );
}
