import { CONFETTI_PATH } from "./constants";

const paths = [CONFETTI_PATH];

export function drawConfetti(
  this: { path: Path2D; radius: number },
  ctx: CanvasRenderingContext2D,
) {
  this.path = this.path || new Path2D(randomElement(paths));

  ctx.scale(this.radius, this.radius);
  ctx.fill(this.path);
}

function randomElement<T>(arr: Array<T>) {
  return arr[Math.floor(Math.random() * arr.length)];
}
