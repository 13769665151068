import { useColorModeValue as mode } from "@chakra-ui/react";
import { Spinner } from "@chakra-ui/react";
import type { SpinnerProps } from "@chakra-ui/react";

export const Loader = ({ size = "xl", ...props }: SpinnerProps) => (
  <Spinner
    size={size}
    color={mode("blue.500", "volt")}
    emptyColor={mode("gray.200", "gray.800")}
    {...props}
  />
);
