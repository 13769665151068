import { defineStyle, defineStyleConfig } from "@chakra-ui/styled-system";

const baseStyle = defineStyle({
  animationDuration: "0.65s",
});

const sizes = {
  xl: defineStyle({
    // `thickness` prop
    borderWidth: "0.25rem",
  }),
};

export default defineStyleConfig({
  baseStyle,
  sizes,
});
