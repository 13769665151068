import { tagAnatomy as parts } from "@chakra-ui/anatomy";
import {
  createMultiStyleConfigHelpers,
  useColorModeValue,
} from "@chakra-ui/react";
import { transparentize } from "@chakra-ui/theme-tools";

import { pickSpecificColor } from "../utils";

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
  label: {
    textStyle: "tooltip",
    color: "gray.800",
  },
});

const variants = {
  subtle: definePartsStyle((props) => {
    const backgroundColor = useColorModeValue(
      pickSpecificColor(props.colorScheme, {
        gray: 100,
      }) ?? `${props.colorScheme}.50`,
      transparentize(`${props.colorScheme}.200`, 0.16),
    );
    const color = useColorModeValue(undefined, `${props.colorScheme}.200`);

    return {
      container: {
        textStyle: "tooltip",
        backgroundColor,
        "> svg": {
          color,
        },
      },
      label: {
        color,
      },
    };
  }),
  outline: definePartsStyle((props) => {
    const color = useColorModeValue(undefined, `${props.colorScheme}.500`);

    return {
      container: {
        "> svg": {
          color,
        },
      },
      label: {
        color,
      },
    };
  }),
};

export default defineMultiStyleConfig({
  baseStyle,
  variants,
}) as any;
