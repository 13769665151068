import type { ReactNode } from "react";
import {
  Flex,
  Skeleton,
  Text,
  type FlexProps,
  IconButton,
} from "@chakra-ui/react";
import type { Table } from "@tanstack/react-table";
import { faArrowLeft, faArrowRight } from "@fortawesome/pro-regular-svg-icons";

import { FaIcon } from "../FaIcon";

export const numberFormatter = new Intl.NumberFormat();

export const TanstackTablePagination = ({
  table,
  isLoading,
  getLocationIndicatorText,
  ...props
}: {
  table: Table<any>;
  isLoading?: boolean;
  getLocationIndicatorText?: (table: Table<any>) => ReactNode;
} & FlexProps) => {
  return (
    <Flex alignItems="center" {...props}>
      {getLocationIndicatorText ? (
        <Skeleton isLoaded={!isLoading} mr={4}>
          {isLoading ? (
            "Loading"
          ) : (
            <Text whiteSpace="nowrap">{getLocationIndicatorText(table)}</Text>
          )}
        </Skeleton>
      ) : null}

      <IconButton
        variant="ghost"
        colorScheme="gray"
        aria-label="Previous page"
        isDisabled={!table.getCanPreviousPage()}
        onClick={table.previousPage}
        icon={<FaIcon icon={faArrowLeft} />}
        mr={1}
      >
        Previous
      </IconButton>

      <IconButton
        variant="ghost"
        colorScheme="gray"
        aria-label="Next page"
        isDisabled={!table.getCanNextPage()}
        onClick={table.nextPage}
        icon={<FaIcon icon={faArrowRight} />}
      >
        Next
      </IconButton>
    </Flex>
  );
};

export const getVagueLocationIndicatorText =
  ({
    totalCount,
    singularName,
    pluralName,
  }: {
    totalCount: number | undefined;
    singularName: string;
    pluralName: string;
  }) =>
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  (table: Table<any>) => {
    if (typeof totalCount === "undefined") {
      return null;
    }

    return `${numberFormatter.format(totalCount)} ${pluralize(
      totalCount,
      singularName,
      pluralName,
    )}`;
  };

export const getDetailedLocationIndicatorText =
  ({
    totalCount,
    singularName,
    pluralName,
  }: {
    totalCount: number | undefined;
    singularName: string;
    pluralName: string;
  }) =>
  // eslint-disable-next-line react/display-name
  (table: Table<any>) => {
    if (typeof totalCount === "undefined") {
      return null;
    }

    const startIndex =
      table.getState().pagination.pageIndex *
        table.getState().pagination.pageSize +
      (table.getRowModel().rows.length ? 1 : 0);

    const endIndex =
      table.getState().pagination.pageIndex *
        table.getState().pagination.pageSize +
      table.getRowModel().rows.length;

    return (
      <>
        <Text as="span" fontWeight="semibold">{`${numberFormatter.format(
          startIndex,
        )}-${numberFormatter.format(endIndex)}`}</Text>
        {` of ${numberFormatter.format(totalCount)} ${pluralize(
          totalCount,
          singularName,
          pluralName,
        )}`}
      </>
    );
  };

const pluralRules = new Intl.PluralRules();

export function pluralize(count: number, singular: string, plural: string) {
  return (pluralRules.select(count) === "one" ? singular : plural).replace(
    /\{n}/g,
    (count || 0).toString(),
  );
}
