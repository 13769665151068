import { faEarth } from "@fortawesome/pro-solid-svg-icons";
import type { SquareProps } from "@chakra-ui/react";
import { Square, useColorModeValue } from "@chakra-ui/react";
import { useState } from "react";
import {
  autoUpdate,
  flip,
  FloatingPortal,
  offset,
  shift,
  useFloating,
  useHover,
  useInteractions,
} from "@floating-ui/react";
import { transparentize } from "@chakra-ui/theme-tools";

import { FaIcon } from "@stordco/fe-components";

import { WidgetTooltip } from "./WidgetTooltip";

export function InternationalOrdersIndicator({
  children,
  boundary,
  percentMax,
  ...props
}: SquareProps & { boundary: Element; percentMax: number }) {
  const [isOpen, setIsOpen] = useState(false);

  const { refs, floatingStyles, context } = useFloating({
    whileElementsMounted: autoUpdate,
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [
      offset(10),
      flip({
        boundary,
        padding: 10,
      }),
      shift({ boundary, padding: 10 }),
    ],
  });

  const hover = useHover(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([hover]);

  const minOpacity = 0.08;
  const opacity = percentMax * (1 - minOpacity) + minOpacity;
  const baseColor = useColorModeValue("blue.400", "blue.200");

  const color = transparentize(baseColor, opacity);
  const iconBoxSize = 6;

  return (
    <>
      <Square
        ref={refs.setReference}
        p={2}
        background="white"
        rounded="base"
        _dark={{
          background: "#2e2e2e",
        }}
        {...props}
        {...getReferenceProps()}
      >
        <FaIcon
          icon={faEarth}
          color={color as any}
          boxSize={iconBoxSize}
          stroke="blackAlpha.200"
          // Icons are 512x512, displayed at a boxSize of 6 (24px), so the scale is 1/21. We want a 2px stroke,
          // hence the width of 42
          strokeWidth={(512 / (iconBoxSize * 4)) * 2}
          _dark={{
            stroke: "#242424",
          }}
        />
      </Square>

      {isOpen && (
        <FloatingPortal>
          <WidgetTooltip
            ref={refs.setFloating}
            style={floatingStyles}
            {...getFloatingProps()}
          >
            {children}
          </WidgetTooltip>
        </FloatingPortal>
      )}
    </>
  );
}
