import userflow from "userflow.js";
import { useNavigate } from "react-router-dom";

import { useConfig } from "@stordco/fe-components";

import { useNetwork } from "./Network";
import { useUser } from "../../../../packages/components/src/hooks/useUser";
import { useNetworkRole } from "../hooks/useNetworkRole";
import { useQuery } from "@tanstack/react-query";

export function UserflowInitializer() {
  useUserflowInitialize();

  return null;
}

export function useUserflowInitialize() {
  const { USERFLOW_CLIENT_KEY } = useConfig();
  const user = useUser();
  const navigate = useNavigate();
  const network = useNetwork();
  const role = useNetworkRole();

  return useQuery({
    queryKey: [
      "UserflowInitializer",
      USERFLOW_CLIENT_KEY,
      user.id,
      user.first_name,
      user.last_name,
      user.email,
      network.company.name,
      network.name,
      network.network_id,
      role,
    ],
    async queryFn() {
      userflow.init(USERFLOW_CLIENT_KEY);
      userflow.setResourceCenterLauncherHidden(true);
      userflow.setCustomNavigate((url) => {
        navigate(url);
      });

      await userflow.identify(user.id!, {
        viewport_width: window.innerWidth,
        viewport_height: window.innerHeight,
        name: `${user.first_name} ${user.last_name}`,
        email: user.email,
        company: network.company.name,
        version: "v2",
        networkName: network.name,
        networkId: network.network_id,
        role,
      });

      return null;
    },
    enabled: Boolean(USERFLOW_CLIENT_KEY),
  });
}
