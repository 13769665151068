import {
  HStack,
  Link,
  Text,
  Button,
  type LinkProps,
  Box,
} from "@chakra-ui/react";
import type { ButtonProps } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import type { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faArrowUpRightFromSquare } from "@fortawesome/pro-regular-svg-icons";
import type { ReactNode } from "react";

import { FaIcon } from "../FaIcon";

import type { NavigationItem } from "./types";

type NavButtonProps = NavigationItem &
  LinkProps & {
    displayIcon?: boolean;
    isDesktop: boolean;
    isCollapsed: boolean;
    rightIcon?: IconDefinition;
    rightElement?: ReactNode;
  };

export const NavButton = ({
  icon,
  label,
  isExternal,
  onClick,
  isDesktop,
  isCollapsed,
  displayIcon = true,
  rightIcon,
  rightElement,
  ...buttonProps
}: NavButtonProps) => {
  return (
    <Link
      as={!isExternal && buttonProps.to ? NavLink : "a"}
      color="whiteAlpha.700"
      px={2}
      py={2.5}
      paddingInlineStart={3}
      marginTop={0}
      borderRadius="base"
      isExternal={isExternal}
      _hover={{ bg: "whiteAlpha.200", color: "white" }}
      {...(!isExternal && buttonProps.to
        ? { to: buttonProps.to }
        : { href: buttonProps.to })}
      className="group"
      display="block"
      _activeLink={{
        bg: "whiteAlpha.200",
        color: "white",
        ".active-icon": { color: "volt" },
      }}
      onClick={onClick}
      {...buttonProps}
    >
      <HStack justify="space-between">
        <HStack spacing="3">
          {/* Don't display icons when the actions are in a parent */}
          {displayIcon && icon ? (
            <FaIcon className="active-icon" icon={icon} />
          ) : null}
          <Text
            marginLeft={displayIcon ? 0 : 6}
            as="span"
            fontSize="sm"
            lineHeight="1.25rem"
            fontWeight="medium"
            opacity={isDesktop && isCollapsed ? 0 : 1}
            transition="opacity 0.2s ease-out"
            display="inline-flex"
            alignItems="center"
            whiteSpace="nowrap"
          >
            {label}
            {rightIcon && (
              <FaIcon width={3} height={3} marginLeft={2} icon={rightIcon} />
            )}
          </Text>
        </HStack>

        {isExternal && (
          <FaIcon
            icon={faArrowUpRightFromSquare}
            display="none"
            _groupHover={{
              display: "inline-block",
            }}
          />
        )}

        {rightElement ? (
          <Box
            opacity={isDesktop && isCollapsed ? 0 : 1}
            transition="opacity 0.2s ease-out"
          >
            {rightElement}
          </Box>
        ) : null}
      </HStack>
    </Link>
  );
};

type ParentNavButtonProps = ButtonProps &
  Pick<NavigationItem, "icon" | "label"> & {
    isOpen: boolean;
    isActiveLink: boolean;
    isCollapsed: boolean;
    isDesktop: boolean;
  };

export const ParentNavButton = ({
  icon,
  label,
  isActiveLink,
  isOpen,
  isCollapsed,
  isDesktop,
  ...buttonProps
}: ParentNavButtonProps) => {
  return (
    <Button
      variant="ghost"
      color={isOpen || isActiveLink ? "white" : "whiteAlpha.700"}
      px={2}
      py={2.5}
      paddingInlineStart={3}
      marginTop={0}
      borderRadius="base"
      fontWeight="medium"
      justifyContent="start"
      _hover={
        isOpen || isActiveLink ? {} : { bg: "whiteAlpha.200", color: "white" }
      }
      _active={{}}
      className="group"
      size="md"
      w="100%"
      {...buttonProps}
    >
      <HStack justify="space-between" spacing="3">
        {icon ? (
          <FaIcon icon={icon} {...(isActiveLink ? { color: "volt" } : {})} />
        ) : null}

        <Text
          as="span"
          fontSize="sm"
          lineHeight="1.25rem"
          fontWeight="medium"
          width="max-content"
          opacity={isDesktop && isCollapsed ? 0 : 1}
          transition="opacity 0.2s ease-out"
        >
          {label}
        </Text>
      </HStack>
    </Button>
  );
};
