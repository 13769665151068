import type {
  FetchBaseQueryBaseError,
  FetchBaseQueryCustomError,
  FetchBaseQueryFetchError,
  FetchBaseQueryParsingError,
} from "./fetchBaseQuery";

export const isParsingError = (
  error: any,
): error is FetchBaseQueryParsingError => error?.status === "PARSING_ERROR";
export const isFetchError = (error: any): error is FetchBaseQueryFetchError =>
  error?.status === "FETCH_ERROR";
export const isCustomError = (error: any): error is FetchBaseQueryCustomError =>
  error?.status === "CUSTOM_ERROR";
export const isBaseError = (error: any): error is FetchBaseQueryBaseError =>
  typeof error?.status === "number";
