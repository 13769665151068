const label = {
  lineHeight: 5,
  fontSize: "sm",
  fontWeight: "semibold",
  color: "heading",
};

const caption = {
  lineHeight: 4,
  fontSize: "xs",
  fontWeight: "normal",
  color: "gray.700",
  _dark: { color: "gray.300" },
};

const hoverable = {
  textDecoration: "underline dashed",
  textDecorationColor: "gray.500",
  textUnderlineOffset: "0.125rem",
};

export default {
  fonts: {
    heading: `"Inter", sans-serif`,
    body: `"Inter", sans-serif`,
    mono: "monospace",
  },
  textStyles: {
    pageTitle: {
      lineHeight: 8,
      fontSize: "3xl",
      fontWeight: "bold",
      color: "heading",
    },
    title: {
      lineHeight: 8,
      fontSize: "1.375rem",
      fontWeight: "semibold",
      color: "heading",
    },
    sectionTitle: {
      lineHeight: 6,
      fontSize: "md",
      fontWeight: "semibold",
      color: "heading",
    },
    subtitle: {
      lineHeight: 6,
      fontSize: "lg",
      fontWeight: "semibold",
      color: "heading",
    },
    label,
    paragraph: {
      lineHeight: 5,
      fontSize: "sm",
      fontWeight: "normal",
    },
    tooltip: {
      lineHeight: 4,
      fontSize: "xs",
      fontWeight: "semibold",
      color: "heading",
    },
    caption,
    miniHeader: {
      lineHeight: 3,
      fontSize: "0.625rem",
      fontWeight: "bold",
      textTransform: "uppercase",
      letterSpacing: "0.06em",
    },
    hoverable,
    hoverableLabel: {
      ...label,
      ...hoverable,
    },
    hoverableCaption: {
      ...caption,
      ...hoverable,
    },
  },
};
