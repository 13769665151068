import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Text,
} from "@chakra-ui/react";
import type { BreadcrumbProps } from "@chakra-ui/react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { faAngleRight, faAngleLeft } from "@fortawesome/pro-regular-svg-icons";

import { FaIcon } from "../FaIcon";

export type BreadcrumbsProps = Omit<BreadcrumbProps, "separator"> & {
  breadcrumbs?: {
    label: string;
    to?: string;
  }[];
};

export const Breadcrumbs = ({ breadcrumbs, ...props }: BreadcrumbsProps) => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Breadcrumb
      separator={
        <FaIcon
          color="gray.700"
          _dark={{ color: "gray.300" }}
          icon={faAngleRight}
        />
      }
      {...props}
    >
      {location.state?.previousPageLink ? (
        <BreadcrumbItem>
          <BreadcrumbLink
            as="span"
            display="inline-flex"
            onClick={() => navigate(-1)}
            alignItems="center"
            gap={2}
          >
            <FaIcon
              icon={faAngleLeft}
              color="gray.700"
              _dark={{ color: "gray.300" }}
            />{" "}
            {location.state.previousPageLink}
          </BreadcrumbLink>
        </BreadcrumbItem>
      ) : (
        breadcrumbs?.map(({ label, to }, index) => {
          return (
            <BreadcrumbItem
              key={index}
              isCurrentPage={index === breadcrumbs.length - 1}
            >
              {to ? (
                <BreadcrumbLink as={Link} to={to}>
                  {label}
                </BreadcrumbLink>
              ) : (
                <BreadcrumbLink as={Text} _hover={{ cursor: "auto" }}>
                  {label}
                </BreadcrumbLink>
              )}
            </BreadcrumbItem>
          );
        }) ?? (
          <BreadcrumbItem>
            <BreadcrumbLink>&#8203;</BreadcrumbLink>
          </BreadcrumbItem>
        )
      )}
    </Breadcrumb>
  );
};
