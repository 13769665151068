import { Image, chakra } from "@chakra-ui/react";
import type { ImageProps, CenterProps } from "@chakra-ui/react";
import { faPlug as faPlugLight } from "@fortawesome/pro-light-svg-icons";
import { faPlug as faPlugRegular } from "@fortawesome/pro-regular-svg-icons";

import { IconBadge } from "@stordco/fe-components";

import amazon from "../../../../icons/amazon.svg";
import ebay from "../../../../icons/ebay.svg";
import etsy from "../../../../icons/etsy.svg";
import home_depot from "../../../../icons/home_depot.svg";
import neiman_marcus from "../../../../icons/neiman_marcus.svg";
import netsuite from "../../../../icons/netsuite.svg";
import nordstrom from "../../../../icons/nordstrom.svg";
import logic_broker from "../../../../icons/logic_broker.svg";
import pipe17 from "../../../../icons/pipe17.svg";
import shopify from "../../../../icons/shopify.svg";
import sps_commerce from "../../../../icons/sps_commerce.svg";
import target from "../../../../icons/target.svg";
import ulta from "../../../../icons/ulta.svg";
import walmart from "../../../../icons/walmart.svg";
import wayfair from "../../../../icons/wayfair.svg";
import woocommerce from "../../../../icons/woocommerce.svg";

// Most of these logos are not supported in the connections UI, but need to still be here for older events for the activity log where a user
// changed the logo
export const srcByConnectionSource = {
  amazon,
  ebay,
  etsy,
  home_depot,
  nordstrom,
  logic_broker,
  neiman_marcus,
  netsuite,
  pipe17,
  shopify,
  sps_commerce,
  target,
  ulta,
  walmart,
  wayfair,
  woocommerce,
} as Record<string, string>;

export const ConnectionLogo = ({
  source,
  ...props
}: {
  source?: string;
  boxSize: ImageProps["boxSize"];
} & Omit<ImageProps, "src" | "alt" | "fallback" | "boxSize">) => {
  const logo = srcByConnectionSource[source ?? ""];

  if (!logo) {
    return <DefaultLogo {...props} />;
  }

  return (
    <Image src={logo} alt={`${source} logo`} borderRadius="base" {...props} />
  );
};

export const DefaultLogo = ({ boxSize, ...props }: CenterProps) => {
  return (
    <chakra.div {...props}>
      <IconBadge
        iconStyles={{ boxSize: "60%" }}
        boxSize={boxSize}
        icon={
          typeof boxSize === "number" && boxSize > 8
            ? faPlugLight
            : faPlugRegular
        }
        variant="square"
        colorScheme="gray"
      />
    </chakra.div>
  );
};
