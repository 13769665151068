import type { ReactNode } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { useApiHeaders, PageLoader } from "@stordco/fe-components";

import NetworkContext from "./NetworkContext";
import type { Network } from "./queries";
import { useNetwork } from "./queries";

const NetworkProvider = ({ children }: { children: ReactNode }) => {
  const networkId = useParams().id!;
  const headers = useApiHeaders();

  const networkQuery = useNetwork({ networkId });

  useEffect(() => {
    // set the network id header as soon as we can, so the request for the network
    // includes it
    headers.set("x-network-id", networkId);

    // Once we have the network
    if (networkQuery.data) {
      // Set the tenant id header, as well
      headers.set("tenant-id", networkQuery.data.tenant_id);
    }
  }, [networkId, headers, networkQuery.data]);

  if (networkQuery.isError) {
    throw networkQuery.error;
  }

  if (networkQuery.isPending) {
    // TODO: suspend
    return <PageLoader />;
  }

  return (
    <NetworkProviderInner network={networkQuery.data}>
      {children}
    </NetworkProviderInner>
  );
};

function NetworkProviderInner({
  network,
  children,
}: {
  network: Network;
  children: ReactNode;
}) {
  return (
    <NetworkContext.Provider
      value={{
        ...network,
        timezone: network.timezone || "UTC",
      }}
    >
      {children}
    </NetworkContext.Provider>
  );
}

export default NetworkProvider;
