import { forwardRef } from "react";
import { Box, useMultiStyleConfig, type BoxProps } from "@chakra-ui/react";
import type { IconDefinition } from "@fortawesome/fontawesome-svg-core";

import { FaIcon } from "./FaIcon";

type Variant = "circle" | "square";

export type IconBadgeProps = BoxProps & {
  iconStyles?: any;
  wrapperStyles?: any;
  size?: IconBadgeSize;
  icon: IconDefinition;
  variant: Variant;
  colorScheme?: "blue" | "gray" | "red" | "green";
};

export type IconBadgeSize = "xs" | "sm" | "md" | "lg" | "xl";

/**
 * A wrapper around `FaIcon`. This component allows you to choose a variant and corresponding color scheme.
 *
 * @example
 * ```ts
 * import { faAngleDown } from "@fortawesome/pro-light-svg-icons";
 *
 * const SomeLargerBlueSquaredIcon = () => <FaIcon size="lg" icon={faAngleDown} variant="square" colorScheme="blue" />
 * ```
 */
// eslint-disable-next-line react/display-name
export const IconBadge = forwardRef<HTMLDivElement, IconBadgeProps>(
  (
    {
      size = "sm",
      icon,
      variant,
      colorScheme,
      iconStyles: iconStylesProp,
      ...props
    },
    ref,
  ) => {
    const { icon: iconStyles, wrapper: wrapperStyles } = useMultiStyleConfig(
      "IconBadge",
      {
        colorScheme: colorScheme as string,
        variant,
        size,
      },
    );

    return (
      <Box ref={ref} __css={wrapperStyles} {...props}>
        <FaIcon icon={icon} {...iconStyles} {...iconStylesProp} />
      </Box>
    );
  },
);
