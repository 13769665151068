import { createContext, useContext, type ReactNode } from "react";

import type { Me, UserRole } from "../hooks/cloudApi/cloudSchemas";
import { useMe, useMyRoles } from "../hooks/cloudApi/cloudComponents";
import { PageLoader } from "./PageLoader";
import { useConfig } from "../hooks";

export type IdentityContextValue = Me & { roles: UserRole[] };

export const IdentityContext = createContext<IdentityContextValue | null>(null);

export function IdentityProvider({ children }: { children: ReactNode }) {
  const meQuery = useMe({});
  const { CLOUD_UI_HOST } = useConfig();

  const myRolesQuery = useMyRoles({
    queryParams: {
      filter: {
        app_aliases: ["oms"],
      },
    },
  });

  if (meQuery.isError || myRolesQuery.isError) {
    throw meQuery.error || myRolesQuery.error;
  }

  if (meQuery.isPending || myRolesQuery.isPending) {
    // TODO: suspend
    return <PageLoader />;
  }

  if (
    meQuery.data.data.type === "auth0" ||
    meQuery.data.data.user.status === "setup"
  ) {
    window.location.assign(`${CLOUD_UI_HOST}/onboarding`);
    return null;
  }

  return (
    <IdentityContext.Provider
      value={{ ...meQuery.data.data, roles: myRolesQuery.data.data }}
    >
      {children}
    </IdentityContext.Provider>
  );
}

export function useIdentity() {
  const user = useContext(IdentityContext);

  if (!user) {
    throw new Error(
      `${useIdentity.name} can only be used inside <${IdentityProvider.name}>`,
    );
  }

  return user;
}
