import { useDatadogRUM } from "./DatadogProvider";
import { useEffect } from "react";
import { useIdentity } from "../IdentityProvider";

export function DatadogUserSetter() {
  const datadogRUM = useDatadogRUM();
  const identity = useIdentity();

  useEffect(() => {
    if (identity.type === "user") {
      datadogRUM?.setUser({
        id: identity.user.id,
        email: identity.user.email,
        name: `${identity.user.first_name} ${identity.user.last_name}`,
      });
    } else if (identity.type === "auth0") {
      datadogRUM?.setUser({
        id: identity.auth0.external_id,
        email: identity.auth0.email,
        name: `${identity.auth0.first_name} ${identity.auth0.last_name}`,
      });
    }
  }, [datadogRUM, identity]);

  return null;
}
