import { useDisclosure } from "@chakra-ui/react";
import { useHotkeys } from "react-hotkeys-hook";
import type { ReactNode } from "react";
import { createContext, useContext } from "react";

import { useDatadogRUM } from "@stordco/fe-components";

import { GlobalSearchModal } from "./GlobalSearchModal";

const GlobalSearchContext = createContext<null | {
  isOpen: boolean;
  onOpen: () => void;
}>(null);

export function useGlobalSearch() {
  const val = useContext(GlobalSearchContext);

  if (!val) {
    throw new Error(
      "`useGlobalSearch` must be called within a `GlobalSearchContext.Provider`",
    );
  }

  return val;
}

export function GlobalSearchProvider({ children }: { children: ReactNode }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const datadogRum = useDatadogRUM();

  useHotkeys("mod+k", (event) => {
    event.preventDefault();
    datadogRum?.addAction("hotkey:open_global_search");

    onOpen();
  });

  return (
    <>
      <GlobalSearchContext.Provider value={{ isOpen, onOpen }}>
        {children}
      </GlobalSearchContext.Provider>

      {isOpen ? <GlobalSearchModal onClose={onClose} /> : null}
    </>
  );
}
