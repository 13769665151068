import {
  Box,
  FormLabel,
  HStack,
  InputGroup,
  InputRightElement,
  Popover,
  PopoverAnchor,
  PopoverBody,
  PopoverContent,
  useDisclosure,
} from "@chakra-ui/react";
import { faCalendar } from "@fortawesome/pro-regular-svg-icons";
import { useDateRangePicker } from "@react-aria/datepicker";
import {
  type DateRangePickerStateOptions,
  useDateRangePickerState,
} from "@react-stately/datepicker";
import { useRef } from "react";

import { FaIcon } from "../FaIcon";

import { DateField, StyledField } from "./DateField";
import { RangeCalendar } from "./RangeCalendar";

export const DateRangePicker = ({
  hideLabel = false,
  ...props
}: DateRangePickerStateOptions & {
  hideLabel?: boolean;
}) => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  const state = useDateRangePickerState({
    ...props,
    onChange: (value) => {
      props.onChange?.(value);
      onClose();
    },
  });
  const ref = useRef(null);
  const {
    groupProps,
    labelProps,
    startFieldProps,
    endFieldProps,
    dialogProps,
    calendarProps,
  } = useDateRangePicker(props, state, ref);

  return (
    <Popover placement="bottom-start" isOpen={isOpen} onClose={onClose}>
      {props.label && (
        <FormLabel {...labelProps} hidden={hideLabel}>
          {props.label}
        </FormLabel>
      )}
      <PopoverAnchor>
        <InputGroup
          {...groupProps}
          ref={ref}
          display="inline-flex"
          onClick={startFieldProps.isDisabled ? undefined : onOpen}
        >
          <StyledField
            width="100%"
            justifyContent="space-between"
            borderColor={startFieldProps.isDisabled ? "gray.100" : "gray.300"}
            _hover={
              startFieldProps.isDisabled ? {} : { borderColor: "gray.400" }
            }
          >
            <HStack>
              <DateField
                placeholder="Select dates"
                {...startFieldProps}
                onOpen={onOpen}
              />

              {endFieldProps.value && (
                <>
                  <Box
                    as="span"
                    aria-hidden="true"
                    color={startFieldProps.isDisabled ? "gray.200" : undefined}
                  >
                    -
                  </Box>
                  <DateField {...endFieldProps} onOpen={onOpen} />
                </>
              )}
            </HStack>

            <InputRightElement boxSize="7">
              <FaIcon
                icon={faCalendar}
                color={startFieldProps.isDisabled ? "gray.400" : undefined}
              />
            </InputRightElement>
          </StyledField>
        </InputGroup>
      </PopoverAnchor>

      <PopoverContent
        width="auto"
        _focusVisible={{ outline: "none" }}
        aria-label="date picker"
        border="none"
        boxShadow="md"
      >
        <PopoverBody {...dialogProps}>
          <RangeCalendar {...calendarProps} />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
