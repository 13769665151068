import { ChakraProvider, type ChakraProviderProps } from "@chakra-ui/react";

import { theme } from "@stordco/oms-theme";
import { renderStordToast } from "../Toast/renderStordToast";

export function StordChakraProvider({
  children,
  ...props
}: ChakraProviderProps) {
  return (
    <ChakraProvider
      theme={theme}
      toastOptions={{
        defaultOptions: {
          position: "bottom-left",
          render: renderStordToast,
          isClosable: true,
        },
      }}
      {...props}
    >
      {children}
    </ChakraProvider>
  );
}
