import { useState } from "react";
import type { ReactNode, ComponentProps, CSSProperties } from "react";
import { feature, mesh } from "topojson-client";
import { geoPath, geoAlbersUsa } from "d3-geo";
import * as delaunay from "d3-delaunay";
import {
  useFloating,
  autoUpdate,
  useHover,
  useInteractions,
  flip,
  shift,
  offset,
  FloatingPortal,
  useDismiss,
  useRole,
} from "@floating-ui/react";
import { chakra, Text, Flex } from "@chakra-ui/react";

import { formatNumber } from "../../../../lib/numbers";

import data from "./states-simplified.json";
import { WidgetTooltip } from "./WidgetTooltip";

const path = geoPath(geoAlbersUsa());

interface Data {
  type: "Topology";
  objects: Record<string, GeometryCollection>;
  arcs: number[][][];
}

interface GeometryCollection {
  type: "GeometryCollection";
  geometries: Array<MultiPolygon | Polygon>;
}

interface MultiPolygon {
  id: string;
  type: "MultiPolygon";
  arcs: number[][][];
  properties: Properties;
}

interface Polygon {
  id: string;
  type: "Polygon";
  arcs: number[][];
  properties: Properties;
}

interface Properties {
  name: string;
}

const states = feature(
  data as unknown as Data,
  (data as unknown as Data).objects.states,
);

const nation = feature(
  data as unknown as Data,
  (data as unknown as Data).objects.nation,
);

const [[minX, minY], [maxX, maxY]] = path.bounds(nation);

const originX = minX;
const originY = minY;
const width = maxX - minX;
const height = maxY - minY;

const borders = mesh(
  data as unknown as Data,
  (data as unknown as Data).objects.states,
  (a, b) => a !== b,
);

const statesWithCentroid = states.features.map((feature) => ({
  feature,
  centroid: path.centroid(feature.geometry),
}));

const statesVoronoi = delaunay.Delaunay.from(
  statesWithCentroid.map(({ centroid }) => centroid),
).voronoi([originX, originY, maxX, maxY]);

const statesData = statesWithCentroid.map((data, index) => ({
  ...data,
  voronoiCellPath: statesVoronoi.renderCell(index),
}));

export function OrdersMap({
  boundaryElement,
  stateData,
  ...props
}: {
  boundaryElement: Element;
  stateData:
    | Record<
        string,
        { count: number; percentMax: number; percentTotal: number }
      >
    | undefined;
} & ComponentProps<"svg">) {
  return (
    <svg viewBox={`${originX} ${originY} ${width} ${height}`} {...props}>
      {/* Draw the nation (background) */}
      <g>
        {nation.features.map((feature, index) => (
          <path
            key={index}
            className="fill-white dark:fill-gray-900"
            d={path(feature.geometry)!}
            strokeWidth={2}
          />
        ))}
      </g>

      {/* Draw the state polygons */}
      <g>
        {states.features.map((feature, index) => {
          const d = path(feature.geometry)!;

          const opacity = (() => {
            if (!stateData?.[feature.id!]) {
              return 0;
            }

            const { percentMax } = stateData[feature.id!];

            const minOpacity = 0.08;

            return percentMax * (1 - minOpacity) + minOpacity;
          })();

          return (
            <path
              key={index}
              className="fill-blue-400/[--opacity] transition-[fill] duration-[0.8s] dark:fill-blue-100/[--opacity]"
              d={d}
              stroke="none"
              style={{ "--opacity": opacity } as CSSProperties}
            />
          );
        })}
      </g>

      {/* Draw the nation (border) */}
      <g>
        {nation.features.map((feature, index) => (
          <path
            key={index}
            className="stroke-blackAlpha-200 dark:stroke-[#242424]"
            d={path(feature.geometry)!}
            strokeWidth={2}
            fill="none"
          />
        ))}
      </g>

      {/* Draw the state borders (this eliminates duplicate borders which we would have if we added a stroke to each state polygon) */}
      <path
        className="stroke-blackAlpha-200 dark:stroke-[#242424]"
        d={path(borders)!}
        strokeWidth={2}
        fill="none"
      />

      {/* Add interactive tooltips for each state (IMPORTANT: these must be last in the svg in order to receive all mouse events) */}
      <g>
        {statesData.map(({ feature, centroid }, index) => {
          const { count, percentTotal } = stateData?.[feature.id!] ?? {
            count: 0,
            percentTotal: 0,
          };

          return (
            <StateTooltip
              key={`voronoi-${index}`}
              voronoiPolygonD={statesVoronoi.renderCell(index)}
              centroid={centroid}
              boundary={boundaryElement}
            >
              <Flex direction="column" gap={1}>
                <Text textStyle="tooltip">{feature.properties.name}</Text>

                <Flex gap={2}>
                  <Text textStyle="caption">Orders</Text>
                  <Text textStyle="tooltip">
                    {formatNumber(count)}{" "}
                    <chakra.span fontWeight="normal">{`(${(
                      percentTotal * 100
                    ).toFixed(1)}%)`}</chakra.span>
                  </Text>
                </Flex>
              </Flex>
            </StateTooltip>
          );
        })}
      </g>
    </svg>
  );
}

function StateTooltip({
  voronoiPolygonD,
  centroid,
  boundary,
  children,
}: {
  voronoiPolygonD: string;
  centroid: [number, number];
  boundary: Element;
  children: ReactNode;
}) {
  const [isOpen, setIsOpen] = useState(false);

  const { refs, floatingStyles, context } = useFloating({
    placement: "top",
    whileElementsMounted: autoUpdate,
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [
      offset(10),
      flip({ boundary, padding: 10 }),
      shift({ boundary, padding: 10 }),
    ],
  });

  const hover = useHover(context);
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: "tooltip" });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    dismiss,
    role,
  ]);

  return (
    <>
      {/* Invisible point used to position the tooltip at the centroid */}
      <circle
        ref={refs.setPositionReference}
        cx={centroid[0]}
        cy={centroid[1]}
        r={2}
        fill="none"
      />

      {/* Invisible polygon which gets the hover events */}
      <path
        ref={refs.setReference}
        d={voronoiPolygonD}
        fill="transparent"
        {...getReferenceProps()}
      />

      {isOpen && (
        <FloatingPortal>
          <WidgetTooltip
            ref={refs.setFloating}
            style={floatingStyles}
            {...getFloatingProps()}
          >
            {children}
          </WidgetTooltip>
        </FloatingPortal>
      )}
    </>
  );
}
