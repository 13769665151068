import { useLDClient } from "launchdarkly-react-client-sdk";

import { useFeatureFlagContext } from "./FeatureFlagProvider";

export const useFlag = <T = boolean>(flag: string, defaultValue?: T): T => {
  const client = useLDClient();
  const context = useFeatureFlagContext();

  if (!client) {
    throw new Error(
      `${useFlag.name} can only be used in Launch Darkly provider`,
    );
  }

  if (!context) {
    throw new Error(
      `${useFlag.name} can only be used in a FeatureFlagProvider`,
    );
  }

  return context.overrides[flag] ?? client.variation(flag, defaultValue);
};
