import { cardAnatomy } from "@chakra-ui/anatomy";
import {
  type ComponentMultiStyleConfig,
  createMultiStyleConfigHelpers,
} from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(cardAnatomy.keys);

const baseStyle = definePartsStyle(() => ({
  container: {
    bg: "bg-surface",
  },
}));

const variants = {
  elevated: definePartsStyle({
    container: {
      boxShadow: "base",
      borderRadius: "base",
    },
  }),
  outline: (props) =>
    definePartsStyle({
      container: {
        boxShadow: "none",
        borderColor: mode("gray.200", "whiteAlpha.300")(props),
        borderWidth: mode("1px", "0px")(props),
        backgroundClip: mode(undefined, "padding-box")(props),
      },
    }),
} as ComponentMultiStyleConfig["variants"];

const sizes = {};

export default defineMultiStyleConfig({ baseStyle, sizes, variants }) as any;
