export const CONFETTI_COLORS = [
  "#3646F5", // blue.500
  "#EE1E37", // red.500
  "#F46C32", // orange.500
  "#FFB214", // mango.500
  "#F2E250", // yellow.500
  "#1BBB3E", // green.500
  "#008FD6", // cyan.500
  "#12BBC4", // teal.500
  "#8C2EFF", // purple.500
  "#CC28A5", // magenta.500
];

export const CONFETTI_PATH =
  "M1.129.009C.849-.258.662-.607.557-.984H-.551C-.657-.607-.849-.258-1.129.009L-.572.984C-.2.882.2.882.572.984L1.129.009ZM-.636-.404H.647L.675-.347.036.766H-.033L-.672-.347-.636-.404Z";
