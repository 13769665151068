import {
  Flex,
  LinkOverlay,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import {
  faArrowRightFromBracket,
  faShuffle,
  faScrewdriverWrench,
} from "@fortawesome/pro-regular-svg-icons";
import { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import { FaIcon } from "../FaIcon";

import { useNavigation } from "./provider";
import { AccountSwitcherButton } from "./AccountSwitcherButton";
import { LinkBoxCard } from "../Card";
import { ChakraSearch } from "../ChakraSearch";

export const AccountSwitcher = ({ isDesktop }: { isDesktop: boolean }) => {
  const { navigationState, userMenu } = useNavigation();
  const networkSelectionModal = useDisclosure();
  const [search, setSearch] = useState("");

  return (
    <>
      <Menu autoSelect={false} matchWidth>
        {({ onClose }) => (
          <>
            <MenuCloser onClose={onClose} />

            <MenuButton
              as={AccountSwitcherButton}
              isCollapsed={isDesktop && navigationState === "collapsed"}
            />

            <MenuList borderRadius="base" shadow="lg" py={2} color="gray.600">
              <MenuItem
                icon={<FaIcon icon={faArrowRightFromBracket} />}
                onClick={userMenu.onLogout}
              >
                Sign out
              </MenuItem>

              {userMenu?.showOperations && userMenu.operationsTo && (
                <>
                  <MenuDivider />

                  <MenuItem
                    as={RouterLink}
                    to={userMenu.operationsTo}
                    command={userMenu.operationsCommand}
                    icon={<FaIcon icon={faScrewdriverWrench} />}
                  >
                    Operations
                  </MenuItem>
                </>
              )}

              {!!userMenu?.accountOptions?.length && (
                <>
                  <MenuDivider />

                  <MenuItem
                    icon={<FaIcon icon={faShuffle} />}
                    onClick={networkSelectionModal.onOpen}
                  >
                    Switch network
                  </MenuItem>
                </>
              )}
            </MenuList>
          </>
        )}
      </Menu>

      <Modal
        isOpen={networkSelectionModal.isOpen}
        onClose={() => {
          networkSelectionModal.onClose();
          setSearch("");
        }}
        scrollBehavior="inside"
      >
        <ModalOverlay />

        <ModalContent>
          <ModalHeader>Switch network</ModalHeader>

          <ModalCloseButton />

          <ModalBody display="flex" flexDirection="column" gap={2}>
            <ChakraSearch
              value={search}
              onChange={setSearch}
              placeholder="Type to search"
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
              autoComplete="off"
            />

            {userMenu.accountOptions
              ?.filter(
                ({ value, label }) =>
                  !search ||
                  value.toLowerCase().includes(search.toLowerCase()) ||
                  label.toLowerCase().includes(search.toLowerCase()),
              )
              .map(({ value, label, href }) => (
                <LinkBoxCard key={value} variant="outline">
                  <Flex px={3} py={2} gap={3} alignItems="center">
                    <LinkOverlay href={href}>
                      <Text fontWeight="medium">{label}</Text>
                    </LinkOverlay>
                  </Flex>
                </LinkBoxCard>
              ))}
          </ModalBody>

          <ModalFooter gap={2}></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

function MenuCloser({ onClose }: { onClose: () => void }) {
  const { navigationState } = useNavigation();

  useEffect(() => {
    if (navigationState !== "hoverExpanded") {
      onClose();
    }
  }, [navigationState, onClose]);

  return null;
}
