import { ComponentMultiStyleConfig } from "@chakra-ui/react";
import { mode, transparentize } from "@chakra-ui/theme-tools";

import { pickSpecificColor } from "../utils";

export default {
  parts: ["wrapper", "icon"],
  baseStyle: (props) => {
    const { colorScheme, theme } = props;

    return {
      wrapper: {
        alignItems: "center",
        bgColor: mode(
          colorScheme === "gray" ? "blackAlpha.100" : `${colorScheme}.50`,
          transparentize(`${colorScheme}.200`, 0.16)(theme),
        )(props),
        justifyContent: "space-around",
        display: "flex",
        flexShrink: 0,
      },
      icon: {
        color: mode(
          pickSpecificColor(colorScheme, {
            blue: 500,
            red: 600,
            green: 600,
            yellow: 600,
            mango: 600,
            gray: 800,
          }),
          `${colorScheme}.200`,
        )(props),
      },
    };
  },
  variants: {
    icon: {
      icon: {
        color: "currentColor",
      },
    },
    circle: ({ size }) => ({
      wrapper: {
        borderRadius: "full",
        boxSize: (
          {
            xs: 6,
            sm: 8,
            md: 12,
            lg: 16,
            xl: 24,
          } as Record<string, number>
        )[size],
      },
    }),
    square: ({ size }) => ({
      wrapper: {
        borderRadius: "base",
        boxSize: (
          {
            xs: 6,
            sm: 8,
            md: 10,
            lg: 14,
            xl: 20,
          } as Record<string, number>
        )[size],
      },
    }),
  },
  sizes: {
    xs: {
      icon: { fontSize: "xs" },
    },
    sm: {
      icon: { fontSize: "md" },
    },
    md: {
      icon: { fontSize: "2xl" },
    },
    lg: {
      icon: { fontSize: "3xl" },
    },
    xl: {
      icon: { fontSize: "5xl" },
    },
  },
} as ComponentMultiStyleConfig;
