import { userInteractivesDefaults } from "../defaultStylings";
import { ComponentSingleStyleConfig } from "@chakra-ui/react";

const config: ComponentSingleStyleConfig = {
  variants: {
    outline: (props) => ({
      field: userInteractivesDefaults(props),
      addon: userInteractivesDefaults(props),
    }),
  },
  defaultProps: {
    size: "sm",
  },
};

export default config;
