import type { ToastId, UseToastOptions } from "@chakra-ui/react";
import { useColorMode, useToast } from "@chakra-ui/react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";

import { useCookie } from "../../hooks/useCookie";

const prefersDarkQuery = window.matchMedia("(prefers-color-scheme:dark)");

const modes = ["system", "light", "dark"] as const;

type Mode = (typeof modes)[number];

export const AppearanceModeController = (): null => {
  const [appearanceMode, setAppearanceMode] = useAppearanceMode();
  const { colorMode, setColorMode } = useColorMode();
  const [initialized, setInitialized] = useState(false);

  // There are two components two this:
  // 1. the chakra-ui value that gets set in local storage via setColorMode
  // 2. the actual color mode that gets set in local storage via setAppearanceMode
  // This effect ensures that we're preferring the cookie value on initial load.
  useEffect(() => {
    if (initialized) return;

    const targetMode = getTargetColorMode(appearanceMode);
    if (appearanceMode === "system" && colorMode !== targetMode) {
      setColorMode(targetMode);
    }
    setInitialized(true);
  }, [appearanceMode, colorMode, initialized, setColorMode]);

  const toast = useToast();

  const toastIdRef = useRef<ToastId>();
  function createOrUpdateToast(opts: UseToastOptions) {
    if (toastIdRef.current) {
      toast.update(toastIdRef.current, opts);
      return;
    }
    toastIdRef.current = toast({
      ...opts,
      onCloseComplete: () => (toastIdRef.current = undefined),
    });
  }

  useHotkeys("mod+shift+m", () => {
    const nextColorMode =
      modes[(modes.indexOf(appearanceMode!) + 1) % modes.length];
    let resolvedColorMode;
    if (nextColorMode === "system") {
      resolvedColorMode = getTargetColorMode(nextColorMode);
    }
    setAppearanceMode(nextColorMode);
    createOrUpdateToast({
      status: "info",
      title: `Color mode changed: ${nextColorMode}${
        resolvedColorMode ? ` (${resolvedColorMode})` : ""
      }`,
    });
  });

  return null;
};

function getTargetColorMode(appearanceMode: string) {
  if (appearanceMode === "system") {
    return prefersDarkQuery.matches ? "dark" : "light";
  }

  return appearanceMode;
}

/**
 * Sets the `colorMode` for the chakra provider _(persists as a local storage entry)_ and sets a cookie for the given value under `appearance-mode`.
 */
export function useAppearanceMode() {
  const { setColorMode } = useColorMode();
  const [value, setAppearanceCookie] = useCookie(
    "appearance-mode",
    "system",
  ) as [Mode, (value: string) => void];

  const handler = useCallback(
    (value: Mode) => {
      setAppearanceCookie(value);

      setColorMode(getTargetColorMode(value));
    },
    [setAppearanceCookie, setColorMode],
  );

  return [value, handler] as const;
}
