import {
  CloseButton,
  Flex,
  Text,
  type UseToastOptions,
} from "@chakra-ui/react";
import { FaIcon, type FaIconProps } from "../FaIcon";
import {
  faCheckCircle,
  faCircleExclamation,
  faCircleInfo,
  faTriangleExclamation,
  faHourglass,
} from "@fortawesome/pro-regular-svg-icons";
import { isValidElement, type ComponentProps } from "react";

type RenderToast = Required<UseToastOptions>["render"];

export const renderStordToast: RenderToast = ({
  status,
  description,
  title,
  isClosable,
  onClose,
}) => {
  let accentColor: ComponentProps<typeof Flex>["bgColor"];
  let icon: FaIconProps["icon"] | null = null;

  switch (status) {
    case "loading":
      accentColor = "teal";
      icon = faHourglass;
      break;
    case "error":
      accentColor = "red";
      icon = faCircleExclamation;
      break;
    case "success":
      accentColor = "green";
      icon = faCheckCircle;
      break;
    case "warning":
      accentColor = "mango";
      icon = faTriangleExclamation;
      break;
    case "info":
      accentColor = "blue";
      icon = faCircleInfo;
      break;
  }

  return (
    <Flex
      p={0}
      justifyContent="space-between"
      borderRadius="0.25rem"
      boxShadow="md"
      minH="2.75rem"
      w="22.5rem"
      bgColor="white"
      color="gray.800"
      _dark={{ bgColor: "gray.800", color: "gray.200" }}
    >
      {icon ? (
        <Flex
          bgColor={`${accentColor}.400`}
          _dark={{ bgColor: `${accentColor}.600` }}
          w="2.25rem"
          borderRadius="0.25rem 0 0 0.25rem"
          justifyContent="center"
          alignItems="center"
        >
          <FaIcon color="white" icon={icon} margin="auto" />
        </Flex>
      ) : null}

      <Flex
        paddingLeft={4}
        paddingRight={2}
        py={3}
        flex={1}
        flexFlow="row wrap"
        position="relative"
      >
        {title && (
          <Text
            textStyle="label"
            w="100%"
            m="0"
            alignSelf={description ? "auto" : "center"}
          >
            {title}
          </Text>
        )}

        {isValidElement(description) ? (
          description
        ) : description ? (
          <Text textStyle="caption" marginTop="0.25rem">
            {description}
          </Text>
        ) : null}
      </Flex>

      {isClosable ? (
        <CloseButton onClick={onClose} size="sm" m="0.5rem" />
      ) : null}
    </Flex>
  );
};
