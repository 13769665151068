import { mode } from "@chakra-ui/theme-tools";
import { tableAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tableAnatomy.keys);

export default defineMultiStyleConfig({
  baseStyle: definePartsStyle({
    table: {
      borderCollapse: "separate",
      borderSpacing: "0px",
      fontSize: "sm",
      borderRadius: "base",
      fontVariantNumeric: "normal",
      borderColor: "gray.200",
      _dark: {
        borderColor: "gray.800",
      },
    },
    td: {},
  }),
  sizes: {
    xs: {
      td: {
        px: 2,
        py: 2,
      },
      th: {
        px: 2,
        py: 2,
      },
    },
    sm: {
      th: {
        py: 2,
      },
    },
  },
  variants: {
    simple: (props) => ({
      th: {
        fontSize: "xs",
        color: mode("gray.800", "white")(props),
        letterSpacing: 0,
        textTransform: "none",
        fontWeight: "semibold",
        whiteSpace: "nowrap",
        borderColor: mode("gray.200", "gray.800")(props),
      },
      td: {
        borderColor: mode("gray.200", "gray.800")(props),
      },
    }),
  },
});
