import { useMemo } from "react";

import type { FilterProps } from "../components/Filter";
import { useSearchParams } from "../hooks/useSearchParams";

export interface UseMultiFilterProps {
  filterKey: string;
  options: FilterProps["options"];
  scope: string;
  onSelect?: FilterProps["onSelect"];
  onClear?: FilterProps["onClear"];
}

export const useMultiFilter = ({
  filterKey,
  options,
  scope,
  onSelect: onSelectProp,
  onClear: onClearProp,
}: UseMultiFilterProps): FilterProps => {
  const [params, { replaceAll }] = useSearchParams();

  const currentFilterValue = params[filterKey];

  const filterScope = useMemo(() => {
    if (!currentFilterValue) return `${scope}`;
    if (Array.isArray(currentFilterValue)) {
      return currentFilterValue.length === 1
        ? (() => {
            const option = options?.find(
              ({ id }) => id === currentFilterValue[0],
            );

            return `${scope}: ${option?.shortLabel ?? option?.label}`;
          })()
        : `${scope}: ${currentFilterValue.length} selected`;
    }

    const option = options?.find(({ id }) => id === currentFilterValue);

    return `${scope}: ${option?.shortLabel ?? option?.label}`;
  }, [options, scope, currentFilterValue]);

  const filterOptions = useMemo(() => {
    return options?.map((option) => ({
      ...option,
      selected: currentFilterValue
        ? Array.isArray(currentFilterValue)
          ? currentFilterValue.includes(option.id)
          : option.id === currentFilterValue
        : false,
    }));
  }, [options, currentFilterValue]);

  return {
    type: "checkbox",
    onClear: (event) => {
      onClearProp?.(event);

      replaceAll({
        ...params,
        page: undefined,
        [filterKey]: undefined,
      });
    },
    filterScope,
    options: filterOptions,
    onSelect: (selection) => {
      onSelectProp?.(selection);

      replaceAll({
        ...params,
        [filterKey]: selection,
      });
    },
  };
};
