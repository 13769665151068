import { useCallback, useEffect, useRef, useState } from "react";

import { getCookie, setCookie } from "../helpers";

export function useCookie(name: string, initialValue: string) {
  const [value, setValue] = useState<string | null>(
    () => getCookie(name) ?? initialValue,
  );

  const skipEventRef = useRef(false);

  useEffect(() => {
    const handleEvent = () => {
      // If this is the same instance that triggered the event
      if (skipEventRef.current) {
        skipEventRef.current = false;

        return;
      }

      setValue(getCookie(name));
    };

    addEventListener(`use-cookie-${name}`, handleEvent);

    return () => {
      removeEventListener(`use-cookie-${name}`, handleEvent);
    };
  }, [name]);

  return [
    value,
    useCallback(
      (value: string) => {
        setValue(value);

        setCookie({
          name,
          value,
        });

        // We dispatch a custom event so every `useCookie` hook is notified
        skipEventRef.current = true;
        window.dispatchEvent(new Event(`use-cookie-${name}`));
      },
      [name],
    ),
  ] as const;
}
