import { useContext } from "react";

import type {
  BackorderStrategy,
  OrderRoutingStrategy,
} from "../../features/Automation/types";

import NetworkContext from "./NetworkContext";
import type { NetworkConfiguration } from "./queries";
import type { ForecastPeriod } from "../../features/InventoryPlanning/queries";

export default function useNetwork() {
  const value = useContext(NetworkContext);

  if (!value) {
    throw new Error(
      `${useNetwork.name} must be called within a NetworkProvider`,
    );
  }

  return value;
}

export const useNetworkId = () => {
  const network = useNetwork();
  return network.network_id;
};

/**
 * This hook is used to get all of the network configurations.
 */
const useNetworkConfigurations = () => {
  const network = useNetwork();
  return network.network_configurations ?? [];
};

const automationNetworkConfigurationFlags = [
  "enable_order_enrichment_automations",
  "enable_force_orders_automations",
  "enable_automations_cs_service_method",
  "enable_automations_cs_ship_option",
  "enable_third_party_billing_automations",
  "enable_automations_cs_bdot",
  "enable_ship_full_cheapest",
  "enable_orchestration",
  "enable_backorder_automations",
  "hold_order_automations_ui",
  "order_modification_automations",
  "desired_delivery_date", // Note: desired_delivery_date controls more than just automations
  "consolidate_fulfillments",
  "enable_shelf_life_compliance_automations",
] as const;

type NetworkConfigurationFlag =
  | (typeof automationNetworkConfigurationFlags)[number]
  | "enable_orchestration"
  | "wms_cancel_protocol"
  | "address_validation_exceptions"
  | "enable_minimum_address_requirement"
  | "work_orders"
  | "lot_approvals"
  | "bundle_availability_calculation"
  | "backorders"
  | "network_delivery_tracking"
  | "channel_reservations"
  | "verify_channel"
  | "product_listings"
  | "show_product_images"
  | "split_order"
  | "order_routing"
  | "unit_id_search"
  | "hold_order"
  | "demand_planning"
  | "demand_planning_forecast_period"
  | "demand_planning_ui"
  | "consumer_experience"
  | "manual_confirmations"
  | "manual_inventory_advice"
  | "shipment_protection_admin";

export type BackordersConfig = Omit<NetworkConfiguration, "metadata"> & {
  metadata: { strategy: BackorderStrategy | null };
};
export type SplitOrderConfig = Omit<NetworkConfiguration, "metadata"> & {
  metadata: { max_number_of_outbounds?: number };
};
export type HoldOrderConfig = Omit<NetworkConfiguration, "metadata"> & {
  metadata: {
    duration?: number | null;
    duration_unit?: "day" | "hour" | "minute" | "indefinite";
  };
};
export type OrderRoutingConfig = Omit<NetworkConfiguration, "metadata"> & {
  metadata: {
    strategy: OrderRoutingStrategy | null;
  };
};

export type ConsolidateFulfillmentsConfig = Omit<
  NetworkConfiguration,
  "metadata"
> & {
  metadata: {
    duration?: number;
    duration_unit?: "minute" | "hour" | "day";
  };
};
type DemandPlanningForecastPeriodConfig = Omit<
  NetworkConfiguration,
  "metadata"
> & {
  metadata: {
    forecast_period: ForecastPeriod;
  };
};

export type NetworkConfigurationObjectType<T> = T extends "backorders"
  ? BackordersConfig
  : T extends "split_order"
  ? SplitOrderConfig
  : T extends "hold_order"
  ? HoldOrderConfig
  : T extends "order_routing"
  ? OrderRoutingConfig
  : T extends "consolidate_fulfillments"
  ? ConsolidateFulfillmentsConfig
  : T extends "demand_planning_forecast_period"
  ? DemandPlanningForecastPeriodConfig
  : NetworkConfiguration;

/**
 * This hook is used to get a network configuration object.
 * Useful when you need to access the metadata.
 */
export const useNetworkConfiguration = <T extends NetworkConfigurationFlag>(
  flagName: T,
): NetworkConfigurationObjectType<T> => {
  const networkConfigs = useNetworkConfigurations();
  const result = networkConfigs.find((config) => config.name === flagName);

  return result as NetworkConfigurationObjectType<T>;
};

/**
 * This hook is used to get the value of a network configuration flag.
 * Returns the enabled value or the default if not found
 */
export const useNetworkConfigurationFlag = (
  flagName: NetworkConfigurationFlag,
  defaultValue = false,
) => {
  const networkConfigs = useNetworkConfigurations();
  return (
    networkConfigs.find((config) => config.name === flagName)?.enabled ??
    defaultValue
  );
};

export const useNetworkConfigurationFlags = (
  flagNames: NetworkConfigurationFlag[],
  defaultValue = false,
) => {
  const networkConfigs = useNetworkConfigurations();
  const result = {} as Record<NetworkConfigurationFlag, boolean>;

  for (const flagName of flagNames) {
    result[flagName] =
      networkConfigs.find((config) => config.name === flagName)?.enabled ??
      defaultValue;
  }

  return result;
};

export const useAutomationNetworkConfigurationFlags = (): Record<
  (typeof automationNetworkConfigurationFlags)[number],
  boolean
> => {
  const networkConfigs = useNetworkConfigurations();
  const result = {} as Record<NetworkConfigurationFlag, boolean>;

  for (const flagName of automationNetworkConfigurationFlags) {
    result[flagName] =
      networkConfigs.find((config) => config.name === flagName)?.enabled ??
      false;
  }

  return result;
};
