import type { FaIconProps } from "@stordco/fe-components";

export const faGiftAddLight: FaIconProps["icon"] = {
  prefix: "fal",
  iconName: "gift-add" as FaIconProps["icon"]["iconName"],
  icon: [
    640,
    512,
    [],
    "",
    "M365.1 32c-17.6 0-33.9 9-43.2 23.9c-15 24-30 48.1-45 72.1c3.7 0 7.4 0 11.1 0c26.7 0 53.3 0 80 0c26.5 0 48-21.5 48-48s-21.5-48-48-48c-1 0-1.9 0-2.9 0zm-130 96l-45-72.1C180.8 41 164.5 32 146.9 32c-1 0-1.9 0-2.9 0c-26.5 0-48 21.5-48 48s21.5 48 48 48l80 0c3.7 0 7.4 0 11.1 0zM256 101l38.8-62C310 14.8 336.5 0 365.1 0c1 0 1.9 0 2.9 0c44.2 0 80 35.8 80 80c0 18-6 34.6-16 48c10.7 0 21.3 0 32 0c26.5 0 48 21.5 48 48c0 5.6 0 11.1 0 16.7c-5.3-.5-10.6-.7-16-.7s-10.7 .2-16 .7c0-5.6 0-11.1 0-16.7c0-8.8-7.2-16-16-16c-32 0-64 0-96 0c-26.7 0-53.3 0-80 0c-5.3 0-10.7 0-16 0c0 32 0 64 0 96c29.4 0 58.8 0 88.2 0c-8.1 9.8-15.2 20.6-21 32c-22.4 0-44.8 0-67.2 0c0 64 0 128 0 192c29.4 0 58.9 0 88.3 0c10 12.1 21.6 22.9 34.5 32c-99.6 0-199.2 0-298.8 0c-35.3 0-64-28.7-64-64c0-53.3 0-106.7 0-160c0-.9 0-1.8 0-2.7C13.4 278.7 0 260.9 0 240c0-21.3 0-42.7 0-64c0-26.5 21.5-48 48-48c10.7 0 21.3 0 32 0C70 114.6 64 98 64 80C64 35.8 99.8 0 144 0c1 0 1.9 0 2.9 0c28.6 0 55.2 14.7 70.3 39c12.9 20.7 25.9 41.3 38.8 62zM144 160l-96 0c-8.8 0-16 7.2-16 16c0 21.3 0 42.7 0 64c0 8.8 7.2 16 16 16c5.3 0 10.7 0 16 0c58.7 0 117.3 0 176 0c0-32 0-64 0-96l-16 0c-26.7 0-53.3 0-80 0zm96 128L64 288c0 53.3 0 106.7 0 160c0 17.7 14.3 32 32 32c48 0 96 0 144 0c0-64 0-128 0-192zm256 0c8.8 0 16 7.2 16 16c0 16 0 32 0 48c16 0 32 0 48 0c8.8 0 16 7.2 16 16s-7.2 16-16 16c-16 0-32 0-48 0c0 16 0 32 0 48c0 8.8-7.2 16-16 16s-16-7.2-16-16c0-16 0-32 0-48c-16 0-32 0-48 0c-8.8 0-16-7.2-16-16s7.2-16 16-16c16 0 32 0 48 0c0-16 0-32 0-48c0-8.8 7.2-16 16-16zM352 368c0-79.5 64.5-144 144-144s144 64.5 144 144s-64.5 144-144 144s-144-64.5-144-144zM496 480c61.9 0 112-50.1 112-112s-50.1-112-112-112s-112 50.1-112 112s50.1 112 112 112z",
  ],
};

export const faGiftAddRegular: FaIconProps["icon"] = {
  prefix: "far",
  iconName: "gift-add" as FaIconProps["icon"]["iconName"],
  icon: [
    640,
    512,
    [],
    "",
    "M231.9 44.5C215.7 16.9 186.1 0 154.2 0c-.7 0-1.5 0-2.2 0C103.4 0 64 39.4 64 88c0 14.4 3.5 28 9.6 40c-8.5 0-17.1 0-25.6 0c-26.5 0-48 21.5-48 48c0 21.3 0 42.7 0 64c0 20.9 13.4 38.7 32 45.3c0 .9 0 1.8 0 2.7c0 53.3 0 106.7 0 160c0 35.3 28.7 64 64 64c99.6 0 199.2 0 298.8 0c-18.3-12.9-34-29.2-46.3-48c-20.2 0-40.3 0-60.5 0c0-58.7 0-117.3 0-176c17.1 0 34.1 0 51.2 0c9.2-18 21.4-34.2 36-48c-29.1 0-58.1 0-87.2 0c0-21.3 0-42.7 0-64c24 0 48 0 72 0c34.7 0 69.3 0 104 0c0 6.3 0 12.6 0 18.9c10.4-1.9 21.1-2.9 32-2.9c5.4 0 10.7 .2 16 .7c0-5.6 0-11.1 0-16.7c0-26.5-21.5-48-48-48c-8.5 0-17.1 0-25.6 0c6.1-12 9.6-25.6 9.6-40c0-48.6-39.4-88-88-88c-.7 0-1.5 0-2.2 0c-31.9 0-61.5 16.9-77.7 44.4c-8 13.7-16.1 27.4-24.1 41.1c-8-13.7-16.1-27.3-24.1-41zM224 176l0 64c-48 0-96 0-144 0c-10.7 0-21.3 0-32 0c0-21.3 0-42.7 0-64l104 0c24 0 48 0 72 0zm0 112l0 176c-42.7 0-85.3 0-128 0c-8.8 0-16-7.2-16-16c0-53.3 0-106.7 0-160c48 0 96 0 144 0zM360 128l-72 0-1.3 0c11.6-19.7 23.2-39.5 34.8-59.2C329.1 55.9 342.9 48 357.8 48c.7 0 1.5 0 2.2 0c22.1 0 40 17.9 40 40s-17.9 40-40 40zm-136 0l-72 0c-22.1 0-40-17.9-40-40s17.9-40 40-40c.7 0 1.5 0 2.2 0c14.9 0 28.8 7.9 36.3 20.8c11.6 19.7 23.2 39.5 34.8 59.2c-.4 0-.9 0-1.3 0zM640 368c0 79.5-64.5 144-144 144s-144-64.5-144-144s64.5-144 144-144s144 64.5 144 144zM480 304l0 48c-16 0-32 0-48 0c-8.8 0-16 7.2-16 16s7.2 16 16 16c16 0 32 0 48 0l0 48c0 8.8 7.2 16 16 16s16-7.2 16-16c0-16 0-32 0-48c16 0 32 0 48 0c8.8 0 16-7.2 16-16s-7.2-16-16-16c-16 0-32 0-48 0c0-16 0-32 0-48c0-8.8-7.2-16-16-16s-16 7.2-16 16z",
  ],
};
