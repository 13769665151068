import {
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Kbd,
  useMergeRefs,
} from "@chakra-ui/react";
import type { InputProps } from "@chakra-ui/react";
import { useHotkeys } from "react-hotkeys-hook";
import { faMagnifyingGlass, faXmark } from "@fortawesome/pro-regular-svg-icons";
import { forwardRef, useRef } from "react";

import { FaIcon } from "../FaIcon";
import { noop } from "../../utils";
import { useDatadogRUM } from "../Datadog";

export interface ChakraSearchProps
  extends Omit<InputProps, "onSubmit" | "defaultValue" | "onChange"> {
  icon?: JSX.Element;
  value: string;
  onBlur?: () => void;
  onChange?: (value: string) => void;
  onClear?: () => void;
  onSearch?: (value: string) => void;
  isClearable?: boolean;
  focusOnClear?: boolean;
  hideShortcutHint?: boolean;
}

export const ChakraSearch = forwardRef<HTMLInputElement, ChakraSearchProps>(
  function ChakraSearch(
    {
      icon = <FaIcon icon={faMagnifyingGlass} />,
      onChange = noop,
      value = "",
      onSearch,
      onBlur,
      onClear,
      isClearable = true,
      focusOnClear = false,
      hideShortcutHint = false,
      ...inputProps
    },
    ref,
  ) {
    const datadogRum = useDatadogRUM();
    const internalRef = useRef<HTMLInputElement>(null);
    const refs = useMergeRefs<HTMLInputElement>(internalRef, ref);

    useHotkeys("/", (e) => {
      e.preventDefault();
      datadogRum?.addAction("hotkey:focus_search");
      internalRef.current?.focus();
    });

    return (
      <form
        name="search-form"
        onSubmit={(e) => {
          e.preventDefault();
          onSearch?.(value);
        }}
      >
        <InputGroup>
          {icon && <InputLeftElement>{icon}</InputLeftElement>}
          <Input
            ref={refs}
            type="text"
            name="search"
            aria-label="search"
            onChange={({ target: { value } }) => onChange?.(value)}
            onBlur={onBlur}
            value={value}
            {...inputProps}
          />

          {isClearable && value.length ? (
            <InputRightElement zIndex={1}>
              <IconButton
                aria-label="Clear search"
                size="xs"
                variant="unstyled"
                onClick={() => {
                  onChange?.("");
                  onClear?.();

                  if (focusOnClear) {
                    internalRef?.current?.focus();
                  }
                }}
                icon={<FaIcon icon={faXmark} />}
              />
            </InputRightElement>
          ) : !hideShortcutHint ? (
            <InputRightElement zIndex={1}>
              <Kbd>/</Kbd>
            </InputRightElement>
          ) : null}
        </InputGroup>
      </form>
    );
  },
);
