import type { ComponentSingleStyleConfig } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

export const Form = {
  baseStyle: (props) => ({
    helperText: {
      mt: 1,
      fontSize: "xs",
      color: mode("gray.700", "gray.300")(props),
      lineHeight: 4,
    },
  }),
} as ComponentSingleStyleConfig;

export const FormError = {
  baseStyle: {
    text: {
      mt: 1,
    },
  },
};

export const FormLabel = {
  baseStyle: (props) => ({
    color: mode("gray.900", "gray.100")(props),
    mb: 1,
    fontSize: "sm",
    fontWeight: "semibold",
    lineHeight: 5,
    _disabled: { opacity: 1 },
  }),
} as ComponentSingleStyleConfig;
