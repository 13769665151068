import type { ReactNode } from "react";
import { Box, Flex, Text } from "@chakra-ui/react";

import { useIsDesktop } from "../../utils";
import { Navbar, Sidebar, useNavigation } from "../Navigation";

import "./toast-adjustments.css";
import { DatadogErrorBoundary } from "../Datadog";
import { ErrorBoundaryPage } from "../ErrorPage";
import { useLocation } from "react-router-dom";

export const LayoutWrapper = ({ children }: { children: ReactNode }) => {
  const isDesktop = useIsDesktop();
  const { navigationState } = useNavigation();
  const location = useLocation();

  return (
    <>
      {window.location.host.includes(".staging.") && (
        <Box
          textAlign="center"
          bgColor="volt"
          position="absolute"
          top={0}
          insetX={0}
          zIndex={9999}
        >
          <Text color="black" textStyle="tooltip">
            STAGING
          </Text>
        </Box>
      )}

      <Flex
        direction={{ base: "column", lg: "row" }}
        height="100vh"
        bg="bg-canvas"
        overflowY="hidden"
      >
        {isDesktop ? <Sidebar /> : <Navbar />}

        <Box
          flex="1"
          overflowY="auto"
          position="relative"
          transition="margin-left 0.2s ease-out"
          px={{ base: 4, sm: 6, lg: 12, xl: 16 }}
          py={6}
          {...(isDesktop && {
            marginLeft:
              navigationState === "expanded"
                ? "var(--sidebar-width-expanded)"
                : "var(--sidebar-width-collapsed)",
          })}
        >
          <DatadogErrorBoundary
            fallback={<ErrorBoundaryPage outsideNavigation={false} />}
            resetKeys={[location.key]}
          >
            {children}
          </DatadogErrorBoundary>
        </Box>
      </Flex>
    </>
  );
};
