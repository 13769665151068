import type { ComponentSingleStyleConfig } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

import { pickSpecificColor } from "../utils";

export default {
  baseStyle: (props) => {
    const { color, colorScheme, size } = props;

    return {
      color:
        color ??
        mode(
          pickSpecificColor(colorScheme, {
            blue: 500,
            red: 600,
            green: 600,
            yellow: 600,
            mango: 600,
            gray: 800,
          }),
          `${colorScheme}.200`,
        )(props),
      fontSize: { xs: "xs", sm: "md", md: "2xl", lg: 32, xl: "5xl" }[
        size as string
      ],
    };
  },
} as ComponentSingleStyleConfig;
