import { Button } from "@chakra-ui/react";
import { faArrowLeft, faHouse } from "@fortawesome/pro-regular-svg-icons";
import { Link, useNavigate } from "react-router-dom";

import { FaIcon } from "../FaIcon";

import { ErrorPage } from "./ErrorPage";

export const ForbiddenErrorPage = ({
  homeUrl,
  outsideNavigation = false,
}: {
  homeUrl?: string;
  outsideNavigation?: boolean;
}) => {
  const navigate = useNavigate();

  return (
    <ErrorPage
      badgeText="403 forbidden"
      title="Authorized cargo only."
      description="It seems you don’t have the necessary permissions to view this page."
      secondaryButton={
        <Button
          variant="outline"
          colorScheme="gray"
          leftIcon={<FaIcon icon={faArrowLeft} />}
          onClick={() => navigate(-1)}
        >
          Go back
        </Button>
      }
      primaryButton={
        homeUrl ? (
          <Button as={Link} leftIcon={<FaIcon icon={faHouse} />} to={homeUrl}>
            Return home
          </Button>
        ) : null
      }
      outsideNavigation={outsideNavigation}
    />
  );
};
