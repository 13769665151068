import { getColor, mode, transparentize } from "@chakra-ui/theme-tools";
import type { StyleFunctionProps } from "@chakra-ui/theme-tools";
import type { ComponentMultiStyleConfig } from "@chakra-ui/react";

const getBg = (props: StyleFunctionProps) => {
  const { theme, colorScheme } = props;

  const lightBg = getColor(theme, `${colorScheme}.50`, colorScheme);
  const darkBg = transparentize(`${colorScheme}.200`, 0.16)(theme);
  return mode(lightBg, darkBg)(props);
};

export default {
  parts: ["container", "title", "description", "icon"],
  baseStyle: (props) => ({
    title: {
      color: mode("gray.800", "gray.200")(props),
      marginEnd: 1,
      textStyle: "label",
      lineHeight: 5,
    },
    description: {
      color: mode("gray.800", "gray.200")(props),
      textStyle: "paragraph",
      lineHeight: 5,
    },
    container: {
      alignItems: "flex-start",
      borderRadius: "base",
      paddingEnd: 2,
      paddingY: 2,
    },
    icon: {
      marginEnd: 3,
      boxSize: 4,
      w: 4,
      h: 4,
    },
  }),
  variants: {
    subtle: (props: StyleFunctionProps) => ({
      container: {
        bg: getBg(props),
      },
      icon: {
        color: getColor(
          props.theme,
          mode(`${props.colorScheme}.700`, `${props.colorScheme}.200`)(props),
          props.colorScheme,
        ),
      },
    }),
  },
} as ComponentMultiStyleConfig;
