import type { NumberInputProps as ChakraNumberInputProps } from "@chakra-ui/react";
import {
  FormControl,
  InputGroup,
  InputLeftAddon,
  FormErrorMessage,
  FormLabel,
  VisuallyHidden,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputStepper,
  NumberInputField,
} from "@chakra-ui/react";
import { faAngleUp, faAngleDown } from "@fortawesome/pro-regular-svg-icons";
import type {
  FieldPath,
  FieldValues,
  UseControllerProps,
} from "react-hook-form";
import { useController } from "react-hook-form";

import { ConditionalWrapper } from "../ConditionalWrapper";
import { FaIcon } from "../FaIcon";
import { getFieldErrorMessage } from "./utils";

export function RHFCurrencyInput<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  isDisabled,
  label,
  hideLabel = false,
  hideErrorMessage,
  isRequired,
  defaultValue,
  rules,
  ...inputProps
}: {
  isDisabled?: boolean;
  label: string;
  hideLabel?: boolean;
  hideErrorMessage?: boolean;
  isRequired?: boolean;
} & UseControllerProps<TFieldValues, TName> &
  ChakraNumberInputProps) {
  const { field, fieldState, formState } = useController({
    control,
    name,
    defaultValue: defaultValue ?? ("" as any),
    rules: {
      min: 0,
      pattern: {
        value: /^\d*(\.\d{1,2})?$/,
        message: "At most 2 decimals",
      },
      ...rules,
    },
  });
  const errorMessage = getFieldErrorMessage(formState, field.name);

  return (
    <FormControl
      isDisabled={isDisabled}
      isRequired={isRequired}
      id={field.name}
      isInvalid={!!errorMessage}
    >
      <ConditionalWrapper
        condition={hideLabel}
        wrapper={(children) => <VisuallyHidden>{children}</VisuallyHidden>}
      >
        <FormLabel htmlFor={field.name}>{label}</FormLabel>
      </ConditionalWrapper>

      <InputGroup>
        <InputLeftAddon userSelect="none">$</InputLeftAddon>
        <NumberInput precision={2} {...field} {...inputProps}>
          <NumberInputField borderLeftRadius="none" />
          <NumberInputStepper>
            <NumberIncrementStepper>
              <FaIcon icon={faAngleUp} size="xs" />
            </NumberIncrementStepper>
            <NumberDecrementStepper>
              <FaIcon icon={faAngleDown} size="xs" />
            </NumberDecrementStepper>
          </NumberInputStepper>
        </NumberInput>
      </InputGroup>

      {hideErrorMessage ? null : (
        <FormErrorMessage>
          {errorMessage ??
            (() => {
              switch (fieldState.error?.type) {
                case "min":
                  return ">= 0";
                case "required":
                  return "Required";
                case "pattern":
                  return "Max 2 decimals";
              }
            })()}
        </FormErrorMessage>
      )}
    </FormControl>
  );
}
