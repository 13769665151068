import type { ReactNode } from "react";
import type { HelmetProps } from "react-helmet-async";
import { Helmet } from "react-helmet-async";

/**
 * This is just a wrapper around react-helmet-async's Helmet component that is primarily useful
 * for setting the title of the page.
 */
export const SEO = (props: HelmetProps & { children?: ReactNode }) => (
  <Helmet {...props} />
);
