import { useMemo } from "react";
import debounce from "lodash/debounce";

import type { AsyncSelectProps } from "@stordco/fe-components";
import { AsyncSelect } from "@stordco/fe-components";

import { type SimpleNetwork, useFetchNetworks } from "../../hooks/useNetworks";

export function NetworkSelect<IsMulti extends boolean = false>({
  ...props
}: Omit<
  AsyncSelectProps<SimpleNetwork, IsMulti>,
  "loadOptions" | "getOptionLabel" | "getOptionValue"
>) {
  const fetchNetworks = useFetchNetworks();

  const loadShipperOptions = useMemo(
    () =>
      debounce(
        (inputValue: string, callback: (networks: SimpleNetwork[]) => void) => {
          fetchNetworks({
            params: {
              "filter[name]": inputValue,
            },
          }).then((response) => {
            callback(response.data);
          });
        },
        250,
      ),
    [fetchNetworks],
  );

  return (
    <AsyncSelect
      loadOptions={loadShipperOptions}
      getOptionLabel={(network) => network.name}
      getOptionValue={(network) => network.network_id}
      {...props}
    />
  );
}
