import { Center } from "@chakra-ui/react";
import type { CenterProps } from "@chakra-ui/react";

import { Loader } from "../Loader";

export const PageLoader = (props: CenterProps) => {
  return (
    <Center
      {...{
        position: "fixed",
        w: "100vw",
        h: "100vh",
        top: 0,
        left: 0,
        pointerEvents: "none",
        ...props,
      }}
    >
      <Loader size="xl" data-testid="page-loader" />
    </Center>
  );
};
