import type { FlexProps } from "@chakra-ui/react";
import { Box, Flex, HStack, Avatar, Text } from "@chakra-ui/react";

import { useNavigation } from "./provider";
import type { ElementRef } from "react";
import { forwardRef } from "react";

export const AccountSwitcherButton = forwardRef<
  ElementRef<"button">,
  FlexProps & { isCollapsed: boolean }
>(function AccountSwitcherButton({ isCollapsed, ...props }, ref) {
  const { companyName, userMenu } = useNavigation();

  return (
    <Flex
      ref={ref}
      as="button"
      {...props}
      w="full"
      display="flex"
      alignItems="center"
      rounded="base"
      px={0}
      py={1}
      paddingInline={1}
      fontSize="sm"
      userSelect="none"
      cursor="pointer"
      outline={0}
      transition="all 0.2s"
      _focus={{ shadow: "outline" }}
      color="whiteAlpha.700"
      _hover={{
        color: "white",
        backgroundColor: "whiteAlpha.200",
      }}
    >
      <HStack flex="1" spacing="3">
        <Avatar
          size="sm"
          name={userMenu.userDisplayName}
          color="white"
          backgroundColor="whiteAlpha.400"
        />
        <Box
          textAlign="start"
          opacity={isCollapsed ? 0 : 1}
          transition="opacity 0.2s ease-out"
        >
          <Text
            fontWeight="semibold"
            lineHeight={5}
            noOfLines={1}
            fontSize="sm"
          >
            {userMenu.userDisplayName}
          </Text>

          <Text fontSize="sm" lineHeight={5} noOfLines={1} fontWeight="normal">
            {companyName}
          </Text>
        </Box>
      </HStack>
    </Flex>
  );
});
