/**
 * Generated by @openapi-codegen
 *
 * @version 1.48.0
 */
import * as reactQuery from "@tanstack/react-query";
import { useCloudContext, CloudContext } from "./cloudContext";
import type * as Fetcher from "./cloudFetcher";
import { cloudFetch } from "./cloudFetcher";
import type * as Schemas from "./cloudSchemas";

export type AuthCheckError = Fetcher.ErrorWrapper<undefined>;

export type AuthCheckResponse = {
  data: Schemas.CheckResult;
};

export type AuthCheckVariables = CloudContext["fetcherOptions"];

export const fetchAuthCheck = (
  variables: AuthCheckVariables,
  signal?: AbortSignal
) =>
  cloudFetch<AuthCheckResponse, AuthCheckError, undefined, {}, {}, {}>({
    url: "/v1/auth/check",
    method: "post",
    ...variables,
    signal,
  });

export const useAuthCheck = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      AuthCheckResponse,
      AuthCheckError,
      AuthCheckVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCloudContext();
  return reactQuery.useMutation<
    AuthCheckResponse,
    AuthCheckError,
    AuthCheckVariables
  >({
    mutationFn: (variables: AuthCheckVariables) =>
      fetchAuthCheck({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type AuthPermissionsPathParams = {
  /**
   * An App ID or alias that the token's permissions will be scoped to
   */
  app: string;
  /**
   * An Organization ID or alias that the token's permissions will be scoped to
   */
  organization: string;
};

export type AuthPermissionsError = Fetcher.ErrorWrapper<undefined>;

export type AuthPermissionsResponse = {
  data: Schemas.AvailablePermissions;
};

export type AuthPermissionsVariables = {
  pathParams: AuthPermissionsPathParams;
} & CloudContext["fetcherOptions"];

export const fetchAuthPermissions = (
  variables: AuthPermissionsVariables,
  signal?: AbortSignal
) =>
  cloudFetch<
    AuthPermissionsResponse,
    AuthPermissionsError,
    undefined,
    {},
    {},
    AuthPermissionsPathParams
  >({
    url: "/v1/auth/organizations/{organization}/apps/{app}/permissions",
    method: "get",
    ...variables,
    signal,
  });

export const useAuthPermissions = <TData = AuthPermissionsResponse>(
  variables: AuthPermissionsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      AuthPermissionsResponse,
      AuthPermissionsError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCloudContext(options);
  return reactQuery.useQuery<
    AuthPermissionsResponse,
    AuthPermissionsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/v1/auth/organizations/{organization}/apps/{app}/permissions",
      operationId: "authPermissions",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchAuthPermissions({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type AuthTokenError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Schemas.ErrorResponses;
    }
  | {
      status: 403;
      payload: Schemas.ErrorResponses;
    }
>;

export type AuthTokenResponse = {
  data: Schemas.TokenResult;
};

export type AuthTokenRequestBody = {
  data: Schemas.TokenRequest;
};

export type AuthTokenVariables = {
  body: AuthTokenRequestBody;
} & CloudContext["fetcherOptions"];

export const fetchAuthToken = (
  variables: AuthTokenVariables,
  signal?: AbortSignal
) =>
  cloudFetch<
    AuthTokenResponse,
    AuthTokenError,
    AuthTokenRequestBody,
    {},
    {},
    {}
  >({ url: "/v1/auth/token", method: "post", ...variables, signal });

export const useAuthToken = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      AuthTokenResponse,
      AuthTokenError,
      AuthTokenVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCloudContext();
  return reactQuery.useMutation<
    AuthTokenResponse,
    AuthTokenError,
    AuthTokenVariables
  >({
    mutationFn: (variables: AuthTokenVariables) =>
      fetchAuthToken({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type AuthValidateError = Fetcher.ErrorWrapper<undefined>;

export type AuthValidateVariables = CloudContext["fetcherOptions"];

export const fetchAuthValidate = (
  variables: AuthValidateVariables,
  signal?: AbortSignal
) =>
  cloudFetch<undefined, AuthValidateError, undefined, {}, {}, {}>({
    url: "/v1/auth/validate",
    method: "get",
    ...variables,
    signal,
  });

export const useAuthValidate = <TData = undefined>(
  variables: AuthValidateVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, AuthValidateError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCloudContext(options);
  return reactQuery.useQuery<undefined, AuthValidateError, TData>({
    queryKey: queryKeyFn({
      path: "/v1/auth/validate",
      operationId: "authValidate",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchAuthValidate({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type MeQueryParams = {
  /**
   * Sort by a given property. Accepts fields: inserted_at, updated_at, app.alias, realm.resource_path, role. Sorts should be passed in the format of `&sort=property:asc,property2:desc`
   */
  sort?: string;
  /**
   * Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2`
   */
  filter?: {
    app_aliases?: string[];
  };
};

export type MeError = Fetcher.ErrorWrapper<undefined>;

export type MeResponse = {
  data: Schemas.Me;
};

export type MeVariables = {
  queryParams?: MeQueryParams;
} & CloudContext["fetcherOptions"];

export const fetchMe = (variables: MeVariables, signal?: AbortSignal) =>
  cloudFetch<MeResponse, MeError, undefined, {}, MeQueryParams, {}>({
    url: "/v1/me",
    method: "get",
    ...variables,
    signal,
  });

export const useMe = <TData = MeResponse>(
  variables: MeVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<MeResponse, MeError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCloudContext(options);
  return reactQuery.useQuery<MeResponse, MeError, TData>({
    queryKey: queryKeyFn({ path: "/v1/me", operationId: "me", variables }),
    queryFn: ({ signal }) =>
      fetchMe({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type CreateMeError = Fetcher.ErrorWrapper<{
  status: 422;
  payload: Schemas.JsonErrorResponse;
}>;

export type CreateMeResponse = {
  data: Schemas.Me;
};

export type CreateMeRequestBody = {
  data: Schemas.CreateMe;
};

export type CreateMeVariables = {
  body: CreateMeRequestBody;
} & CloudContext["fetcherOptions"];

export const fetchCreateMe = (
  variables: CreateMeVariables,
  signal?: AbortSignal
) =>
  cloudFetch<CreateMeResponse, CreateMeError, CreateMeRequestBody, {}, {}, {}>({
    url: "/v1/me",
    method: "post",
    ...variables,
    signal,
  });

export const useCreateMe = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      CreateMeResponse,
      CreateMeError,
      CreateMeVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCloudContext();
  return reactQuery.useMutation<
    CreateMeResponse,
    CreateMeError,
    CreateMeVariables
  >({
    mutationFn: (variables: CreateMeVariables) =>
      fetchCreateMe({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type UpdateMeError = Fetcher.ErrorWrapper<{
  status: 422;
  payload: Schemas.JsonErrorResponse;
}>;

export type UpdateMeResponse = {
  data: Schemas.Me;
};

export type UpdateMeRequestBody = {
  data: Schemas.UpdateMe;
};

export type UpdateMeVariables = {
  body: UpdateMeRequestBody;
} & CloudContext["fetcherOptions"];

export const fetchUpdateMe = (
  variables: UpdateMeVariables,
  signal?: AbortSignal
) =>
  cloudFetch<UpdateMeResponse, UpdateMeError, UpdateMeRequestBody, {}, {}, {}>({
    url: "/v1/me",
    method: "put",
    ...variables,
    signal,
  });

export const useUpdateMe = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      UpdateMeResponse,
      UpdateMeError,
      UpdateMeVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCloudContext();
  return reactQuery.useMutation<
    UpdateMeResponse,
    UpdateMeError,
    UpdateMeVariables
  >({
    mutationFn: (variables: UpdateMeVariables) =>
      fetchUpdateMe({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type MyOrganizationPathParams = {
  /**
   * The organization id or alias
   */
  organizationId: string;
};

export type MyOrganizationError = Fetcher.ErrorWrapper<undefined>;

export type MyOrganizationResponse = {
  data: Schemas.MyOrganization;
};

export type MyOrganizationVariables = {
  pathParams: MyOrganizationPathParams;
} & CloudContext["fetcherOptions"];

export const fetchMyOrganization = (
  variables: MyOrganizationVariables,
  signal?: AbortSignal
) =>
  cloudFetch<
    MyOrganizationResponse,
    MyOrganizationError,
    undefined,
    {},
    {},
    MyOrganizationPathParams
  >({
    url: "/v1/me/organizations/{organizationId}",
    method: "get",
    ...variables,
    signal,
  });

export const useMyOrganization = <TData = MyOrganizationResponse>(
  variables: MyOrganizationVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      MyOrganizationResponse,
      MyOrganizationError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCloudContext(options);
  return reactQuery.useQuery<
    MyOrganizationResponse,
    MyOrganizationError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/v1/me/organizations/{organizationId}",
      operationId: "myOrganization",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchMyOrganization({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type MyRolesQueryParams = {
  /**
   * Sort by a given property. Accepts fields: . Sorts should be passed in the format of `&sort=property:asc,property2:desc`
   */
  sort?: string;
  /**
   * Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2`
   */
  filter?: {
    app_aliases?: string[];
  };
};

export type MyRolesError = Fetcher.ErrorWrapper<undefined>;

export type MyRolesResponse = {
  data: Schemas.UserRole[];
  meta: Schemas.PaginationMetadata;
};

export type MyRolesVariables = {
  queryParams?: MyRolesQueryParams;
} & CloudContext["fetcherOptions"];

export const fetchMyRoles = (
  variables: MyRolesVariables,
  signal?: AbortSignal
) =>
  cloudFetch<
    MyRolesResponse,
    MyRolesError,
    undefined,
    {},
    MyRolesQueryParams,
    {}
  >({ url: "/v1/me/roles", method: "get", ...variables, signal });

export const useMyRoles = <TData = MyRolesResponse>(
  variables: MyRolesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<MyRolesResponse, MyRolesError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCloudContext(options);
  return reactQuery.useQuery<MyRolesResponse, MyRolesError, TData>({
    queryKey: queryKeyFn({
      path: "/v1/me/roles",
      operationId: "myRoles",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchMyRoles({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type OrganizationInvitationsIndexQueryParams = {
  /**
   * Page Number
   */
  page?: number;
  /**
   * The number of items to return
   */
  page_size?: number;
  /**
   * Sort by a given property. Accepts fields: alias, name, inserted_at, updated_at. Sorts should be passed in the format of `&sort=property:asc,property2:desc`
   */
  sort?: string;
  /**
   * Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2`
   */
  filter?: {
    alias?: string;
    name?: string;
  };
};

export type OrganizationInvitationsIndexError = Fetcher.ErrorWrapper<{
  status: 422;
  payload: Schemas.JsonErrorResponse;
}>;

export type OrganizationInvitationsIndexResponse = {
  data: Schemas.Organization[];
  meta: Schemas.PaginationMetadata;
};

export type OrganizationInvitationsIndexVariables = {
  queryParams?: OrganizationInvitationsIndexQueryParams;
} & CloudContext["fetcherOptions"];

export const fetchOrganizationInvitationsIndex = (
  variables: OrganizationInvitationsIndexVariables,
  signal?: AbortSignal
) =>
  cloudFetch<
    OrganizationInvitationsIndexResponse,
    OrganizationInvitationsIndexError,
    undefined,
    {},
    OrganizationInvitationsIndexQueryParams,
    {}
  >({
    url: "/v1/organization_invitations",
    method: "get",
    ...variables,
    signal,
  });

export const useOrganizationInvitationsIndex = <
  TData = OrganizationInvitationsIndexResponse
>(
  variables: OrganizationInvitationsIndexVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      OrganizationInvitationsIndexResponse,
      OrganizationInvitationsIndexError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCloudContext(options);
  return reactQuery.useQuery<
    OrganizationInvitationsIndexResponse,
    OrganizationInvitationsIndexError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/v1/organization_invitations",
      operationId: "organizationInvitationsIndex",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchOrganizationInvitationsIndex(
        { ...fetcherOptions, ...variables },
        signal
      ),
    ...options,
    ...queryOptions,
  });
};

export type OrganizationInvitationsAcknowledgePathParams = {
  /**
   * The organization id or alias
   */
  organizationId: string;
  /**
   * The action to perform on the invitation, either accept or reject.
   */
  action: "accept" | "reject";
};

export type OrganizationInvitationsAcknowledgeError = Fetcher.ErrorWrapper<{
  status: 422;
  payload: Schemas.JsonErrorResponse;
}>;

export type OrganizationInvitationsAcknowledgeVariables = {
  pathParams: OrganizationInvitationsAcknowledgePathParams;
} & CloudContext["fetcherOptions"];

export const fetchOrganizationInvitationsAcknowledge = (
  variables: OrganizationInvitationsAcknowledgeVariables,
  signal?: AbortSignal
) =>
  cloudFetch<
    undefined,
    OrganizationInvitationsAcknowledgeError,
    undefined,
    {},
    {},
    OrganizationInvitationsAcknowledgePathParams
  >({
    url: "/v1/organization_invitations/{organizationId}/{action}",
    method: "post",
    ...variables,
    signal,
  });

export const useOrganizationInvitationsAcknowledge = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      OrganizationInvitationsAcknowledgeError,
      OrganizationInvitationsAcknowledgeVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCloudContext();
  return reactQuery.useMutation<
    undefined,
    OrganizationInvitationsAcknowledgeError,
    OrganizationInvitationsAcknowledgeVariables
  >({
    mutationFn: (variables: OrganizationInvitationsAcknowledgeVariables) =>
      fetchOrganizationInvitationsAcknowledge({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type OrganizationsIndexQueryParams = {
  /**
   * Page Number
   */
  page?: number;
  /**
   * The number of items to return
   */
  page_size?: number;
  /**
   * Sort by a given property. Accepts fields: alias, name, inserted_at, updated_at. Sorts should be passed in the format of `&sort=property:asc,property2:desc`
   */
  sort?: string;
  /**
   * Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2`
   */
  filter?: {
    alias?: string;
    name?: string;
  };
  /**
   * Search by the following fields: alias, name
   */
  search?: string;
};

export type OrganizationsIndexError = Fetcher.ErrorWrapper<{
  status: 422;
  payload: Schemas.JsonErrorResponse;
}>;

export type OrganizationsIndexResponse = {
  data: Schemas.Organization[];
  meta: Schemas.PaginationMetadata;
};

export type OrganizationsIndexVariables = {
  queryParams?: OrganizationsIndexQueryParams;
} & CloudContext["fetcherOptions"];

export const fetchOrganizationsIndex = (
  variables: OrganizationsIndexVariables,
  signal?: AbortSignal
) =>
  cloudFetch<
    OrganizationsIndexResponse,
    OrganizationsIndexError,
    undefined,
    {},
    OrganizationsIndexQueryParams,
    {}
  >({ url: "/v1/organizations", method: "get", ...variables, signal });

export const useOrganizationsIndex = <TData = OrganizationsIndexResponse>(
  variables: OrganizationsIndexVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      OrganizationsIndexResponse,
      OrganizationsIndexError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCloudContext(options);
  return reactQuery.useQuery<
    OrganizationsIndexResponse,
    OrganizationsIndexError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/v1/organizations",
      operationId: "organizationsIndex",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchOrganizationsIndex({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type OrganizationsShowPathParams = {
  /**
   * The organization id or alias
   */
  organizationId: string;
};

export type OrganizationsShowError = Fetcher.ErrorWrapper<undefined>;

export type OrganizationsShowResponse = {
  data: Schemas.Organization;
};

export type OrganizationsShowVariables = {
  pathParams: OrganizationsShowPathParams;
} & CloudContext["fetcherOptions"];

export const fetchOrganizationsShow = (
  variables: OrganizationsShowVariables,
  signal?: AbortSignal
) =>
  cloudFetch<
    OrganizationsShowResponse,
    OrganizationsShowError,
    undefined,
    {},
    {},
    OrganizationsShowPathParams
  >({
    url: "/v1/organizations/{organizationId}",
    method: "get",
    ...variables,
    signal,
  });

export const useOrganizationsShow = <TData = OrganizationsShowResponse>(
  variables: OrganizationsShowVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      OrganizationsShowResponse,
      OrganizationsShowError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCloudContext(options);
  return reactQuery.useQuery<
    OrganizationsShowResponse,
    OrganizationsShowError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/v1/organizations/{organizationId}",
      operationId: "organizationsShow",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchOrganizationsShow({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type ActivitiesIndexPathParams = {
  /**
   * The organization id or alias
   */
  organizationId: string;
};

export type ActivitiesIndexQueryParams = {
  /**
   * Page Number
   */
  page?: number;
  /**
   * The number of items to return
   */
  page_size?: number;
  /**
   * Sort by a given property. Accepts fields: occurred_at. Sorts should be passed in the format of `&sort=property:asc,property2:desc`
   */
  sort?: string;
  /**
   * Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2`
   */
  filter?: {
    activity_data?: string;
    activity_name?: string;
    actor_data?: string;
    actor_id?: string;
    actor_type?: string;
    occurred_after?: string;
    occurred_before?: string;
    segmentation_key?: string;
    source?: string;
    source_data?: string;
  };
  /**
   * Search by the following fields:
   */
  search?: string;
};

export type ActivitiesIndexError = Fetcher.ErrorWrapper<undefined>;

export type ActivitiesIndexResponse = {
  data: Schemas.Activity[];
  meta: Schemas.PaginationMetadata;
};

export type ActivitiesIndexVariables = {
  pathParams: ActivitiesIndexPathParams;
  queryParams?: ActivitiesIndexQueryParams;
} & CloudContext["fetcherOptions"];

export const fetchActivitiesIndex = (
  variables: ActivitiesIndexVariables,
  signal?: AbortSignal
) =>
  cloudFetch<
    ActivitiesIndexResponse,
    ActivitiesIndexError,
    undefined,
    {},
    ActivitiesIndexQueryParams,
    ActivitiesIndexPathParams
  >({
    url: "/v1/organizations/{organizationId}/activities",
    method: "get",
    ...variables,
    signal,
  });

export const useActivitiesIndex = <TData = ActivitiesIndexResponse>(
  variables: ActivitiesIndexVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ActivitiesIndexResponse,
      ActivitiesIndexError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCloudContext(options);
  return reactQuery.useQuery<
    ActivitiesIndexResponse,
    ActivitiesIndexError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/v1/organizations/{organizationId}/activities",
      operationId: "activitiesIndex",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchActivitiesIndex({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type ApiKeysIndexPathParams = {
  /**
   * The organization id or alias
   */
  organizationId: string;
};

export type ApiKeysIndexQueryParams = {
  /**
   * Page Number
   */
  page?: number;
  /**
   * The number of items to return
   */
  page_size?: number;
  /**
   * Sort by a given property. Accepts fields: name, inserted_at, updated_at. Sorts should be passed in the format of `&sort=property:asc,property2:desc`
   */
  sort?: string;
  /**
   * Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2`
   */
  filter?: {
    expired?: string;
    name?: string;
  };
  /**
   * Search by the following fields: name, secret_key_last_4
   */
  search?: string;
};

export type ApiKeysIndexError = Fetcher.ErrorWrapper<{
  status: 422;
  payload: Schemas.JsonErrorResponse;
}>;

export type ApiKeysIndexResponse = {
  data: Schemas.APIKey[];
  meta: Schemas.PaginationMetadata;
};

export type ApiKeysIndexVariables = {
  pathParams: ApiKeysIndexPathParams;
  queryParams?: ApiKeysIndexQueryParams;
} & CloudContext["fetcherOptions"];

export const fetchApiKeysIndex = (
  variables: ApiKeysIndexVariables,
  signal?: AbortSignal
) =>
  cloudFetch<
    ApiKeysIndexResponse,
    ApiKeysIndexError,
    undefined,
    {},
    ApiKeysIndexQueryParams,
    ApiKeysIndexPathParams
  >({
    url: "/v1/organizations/{organizationId}/api_keys",
    method: "get",
    ...variables,
    signal,
  });

export const useApiKeysIndex = <TData = ApiKeysIndexResponse>(
  variables: ApiKeysIndexVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<ApiKeysIndexResponse, ApiKeysIndexError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCloudContext(options);
  return reactQuery.useQuery<ApiKeysIndexResponse, ApiKeysIndexError, TData>({
    queryKey: queryKeyFn({
      path: "/v1/organizations/{organizationId}/api_keys",
      operationId: "apiKeysIndex",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchApiKeysIndex({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type ApiKeysCreatePathParams = {
  /**
   * The organization id or alias
   */
  organizationId: string;
};

export type ApiKeysCreateError = Fetcher.ErrorWrapper<{
  status: 422;
  payload: Schemas.JsonErrorResponse;
}>;

export type ApiKeysCreateResponse = {
  data: Schemas.ApiKeyWithSecret;
};

export type ApiKeysCreateRequestBody = {
  data: Schemas.Create;
};

export type ApiKeysCreateVariables = {
  body: ApiKeysCreateRequestBody;
  pathParams: ApiKeysCreatePathParams;
} & CloudContext["fetcherOptions"];

export const fetchApiKeysCreate = (
  variables: ApiKeysCreateVariables,
  signal?: AbortSignal
) =>
  cloudFetch<
    ApiKeysCreateResponse,
    ApiKeysCreateError,
    ApiKeysCreateRequestBody,
    {},
    {},
    ApiKeysCreatePathParams
  >({
    url: "/v1/organizations/{organizationId}/api_keys",
    method: "post",
    ...variables,
    signal,
  });

export const useApiKeysCreate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      ApiKeysCreateResponse,
      ApiKeysCreateError,
      ApiKeysCreateVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCloudContext();
  return reactQuery.useMutation<
    ApiKeysCreateResponse,
    ApiKeysCreateError,
    ApiKeysCreateVariables
  >({
    mutationFn: (variables: ApiKeysCreateVariables) =>
      fetchApiKeysCreate({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ApiKeysRotateSecretPathParams = {
  /**
   * The organization id or alias
   */
  organizationId: string;
  /**
   * The API key id
   */
  apiKeyId: string;
};

export type ApiKeysRotateSecretError = Fetcher.ErrorWrapper<{
  status: 422;
  payload: Schemas.JsonErrorResponse;
}>;

export type ApiKeysRotateSecretResponse = {
  data: Schemas.ApiKeyWithSecret;
};

export type ApiKeysRotateSecretRequestBody = {
  data: Schemas.RotateSecret;
};

export type ApiKeysRotateSecretVariables = {
  body: ApiKeysRotateSecretRequestBody;
  pathParams: ApiKeysRotateSecretPathParams;
} & CloudContext["fetcherOptions"];

export const fetchApiKeysRotateSecret = (
  variables: ApiKeysRotateSecretVariables,
  signal?: AbortSignal
) =>
  cloudFetch<
    ApiKeysRotateSecretResponse,
    ApiKeysRotateSecretError,
    ApiKeysRotateSecretRequestBody,
    {},
    {},
    ApiKeysRotateSecretPathParams
  >({
    url: "/v1/organizations/{organizationId}/api_keys/{apiKeyId}/secret_key/rotate",
    method: "post",
    ...variables,
    signal,
  });

export const useApiKeysRotateSecret = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      ApiKeysRotateSecretResponse,
      ApiKeysRotateSecretError,
      ApiKeysRotateSecretVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCloudContext();
  return reactQuery.useMutation<
    ApiKeysRotateSecretResponse,
    ApiKeysRotateSecretError,
    ApiKeysRotateSecretVariables
  >({
    mutationFn: (variables: ApiKeysRotateSecretVariables) =>
      fetchApiKeysRotateSecret({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ApiKeysShowPathParams = {
  /**
   * The organization id or alias
   */
  organizationId: string;
  /**
   * The API key id
   */
  id: string;
};

export type ApiKeysShowError = Fetcher.ErrorWrapper<undefined>;

export type ApiKeysShowResponse = {
  data: Schemas.APIKey;
};

export type ApiKeysShowVariables = {
  pathParams: ApiKeysShowPathParams;
} & CloudContext["fetcherOptions"];

export const fetchApiKeysShow = (
  variables: ApiKeysShowVariables,
  signal?: AbortSignal
) =>
  cloudFetch<
    ApiKeysShowResponse,
    ApiKeysShowError,
    undefined,
    {},
    {},
    ApiKeysShowPathParams
  >({
    url: "/v1/organizations/{organizationId}/api_keys/{id}",
    method: "get",
    ...variables,
    signal,
  });

export const useApiKeysShow = <TData = ApiKeysShowResponse>(
  variables: ApiKeysShowVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<ApiKeysShowResponse, ApiKeysShowError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCloudContext(options);
  return reactQuery.useQuery<ApiKeysShowResponse, ApiKeysShowError, TData>({
    queryKey: queryKeyFn({
      path: "/v1/organizations/{organizationId}/api_keys/{id}",
      operationId: "apiKeysShow",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchApiKeysShow({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type ApiKeysUpdatePathParams = {
  /**
   * The organization id or alias
   */
  organizationId: string;
  /**
   * The API key id
   */
  id: string;
};

export type ApiKeysUpdateError = Fetcher.ErrorWrapper<{
  status: 422;
  payload: Schemas.JsonErrorResponse;
}>;

export type ApiKeysUpdateResponse = {
  data: Schemas.APIKey;
};

export type ApiKeysUpdateRequestBody = {
  data: Schemas.Update;
};

export type ApiKeysUpdateVariables = {
  body: ApiKeysUpdateRequestBody;
  pathParams: ApiKeysUpdatePathParams;
} & CloudContext["fetcherOptions"];

export const fetchApiKeysUpdate = (
  variables: ApiKeysUpdateVariables,
  signal?: AbortSignal
) =>
  cloudFetch<
    ApiKeysUpdateResponse,
    ApiKeysUpdateError,
    ApiKeysUpdateRequestBody,
    {},
    {},
    ApiKeysUpdatePathParams
  >({
    url: "/v1/organizations/{organizationId}/api_keys/{id}",
    method: "patch",
    ...variables,
    signal,
  });

export const useApiKeysUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      ApiKeysUpdateResponse,
      ApiKeysUpdateError,
      ApiKeysUpdateVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCloudContext();
  return reactQuery.useMutation<
    ApiKeysUpdateResponse,
    ApiKeysUpdateError,
    ApiKeysUpdateVariables
  >({
    mutationFn: (variables: ApiKeysUpdateVariables) =>
      fetchApiKeysUpdate({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ApiKeysUpdate2PathParams = {
  /**
   * The organization id or alias
   */
  organizationId: string;
  /**
   * The API key id
   */
  id: string;
};

export type ApiKeysUpdate2Error = Fetcher.ErrorWrapper<{
  status: 422;
  payload: Schemas.JsonErrorResponse;
}>;

export type ApiKeysUpdate2Response = {
  data: Schemas.APIKey;
};

export type ApiKeysUpdate2RequestBody = {
  data: Schemas.Update;
};

export type ApiKeysUpdate2Variables = {
  body: ApiKeysUpdate2RequestBody;
  pathParams: ApiKeysUpdate2PathParams;
} & CloudContext["fetcherOptions"];

export const fetchApiKeysUpdate2 = (
  variables: ApiKeysUpdate2Variables,
  signal?: AbortSignal
) =>
  cloudFetch<
    ApiKeysUpdate2Response,
    ApiKeysUpdate2Error,
    ApiKeysUpdate2RequestBody,
    {},
    {},
    ApiKeysUpdate2PathParams
  >({
    url: "/v1/organizations/{organizationId}/api_keys/{id}",
    method: "put",
    ...variables,
    signal,
  });

export const useApiKeysUpdate2 = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      ApiKeysUpdate2Response,
      ApiKeysUpdate2Error,
      ApiKeysUpdate2Variables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCloudContext();
  return reactQuery.useMutation<
    ApiKeysUpdate2Response,
    ApiKeysUpdate2Error,
    ApiKeysUpdate2Variables
  >({
    mutationFn: (variables: ApiKeysUpdate2Variables) =>
      fetchApiKeysUpdate2({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type OrganizationAppsIndexPathParams = {
  /**
   * The organization id or alias
   */
  organizationId: string;
};

export type OrganizationAppsIndexQueryParams = {
  /**
   * Page Number
   */
  page?: number;
  /**
   * The number of items to return
   */
  page_size?: number;
  /**
   * Sort by a given property. Accepts fields: alias, name, inserted_at, updated_at. Sorts should be passed in the format of `&sort=property:asc,property2:desc`
   */
  sort?: string;
  /**
   * Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2`
   */
  filter?: {
    alias?: string;
    name?: string;
  };
  /**
   * Search by the following fields: alias, description, name
   */
  search?: string;
};

export type OrganizationAppsIndexError = Fetcher.ErrorWrapper<{
  status: 422;
  payload: Schemas.JsonErrorResponse;
}>;

export type OrganizationAppsIndexResponse = {
  data: Schemas.App[];
  meta: Schemas.PaginationMetadata;
};

export type OrganizationAppsIndexVariables = {
  pathParams: OrganizationAppsIndexPathParams;
  queryParams?: OrganizationAppsIndexQueryParams;
} & CloudContext["fetcherOptions"];

export const fetchOrganizationAppsIndex = (
  variables: OrganizationAppsIndexVariables,
  signal?: AbortSignal
) =>
  cloudFetch<
    OrganizationAppsIndexResponse,
    OrganizationAppsIndexError,
    undefined,
    {},
    OrganizationAppsIndexQueryParams,
    OrganizationAppsIndexPathParams
  >({
    url: "/v1/organizations/{organizationId}/apps",
    method: "get",
    ...variables,
    signal,
  });

export const useOrganizationAppsIndex = <TData = OrganizationAppsIndexResponse>(
  variables: OrganizationAppsIndexVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      OrganizationAppsIndexResponse,
      OrganizationAppsIndexError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCloudContext(options);
  return reactQuery.useQuery<
    OrganizationAppsIndexResponse,
    OrganizationAppsIndexError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/v1/organizations/{organizationId}/apps",
      operationId: "organizationAppsIndex",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchOrganizationAppsIndex({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type RealmsIndexPathParams = {
  /**
   * The organization id or alias
   */
  organizationId: string;
  /**
   * The app id or alias
   */
  appId: string;
};

export type RealmsIndexQueryParams = {
  /**
   * Page Number
   */
  page?: number;
  /**
   * The number of items to return
   */
  page_size?: number;
  /**
   * Sort by a given property. Accepts fields: name, inserted_at, updated_at, app_alias, resource_path. Sorts should be passed in the format of `&sort=property:asc,property2:desc`
   */
  sort?: string;
  /**
   * Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2`
   */
  filter?: {
    name?: string;
  };
  /**
   * Search by the following fields: name, description, resource_path
   */
  search?: string;
};

export type RealmsIndexError = Fetcher.ErrorWrapper<undefined>;

export type RealmsIndexResponse = {
  data: Schemas.Realm[];
  meta: Schemas.PaginationMetadata;
};

export type RealmsIndexVariables = {
  pathParams: RealmsIndexPathParams;
  queryParams?: RealmsIndexQueryParams;
} & CloudContext["fetcherOptions"];

export const fetchRealmsIndex = (
  variables: RealmsIndexVariables,
  signal?: AbortSignal
) =>
  cloudFetch<
    RealmsIndexResponse,
    RealmsIndexError,
    undefined,
    {},
    RealmsIndexQueryParams,
    RealmsIndexPathParams
  >({
    url: "/v1/organizations/{organizationId}/apps/{appId}/realms",
    method: "get",
    ...variables,
    signal,
  });

export const useRealmsIndex = <TData = RealmsIndexResponse>(
  variables: RealmsIndexVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<RealmsIndexResponse, RealmsIndexError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCloudContext(options);
  return reactQuery.useQuery<RealmsIndexResponse, RealmsIndexError, TData>({
    queryKey: queryKeyFn({
      path: "/v1/organizations/{organizationId}/apps/{appId}/realms",
      operationId: "realmsIndex",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchRealmsIndex({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type RealmsDeletePathParams = {
  /**
   * The organization id or alias
   */
  organizationId: string;
  /**
   * The app id or alias
   */
  appId: string;
  /**
   * The resource_path to the realm, including its resource_id
   */
  resourcePath: string;
};

export type RealmsDeleteError = Fetcher.ErrorWrapper<undefined>;

export type RealmsDeleteVariables = {
  pathParams: RealmsDeletePathParams;
} & CloudContext["fetcherOptions"];

export const fetchRealmsDelete = (
  variables: RealmsDeleteVariables,
  signal?: AbortSignal
) =>
  cloudFetch<
    undefined,
    RealmsDeleteError,
    undefined,
    {},
    {},
    RealmsDeletePathParams
  >({
    url: "/v1/organizations/{organizationId}/apps/{appId}/realms/{resourcePath}",
    method: "delete",
    ...variables,
    signal,
  });

export const useRealmsDelete = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      RealmsDeleteError,
      RealmsDeleteVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCloudContext();
  return reactQuery.useMutation<
    undefined,
    RealmsDeleteError,
    RealmsDeleteVariables
  >({
    mutationFn: (variables: RealmsDeleteVariables) =>
      fetchRealmsDelete({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RealmsShowPathParams = {
  /**
   * The organization id or alias
   */
  organizationId: string;
  /**
   * The app id or alias
   */
  appId: string;
  /**
   * The resource_path to the realm, including its resource_id
   */
  resourcePath: string;
};

export type RealmsShowError = Fetcher.ErrorWrapper<undefined>;

export type RealmsShowResponse = {
  data: Schemas.Realm;
};

export type RealmsShowVariables = {
  pathParams: RealmsShowPathParams;
} & CloudContext["fetcherOptions"];

export const fetchRealmsShow = (
  variables: RealmsShowVariables,
  signal?: AbortSignal
) =>
  cloudFetch<
    RealmsShowResponse,
    RealmsShowError,
    undefined,
    {},
    {},
    RealmsShowPathParams
  >({
    url: "/v1/organizations/{organizationId}/apps/{appId}/realms/{resourcePath}",
    method: "get",
    ...variables,
    signal,
  });

export const useRealmsShow = <TData = RealmsShowResponse>(
  variables: RealmsShowVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<RealmsShowResponse, RealmsShowError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCloudContext(options);
  return reactQuery.useQuery<RealmsShowResponse, RealmsShowError, TData>({
    queryKey: queryKeyFn({
      path: "/v1/organizations/{organizationId}/apps/{appId}/realms/{resourcePath}",
      operationId: "realmsShow",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchRealmsShow({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type RealmsUpdatePathParams = {
  /**
   * The organization id or alias
   */
  organizationId: string;
  /**
   * The app id or alias
   */
  appId: string;
  /**
   * The resource_path to the realm, including its resource_id
   */
  resourcePath: string;
};

export type RealmsUpdateError = Fetcher.ErrorWrapper<{
  status: 422;
  payload: Schemas.JsonErrorResponse;
}>;

export type RealmsUpdateResponse = {
  data: Schemas.Realm;
};

export type RealmsUpdateRequestBody = {
  data: Schemas.CreateRealm;
};

export type RealmsUpdateVariables = {
  body: RealmsUpdateRequestBody;
  pathParams: RealmsUpdatePathParams;
} & CloudContext["fetcherOptions"];

export const fetchRealmsUpdate = (
  variables: RealmsUpdateVariables,
  signal?: AbortSignal
) =>
  cloudFetch<
    RealmsUpdateResponse,
    RealmsUpdateError,
    RealmsUpdateRequestBody,
    {},
    {},
    RealmsUpdatePathParams
  >({
    url: "/v1/organizations/{organizationId}/apps/{appId}/realms/{resourcePath}",
    method: "put",
    ...variables,
    signal,
  });

export const useRealmsUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      RealmsUpdateResponse,
      RealmsUpdateError,
      RealmsUpdateVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCloudContext();
  return reactQuery.useMutation<
    RealmsUpdateResponse,
    RealmsUpdateError,
    RealmsUpdateVariables
  >({
    mutationFn: (variables: RealmsUpdateVariables) =>
      fetchRealmsUpdate({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type OrganizationAppsShowPathParams = {
  /**
   * The organization id or alias
   */
  organizationId: string;
  /**
   * The app id or alias
   */
  id: string;
};

export type OrganizationAppsShowError = Fetcher.ErrorWrapper<undefined>;

export type OrganizationAppsShowResponse = {
  data: Schemas.App;
};

export type OrganizationAppsShowVariables = {
  pathParams: OrganizationAppsShowPathParams;
} & CloudContext["fetcherOptions"];

export const fetchOrganizationAppsShow = (
  variables: OrganizationAppsShowVariables,
  signal?: AbortSignal
) =>
  cloudFetch<
    OrganizationAppsShowResponse,
    OrganizationAppsShowError,
    undefined,
    {},
    {},
    OrganizationAppsShowPathParams
  >({
    url: "/v1/organizations/{organizationId}/apps/{id}",
    method: "get",
    ...variables,
    signal,
  });

export const useOrganizationAppsShow = <TData = OrganizationAppsShowResponse>(
  variables: OrganizationAppsShowVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      OrganizationAppsShowResponse,
      OrganizationAppsShowError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCloudContext(options);
  return reactQuery.useQuery<
    OrganizationAppsShowResponse,
    OrganizationAppsShowError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/v1/organizations/{organizationId}/apps/{id}",
      operationId: "organizationAppsShow",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchOrganizationAppsShow({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type OrganizationsAvailablePermissionsPathParams = {
  /**
   * The organization id or alias
   */
  organizationId: string;
};

export type OrganizationsAvailablePermissionsError =
  Fetcher.ErrorWrapper<undefined>;

export type OrganizationsAvailablePermissionsResponse = {
  data: Schemas.AvailablePermissions;
};

export type OrganizationsAvailablePermissionsVariables = {
  pathParams: OrganizationsAvailablePermissionsPathParams;
} & CloudContext["fetcherOptions"];

export const fetchOrganizationsAvailablePermissions = (
  variables: OrganizationsAvailablePermissionsVariables,
  signal?: AbortSignal
) =>
  cloudFetch<
    OrganizationsAvailablePermissionsResponse,
    OrganizationsAvailablePermissionsError,
    undefined,
    {},
    {},
    OrganizationsAvailablePermissionsPathParams
  >({
    url: "/v1/organizations/{organizationId}/available_permissions",
    method: "get",
    ...variables,
    signal,
  });

export const useOrganizationsAvailablePermissions = <
  TData = OrganizationsAvailablePermissionsResponse
>(
  variables: OrganizationsAvailablePermissionsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      OrganizationsAvailablePermissionsResponse,
      OrganizationsAvailablePermissionsError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCloudContext(options);
  return reactQuery.useQuery<
    OrganizationsAvailablePermissionsResponse,
    OrganizationsAvailablePermissionsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/v1/organizations/{organizationId}/available_permissions",
      operationId: "organizationsAvailablePermissions",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchOrganizationsAvailablePermissions(
        { ...fetcherOptions, ...variables },
        signal
      ),
    ...options,
    ...queryOptions,
  });
};

export type InsightsCreatePathParams = {
  /**
   * The organization id or alias
   */
  organizationId: string;
};

export type InsightsCreateError = Fetcher.ErrorWrapper<undefined>;

export type InsightsCreateResponse = {
  data: Schemas.Domo;
};

export type InsightsCreateRequestBody = {
  data: Schemas.CreateInsightsRequest;
};

export type InsightsCreateVariables = {
  body: InsightsCreateRequestBody;
  pathParams: InsightsCreatePathParams;
} & CloudContext["fetcherOptions"];

export const fetchInsightsCreate = (
  variables: InsightsCreateVariables,
  signal?: AbortSignal
) =>
  cloudFetch<
    InsightsCreateResponse,
    InsightsCreateError,
    InsightsCreateRequestBody,
    {},
    {},
    InsightsCreatePathParams
  >({
    url: "/v1/organizations/{organizationId}/insights",
    method: "post",
    ...variables,
    signal,
  });

export const useInsightsCreate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      InsightsCreateResponse,
      InsightsCreateError,
      InsightsCreateVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCloudContext();
  return reactQuery.useMutation<
    InsightsCreateResponse,
    InsightsCreateError,
    InsightsCreateVariables
  >({
    mutationFn: (variables: InsightsCreateVariables) =>
      fetchInsightsCreate({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type OrganizationsMembersIndexPathParams = {
  /**
   * The organization id or alias
   */
  organizationId: string;
};

export type OrganizationsMembersIndexQueryParams = {
  /**
   * Page Number
   */
  page?: number;
  /**
   * The number of items to return
   */
  page_size?: number;
  /**
   * Sort by a given property. Accepts fields: first_name, last_name, email, membership_status, inserted_at, updated_at. Sorts should be passed in the format of `&sort=property:asc,property2:desc`
   */
  sort?: string;
  /**
   * Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2`
   */
  filter?: {
    email?: string;
    external_id?: string;
    membership_status?: string;
    name?: string;
    resource_path?: string;
  };
  /**
   * Search by the following fields: email, external_id, membership_status, name
   */
  search?: string;
};

export type OrganizationsMembersIndexError = Fetcher.ErrorWrapper<{
  status: 422;
  payload: Schemas.JsonErrorResponse;
}>;

export type OrganizationsMembersIndexResponse = {
  data: Schemas.Member[];
  meta: Schemas.PaginationMetadata;
};

export type OrganizationsMembersIndexVariables = {
  pathParams: OrganizationsMembersIndexPathParams;
  queryParams?: OrganizationsMembersIndexQueryParams;
} & CloudContext["fetcherOptions"];

export const fetchOrganizationsMembersIndex = (
  variables: OrganizationsMembersIndexVariables,
  signal?: AbortSignal
) =>
  cloudFetch<
    OrganizationsMembersIndexResponse,
    OrganizationsMembersIndexError,
    undefined,
    {},
    OrganizationsMembersIndexQueryParams,
    OrganizationsMembersIndexPathParams
  >({
    url: "/v1/organizations/{organizationId}/members",
    method: "get",
    ...variables,
    signal,
  });

export const useOrganizationsMembersIndex = <
  TData = OrganizationsMembersIndexResponse
>(
  variables: OrganizationsMembersIndexVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      OrganizationsMembersIndexResponse,
      OrganizationsMembersIndexError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCloudContext(options);
  return reactQuery.useQuery<
    OrganizationsMembersIndexResponse,
    OrganizationsMembersIndexError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/v1/organizations/{organizationId}/members",
      operationId: "organizationsMembersIndex",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchOrganizationsMembersIndex(
        { ...fetcherOptions, ...variables },
        signal
      ),
    ...options,
    ...queryOptions,
  });
};

export type OrganizationsMembersCreatePathParams = {
  /**
   * The organization id or alias
   */
  organizationId: string;
};

export type OrganizationsMembersCreateError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Schemas.JsonErrorResponse;
    }
  | {
      status: 422;
      payload: Schemas.JsonErrorResponse;
    }
>;

export type OrganizationsMembersCreateResponse = {
  data: Schemas.Member;
};

export type OrganizationsMembersCreateRequestBody = {
  data: Schemas.CreateMember;
};

export type OrganizationsMembersCreateVariables = {
  body: OrganizationsMembersCreateRequestBody;
  pathParams: OrganizationsMembersCreatePathParams;
} & CloudContext["fetcherOptions"];

export const fetchOrganizationsMembersCreate = (
  variables: OrganizationsMembersCreateVariables,
  signal?: AbortSignal
) =>
  cloudFetch<
    OrganizationsMembersCreateResponse,
    OrganizationsMembersCreateError,
    OrganizationsMembersCreateRequestBody,
    {},
    {},
    OrganizationsMembersCreatePathParams
  >({
    url: "/v1/organizations/{organizationId}/members",
    method: "post",
    ...variables,
    signal,
  });

export const useOrganizationsMembersCreate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      OrganizationsMembersCreateResponse,
      OrganizationsMembersCreateError,
      OrganizationsMembersCreateVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCloudContext();
  return reactQuery.useMutation<
    OrganizationsMembersCreateResponse,
    OrganizationsMembersCreateError,
    OrganizationsMembersCreateVariables
  >({
    mutationFn: (variables: OrganizationsMembersCreateVariables) =>
      fetchOrganizationsMembersCreate({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type OrganizationsMembersDeletePathParams = {
  /**
   * The organization id or alias
   */
  organizationId: string;
  /**
   * The user id
   */
  id: string;
};

export type OrganizationsMembersDeleteError = Fetcher.ErrorWrapper<undefined>;

export type OrganizationsMembersDeleteVariables = {
  pathParams: OrganizationsMembersDeletePathParams;
} & CloudContext["fetcherOptions"];

export const fetchOrganizationsMembersDelete = (
  variables: OrganizationsMembersDeleteVariables,
  signal?: AbortSignal
) =>
  cloudFetch<
    undefined,
    OrganizationsMembersDeleteError,
    undefined,
    {},
    {},
    OrganizationsMembersDeletePathParams
  >({
    url: "/v1/organizations/{organizationId}/members/{id}",
    method: "delete",
    ...variables,
    signal,
  });

export const useOrganizationsMembersDelete = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      OrganizationsMembersDeleteError,
      OrganizationsMembersDeleteVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCloudContext();
  return reactQuery.useMutation<
    undefined,
    OrganizationsMembersDeleteError,
    OrganizationsMembersDeleteVariables
  >({
    mutationFn: (variables: OrganizationsMembersDeleteVariables) =>
      fetchOrganizationsMembersDelete({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type OrganizationsMembersShowPathParams = {
  /**
   * The organization id or alias
   */
  organizationId: string;
  /**
   * The id of the user
   */
  id: string;
};

export type OrganizationsMembersShowError = Fetcher.ErrorWrapper<undefined>;

export type OrganizationsMembersShowResponse = {
  data: Schemas.Member;
};

export type OrganizationsMembersShowVariables = {
  pathParams: OrganizationsMembersShowPathParams;
} & CloudContext["fetcherOptions"];

export const fetchOrganizationsMembersShow = (
  variables: OrganizationsMembersShowVariables,
  signal?: AbortSignal
) =>
  cloudFetch<
    OrganizationsMembersShowResponse,
    OrganizationsMembersShowError,
    undefined,
    {},
    {},
    OrganizationsMembersShowPathParams
  >({
    url: "/v1/organizations/{organizationId}/members/{id}",
    method: "get",
    ...variables,
    signal,
  });

export const useOrganizationsMembersShow = <
  TData = OrganizationsMembersShowResponse
>(
  variables: OrganizationsMembersShowVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      OrganizationsMembersShowResponse,
      OrganizationsMembersShowError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCloudContext(options);
  return reactQuery.useQuery<
    OrganizationsMembersShowResponse,
    OrganizationsMembersShowError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/v1/organizations/{organizationId}/members/{id}",
      operationId: "organizationsMembersShow",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchOrganizationsMembersShow(
        { ...fetcherOptions, ...variables },
        signal
      ),
    ...options,
    ...queryOptions,
  });
};

export type OrganizationsMembersUpdatePathParams = {
  /**
   * The organization id or alias
   */
  organizationId: string;
  /**
   * The id of the user to update
   */
  id: string;
};

export type OrganizationsMembersUpdateError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Schemas.JsonErrorResponse;
    }
  | {
      status: 422;
      payload: Schemas.JsonErrorResponse;
    }
>;

export type OrganizationsMembersUpdateResponse = {
  data: Schemas.Member;
};

export type OrganizationsMembersUpdateRequestBody = {
  data: Schemas.UpdateMember;
};

export type OrganizationsMembersUpdateVariables = {
  body: OrganizationsMembersUpdateRequestBody;
  pathParams: OrganizationsMembersUpdatePathParams;
} & CloudContext["fetcherOptions"];

export const fetchOrganizationsMembersUpdate = (
  variables: OrganizationsMembersUpdateVariables,
  signal?: AbortSignal
) =>
  cloudFetch<
    OrganizationsMembersUpdateResponse,
    OrganizationsMembersUpdateError,
    OrganizationsMembersUpdateRequestBody,
    {},
    {},
    OrganizationsMembersUpdatePathParams
  >({
    url: "/v1/organizations/{organizationId}/members/{id}",
    method: "patch",
    ...variables,
    signal,
  });

export const useOrganizationsMembersUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      OrganizationsMembersUpdateResponse,
      OrganizationsMembersUpdateError,
      OrganizationsMembersUpdateVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCloudContext();
  return reactQuery.useMutation<
    OrganizationsMembersUpdateResponse,
    OrganizationsMembersUpdateError,
    OrganizationsMembersUpdateVariables
  >({
    mutationFn: (variables: OrganizationsMembersUpdateVariables) =>
      fetchOrganizationsMembersUpdate({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type OrganizationsMembersUpdate2PathParams = {
  /**
   * The organization id or alias
   */
  organizationId: string;
  /**
   * The id of the user to update
   */
  id: string;
};

export type OrganizationsMembersUpdate2Error = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Schemas.JsonErrorResponse;
    }
  | {
      status: 422;
      payload: Schemas.JsonErrorResponse;
    }
>;

export type OrganizationsMembersUpdate2Response = {
  data: Schemas.Member;
};

export type OrganizationsMembersUpdate2RequestBody = {
  data: Schemas.UpdateMember;
};

export type OrganizationsMembersUpdate2Variables = {
  body: OrganizationsMembersUpdate2RequestBody;
  pathParams: OrganizationsMembersUpdate2PathParams;
} & CloudContext["fetcherOptions"];

export const fetchOrganizationsMembersUpdate2 = (
  variables: OrganizationsMembersUpdate2Variables,
  signal?: AbortSignal
) =>
  cloudFetch<
    OrganizationsMembersUpdate2Response,
    OrganizationsMembersUpdate2Error,
    OrganizationsMembersUpdate2RequestBody,
    {},
    {},
    OrganizationsMembersUpdate2PathParams
  >({
    url: "/v1/organizations/{organizationId}/members/{id}",
    method: "put",
    ...variables,
    signal,
  });

export const useOrganizationsMembersUpdate2 = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      OrganizationsMembersUpdate2Response,
      OrganizationsMembersUpdate2Error,
      OrganizationsMembersUpdate2Variables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCloudContext();
  return reactQuery.useMutation<
    OrganizationsMembersUpdate2Response,
    OrganizationsMembersUpdate2Error,
    OrganizationsMembersUpdate2Variables
  >({
    mutationFn: (variables: OrganizationsMembersUpdate2Variables) =>
      fetchOrganizationsMembersUpdate2({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type OrganizationsMembersActivatePathParams = {
  /**
   * The organization id or alias
   */
  organizationId: string;
  /**
   * The id of the user to activate
   */
  userId: string;
};

export type OrganizationsMembersActivateError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Schemas.JsonErrorResponse;
    }
  | {
      status: 422;
      payload: Schemas.ValidationErrors;
    }
>;

export type OrganizationsMembersActivateResponse = {
  data: Schemas.Member;
};

export type OrganizationsMembersActivateVariables = {
  pathParams: OrganizationsMembersActivatePathParams;
} & CloudContext["fetcherOptions"];

export const fetchOrganizationsMembersActivate = (
  variables: OrganizationsMembersActivateVariables,
  signal?: AbortSignal
) =>
  cloudFetch<
    OrganizationsMembersActivateResponse,
    OrganizationsMembersActivateError,
    undefined,
    {},
    {},
    OrganizationsMembersActivatePathParams
  >({
    url: "/v1/organizations/{organizationId}/members/{userId}/activate",
    method: "post",
    ...variables,
    signal,
  });

export const useOrganizationsMembersActivate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      OrganizationsMembersActivateResponse,
      OrganizationsMembersActivateError,
      OrganizationsMembersActivateVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCloudContext();
  return reactQuery.useMutation<
    OrganizationsMembersActivateResponse,
    OrganizationsMembersActivateError,
    OrganizationsMembersActivateVariables
  >({
    mutationFn: (variables: OrganizationsMembersActivateVariables) =>
      fetchOrganizationsMembersActivate({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type OrganizationsMembersDeactivatePathParams = {
  /**
   * The organization id or alias
   */
  organizationId: string;
  /**
   * The id of the user to deactivate
   */
  userId: string;
};

export type OrganizationsMembersDeactivateError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Schemas.JsonErrorResponse;
    }
  | {
      status: 422;
      payload: Schemas.JsonErrorResponse;
    }
>;

export type OrganizationsMembersDeactivateResponse = {
  data: Schemas.Member;
};

export type OrganizationsMembersDeactivateVariables = {
  pathParams: OrganizationsMembersDeactivatePathParams;
} & CloudContext["fetcherOptions"];

export const fetchOrganizationsMembersDeactivate = (
  variables: OrganizationsMembersDeactivateVariables,
  signal?: AbortSignal
) =>
  cloudFetch<
    OrganizationsMembersDeactivateResponse,
    OrganizationsMembersDeactivateError,
    undefined,
    {},
    {},
    OrganizationsMembersDeactivatePathParams
  >({
    url: "/v1/organizations/{organizationId}/members/{userId}/deactivate",
    method: "post",
    ...variables,
    signal,
  });

export const useOrganizationsMembersDeactivate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      OrganizationsMembersDeactivateResponse,
      OrganizationsMembersDeactivateError,
      OrganizationsMembersDeactivateVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCloudContext();
  return reactQuery.useMutation<
    OrganizationsMembersDeactivateResponse,
    OrganizationsMembersDeactivateError,
    OrganizationsMembersDeactivateVariables
  >({
    mutationFn: (variables: OrganizationsMembersDeactivateVariables) =>
      fetchOrganizationsMembersDeactivate({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type OrganizationsMembersResendInvitationPathParams = {
  /**
   * The organization id or alias
   */
  organizationId: string;
  /**
   * The id of the user to resend their invitation
   */
  userId: string;
};

export type OrganizationsMembersResendInvitationError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Schemas.JsonErrorResponse;
    }
  | {
      status: 422;
      payload: Schemas.ValidationErrors;
    }
>;

export type OrganizationsMembersResendInvitationVariables = {
  pathParams: OrganizationsMembersResendInvitationPathParams;
} & CloudContext["fetcherOptions"];

export const fetchOrganizationsMembersResendInvitation = (
  variables: OrganizationsMembersResendInvitationVariables,
  signal?: AbortSignal
) =>
  cloudFetch<
    undefined,
    OrganizationsMembersResendInvitationError,
    undefined,
    {},
    {},
    OrganizationsMembersResendInvitationPathParams
  >({
    url: "/v1/organizations/{organizationId}/members/{userId}/resend_invitation",
    method: "post",
    ...variables,
    signal,
  });

export const useOrganizationsMembersResendInvitation = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      OrganizationsMembersResendInvitationError,
      OrganizationsMembersResendInvitationVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCloudContext();
  return reactQuery.useMutation<
    undefined,
    OrganizationsMembersResendInvitationError,
    OrganizationsMembersResendInvitationVariables
  >({
    mutationFn: (variables: OrganizationsMembersResendInvitationVariables) =>
      fetchOrganizationsMembersResendInvitation({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type RealmsListAllPathParams = {
  /**
   * The organization id or alias
   */
  organizationId: string;
};

export type RealmsListAllQueryParams = {
  /**
   * Page Number
   */
  page?: number;
  /**
   * The number of items to return
   */
  page_size?: number;
  /**
   * Sort by a given property. Accepts fields: name, inserted_at, updated_at, app_alias, resource_path. Sorts should be passed in the format of `&sort=property:asc,property2:desc`
   */
  sort?: string;
  /**
   * Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2`
   */
  filter?: {
    name?: string;
  };
  /**
   * Search by the following fields: name, description, resource_path
   */
  search?: string;
};

export type RealmsListAllError = Fetcher.ErrorWrapper<{
  status: 422;
  payload: Schemas.JsonErrorResponse;
}>;

export type RealmsListAllResponse = {
  data: Schemas.Realm[];
  meta: Schemas.PaginationMetadata;
};

export type RealmsListAllVariables = {
  pathParams: RealmsListAllPathParams;
  queryParams?: RealmsListAllQueryParams;
} & CloudContext["fetcherOptions"];

export const fetchRealmsListAll = (
  variables: RealmsListAllVariables,
  signal?: AbortSignal
) =>
  cloudFetch<
    RealmsListAllResponse,
    RealmsListAllError,
    undefined,
    {},
    RealmsListAllQueryParams,
    RealmsListAllPathParams
  >({
    url: "/v1/organizations/{organizationId}/realms",
    method: "get",
    ...variables,
    signal,
  });

export const useRealmsListAll = <TData = RealmsListAllResponse>(
  variables: RealmsListAllVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      RealmsListAllResponse,
      RealmsListAllError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCloudContext(options);
  return reactQuery.useQuery<RealmsListAllResponse, RealmsListAllError, TData>({
    queryKey: queryKeyFn({
      path: "/v1/organizations/{organizationId}/realms",
      operationId: "realmsListAll",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchRealmsListAll({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type SessionCreateError = Fetcher.ErrorWrapper<undefined>;

export type SessionCreateVariables = CloudContext["fetcherOptions"];

export const fetchSessionCreate = (
  variables: SessionCreateVariables,
  signal?: AbortSignal
) =>
  cloudFetch<undefined, SessionCreateError, undefined, {}, {}, {}>({
    url: "/v1/socket/session",
    method: "post",
    ...variables,
    signal,
  });

export const useSessionCreate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      SessionCreateError,
      SessionCreateVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCloudContext();
  return reactQuery.useMutation<
    undefined,
    SessionCreateError,
    SessionCreateVariables
  >({
    mutationFn: (variables: SessionCreateVariables) =>
      fetchSessionCreate({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type QueryOperation =
  | {
      path: "/v1/auth/organizations/{organization}/apps/{app}/permissions";
      operationId: "authPermissions";
      variables: AuthPermissionsVariables;
    }
  | {
      path: "/v1/auth/validate";
      operationId: "authValidate";
      variables: AuthValidateVariables;
    }
  | {
      path: "/v1/me";
      operationId: "me";
      variables: MeVariables;
    }
  | {
      path: "/v1/me/organizations/{organizationId}";
      operationId: "myOrganization";
      variables: MyOrganizationVariables;
    }
  | {
      path: "/v1/me/roles";
      operationId: "myRoles";
      variables: MyRolesVariables;
    }
  | {
      path: "/v1/organization_invitations";
      operationId: "organizationInvitationsIndex";
      variables: OrganizationInvitationsIndexVariables;
    }
  | {
      path: "/v1/organizations";
      operationId: "organizationsIndex";
      variables: OrganizationsIndexVariables;
    }
  | {
      path: "/v1/organizations/{organizationId}";
      operationId: "organizationsShow";
      variables: OrganizationsShowVariables;
    }
  | {
      path: "/v1/organizations/{organizationId}/activities";
      operationId: "activitiesIndex";
      variables: ActivitiesIndexVariables;
    }
  | {
      path: "/v1/organizations/{organizationId}/api_keys";
      operationId: "apiKeysIndex";
      variables: ApiKeysIndexVariables;
    }
  | {
      path: "/v1/organizations/{organizationId}/api_keys/{id}";
      operationId: "apiKeysShow";
      variables: ApiKeysShowVariables;
    }
  | {
      path: "/v1/organizations/{organizationId}/apps";
      operationId: "organizationAppsIndex";
      variables: OrganizationAppsIndexVariables;
    }
  | {
      path: "/v1/organizations/{organizationId}/apps/{appId}/realms";
      operationId: "realmsIndex";
      variables: RealmsIndexVariables;
    }
  | {
      path: "/v1/organizations/{organizationId}/apps/{appId}/realms/{resourcePath}";
      operationId: "realmsShow";
      variables: RealmsShowVariables;
    }
  | {
      path: "/v1/organizations/{organizationId}/apps/{id}";
      operationId: "organizationAppsShow";
      variables: OrganizationAppsShowVariables;
    }
  | {
      path: "/v1/organizations/{organizationId}/available_permissions";
      operationId: "organizationsAvailablePermissions";
      variables: OrganizationsAvailablePermissionsVariables;
    }
  | {
      path: "/v1/organizations/{organizationId}/members";
      operationId: "organizationsMembersIndex";
      variables: OrganizationsMembersIndexVariables;
    }
  | {
      path: "/v1/organizations/{organizationId}/members/{id}";
      operationId: "organizationsMembersShow";
      variables: OrganizationsMembersShowVariables;
    }
  | {
      path: "/v1/organizations/{organizationId}/realms";
      operationId: "realmsListAll";
      variables: RealmsListAllVariables;
    };
