import type { ComponentSingleStyleConfig } from "@chakra-ui/react";
import { getColor, mode, transparentize } from "@chakra-ui/theme-tools";

import { pickSpecificColor } from "../utils";

export default {
  baseStyle: {
    px: "0.3125rem",
  },
  variants: {
    solid: (props) => {
      const { colorScheme: c } = props;
      const dark = transparentize(`${c}.500`, 0.6)(props.theme);

      return {
        bg: mode(
          pickSpecificColor(c, {
            gray: 600,
            yellow: 600,
          }) ?? `${c}.500`,
          dark,
        )(props),
        color: mode("white", "whiteAlpha.800")(props),
      };
    },
    subtle: (props) => {
      const { colorScheme: c } = props;
      const darkBg = transparentize(`${c}.200`, 0.16)(props.theme);
      return {
        bg: mode(
          pickSpecificColor(c, {
            gray: 200,
            blue: 50,
            purple: 50,
            yellow: 200,
            teal: 50,
            cyan: 50,
            magenta: 50,
            orange: 50,
          }) ?? `${c}.100`,
          darkBg,
        )(props),
        color: mode(
          pickSpecificColor(c, {
            gray: 800,
            red: 700,
            mango: 800,
            green: 800,
            blue: 500,
            purple: 700,
            yellow: 800,
            teal: 800,
            cyan: 700,
            magenta: 700,
            orange: 700,
          }),
          `${c}.200`,
        )(props),
      };
    },
    outline: (props) => {
      const { colorScheme: c } = props;
      const darkColor = transparentize(`${c}.200`, 0.8)(props.theme);
      const lightColor = getColor(props.theme, `${c}.500`);
      const color = mode(
        pickSpecificColor(c, {
          gray: 600,
          mango: 600,
          yellow: 700,
          teal: 600,
          cyan: 600,
          orange: 600,
        }) ?? lightColor,
        darkColor,
      )(props);

      return {
        color,
        borderColor: color,
        border: "0.0625rem solid",
        boxShadow: "none",
      };
    },
  },
  sizes: {
    sm: {
      fontSize: "0.75rem",
      fontWeight: 700,
      lineHeight: "1.125rem",
      textTransform: "uppercase",
    },
    xl: {
      fontSize: "1rem",
      fontWeight: 700,
      lineHeight: "1.5rem",
      textTransform: "uppercase",
    },
  },
  defaultProps: {
    size: "sm",
  },
} as ComponentSingleStyleConfig;
