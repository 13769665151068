import * as React from "react";
import { Button, ButtonGroup, useColorModeValue } from "@chakra-ui/react";
import type { ButtonProps } from "@chakra-ui/react";

export type ToggleOption = {
  display: React.ReactNode;
  selected?: boolean;
  value: string;
};

export interface ButtonToggleProps {
  onChange: (value: string) => void;
  options: ToggleOption[];
  size?: ButtonProps["size"];
}

export const ButtonToggle = ({
  onChange,
  options,
  size = "md",
}: ButtonToggleProps) => {
  const colorScheme = useColorModeValue("blue", "gray");
  const [selectedOption, setSelectedOption] = React.useState(
    options.find((op) => op?.selected)?.value || options[0]?.value,
  );

  const handleClick = (value: string) => {
    setSelectedOption(value);
    onChange(value);
  };

  const renderButtons = (_options: ToggleOption[]) => {
    return _options.map(({ display, value }, idx) => {
      return (
        <Button
          key={value}
          onClick={() => handleClick(value)}
          {...(idx !== 0 ? { borderLeftRadius: 0 } : {})}
          {...(idx !== _options.length - 1 ? { borderRightRadius: 0 } : {})}
          w="50%"
          py="0"
          alignItems="center"
          isActive={selectedOption === value}
          colorScheme={colorScheme}
          variant={selectedOption === value ? "solid" : "outline"}
        >
          {display}
        </Button>
      );
    });
  };

  return (
    <ButtonGroup spacing={0} size={size} w="100%" justifyContent="center">
      {renderButtons(options)}
    </ButtonGroup>
  );
};
