export function extractUUID(str: string) {
  // matches UUID 1-5, we use 4
  const regex =
    /[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}/i;
  const match = str.match(regex);
  if (!match) {
    throw new Error(`No UUID found in the string: ${str}`);
  }
  return match[0];
}
