import type * as React from "react";

import { useFlag } from "../../components/FeatureFlag/useFlag";

import { MaintenanceErrorPage } from "./MaintenanceErrorPage";

export interface MaintenanceModeGateProps {
  children?: React.ReactNode;
}

export const MaintenanceModeGate = ({ children }: MaintenanceModeGateProps) => {
  const isMaintenanceModeActive = useFlag("maintenance-mode-active", false);

  if (isMaintenanceModeActive) {
    return <MaintenanceErrorPage />;
  }

  return <>{children}</>;
};
