import { useCallback } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { isBaseError, qs, useApi } from "@stordco/fe-components";

import { useNetworkId } from "../../../components/Network";

export type ProductListing = {
  id: string;
  network_id: string;
  listing: string;
  name: string;
  sku: string;
  item_id: string;
  rank_by_expiration: boolean;
  channel_id: string;
  channel_name: string;
  deleted_at: string;
  inserted_at: string;
  updated_at: string;
  items: ProductListingItem[];
};

export type ProductListingItem = {
  sku: string;
  name: string;
  image_urls: string[];
};

export type ProductListMetadata = {
  after: string | null;
  before: string | null;
  limit: number;
  total_count: number;
};

type UseProductListingsProps = {
  listing?: string;
  sort?: string;
  channel_id?: string;
  search_term?: string;
  search_field?: string;
  after?: string;
  before?: string;
  limit?: number;
};

export const useProductListingSearch = () => {
  const api = useApi();
  const networkId = useNetworkId();

  return useCallback(
    ({
      search_term,
      search_field,
      sort,
      channel_id,
      after,
      before,
      limit,
    }: UseProductListingsProps) =>
      api.get<{
        data: ProductListing[];
        metadata: ProductListMetadata;
      }>({
        url: `/v1/networks/${networkId}/listings`,
        baseOverrides: {
          paramsSerializer: (params) =>
            qs.stringify(params, { arrayFormat: "bracket" }),
        },
        params: {
          ...(search_field ? { [search_field]: search_term } : {}),
          sort: [sort],
          channel_id,
          after,
          before,
          limit,
        },
      }),
    [api, networkId],
  );
};

export const useProductListings = (params: UseProductListingsProps) => {
  const productListingSearch = useProductListingSearch();

  return useQuery({
    queryKey: ["ProductListings", params],
    queryFn: () => productListingSearch(params),
  });
};

export function useProductListing(id: string, opts?: { enabled?: boolean }) {
  const api = useApi();
  const networkId = useNetworkId();

  return useQuery({
    queryKey: ["ProductListings", networkId, id],
    queryFn: () =>
      api.get<ProductListing>({
        url: `/v1/networks/${networkId}/listings/${id}`,
      }),
    retry: (failureCount, error) => {
      if (isBaseError(error) && error.status === 404) {
        return false;
      }

      return failureCount < 3;
    },
    ...opts,
  });
}

export interface ListingUpsertPayload {
  id?: string;
  listing: string;
  name: string;
  skus: string[];
  rank_by_expiration: boolean;
  channel_id: string;
}

export const useUpsertProductListings = () => {
  const api = useApi();
  const networkId = useNetworkId();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (listings: ListingUpsertPayload[]) =>
      api.post({
        url: `/v1/networks/${networkId}/listings`,
        body: {
          listings,
        },
      }),
    async onSuccess() {
      await queryClient.invalidateQueries({
        queryKey: ["ProductListings"],
      });
    },
  });
};

export function useDeleteListings() {
  const api = useApi();
  const networkId = useNetworkId();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ listing_ids }: { listing_ids: string[] }) =>
      api.post({
        url: `/v1/networks/${networkId}/delete_listings`,
        body: { listing_ids },
      }),
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ["ProductListings"],
      });
    },
  });
}
