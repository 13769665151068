import {
  faBoxOpen,
  faMagnifyingGlass,
  faTriangleExclamation,
  type IconDefinition,
} from "@fortawesome/pro-light-svg-icons";

import type { EmptyDataProps } from "./EmptyData";
import { EmptyData, EmptyDataDescription } from "./EmptyData";

export const NoResultsFound = ({
  onCreateClick,
  objectName,
  icon,
  primaryAction,
}: {
  objectName?: { singular: string; plural: string };
  icon?: IconDefinition;
} & (
  | { onCreateClick?: () => void; primaryAction?: never }
  | {
      onCreateClick?: never;
      primaryAction: { label: string; onClick: () => void };
    }
)) => (
  <EmptyData
    icon={icon ?? faBoxOpen}
    title={`No ${objectName?.plural ?? "items"} to display`}
    primaryAction={
      onCreateClick
        ? {
            label: `Create ${objectName?.singular ?? "item"}`,
            onClick: onCreateClick,
          }
        : primaryAction
        ? primaryAction
        : null
    }
  >
    <EmptyDataDescription>
      When {objectName?.plural ?? "items"} are created they will appear here.
    </EmptyDataDescription>
  </EmptyData>
);

export const ErrorFetchingData = ({
  onRetryClick,
  description = "Sorry, there was an error loading the data.",
  ...props
}: {
  onRetryClick?: () => void;
  description?: string | null;
} & EmptyDataProps) => (
  <EmptyData
    icon={faTriangleExclamation}
    title="Error fetching data"
    secondaryAction={
      onRetryClick
        ? {
            label: "Retry",
            onClick: onRetryClick,
          }
        : null
    }
    {...props}
  >
    {description ? (
      <EmptyDataDescription>{description}</EmptyDataDescription>
    ) : null}
  </EmptyData>
);

export const NoFilteredResultsFound = ({
  onClearFiltersClick,
}: {
  onClearFiltersClick?: () => void;
}) => (
  <EmptyData
    icon={faMagnifyingGlass}
    title="No results found"
    secondaryAction={
      onClearFiltersClick
        ? {
            label: "Clear filters",
            onClick: onClearFiltersClick,
          }
        : null
    }
  >
    <EmptyDataDescription>
      Try updating your search and/or filters.
    </EmptyDataDescription>
  </EmptyData>
);
