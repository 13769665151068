import { type FlexProps, Text, Flex, Box, Spacer } from "@chakra-ui/react";
import { type ReactNode } from "react";

import { isStringElement } from "../../utils";
import { ConditionalWrapper } from "../ConditionalWrapper";

import { Card } from ".";

type CardHeaderProps = {
  title: ReactNode;
  description?: ReactNode;
  leftElement?: ReactNode;
  rightElement?: ReactNode;
} & Omit<FlexProps, "title">;
export function CardHeader({
  title,
  description,
  leftElement,
  rightElement,
  ...props
}: CardHeaderProps) {
  return (
    <Flex alignItems="center" gap={4} {...props}>
      {leftElement}

      <Flex direction="column" gap={1} flexGrow={1}>
        <Flex>
          <ConditionalWrapper
            condition={isStringElement(title)}
            wrapper={(children) => <Card.Title>{children}</Card.Title>}
          >
            {title}
          </ConditionalWrapper>

          <Spacer minWidth={2} />

          <Box>{rightElement}</Box>
        </Flex>

        <ConditionalWrapper
          condition={isStringElement(description)}
          wrapper={(children) => <Text>{children}</Text>}
        >
          {description}
        </ConditionalWrapper>
      </Flex>
    </Flex>
  );
}

export function CardHeaderWithCenteredRightElement({
  rightElement,
  ...props
}: CardHeaderProps) {
  return (
    <Flex alignItems="center" gap={2}>
      <Card.Header flex={1} {...props} />
      {rightElement}
    </Flex>
  );
}
