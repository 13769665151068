import { useCallback, useEffect, useRef, useState } from "react";

type Json = null | string | number | boolean | Json[] | { [key: string]: Json };

export const useLocalStorage = <T extends Json>(
  key: string,
  initialValue: T | (() => T),
): [T, (valueOrUpdater: T | ((currentValue: T) => T)) => void] => {
  const readValue = useCallback((): T => {
    const getInitialValue = () =>
      typeof initialValue === "function" ? initialValue() : initialValue;

    try {
      const value = window.localStorage.getItem(key);

      return value ? (JSON.parse(value) as T) : getInitialValue();
    } catch (error) {
      console.warn(`Failed to parse value in localStorage for key '${key}'`);
      console.log(error);

      return getInitialValue();
    }
  }, [key, initialValue]);

  const [prevKey, setPrevKey] = useState(key);
  const [storedValue, setStoredValue] = useState(readValue);

  if (key !== prevKey) {
    setStoredValue(readValue());
    setPrevKey(key);
  }

  const skipEventRef = useRef(false);

  const setValue = useCallback(
    (value: T | ((currentValue: T) => T)) => {
      try {
        setStoredValue((storedValue) => {
          const valueToStore =
            typeof value === "function" ? value(storedValue) : value;

          window.localStorage.setItem(key, JSON.stringify(valueToStore));

          return valueToStore;
        });

        // We dispatch a custom event so every `useLocalStorage` hook is notified
        skipEventRef.current = true;
        window.dispatchEvent(new Event(`local-storage-${key}`));
      } catch (error) {
        console.warn(`Failed to set value in localStorage for key '${key}'`);
        console.log(error);
      }
    },
    [key],
  );

  useEffect(() => {
    const handleEvent = () => {
      if (skipEventRef.current) {
        skipEventRef.current = false;

        return;
      }

      const value = window.localStorage.getItem(key)!;

      setStoredValue(JSON.parse(value) as T);
    };

    addEventListener(`local-storage-${key}`, handleEvent);

    return () => {
      removeEventListener(`local-storage-${key}`, handleEvent);
    };
  }, [key]);

  return [storedValue, setValue];
};
