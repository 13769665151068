import { defineStyleConfig } from "@chakra-ui/styled-system";
import { mode } from "@chakra-ui/theme-tools";

export default defineStyleConfig({
  baseStyle: (props) => ({
    borderRadius: "base",
    fontWeight: "semibold",
    fontFamily: "body",
    background: mode("gray.50", "whiteAlpha.100")(props),
    borderBottomWidth: "2px",
    _dark: {
      borderColor: "gray.700",
    },
  }),
});
