import _qs from "query-string";

export * from "./helpers";
export * from "./hooks";
export * from "./components";
export * from "./context";
export * from "./icons";
export * from "./utils";
export * from "./types";

/**
 * Reexport of the query-string library.
 *
 * @remarks Useful for building paths when dealing with `useStordFetch`. In most cases, you should be able to leverage the `useSearchParams` hook instead.
 * @see https://github.com/sindresorhus/query-string
 * @example
 * ```
 * return useQuery([`orders`, networkId, params],
 *   async () => {
 *     const response = await apiFetch<OrderResponse>({
 *       path: `/bff/v1/networks/${networkId}/orders?${qs.stringify(params)}`,
 *       options: {
 *         requestContentType: "json",
 *         disableDefaultErrorHandling: true,
 *       },
 *     });
 * });
 *```
 **/
export const qs = _qs;

export { Tooltip } from "@chakra-ui/react";
