import { createContext, useContext, type ReactNode } from "react";
import { useParams } from "react-router-dom";

import {
  useIsAdmin,
  useIdentity,
  NotFoundErrorPage,
  PageLoader,
} from "@stordco/fe-components";

import { useAuthPermissions } from "../hooks/cloudApi/cloudComponents";

export type NetworkPermissionsContextValue =
  | "admin"
  | OMSPermissionsData
  | null;

export const NetworkPermissionsContext =
  createContext<NetworkPermissionsContextValue>(null);

export function NetworkPermissionsProvider({
  children,
}: {
  children: ReactNode;
}) {
  if (useIsAdmin()) {
    return (
      <NetworkPermissionsContext.Provider value="admin">
        {children}
      </NetworkPermissionsContext.Provider>
    );
  }

  return <NonAdminPermissionsProvider>{children}</NonAdminPermissionsProvider>;
}

function NonAdminPermissionsProvider({ children }: { children: ReactNode }) {
  const user = useIdentity();
  const networkId = useParams().id!;

  const omsOrganization = user.organizations.find((organization) => {
    const app = organization.apps?.find((app) => app.alias === "oms");

    if (!app) {
      return false;
    }

    return app.realms.some((realm) => realm.resource_id === networkId);
  });

  if (!omsOrganization) {
    return <NotFoundErrorPage outsideNavigation />;
  }

  return (
    <OrganizationPermissionsProvider
      organization={omsOrganization.id}
      networkId={networkId}
    >
      {children}
    </OrganizationPermissionsProvider>
  );
}

function OrganizationPermissionsProvider({
  children,
  organization,
  networkId,
}: {
  children: ReactNode;
  organization: string;
  networkId: string;
}) {
  const permissionsQuery = useAuthPermissions<{
    data: {
      permissions: {
        networks: {
          [networkId: string]: OMSPermissionsData;
        };
      };
    };
  }>({
    pathParams: {
      app: "oms",
      organization,
    },
  });

  if (permissionsQuery.isError) {
    throw permissionsQuery.error;
  }

  if (permissionsQuery.isPending) {
    // TODO: suspend
    return <PageLoader />;
  }

  return (
    <NetworkPermissionsContext.Provider
      value={permissionsQuery.data.data.permissions.networks[networkId]}
    >
      {children}
    </NetworkPermissionsContext.Provider>
  );
}

// Values from https://github.com/stordco/cloud-service/blob/main/priv/apps/oms.yaml#L99-L241
interface OMSPermissions {
  $self: "read" | "update";
  company: "read";
  facility_activities: "create" | "read" | "update" | "delete";
  trade_connections: "read" | "update";
  orders: "create" | "read" | "update" | "delete" | "cancel";
  order_lines: "create" | "read" | "update" | "delete" | "cancel";
  items: "create" | "read" | "update" | "delete";
  inventory: "create" | "read" | "inventory_advice";
  documents: "create" | "read" | "update" | "delete";
  automations: "create" | "read" | "update" | "delete";
  contracted_carrier_methods: "read";
  suppliers: "create" | "read" | "update";
  supplier_sku_configurations: "create" | "read" | "update";
  supplier_contacts: "create" | "read" | "update";
  warehouses: "create" | "read" | "update" | "delete";
  delivery_tracking: "read";
  estimated_delivery_date: "read";
  shipment_protection: "read";
  channels: "create" | "read" | "update" | "delete";
  forecasts: "read" | "update";
}

type PermissionString = {
  [T in keyof OMSPermissions]: `${T}.${OMSPermissions[T]}`;
}[keyof OMSPermissions];

type OMSPermissionsData = {
  [T in keyof OMSPermissions]?: Array<OMSPermissions[T]>;
};

export function useHasNetworkPermissions(
  requiredPermissions: PermissionString[],
  method: "some" | "every" = "every",
) {
  const permissions = useContext(NetworkPermissionsContext);

  if (!permissions) {
    throw new Error(
      `${useHasNetworkPermissions.name} can only be used inside a <${NetworkPermissionsProvider.name}>`,
    );
  }

  if (permissions === "admin") {
    return true;
  }

  return requiredPermissions[method]((requiredPermission) => {
    const [target, permission] = requiredPermission.split(".") as [
      keyof OMSPermissions,
      any,
    ];

    return permissions[target]?.includes(permission) ?? false;
  });
}
