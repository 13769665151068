import {
  useIsAdmin,
  type NavigationProviderProps,
} from "@stordco/fe-components";
import { useAuthentication, useUser } from "@stordco/fe-components";

import { useNetworkId } from "../../Network";
import { useCallback, useMemo } from "react";

const getOs = () => {
  const os = ["Windows", "Linux", "Mac"]; // add your OS values
  return os.find((v) => navigator.appVersion.indexOf(v) >= 0);
};

export const useUserMenu = (): NavigationProviderProps["userMenu"] => {
  const os = getOs();
  const client = useAuthentication();
  const networkId = useNetworkId();

  const user = useUser();

  const isAdmin = useIsAdmin();
  const omsRealmOptions = useMemo(() => {
    const options: { label: string; value: string; href: string }[] = [];

    for (const org of user.organizations) {
      for (const app of org.apps!) {
        for (const realm of app.realms) {
          if (app.alias === "oms") {
            options.push({
              value: realm.resource_id,
              label: `${realm.name}`,
              href: `/distribution_networks/${realm.resource_id}`,
            });
          }
        }
      }
    }

    return options;
  }, [user.organizations]);

  const onLogout = useCallback(() => {
    client.logout({ returnTo: window.location.origin });
  }, [client]);

  return {
    showOperations: isAdmin,
    selectedAccount: networkId,
    userDisplayName: `${user.first_name} ${user.last_name}`,
    operationsTo: "/admin",
    operationsCommand: `${os === "Mac" ? "⌘" : "Ctrl"}⇧O`,
    onLogout,
    accountOptions: omsRealmOptions.length > 1 ? omsRealmOptions : [],
  };
};
