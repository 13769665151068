import { useIdentity } from "../components/IdentityProvider";

/**
 * Checks if the user is an OMS Admin via `cloud-service`.
 *
 * `oms_admin` is a specific app that a user can be given a role for.
 *
 * @returns true if the user is an admin, false otherwise
 */
export function useIsAdmin() {
  const user = useIdentity();

  return user.organizations.some((organization) =>
    organization.apps!.some((app) => app.alias === "oms_admin"),
  );
}
