export default {
  colors: {
    body: {
      default: "white",
      _dark: "black",
    },
    heading: {
      default: "gray.900",
      _dark: "gray.100",
    },
    text: {
      default: "gray.800",
      _dark: "gray.200",
    },
    "bg-canvas": {
      default: "gray.50",
      _dark: "black",
    },
    "bg-surface": {
      default: "white",
      _dark: "whiteAlpha.200",
    },
    "tooltip-bg": {
      default: "gray.900",
      _dark: "black",
    },
  },
};
