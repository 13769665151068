import {
  Button,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  useBreakpointValue,
} from "@chakra-ui/react";
import { faAngleDown } from "@fortawesome/pro-regular-svg-icons";
import { Link as RouterLink, Outlet } from "react-router-dom";

import {
  FaIcon,
  Header,
  LinkTabs,
  PageLoader,
  useUser,
} from "@stordco/fe-components";

import { useNetworkId } from "../../components/Network";
import { Suspense, Fragment } from "react";
import { useHasNetworkPermissions } from "../../components/NetworkPermissionsProvider";

export default function Dashboard() {
  const showFullTitle = useBreakpointValue(
    { base: false, sm: true },
    { ssr: false },
  );

  const networkId = useNetworkId();
  const user = useUser();
  const hasOrdersCreatePermission = useHasNetworkPermissions(["orders.create"]);
  const hasItemsCreatePermission = useHasNetworkPermissions(["items.create"]);

  const actionGroups = [];

  if (hasOrdersCreatePermission) {
    actionGroups.push({
      title: "Create order",
      items: [
        {
          to: `/distribution_networks/${networkId}/orders/create_sales_order`,
          children: "Sales order",
        },
        {
          to: `/distribution_networks/${networkId}/orders/create_purchase_order`,
          children: "Purchase order",
        },
        {
          to: `/distribution_networks/${networkId}/orders/create_transfer_order`,
          children: "Transfer order",
        },
      ],
    });
  }

  if (hasItemsCreatePermission) {
    actionGroups.push({
      title: "Create product",
      items: [
        {
          to: `/distribution_networks/${networkId}/product_catalog`,
          state: { isItemModalOpen: true },
          children: "Items",
        },
        {
          to: `/distribution_networks/${networkId}/product_catalog`,
          state: { isBundleModalOpen: true },
          children: "Bundle",
        },
      ],
    });
  }

  return (
    <>
      <Header
        title={
          showFullTitle && user.first_name
            ? `Welcome back, ${user.first_name}!`
            : "Welcome back!"
        }
        seoTitle="Dashboard"
        actions={
          actionGroups.length ? (
            <Menu>
              <MenuButton
                as={Button}
                rightIcon={<FaIcon icon={faAngleDown} />}
                variant="outline"
                colorScheme="gray"
              >
                Actions
              </MenuButton>

              <MenuList>
                {actionGroups.map((group, index) => (
                  <Fragment key={index}>
                    {index > 0 ? <MenuDivider /> : null}

                    <MenuGroup title={group.title}>
                      {group.items.map((item, index) => (
                        <MenuItem key={index} as={RouterLink} {...item} />
                      ))}
                    </MenuGroup>
                  </Fragment>
                ))}
              </MenuList>
            </Menu>
          ) : null
        }
        mb={4}
      />

      <LinkTabs mb={4}>
        <LinkTabs.TabList>
          <LinkTabs.Tab to="" end>
            Fulfillment
          </LinkTabs.Tab>

          <LinkTabs.Tab to="inventory" end>
            Inventory
          </LinkTabs.Tab>
        </LinkTabs.TabList>
      </LinkTabs>

      <Suspense fallback={<PageLoader />}>
        <Outlet />
      </Suspense>
    </>
  );
}
