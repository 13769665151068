import { ErrorBoundary, type ErrorBoundaryProps } from "react-error-boundary";

import { datadogRum } from "@datadog/browser-rum";

export function DatadogErrorBoundary(props: ErrorBoundaryProps) {
  return (
    <ErrorBoundary
      onError={(error, info) => {
        props.onError?.(error, info);

        datadogRum.addError(error, {
          scope: "error-boundary",
        });
      }}
      {...props}
    />
  );
}
