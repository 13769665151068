import { Navigate, useParams } from "react-router-dom";

import { ErrorFetchingData, PageLoader } from "@stordco/fe-components";

import { useOrderDetails } from "../../hooks/queries/useOrderDetails";
import { useNetworkId } from "../../components/Network";

export function OrderDetailsRedirect() {
  const orderId = useParams().orderId!;
  const networkId = useNetworkId();

  const {
    data: orderDetails,
    isPending,
    isError,
    refetch,
  } = useOrderDetails({ id: orderId });

  if (isPending) {
    return <PageLoader />;
  }

  if (isError) {
    return <ErrorFetchingData onRetryClick={refetch} />;
  }

  return (
    <Navigate
      to={`/distribution_networks/${networkId}/orders/${orderDetails.type}/${orderId}`}
      replace
    />
  );
}
