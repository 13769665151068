import debounce from "lodash/debounce.js";
import type { ForwardedRef } from "react";
import { forwardRef, useState } from "react";
import type { OptionProps } from "react-select";

import type { AsyncSelectProps } from "@stordco/fe-components";
import {
  AsyncSelect,
  OurSelectOption,
  StackedCell,
} from "@stordco/fe-components";
import type { TagProps } from "@chakra-ui/react";
import { Text, HStack, Badge } from "@chakra-ui/react";

import type { Product, ProductSearchParams } from "../../hooks";
import { useProductSearch } from "../../hooks";
import { MaybeProductImage } from "../MaybeProductImage";

export type ProductSelectProps<IsMulti extends boolean = false> =
  AsyncSelectProps<Product, IsMulti> & {
    productSearchType?: ProductSearchParams["type"];
    skusToExclude?: Record<string, string>;
    tagProps?: TagProps;
    filters?: {
      exclude_digital?: boolean;
      track_by_expiration?: boolean;
      track_by_lot?: boolean;
    };
  };

const ProductSelectComponent = <IsMulti extends boolean>(
  {
    components,
    productSearchType,
    filters = {},
    skusToExclude,
    tagProps,
    ...props
  }: ProductSelectProps<IsMulti>,
  ref: ForwardedRef<HTMLElement>,
) => {
  const productSearch = useProductSearch();
  const [searchOptions, setSearchOptions] = useState<Product[] | true>(true);

  return (
    <AsyncSelect<Product & { disabledCopy?: string }, IsMulti>
      ref={ref}
      loadOptions={debounce(
        (inputValue: string, callback: (products: Product[]) => void) => {
          productSearch({
            search: inputValue,
            sort: "sku",
            type: productSearchType,
            ...filters,
          }).then(({ data }) => {
            const updatedData = data.map((product) => ({
              ...product,
              disabledCopy: skusToExclude?.[product.sku],
            }));
            setSearchOptions(updatedData);
            callback(updatedData);
          });
        },
        250,
      )}
      // This behavior exists to display the last query's results when the user clicks on the input again. This is for the use case of
      // a user searching for a product, selecting the wrong one, and then clicking on the input again to select for the correct product.
      defaultOptions={searchOptions}
      noOptionsMessage={({ inputValue }) =>
        inputValue ? "No results" : "Type to search available options"
      }
      getOptionLabel={(product) => `${product.sku} - ${product.name}`}
      getOptionValue={(product) => product.sku}
      formatOptionLabel={(product) => (
        <MaybeProductImage sku={product.sku} size="20px" gap={2}>
          {product.sku}
        </MaybeProductImage>
      )}
      components={{
        Option: ProductSelectOption,
        ...components,
      }}
      isSearchable
      menuPortalTarget={document.body}
      tagProps={{
        size: "md",
        ...tagProps,
      }}
      {...props}
    />
  );
};

export const ProductSelect = forwardRef(ProductSelectComponent) as <
  IsMulti extends boolean = false,
>(
  props: ProductSelectProps<IsMulti> & {
    ref?: ForwardedRef<HTMLElement>;
  },
) => ReturnType<typeof ProductSelectComponent>;

function ProductSelectOption<IsMulti extends boolean>(
  props: OptionProps<Product & { disabledCopy?: string }, IsMulti>,
) {
  const { sku, name, disabledCopy } = props.data;

  return (
    <OurSelectOption {...props} isDisabled={Boolean(disabledCopy)}>
      <MaybeProductImage sku={sku}>
        <StackedCell
          lines={[
            <Text key="sku">{sku}</Text>,
            <HStack key="name">
              <Text
                textStyle="caption"
                overflow="hidden"
                textOverflow="ellipsis"
              >
                {name}
              </Text>
              {disabledCopy ? (
                <Badge colorScheme="green" key="badge">
                  {disabledCopy}
                </Badge>
              ) : null}
            </HStack>,
          ]}
          overflow={"hidden"}
        />
      </MaybeProductImage>
    </OurSelectOption>
  );
}
