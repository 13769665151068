import type { ComponentSingleStyleConfig } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

export default {
  baseStyle: (props) => ({
    container: {
      backgroundColor: mode("gray.100", "gray.900")(props),
      color: mode("gray.800", "gray.200")(props),
    },
  }),
} as ComponentSingleStyleConfig;
