import type React from "react";
import { Box, Flex, type BoxProps } from "@chakra-ui/react";

import { isStringElement } from "../../utils";
import { ConditionalWrapper } from "../ConditionalWrapper";
import { SEO } from "../SEO";

import { Breadcrumbs } from "./Breadcrumbs";
import { HeaderTitle } from "./HeaderTitle";
import type { BreadcrumbsProps } from "./Breadcrumbs";

export type HeaderProps = Omit<BoxProps, "title"> & {
  title: React.ReactNode;
  seoTitle?: string;
  shouldWrapFirstLine?: boolean;
  subtitle?: React.ReactNode;
  breadcrumbs?: BreadcrumbsProps["breadcrumbs"];
  actions?: React.ReactNode;
  tabs?: React.ReactNode;
};

export const Header = ({
  title,
  seoTitle,
  shouldWrapFirstLine,
  breadcrumbs,
  actions,
  subtitle,
  tabs,
  ...props
}: HeaderProps) => {
  return (
    <Box {...props}>
      <SEO title={seoTitle ?? (typeof title === "string" ? title : "")} />

      <Breadcrumbs breadcrumbs={breadcrumbs} mb={4} />

      <Flex direction="column" gap={1} {...(tabs && { mb: 2 })}>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          gap={4}
          wrap={shouldWrapFirstLine ? "wrap" : undefined}
        >
          <ConditionalWrapper
            condition={isStringElement(title)}
            wrapper={(children) => <HeaderTitle>{children}</HeaderTitle>}
          >
            {title}
          </ConditionalWrapper>

          {actions}
        </Flex>

        {subtitle}
      </Flex>

      {tabs}
    </Box>
  );
};
