import { useQuery } from "@tanstack/react-query";
import { useCallback } from "react";

import { useApi } from "@stordco/fe-components";

import type { Address, FacilityType, Holiday } from "./useFacilities";

type FacilityForTradeConnection = {
  alias: string;
  company_id: string;
  email: null | string;
  facility_display_name: null;
  facility_id: string;
  holidays: Holiday[] | null;
  inbound_docks: number | null;
  location_description: null;
  name: string;
  outbound_docks: number | null;
  tenant_id: string;
  timezone: string;
  total_square_feet: null | string;
  type: FacilityType;
  warehouse_management_system_id: null | string;
  wifi_enabled: boolean | null;
};

type TradeConnectionNetwork = {
  address: string | null;
  address_id: string | null;
  company_id: string;
  manual_order_creation: boolean;
  name: string;
  network_id: string;
  stord_email: string;
  tenant_id: string;
  timezone: string | null;
};

export type Wms = {
  display_name: string;
  inserted_at: string;
  name: string;
  updated_at: string;
  wms_id: string;
};

export type TradeConnection = {
  allocated_square_feet: null | string;
  facility_display_name: null | string;
  facility_id: string;
  network: TradeConnectionNetwork;
  network_id: string;
  rank: number;
  routing_adder: null | string;
  trade_connection_id: string;
  wms_id: null | string;
  wms?: Wms;
  disabled: boolean;
};

export type TradeConnectionForNetwork = TradeConnection & {
  facility: FacilityForTradeConnection;
};

export type V1Network = {
  type: "distributionNetwork";
  id: string;
  attributes: {
    insertedAt: string;
    manualOrderCreation: boolean;
    name: string;
    stordEmail: string;
    updatedAt: string;
  };
  relationships: {
    company: {
      data: { id: string; type: "company" };
      links: { related: string };
    };
    items: Links;
    shippingOrders: Links;
    tradeConnections: Links;
    warehouses: Links;
  };
};

type Links = {
  links: {
    related: string;
  };
};

export type Company = {
  company_id: string;
  contract_date: null;
  name: string;
  renewal_date: null;
  tenant_id: string;
  updated_at: string;
  updated_by: string | null;
};

export type Network = {
  address?: Omit<Address, "country_code"> & { country_code: string };
  billing_name: string | null;
  company: Omit<Company, "updated_by"> & {
    updated_by: string;
  };
  company_id: string;
  connections_count: number;
  facilities_count: number;
  items_count: number;
  kits_count: number;
  manual_order_creation: boolean;
  name: string;
  network_id: string;
  products_count: number;
  protection_revenue_split: string | null;
  status: "active" | "inactive";
  stord_email: string;
  tenant_company_mappings: Array<{ tenant_company_mapping_id: string }>;
  tenant_id: string;
  timezone: string | null;
  trade_connections: Array<TradeConnectionForNetwork>;
  users_count: number;
};

// TODO: Flesh out this type, it's not quite _this_ simple
export type SimpleNetwork = {
  network_id: string;
  tenant_id: string;
  name: string;
};

type NetworkType<E extends boolean> = E extends true ? Network : SimpleNetwork;

interface Params {
  limit?: string;
  before?: string;
  after?: string;
  "filter[status]"?: string;
  "filter[name]"?: string;
  "filter[network_id]"?: string;
  "filter[network_id][]"?: string[];
  sort?:
    | "name"
    | "-name"
    | "status"
    | "-status"
    | "inserted_at"
    | "-inserted_at"
    | "updated_at"
    | "-updated_at";
}

export function useFetchNetworks<E extends boolean>() {
  const api = useApi();

  return useCallback(
    ({ params, enhanced }: { params: Params; enhanced?: E }) =>
      api.get<{
        data: NetworkType<E>[];
        metadata: {
          after: string | null;
          before: string | null;
          limit: number;
          total_count: number;
        };
      }>({
        url: "/bff/admin/v2/networks",
        params: {
          limit: params.limit,
          before: params.before,
          after: params.after,
          "filter[name]": params["filter[name]"]
            ? `%${params["filter[name]"]}%` // just ilike the thing
            : undefined,
          "filter[status]": params["filter[status]"],
          "filter[network_id]": params["filter[network_id]"],
          "filter[network_id][]": params["filter[network_id][]"],
          sort: params.sort,
          enhanced,
        },
      }),
    [api],
  );
}

/**
 * Fetch V2 networks.
 */
export function useNetworks<E extends boolean = false>(
  props: { params: Params; enhanced?: E } = { params: {} },
) {
  const fetchNetworks = useFetchNetworks<E>();

  return useQuery({
    queryKey: ["Networks", props],
    queryFn: () => fetchNetworks(props),
  });
}
