// TODO: these formatters are currently only used in this repo, but they could be generalized for usage

import { isThisYear } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";

// across all stord clients.
export const formatDate = (
  date: Date | null | undefined,
  year: Intl.DateTimeFormatOptions["year"] = "2-digit",
  month: Intl.DateTimeFormatOptions["month"] = "2-digit",
  day: Intl.DateTimeFormatOptions["day"] = "2-digit",
) => {
  if (!date) {
    return null;
  }

  const options: Intl.DateTimeFormatOptions = {
    month,
    day,
    year,
    timeZone: "UTC",
  };

  return new Intl.DateTimeFormat("en-US", options as any)
    .format(date)
    .replace(/\//g, "-");
};

export const formatLongDate = (date: Date | null | undefined) => {
  if (!date) {
    return null;
  }

  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
    timeZone: "UTC",
  };
  return new Intl.DateTimeFormat("en-US", options).format(date);
};

export const formatTime = (
  date: Date | null | undefined,
  timeZone: Intl.DateTimeFormatOptions["timeZone"] = "Etc/UTC",
) => {
  if (!date) {
    return null;
  }

  const options: Intl.DateTimeFormatOptions = {
    hour: "2-digit",
    minute: "2-digit",
    timeZone,
    timeZoneName: "short",
  };

  return new Intl.DateTimeFormat("en-US", options).format(date);
};

export const getTimeStampString = (date: Date): string => {
  return `${formatTime(date)} ${formatDate(date)}`;
};

export const getDateTimeTzString = (
  date: string,
  time?: string,
  utcOffset?: string,
  timeZone?: string,
) => {
  const dateTimeStrings: { time: string | null; date: string | null } = {
    time: null,
    date: null,
  };

  if (date) {
    dateTimeStrings.date = formatDate(new Date(date));
  }

  if (time && utcOffset && timeZone) {
    dateTimeStrings.time = formatTime(
      new Date(date + "T" + time + utcOffset),
      timeZone,
    );
  }
  return dateTimeStrings;
};

export const DATETIME_FORMAT_STRINGS = {
  time: "h:mm a zzz",
  dateWithYear: "MMM d, yyyy",
  dateWithoutYear: "MMM d",
  dateWithDayAndYear: "E, MMM d, yyyy",
  dateWithDayWithoutYear: "E, MMM d",
  dateWithDayAndTimeAndYear: "E, MMM d, yyyy h:mm a zzz",
  dateWithDayAndTimeWithoutYear: "E, MMM d h:mm a zzz",
  dateWithTimeWithoutYear: "MMM d, h:mm a zzz",
  dayOfWeek: "EEEE",
};

export type DateTimeFormatInputs =
  | "date"
  | "time"
  | "datetime"
  | "dateWithDay"
  | "dateNoYear"
  | "dateWithDayNoYear"
  | "dateWithDayAndTime"
  | "dayOfWeek";
export const formatDateTime = (
  date: Date | number,
  timeZone: string,
  type: DateTimeFormatInputs | undefined,
  formatStr?: string | undefined,
) => {
  switch (type) {
    case "date":
      return formatInTimeZone(
        date,
        timeZone,
        formatStr
          ? formatStr
          : isThisYear(date)
          ? DATETIME_FORMAT_STRINGS.dateWithoutYear
          : DATETIME_FORMAT_STRINGS.dateWithYear,
      );
    case "dateNoYear":
      return formatInTimeZone(
        date,
        timeZone,
        formatStr ? formatStr : DATETIME_FORMAT_STRINGS.dateWithoutYear,
      );
    case "dateWithDay":
      return formatInTimeZone(
        date,
        timeZone,
        formatStr
          ? formatStr
          : isThisYear(date)
          ? DATETIME_FORMAT_STRINGS.dateWithDayWithoutYear
          : DATETIME_FORMAT_STRINGS.dateWithDayAndYear,
      );
    case "dateWithDayNoYear":
      return formatInTimeZone(
        date,
        timeZone,
        formatStr ? formatStr : DATETIME_FORMAT_STRINGS.dateWithDayWithoutYear,
      );
    case "dateWithDayAndTime":
      return formatInTimeZone(
        date,
        timeZone,
        formatStr
          ? formatStr
          : isThisYear(date)
          ? DATETIME_FORMAT_STRINGS.dateWithDayAndTimeWithoutYear
          : DATETIME_FORMAT_STRINGS.dateWithDayAndTimeAndYear,
      );
    case "dayOfWeek":
      return formatInTimeZone(
        date,
        timeZone,
        formatStr ? formatStr : DATETIME_FORMAT_STRINGS.dayOfWeek,
      );
    case "time":
      return formatInTimeZone(
        date,
        timeZone,
        formatStr ?? DATETIME_FORMAT_STRINGS.time,
      );
    default:
      return formatInTimeZone(
        date,
        timeZone,
        formatStr ??
          `${
            isThisYear(date)
              ? DATETIME_FORMAT_STRINGS.dateWithoutYear
              : DATETIME_FORMAT_STRINGS.dateWithYear
          }, ${DATETIME_FORMAT_STRINGS.time}`,
      );
  }
};

/** Safely parse a date string that might be `null`
 * `new Date(null)` returns 01-01-1970T00:00:00Z
 */
export const parseDate = (dateString: string | null | undefined) => {
  if (!dateString) {
    return null;
  }

  return new Date(dateString);
};

export const parseCookie = (cname: string): any => {
  if (
    ![
      "localhost",
      "warehouse.staging.stord.com",
      "shipper.staging.stord.com",
      "admin.staging.stord.com",
    ].includes(location.hostname)
  ) {
    return false;
  }
  const name = cname + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return JSON.parse(c.substring(name.length, c.length));
    }
  }
  return null;
};

const baseUSNumberFormat = new Intl.NumberFormat("en-US");
const baseUSDecimalFormat = new Intl.NumberFormat("en-US", {
  style: "decimal",
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});
const baseUSCurrencyFormat = new Intl.NumberFormat("en-us", {
  style: "currency",
  currency: "USD",
});

export const formatNumber = (
  value: number | null | undefined,
  defaultValue = "-",
) => {
  if (typeof value !== "number") return defaultValue;

  return baseUSNumberFormat.format(value);
};

export const formatDecimal = (
  value: number | null | undefined,
  defaultValue = "-",
) => {
  if (typeof value !== "number") return defaultValue;

  return baseUSDecimalFormat.format(value);
};

export const formatCurrency = (
  value: string | number | null | undefined,
  defaultValue = "-",
) => {
  let valueToFormat = value;
  if (valueToFormat === null || valueToFormat === undefined)
    return defaultValue;

  if (typeof valueToFormat !== "number" && typeof valueToFormat === "string") {
    valueToFormat = parseFloat(valueToFormat);
  }

  if (isNaN(valueToFormat)) return defaultValue;

  return baseUSCurrencyFormat.format(valueToFormat);
};
