import { Box, Stack, StackDivider } from "@chakra-ui/react";

import { createCalendar, type DateValue } from "@internationalized/date";
import {
  type RangeCalendarProps,
  useRangeCalendar,
} from "@react-aria/calendar";
import { useLocale } from "@react-aria/i18n";
import { useRangeCalendarState } from "@react-stately/calendar";
import { useRef } from "react";

import { CalendarGrid, CalendarHeader } from "./Calendar";

export const RangeCalendar = (props: RangeCalendarProps<DateValue>) => {
  const { locale } = useLocale();
  const state = useRangeCalendarState({
    ...props,
    visibleDuration: { months: 2 },
    locale,
    createCalendar,
  });

  const ref = useRef<HTMLDivElement>(null);
  const { calendarProps, prevButtonProps, nextButtonProps, title } =
    useRangeCalendar(props, state, ref);

  return (
    <Box {...calendarProps} ref={ref} maxWidth="xl">
      <CalendarHeader>
        <CalendarHeader.PrevYear state={state} />
        <CalendarHeader.PrevMonth {...prevButtonProps} />
        <CalendarHeader.Title title={title} size="sm" />
        <CalendarHeader.NextMonth {...nextButtonProps} />
        <CalendarHeader.NextYear state={state} />
      </CalendarHeader>
      <Stack direction="row" gap={2} divider={<StackDivider />}>
        <CalendarGrid state={state} />
        <CalendarGrid state={state} offset={{ months: 1 }} />
      </Stack>
    </Box>
  );
};
