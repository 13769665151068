import { Helmet, type HelmetProps } from "react-helmet-async";
import { useMemo, type ReactNode } from "react";

import { useUser, useIsAdmin } from "@stordco/fe-components";

import { useNetwork } from "../Network";

/**
 * Wrapper around Helmet that injects the distribution network name into the title for Stord Admins
 * @see https://github.com/nfl/react-helmet for more info on the Helmet component
 * @see https://www.npmjs.com/package/react-helmet-async for fork-specific info
 */
export const SEO = (props: HelmetProps & { children?: ReactNode }) => {
  const network = useNetwork();
  const user = useUser();
  const isAdmin = useIsAdmin();

  const hasAccessToMultipleNetworks = useMemo(() => {
    // If user is admin, they have access to all networks
    if (isAdmin) {
      return true;
    }

    const networks = [];

    for (const organization of user.organizations) {
      for (const app of organization.apps!.filter(
        (app) => app.alias === "oms",
      )) {
        networks.push(...app.realms);
      }
    }

    return networks.length > 1;
  }, [user, isAdmin]);

  // Make it easy for users with access to multiple networks to know which network the tab is for
  const titleTemplate = hasAccessToMultipleNetworks
    ? `%s (${network.name}) | Stord OMS`
    : "%s | Stord OMS";

  return (
    <Helmet defaultTitle="Stord OMS" titleTemplate={titleTemplate} {...props} />
  );
};
