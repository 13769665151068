import { Button, Flex, Text, Tooltip, Box, Circle } from "@chakra-ui/react";
import { faCircleInfo } from "@fortawesome/pro-regular-svg-icons";
import { useMemo, useState } from "react";
import { transparentize } from "@chakra-ui/theme-tools";
import type { LinkProps } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import { addDays } from "date-fns";
import { motion } from "framer-motion";
import {
  autoUpdate,
  flip,
  FloatingPortal,
  offset,
  shift,
  useClientPoint,
  useFloating,
  useHover,
  useInteractions,
  useRole,
} from "@floating-ui/react";

import {
  Card,
  FaIcon,
  qs,
  useDatadogRUM,
  useSearchParams,
} from "@stordco/fe-components";

import { useFormatInPreferredTimezone } from "../../../../hooks";
import { useNetworkId } from "../../../../components/Network";
import { formatNumber } from "../../../../lib/numbers";
import { safePercent } from "../../../../utils/safe-percent";

import { StatButtonLink } from "../../StatButtonLink";
import { useWidgetData } from "./use-widget-data";
import { LoadingOverlay } from "../../LoadingOverlay";
import { WidgetErrorFetchingData } from "../../WidgetErrorFetchingData";
import { WidgetTooltip } from "./WidgetTooltip";

const MotionBox = motion(Box);

export function OpenOrders() {
  const [params] = useSearchParams();
  const { data, isLoading, isError, refetch } = useWidgetData("open-orders");
  const format = useFormatInPreferredTimezone();
  const networkId = useNetworkId();
  const datadogRUM = useDatadogRUM();

  const rowCalculations = useMemo(() => {
    if (!data) {
      return {
        last5Days: [
          ...Array.from({ length: 5 }, (...[, index]) => ({
            date: addDays(new Date(), -index).toISOString(),
            count: 0,
            lateCount: 0,
            percentMax: 0,
            latePercent: 0,
          })),
        ],
        olderCount: 0,
        olderLateCount: 0,
        olderPercentMax: 0,
        olderLatePercent: 0,
      };
    }

    const last5DaysTotal = data.accepted_last_5_days.reduce(
      (total, { count }) => total + count,
      0,
    );

    const last5DaysLateTotal = data.accepted_last_5_days.reduce(
      (total, { late_count }) => total + late_count,
      0,
    );

    const olderCount = data.total_count - last5DaysTotal;
    const olderLateCount = data.total_late_count - last5DaysLateTotal;

    const max = [
      ...data.accepted_last_5_days.map(({ count }) => count),
      olderCount,
    ].reduce((max, count) => Math.max(max, count));

    return {
      olderCount,
      olderLateCount,
      olderPercentMax: safePercent(olderCount, max),
      olderLatePercent: safePercent(olderLateCount, olderCount),
      last5Days: [...data.accepted_last_5_days]
        .sort((a, b) => b.date.localeCompare(a.date))
        .map(({ date, count, late_count }) => ({
          date,
          count,
          lateCount: late_count,
          percentMax: safePercent(count, max),
          latePercent: safePercent(late_count, count),
        })),
    };
  }, [data]);

  return (
    <Card>
      <Card.PaddedContent
        display="flex"
        flexDirection="column"
        gap={4}
        flex={1}
      >
        <Flex alignItems="center" gap={2} alignSelf="start">
          <Card.Title>Open</Card.Title>

          <Tooltip
            label="Orders in Open, Accepted, Partially shipped, or Partially backordered statuses"
            placement="top"
          >
            <FaIcon icon={faCircleInfo} boxSize={3} p={1} m={-1} />
          </Tooltip>
        </Flex>

        {isError ? (
          <WidgetErrorFetchingData onRetryClick={refetch} />
        ) : (
          <>
            <Flex gap={4}>
              <StatButtonLink
                number={formatNumber(data?.total_count)}
                helpText="Open"
                to={qs.stringifyUrl({
                  url: `/distribution_networks/${networkId}/orders`,
                  query: {
                    type: "sales",
                    status: [
                      "accepted",
                      "open",
                      "partially_shipped",
                      "partially_backordered",
                    ],
                    ...params,
                  },
                })}
                statProps={{ size: "lg" }}
                onClick={() => {
                  datadogRUM?.addAction("dashboard:view_orders", {
                    type: "open",
                    count: data?.total_count,
                  });
                }}
                flex={1}
              />

              <StatButtonLink
                number={formatNumber(data?.total_late_count)}
                helpText="Open and late"
                to={qs.stringifyUrl({
                  url: `/distribution_networks/${networkId}/orders`,
                  query: {
                    type: "sales",
                    status: [
                      "accepted",
                      "open",
                      "partially_shipped",
                      "partially_backordered",
                    ],
                    // The 2nd date in `last5Days` is yesterday
                    expected_sla_date_end_date:
                      rowCalculations.last5Days[1].date.substring(0, 10),
                    ...params,
                  },
                })}
                statProps={{ size: "lg" }}
                onClick={() => {
                  datadogRUM?.addAction("dashboard:view_orders", {
                    type: "open_and_late",
                    count: data?.total_late_count,
                  });
                }}
                flex={1}
              />
            </Flex>

            <Text textStyle="label">Open orders by accepted date</Text>

            <Flex direction="column">
              {[
                ...rowCalculations.last5Days.map(
                  (
                    { date, count, lateCount, percentMax, latePercent },
                    index,
                  ) => ({
                    label: format(date, { type: "dateNoYear" })!,
                    tooltipLabel: format(date, { type: "dateWithDay" })!,
                    percent: percentMax,
                    latePercent,
                    count,
                    lateCount,
                    to: qs.stringifyUrl({
                      url: `/distribution_networks/${networkId}/orders`,
                      query: {
                        type: "sales",
                        status: [
                          "accepted",
                          "open",
                          "partially_shipped",
                          "partially_backordered",
                        ],
                        inserted_at_start_date: date.substring(0, 10),
                        inserted_at_end_date: date.substring(0, 10),
                        ...params,
                      },
                    }),
                    onClick() {
                      datadogRUM?.addAction("dashboard:view_orders", {
                        type: "open_by_date",
                        daysAgo: index,
                        count,
                      });
                    },
                  }),
                ),
                {
                  label: "Older",
                  tooltipLabel: "Older",
                  percent: rowCalculations.olderPercentMax,
                  latePercent: rowCalculations.olderLatePercent,
                  count: rowCalculations.olderCount,
                  lateCount: rowCalculations.olderLateCount,
                  to: qs.stringifyUrl({
                    url: `/distribution_networks/${networkId}/orders`,
                    query: {
                      type: "sales",
                      status: [
                        "accepted",
                        "open",
                        "partially_shipped",
                        "partially_backordered",
                      ],
                      inserted_at_end_date: addDays(
                        new Date(rowCalculations.last5Days.at(-1)!.date),
                        -1,
                      )
                        .toISOString()
                        .substring(0, 10),
                      ...params,
                    },
                  }),
                  onClick() {
                    datadogRUM?.addAction("dashboard:view_orders", {
                      type: "open_older",
                      count: rowCalculations.olderCount,
                    });
                  },
                },
              ].map(
                (
                  {
                    label,
                    tooltipLabel,
                    percent,
                    latePercent,
                    count,
                    lateCount,
                    to,
                  },
                  index,
                ) => (
                  <OpenStatRow
                    key={index}
                    to={to}
                    label={label}
                    tooltipLabel={tooltipLabel}
                    percent={percent}
                    latePercent={latePercent}
                    count={count}
                    lateCount={lateCount}
                  />
                ),
              )}
            </Flex>
          </>
        )}

        {isLoading ? <LoadingOverlay /> : null}
      </Card.PaddedContent>
    </Card>
  );
}

function OpenStatRow({
  to,
  label,
  tooltipLabel,
  percent,
  latePercent,
  count,
  lateCount,
}: Pick<LinkProps, "to"> & {
  label: string;
  tooltipLabel: string;
  percent: number;
  latePercent: number;
  count: number;
  lateCount: number;
}) {
  const countWidth = 48;

  const [isOpen, setIsOpen] = useState(false);

  const { refs, floatingStyles, context } = useFloating({
    placement: "right-start",
    whileElementsMounted: autoUpdate,
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [offset(10), flip(), shift()],
  });

  const hover = useHover(context);
  const role = useRole(context, { role: "tooltip" });
  const clientPoint = useClientPoint(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    role,
    clientPoint,
  ]);

  const openCount = count - lateCount;

  return (
    <>
      <Button
        ref={refs.setReference}
        as={RouterLink}
        to={to}
        variant="ghost"
        colorScheme="gray"
        textAlign="left"
        height="auto"
        px={2}
        mx={-2}
        py={0.5}
        justifyContent="start"
        gap={1}
        {...getReferenceProps()}
      >
        <Text
          width="48px"
          color="gray.700"
          _dark={{ color: "gray.300" }}
          fontSize="xs"
        >
          {label}
        </Text>

        <Box flex={1}>
          <Box width={`calc(100% - ${countWidth}px)`} position="relative">
            <MotionBox
              animate={{
                width: `max(${percent * 100}%, 1px)`,
              }}
              transition={{
                ease: "easeOut",
                duration: 0.8,
              }}
              initial={false}
              height={3}
              position="relative"
            >
              <Box
                bgColor="blue.200"
                _dark={{
                  bgColor: transparentize("blue.200", 0.48),
                }}
                rounded="base"
                position="absolute"
                inset={0}
              />

              <Box
                bgColor="magenta.400"
                _dark={{
                  bgColor: transparentize("magenta.200", 0.48),
                }}
                rounded="base"
                position="absolute"
                top={0}
                bottom={0}
                left={0}
                width={`${latePercent * 100}%`}
              />
            </MotionBox>

            <Text
              width={`${countWidth}px`}
              fontWeight="semibold"
              position="absolute"
              left={`calc(${percent * 100}% + 8px)`}
              top="50%"
              transform={`translateY(-50%)`}
              fontSize="xs"
              color="gray.900"
              _dark={{
                color: "gray.100",
              }}
            >
              {formatNumber(count)}
            </Text>
          </Box>
        </Box>
      </Button>

      {isOpen && (
        <FloatingPortal>
          <WidgetTooltip
            ref={refs.setFloating}
            style={floatingStyles}
            {...getFloatingProps()}
          >
            <Flex direction="column" gap={1}>
              <Text textStyle="tooltip">{tooltipLabel}</Text>

              <Flex alignItems="center" gap={2}>
                <Circle
                  bgColor="blue.200"
                  _dark={{
                    bgColor: transparentize("blue.200", 0.48),
                  }}
                  size="2"
                />

                <Text textStyle="caption">Open within SLA</Text>

                <Text textStyle="tooltip" ml="auto">
                  {formatNumber(openCount)}
                </Text>
              </Flex>

              <Flex alignItems="center" gap={2}>
                <Circle
                  bgColor="magenta.400"
                  _dark={{
                    bgColor: transparentize("magenta.200", 0.48),
                  }}
                  size="2"
                />

                <Text textStyle="caption">Open and late</Text>

                <Text textStyle="tooltip" ml="auto">
                  {formatNumber(lateCount)}
                </Text>
              </Flex>
            </Flex>
          </WidgetTooltip>
        </FloatingPortal>
      )}
    </>
  );
}
