import { ErrorFetchingData } from "@stordco/fe-components";

export function WidgetErrorFetchingData({
  onRetryClick,
}: {
  onRetryClick: () => void;
}) {
  return (
    <ErrorFetchingData
      size="md"
      containerOverride={{
        minHeight: undefined,
        my: "auto",
      }}
      containerProps={{ minHeight: "auto" }}
      onRetryClick={onRetryClick}
      description={null}
      py={4}
    />
  );
}
