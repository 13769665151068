import { useState, useEffect, type ReactNode } from "react";
import { useLDClient } from "launchdarkly-react-client-sdk";

import { PageLoader, useUser } from "@stordco/fe-components";

import type { NetworkContextValue } from "./Network";
import { useNetwork } from "./Network";

export function LaunchDarklyIdentifier({
  children,
  network,
}: {
  children: ReactNode;
  network?: NetworkContextValue;
}) {
  const ldClient = useLDClient();

  if (!ldClient) {
    throw new Error(
      `${useLDClient.name}() returned ${ldClient}. Missing <FeatureFlagProvider>?`,
    );
  }

  const [isIdentified, setIsIdentified] = useState(false);
  const user = useUser();

  useEffect(() => {
    const { organizations, roles, ...attributes } = user;

    ldClient
      .identify({
        kind: "user",
        key: user.id,
        ...attributes,
        network: network?.network_id,
      })
      .catch(console.log)
      .finally(() => {
        setIsIdentified(true);
      });
  }, [ldClient, network?.network_id, user]);

  if (!isIdentified) {
    // TODO: suspend
    return <PageLoader />;
  }

  return children;
}

export function LaunchDarklyIdentifierWithNetwork({
  children,
}: {
  children: ReactNode;
}) {
  const network = useNetwork();

  return (
    <LaunchDarklyIdentifier network={network}>
      {children}
    </LaunchDarklyIdentifier>
  );
}
