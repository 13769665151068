import { useContext } from "react";

import { AuthenticationContext } from "../context";

/**
 * @remarks This hook is now just a wrapper around auth0-react's `useAuth0` hook. If you need access to the current user,
 * please use `useUser()` instead. The `user` details that this hook returns is not what you're looking for.
 */
export function useAuthentication() {
  const value = useContext(AuthenticationContext);

  if (!value) {
    throw new Error(
      `${useAuthentication.name} must be called within a <AuthenticationProvider>`,
    );
  }

  return value;
}
