import { Box, forwardRef, type BoxProps } from "@chakra-ui/react";

/**
 * Use this to get the standard card padding.
 * You might skip this if you have something that should stretch to the edges of the card,
 * like a table or an image.
 */
export const CardPaddedContent = forwardRef<BoxProps, "div">(
  function CardPaddedContent(props, ref) {
    return <Box p={6} ref={ref} {...props} />;
  },
);
