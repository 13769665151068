import { useQuery } from "@tanstack/react-query";

import { useApi } from "@stordco/fe-components";

export type Territory = {
  territory_code: string;
  label: string;
  sort: number;
};

export const useTerritories = () => {
  const api = useApi();

  return useQuery({
    queryKey: ["territories"],
    queryFn: () => api<{ data: Territory[] }>("/bff/v1/reference/territories"),
    select: ({ data }) => {
      return data.slice().sort((a, b) => {
        if (a.territory_code === "US") {
          return -1; // `US` comes first
        }
        if (b.territory_code === "US") {
          return 1; // `US` comes first
        }

        if (a.territory_code === "CA") {
          return -1; // `Canada` comes second
        }
        if (b.territory_code === "CA") {
          return 1; // `Canada` comes second
        }

        return a.label.localeCompare(b.label); // Sort rest of the countries alphabetically
      });
    },
    staleTime: 5 * 60 * 1000, // 5 minutes
  });
};

export const formatTerritory = (territory: Territory) => {
  return `${territory.territory_code} - ${territory.label}`;
};

export type Subdivision = {
  subdivision_code: string;
  label: string;
  sort: number;
};

export const useSubdivisions = (territoryCode: string | undefined) => {
  const api = useApi();

  return useQuery({
    queryKey: ["subdivisions", territoryCode],
    queryFn: () =>
      api<{ data: Subdivision[] }>(
        `/bff/v1/reference/territories/${territoryCode}`,
      ),
    select: ({ data }) => {
      // Temporary workaround to add military addresses to US subdivisions response
      if (territoryCode?.toUpperCase() === "US") {
        return [
          ...data,
          { label: "Armed Forces Americas", subdivision_code: "AA" },
          { label: "Armed Forces Europe", subdivision_code: "AE" },
          { label: "Armed Forces Pacific", subdivision_code: "AP" },
          { label: "U.S. Virgin Islands", subdivision_code: "VI" },
        ]
          .sort((first, second) =>
            first.subdivision_code.localeCompare(second.subdivision_code),
          )
          .map((subdivision, index) => ({ ...subdivision, sort: index }));
      }

      return data;
    },
    staleTime: 5 * 60 * 1000, // 5 minutes
    enabled: !!territoryCode,
  });
};
