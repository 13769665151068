import type { ComponentProps } from "react";
import { chakra, useMultiStyleConfig } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";

export function LinkTabs(props: ComponentProps<typeof chakra.div>) {
  const styles = useMultiStyleConfig("Tabs", props);

  return <chakra.div {...props} __css={styles.root} />;
}

LinkTabs.TabList = function TabList(props: ComponentProps<typeof chakra.div>) {
  const styles = useMultiStyleConfig("Tabs", props);

  return (
    <chakra.div
      {...props}
      role="tablist"
      __css={{
        display: "flex",
        ...styles.tablist,
      }}
    />
  );
};

const ChakraNavLink = chakra(NavLink);

LinkTabs.Tab = function Tab(props: ComponentProps<typeof ChakraNavLink>) {
  const styles = useMultiStyleConfig("Tabs", props);

  return (
    <ChakraNavLink
      {...props}
      role="tab"
      __css={{
        outline: "0",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        _activeLink: {
          ...(styles.tab as any)._selected,
        },
        ...styles.tab,
      }}
    />
  );
};
