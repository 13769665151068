import type { ReactElement } from "react";
import type { FormControlProps } from "@chakra-ui/react";
import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  InputGroup,
  type InputGroupProps,
  VisuallyHidden,
} from "@chakra-ui/react";
import type {
  FieldPath,
  FieldValues,
  UseControllerProps,
} from "react-hook-form";
import { useController } from "react-hook-form";
import type { GroupBase } from "react-select";

import type { SelectOption, SelectProps } from "../../Select";
import { Select } from "../../Select";
import { ConditionalWrapper } from "../../ConditionalWrapper";
import { getFieldErrorMessage } from "../utils";

import { getOnChange, getValues } from "./rhf-select-components";

/**
 * @remarks When using `isMulti` RHFSelect will automatically transform your value into `string[]`.
 *          It requires `Option` to be `{ value: string; label: string }`.
 */
export const RHFSelect = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  OptionType extends SelectOption = SelectOption,
  IsMulti extends boolean = false,
  GroupType extends GroupBase<OptionType> = GroupBase<OptionType>,
>({
  label,
  hideLabel = false,
  placeholder,
  options = [],
  helperText,
  isRequired,
  isDisabled,
  isMulti,
  defaultValue,
  name,
  rules,
  shouldUnregister,
  control,
  hideCheckIcon = false,
  onChange: parentOnChange,
  rightAddon,
  groupProps,
  numSelectedPlaceholder,
  formControlProps,
  ...selectProps
}: UseControllerProps<TFieldValues, TName> &
  Pick<FormControlProps, "isDisabled" | "isRequired"> & {
    helperText?: string;
    label: string;
    hideLabel?: boolean;
    placeholder?: string;
    hideCheckIcon?: boolean;
    options: OptionType[] | GroupType[];
    onChange?: (value: any) => void;
    rightAddon?: ReactElement;
    groupProps?: InputGroupProps;
    addOnDirection?: "right" | "left";
    numSelectedPlaceholder?: true;
    formControlProps?: FormControlProps;
  } & Omit<
    SelectProps<OptionType, IsMulti, GroupType>,
    "defaultValue" | "onChange"
  >) => {
  const { field, formState } = useController({
    defaultValue: defaultValue ?? ("" as any),
    name,
    rules,
    shouldUnregister,
    control,
  });

  const { onChange, value, ...fieldProps } = field;
  const errorMessage = getFieldErrorMessage(formState, field.name);
  const canShowHelperText = !errorMessage && !!helperText;

  return (
    <FormControl
      isInvalid={!!errorMessage}
      id={field.name}
      isRequired={isRequired}
      isDisabled={isDisabled}
      {...formControlProps}
    >
      <ConditionalWrapper
        condition={hideLabel}
        wrapper={(children) => <VisuallyHidden>{children}</VisuallyHidden>}
      >
        <FormLabel htmlFor={field.name}>{label}</FormLabel>
      </ConditionalWrapper>
      <ConditionalWrapper
        condition={Boolean(rightAddon)}
        wrapper={(children) => (
          <InputGroup {...groupProps}>
            {children}
            {rightAddon}
          </InputGroup>
        )}
      >
        <Select
          {...fieldProps}
          aria-label={label}
          isMulti={isMulti}
          placeholder={placeholder}
          options={options}
          hideCheckIcon={hideCheckIcon}
          // NOTE: this complains about it being a string which it really shouldn't be
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          value={getValues(!!isMulti, options, value)}
          isDisabled={isDisabled}
          isRequired={isRequired}
          {...selectProps}
          {...(rightAddon && {
            addOnDirection: "left",
          })}
          onChange={(option, state) =>
            getOnChange({
              option,
              state,
              isMulti: !!isMulti,
              options,
              onChange,
              parentOnChange,
            })
          }
          {...(numSelectedPlaceholder && {
            controlShouldRenderValue: false,
            placeholder:
              Array.isArray(field.value) && field.value.length
                ? `${field.value.length} selected`
                : placeholder,
          })}
        />
      </ConditionalWrapper>

      {canShowHelperText && <FormHelperText>{helperText}</FormHelperText>}
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  );
};
