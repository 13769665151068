import {
  type ComponentSingleStyleConfig,
  popperCSSVars,
  Tooltip,
} from "@chakra-ui/react";
import type { TooltipProps } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

import typography from "../foundations/typography";

/**
 * @remarks This looks strange, because it is. Chakra has a limitation on to what and how we can theme things,
 * so we have to set the default props like we're doing here.
 * @see https://github.com/chakra-ui/chakra-ui/issues/1424
 */
const tooltipProps: Omit<TooltipProps, "children"> = {
  ...Tooltip.defaultProps,
  ...typography.textStyles.tooltip,
  hasArrow: true,
  color: "gray.100",
};
Tooltip.defaultProps = tooltipProps;

const TooltipStyles = {
  baseStyle: (props) => ({
    // There's no other way to style the tooltip arrow besides targeting the CSS var;
    // this is how Chakra styles it in their own tooltip component.
    [popperCSSVars.arrowBg.var]: mode(
      "var(--chakra-colors-tooltip-bg)",
      "var(--chakra-colors-gray-800)",
    )(props),
    bg: mode("tooltip-bg", "gray.800")(props),
  }),
} as ComponentSingleStyleConfig;

export default TooltipStyles;
