import {
  type ComponentSingleStyleConfig,
  Modal,
  ModalProps,
} from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

import typography from "../foundations/typography";

const modalProps: Omit<ModalProps, "children" | "isOpen" | "onClose"> = {
  ...Modal.defaultProps,
  allowPinchZoom: true,
  closeOnOverlayClick: true,
  closeOnEsc: true,
};
Modal.defaultProps = modalProps;

export default {
  baseStyle: (props) => ({
    closeButton: {
      top: 3,
    },
    dialog: {
      bgColor: mode(null, "gray.900")(props),
    },
    header: {
      ...typography.textStyles.subtitle,
      paddingTop: 6,
      paddingBottom: 2,
      color: mode("gray.900", "gray.100")(props),
    },
    body: {
      textStyle: "paragraph",
    },
  }),
} as ComponentSingleStyleConfig;
