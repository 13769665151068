import { Link as ReactRouterLink } from "react-router-dom";
import { Button, Link, Text } from "@chakra-ui/react";
import { faRightToBracket } from "@fortawesome/pro-regular-svg-icons";

import { FaIcon } from "..";

import { ErrorPage } from ".";

export const AuthenticationErrorPage = () => (
  <ErrorPage
    title="Hmmm..."
    description={
      <Text>
        We&apos;re having trouble loading your information.{" "}
        <Link href="https://status.stord.com/">Check our status page</Link> to
        see if we&apos;re experiencing an outage or{" "}
        <Link href="https://helpdesk.stord.com/">contact support</Link> and
        we&apos;ll see how we can help.
      </Text>
    }
    primaryButton={
      <Button
        as={ReactRouterLink}
        leftIcon={<FaIcon icon={faRightToBracket} />}
        to="/"
      >
        Go to login
      </Button>
    }
    outsideNavigation
  />
);
