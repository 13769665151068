import type { WithoutLooseAutocomplete } from "../types";

export const statusToDisplayName: Record<KnownOrderStatus, string> = {
  accepted: "Accepted",
  available: "Available",
  backlog: "Backlog",
  backordered_hold: "Backordered hold",
  backordered: "Backordered",
  canceled: "Canceled",
  delivered: "Delivered",
  on_hold: "On Hold",
  open: "Open",
  packed: "Packed",
  partially_backordered: "Partially backordered",
  partially_delivered: "Partially delivered",
  partially_fulfilled: "Partially fulfilled",
  partially_received: "Partially received",
  partially_shipped: "Partially shipped",
  pending: "Pending",
  pick: "Pick",
  ready_for_fulfillment: "Ready for fulfillment",
  received: "Received",
  shipped: "Shipped",
  staged: "Staged",
  released: "Released",
};

export type KnownOrderStatus =
  | "accepted"
  | "available"
  | "backlog"
  | "backordered_hold"
  | "backordered"
  | "canceled"
  | "delivered"
  | "on_hold"
  | "open"
  | "packed"
  | "partially_backordered"
  | "partially_delivered"
  | "partially_fulfilled"
  | "partially_received"
  | "partially_shipped"
  | "pending"
  | "pick"
  | "ready_for_fulfillment"
  | "received"
  | "released"
  | "shipped"
  | "staged";

export type KnownOrderStatusV1 = "completed" | "open" | "cancelled";

type StrictOrderStatus = WithoutLooseAutocomplete<KnownOrderStatus>;

export const getStatusColorScheme = (
  status: StrictOrderStatus | KnownOrderStatusV1,
) => {
  switch (status) {
    case "delivered":
      return "teal";
    case "shipped":
    case "received":
    case "completed":
      return "green";
    case "partially_fulfilled":
    case "partially_shipped":
    case "partially_delivered":
    case "partially_received":
      return "mango";
    case "backordered":
    case "partially_backordered":
    case "on_hold":
    case "cancelled":
      return "red";
    case "open":
    case "backlog":
    case "released":
    case "pick":
    case "packed":
    case "staged":
    case "ready_for_fulfillment":
      return "blue";
    case "available":
    case "canceled":
    case "accepted":
      return "gray";
    default:
      return "black";
  }
};

export type StatusColor = ReturnType<typeof getStatusColorScheme>;
