import {
  Center,
  Divider,
  Flex,
  Spacer,
  Stack,
  Collapse,
  Box,
  IconButton,
  Tooltip,
  Kbd,
  DarkMode,
  useBreakpointValue,
} from "@chakra-ui/react";
import { faAngleDown, faThumbtack } from "@fortawesome/pro-regular-svg-icons";
import {
  type Dispatch,
  type SetStateAction,
  useCallback,
  useState,
  useEffect,
  useRef,
} from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useLocation } from "react-router-dom";

import { FaIcon } from "../FaIcon";
import { useIsDesktop } from "../../utils";
import { getIsModalOpen } from "../../utils/useIsModalOpen";
import { StordEmblem } from "../StordLogo/StordEmblem";
import { StordLogo } from "../StordLogo/StordLogo";
import { useLocalStorage } from "../../hooks";

import { ParentNavButton, NavButton } from "./NavButton";
import { type NavigationState, useNavigation } from "./provider";
import type { NavigationItem } from "./types";
import { AccountSwitcher } from "./AccountSwitcher";
import { Global } from "@emotion/react";

const MenuDivider = () => (
  <Center height={4}>
    <Divider borderColor="whiteAlpha.400" height={0} />
  </Center>
);

const GroupNavButton = ({
  action,
  onToggle,
  openAccordion,
  isDesktop,
  closeNavbarDrawerAction,
  navigationState,
  setOpenAccordion,
}: {
  action: NavigationItem;
  onToggle: (actionLabel: string) => void;
  openAccordion: string | null;
  isDesktop: boolean;
  closeNavbarDrawerAction?: () => void;
  navigationState: NavigationState;
  setOpenAccordion: Dispatch<SetStateAction<string | null>>;
}) => {
  const location = useLocation();

  const isParentButtonActive = action
    .children!.map((child) => child.to)
    .some((to) =>
      location.pathname.startsWith(
        new URL(to ?? "", window.location.origin).pathname,
      ),
    );
  const isOpen = openAccordion === action.label;
  const isCollapsed = navigationState === "collapsed";

  useEffect(() => {
    if (
      (isDesktop &&
        (navigationState === "hoverExpanded" ||
          navigationState === "expanded") &&
        isParentButtonActive) ||
      (!isDesktop && isParentButtonActive)
    ) {
      setOpenAccordion(action.label);
    }
  }, [
    action.label,
    isDesktop,
    isParentButtonActive,
    navigationState,
    setOpenAccordion,
  ]);

  return (
    <Box
      {...(isOpen || isParentButtonActive ? { bg: "whiteAlpha.200" } : {})}
      borderRadius="base"
    >
      <ParentNavButton
        key={`${action}`}
        onClick={() => onToggle(action.label)}
        rightIcon={
          <FaIcon
            icon={faAngleDown}
            rotation={isOpen ? 180 : undefined}
            transition="transform 0.1s ease-in, opacity 0.2s ease-out"
            {...(isParentButtonActive ? { color: "white" } : {})}
            opacity={isCollapsed && isDesktop ? 0 : 1}
          />
        }
        isDesktop={isDesktop}
        justifyContent="space-between"
        isActiveLink={isParentButtonActive}
        isOpen={isOpen}
        icon={action.icon}
        label={action.label}
        isCollapsed={isCollapsed}
      />
      <Collapse in={isOpen} animateOpacity>
        <Stack spacing={1} shouldWrapChildren paddingY={1}>
          {action.children!.map((child) => (
            <NavButton
              key={`${child.label}`}
              mx={isCollapsed ? 0 : 1}
              {...child}
              isDesktop={isDesktop}
              displayIcon={false}
              isCollapsed={isCollapsed}
              onClick={() => {
                if (!isDesktop) {
                  closeNavbarDrawerAction?.();
                }
              }}
            />
          ))}
        </Stack>
      </Collapse>
    </Box>
  );
};

// This could be a group or individual nav button, but we're not sure yet.
const GenericNavButton = ({
  action,
  onParentActionClick,
  openAccordion,
  closeNavbarDrawerAction,
  isDesktop,
  setOpenAccordion,
  navigationState,
}: {
  action: NavigationItem;
  onParentActionClick: (parentActionLabel: string) => void;
  openAccordion: string | null;
  closeNavbarDrawerAction?: () => void;
  isDesktop: boolean;
  setOpenAccordion: Dispatch<SetStateAction<string | null>>;
  navigationState: NavigationState;
}) => {
  const key = action.label;
  const isCollapsed = navigationState === "collapsed";

  if (action.children) {
    if (action.children.length > 1) {
      return (
        <GroupNavButton
          key={key}
          action={action}
          onToggle={onParentActionClick}
          openAccordion={openAccordion}
          isDesktop={isDesktop}
          closeNavbarDrawerAction={closeNavbarDrawerAction}
          navigationState={navigationState}
          setOpenAccordion={setOpenAccordion}
        />
      );
    }

    // If there's only one child in the parent, don't display the parent and only display the one child
    if (action.children.length === 1) {
      return (
        <NavButton
          key={key}
          onClick={() => {
            setOpenAccordion(null);
            if (!isDesktop) {
              closeNavbarDrawerAction?.();
            }
          }}
          isDesktop={isDesktop}
          isCollapsed={isCollapsed}
          {...action.children[0]}
        />
      );
    }

    return null;
  }

  return (
    <NavButton
      key={key}
      onClick={() => {
        setOpenAccordion(null);
        if (!isDesktop) {
          closeNavbarDrawerAction?.();
        }
      }}
      isCollapsed={isCollapsed}
      isDesktop={isDesktop}
      {...(action as Omit<typeof action, "children">)}
    />
  );
};

type SidebarProps = {
  // This is used to close the mobile navbar, when applicable, when a user clicks on a navigation item
  closeNavbarDrawerAction?: () => void;
};

export const Sidebar = ({ closeNavbarDrawerAction }: SidebarProps) => {
  const { navigationState, setNavigationState, links } = useNavigation();
  const isDesktop = useIsDesktop();
  const [openAccordionLabel, setOpenAccordionLabel] = useState<string | null>(
    null,
  );
  const isUnderXL = useBreakpointValue(
    { base: true, xl: false },
    { ssr: false },
  );

  // Use local storage
  const [autoCollapsed, setAutoCollapsed] = useLocalStorage(
    "sidebar-autocollapsed",
    false as boolean,
  );

  // Keep track of whether the nav is expanding to the pinned state;
  // when it is, we will hide the pin/unpin tooltip and render the pin/unpin button's onClick
  // useless to avoid getting into some weird hybrid states
  const [isExpandingToPinnedState, setIsExpandingToPinnedState] =
    useState(false);

  const isCollapsed = navigationState === "collapsed";
  const isHoverExpanded = navigationState === "hoverExpanded";
  const pinUnpinButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (isUnderXL && navigationState === "expanded") {
      setNavigationState("collapsed");
      setAutoCollapsed(true);
    } else if (!isUnderXL && navigationState !== "expanded" && autoCollapsed) {
      setNavigationState("expanded");
      setAutoCollapsed(false);
    }
  }, [
    autoCollapsed,
    isUnderXL,
    navigationState,
    setAutoCollapsed,
    setNavigationState,
  ]);

  useEffect(() => {
    if (isDesktop) {
      if (isHoverExpanded) {
        pinUnpinButtonRef.current?.blur();
      } else if (isCollapsed) {
        pinUnpinButtonRef.current?.blur();
        setOpenAccordionLabel(null);
      }
    }
  }, [
    isCollapsed,
    isDesktop,
    isHoverExpanded,
    pinUnpinButtonRef,
    setIsExpandingToPinnedState,
    setOpenAccordionLabel,
  ]);

  const onParentActionClick = useCallback(
    (parentActionLabel: string) => {
      if (parentActionLabel === openAccordionLabel) {
        setOpenAccordionLabel(null);
      } else {
        setOpenAccordionLabel(parentActionLabel);
      }
    },
    [openAccordionLabel, setOpenAccordionLabel],
  );

  const expandNav = useCallback(() => {
    setNavigationState("expanded");

    setIsExpandingToPinnedState(true);

    // After 0.2s (the length of the transitions), the expansion is complete, so reset the state
    const timeout = setTimeout(() => {
      setIsExpandingToPinnedState(false);
    }, 200);

    return () => clearTimeout(timeout);
  }, [setNavigationState]);

  useHotkeys(
    "[",
    () => {
      if (isUnderXL) {
        return;
      }

      const isModalOpen = getIsModalOpen();

      if (!isModalOpen) {
        if (isCollapsed || isHoverExpanded) {
          expandNav();
        } else {
          setNavigationState("collapsed");
        }
      }
    },
    [isCollapsed, isHoverExpanded, setNavigationState],
  );

  // When the user navigates away from the page, don't keep the local storage saved as "hoverExpanded";
  // Otherwise, when a user re-opens S1C later, the nav will be in a weird hoverExpanded state without the user hovering over it
  useEffect(() => {
    const collapseNavigation = () => {
      if (document.hidden && navigationState === "hoverExpanded") {
        setNavigationState("collapsed");
      }
    };

    document.addEventListener("visibilitychange", collapseNavigation);

    return () => {
      document.removeEventListener("visibilitychange", collapseNavigation);
    };
  }, [navigationState, setNavigationState]);

  return (
    <>
      <Global
        styles={{
          ":root": {
            "--sidebar-width-collapsed": "4rem",
            "--sidebar-width-expanded": "16rem",
          },
        }}
      />

      <Flex
        direction="column"
        position="absolute"
        width={{
          base: "full",
          lg: isCollapsed
            ? "var(--sidebar-width-collapsed)"
            : "var(--sidebar-width-expanded)",
        }}
        height="full"
        paddingTop={6}
        paddingInline={3}
        bg="black"
        color="white"
        boxShadow="base"
        // The background color is whiteAlpha.200 over a black background, without the transparency that comes with whiteAlpha.200;
        // making it whiteAlpha.200 made elements show through the nav when hover expanded
        _dark={{
          bg: "#242424",
          boxShadow: isHoverExpanded ? "dark-lg" : "base",
        }}
        transition="width 0.2s ease-out"
        overflowY={isCollapsed ? "hidden" : "auto"}
        overflowX="clip"
        onMouseEnter={() => {
          if (isCollapsed) {
            setNavigationState("hoverExpanded");
          }
        }}
        onMouseLeave={() => {
          if (isCollapsed || isHoverExpanded) {
            setNavigationState("collapsed");
          }
        }}
        zIndex="sidebar"
      >
        <Stack spacing={4} shouldWrapChildren>
          <Flex
            alignItems="center"
            justifyContent="space-between"
            minWidth="max-content"
            height={5}
          >
            <Flex gap={1} marginLeft={2}>
              <StordEmblem fill="white" width="23px" />
              <Box
                opacity={isDesktop && isCollapsed ? 0 : 1}
                transition="opacity 0.2s ease-out"
              >
                <StordLogo fill="white" width="67px" />
              </Box>
            </Flex>

            {isDesktop ? (
              <Tooltip
                marginLeft={3}
                label={
                  <Flex gap={2}>
                    {isHoverExpanded ? "Pin" : "Unpin"}
                    <DarkMode>
                      <Kbd>[</Kbd>
                    </DarkMode>
                  </Flex>
                }
                placement="right"
                arrowPadding={0}
                visibility={isExpandingToPinnedState ? "hidden" : "visible"}
              >
                <IconButton
                  aria-label={`${isHoverExpanded ? "Pin" : "Unpin"} sidebar`}
                  variant="ghost"
                  colorScheme="white"
                  opacity={0.64}
                  _hover={{ opacity: 1, bgColor: "whiteAlpha.200" }}
                  icon={<FaIcon icon={faThumbtack} size="sm" />}
                  onClick={() => {
                    if (!isExpandingToPinnedState) {
                      if (isHoverExpanded) {
                        expandNav();
                      } else {
                        setNavigationState("collapsed");
                      }
                    }
                  }}
                  // Avoid being able to focus on the pin button when it's collapsed
                  visibility={isCollapsed || isUnderXL ? "hidden" : "visible"}
                  ref={pinUnpinButtonRef}
                />
              </Tooltip>
            ) : null}
          </Flex>
          <Stack spacing={1}>
            {links.primary.map((action) => (
              <GenericNavButton
                key={action.label}
                action={action}
                onParentActionClick={onParentActionClick}
                openAccordion={
                  !isDesktop ||
                  isHoverExpanded ||
                  navigationState === "expanded"
                    ? openAccordionLabel
                    : null
                }
                isDesktop={!!isDesktop}
                closeNavbarDrawerAction={closeNavbarDrawerAction}
                setOpenAccordion={setOpenAccordionLabel}
                navigationState={navigationState}
              />
            ))}
          </Stack>
        </Stack>
        <Spacer />
        <Stack spacing={1} marginTop={1}>
          {links.secondary.map((action) => (
            <GenericNavButton
              key={action.label}
              action={action}
              onParentActionClick={onParentActionClick}
              openAccordion={
                !isDesktop || isHoverExpanded || navigationState === "expanded"
                  ? openAccordionLabel
                  : null
              }
              closeNavbarDrawerAction={closeNavbarDrawerAction}
              isDesktop={!!isDesktop}
              setOpenAccordion={setOpenAccordionLabel}
              navigationState={navigationState}
            />
          ))}
        </Stack>

        <Stack spacing={2} shouldWrapChildren pb={4}>
          <MenuDivider />
          <AccountSwitcher isDesktop={!!isDesktop} />
        </Stack>
      </Flex>
    </>
  );
};
